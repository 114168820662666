import { Box, styled } from '@mui/material';

export const BuildingW = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: '0 12px',
  border: `1px solid ${theme.palette.secondary.p020}`,
  borderRadius: '4px',
  cursor: 'pointer',
  background: theme.palette.background.paper,

  '&:hover': {
    background: theme.palette.secondary.p005,
  },
}));
