import { Box, styled } from '@mui/material';

export const PageStatusW = styled(Box)`
  position: relative;
  flex-grow: 1;
  height: 100%;
`;

export const ContentWrap = styled('div')`
  height: 100%;
`;

export const Cover = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.8;
`;
