import { Divider } from '@mui/material';
import { ReactElement } from 'react';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { BuildingNotesForm } from 'components';
import { ButtonSizes } from 'components/Button/interface';
import { InquiryList } from 'features/inquiry';
import { useAuth } from 'hooks';
import { ApplyInquiry, BuildingStatus, TourInquiry } from 'types';

import { AddInquiryButton, ButtonsW, NotesWrap } from '../../styled';

interface BuildingCollapseContentProps {
  buildingId: string;
  notes: string;
  isLoading: boolean;
  handleCreateApply: () => void;
  handleOpenCreateTourModal: () => void;
  buildingStatus: BuildingStatus;
  inquiries: (TourInquiry | ApplyInquiry)[];
}

export function BuildingCollapseContent({
  buildingId,
  notes,
  handleCreateApply,
  handleOpenCreateTourModal,
  isLoading,
  buildingStatus,
  inquiries,
}: BuildingCollapseContentProps): ReactElement {
  const isDisabled = buildingStatus === BuildingStatus.NEW;
  const isDismissed = buildingStatus === BuildingStatus.DISMISSED;

  const { isReadOnlyAccess } = useAuth();

  return (
    <>
      <Divider sx={{ marginLeft: '32px' }} />
      <InquiryList inquiries={inquiries} buildingStatus={buildingStatus} />
      {!isDismissed && !isReadOnlyAccess && (
        <>
          <ButtonsW>
            <AddInquiryButton
              color="secondary"
              buttonSize={ButtonSizes.xSmall}
              onClick={handleOpenCreateTourModal}
              disabled={isDisabled}
            >
              <PlusIcon
                style={{
                  opacity: isDisabled ? 0.3 : 'unset',
                  transition: 'opacity 0.2s ease-in',
                }}
              />
              Add tour
            </AddInquiryButton>
            <AddInquiryButton
              color="secondary"
              buttonSize={ButtonSizes.xSmall}
              onClick={handleCreateApply}
              loading={isLoading}
              disabled={isDisabled}
            >
              <PlusIcon
                style={{
                  opacity: isDisabled ? 0.3 : 'unset',
                  transition: 'opacity 0.2s ease-in',
                }}
              />
              Add application
            </AddInquiryButton>
          </ButtonsW>
          <Divider sx={{ marginLeft: '40px' }} />
        </>
      )}
      <NotesWrap>
        <BuildingNotesForm isDisabled={isReadOnlyAccess} buildingId={buildingId} notes={notes} />
      </NotesWrap>
    </>
  );
}
