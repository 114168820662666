import { ReactElement, useContext } from 'react';
import { Control } from 'react-hook-form';

import { PageSectionsState } from 'features/contactDetails';
import { BuildingChatFormValues } from 'types';

import { InputW } from '../styled';
import { UserMentionInput } from './components';
import { SendButtonW } from './styled';
import { countWidth } from './utils';

interface PrivateNoteInputProps {
  control: Control<BuildingChatFormValues>;
  handleAddMention: (id: string, display: string) => void;
  isValid: boolean;
  handleFormSubmit: () => void;
}

export function PrivateNoteInput({
  control,
  handleAddMention,
  isValid,
  handleFormSubmit,
}: PrivateNoteInputProps): ReactElement {
  const pageSections = useContext(PageSectionsState);

  return (
    <InputW>
      <UserMentionInput
        control={control}
        addMention={handleAddMention}
        maxWidth={countWidth(pageSections)}
      />
      <SendButtonW isDisabled={!isValid} onClick={handleFormSubmit} />
    </InputW>
  );
}
