import { ReactElement } from 'react';

import { AddBuildingSearchInput } from 'features/buildings/components';
import { Campaign } from 'types';

import useAddBuilding from './hooks';

interface AddBuildingProps {
  campaign: Campaign;
  handleClose: () => void;
}

export const AddBuilding = ({ campaign, handleClose }: AddBuildingProps): ReactElement => {
  const {
    buildings,
    handleAddBuilding,
    enabled,
    isLoading,
    searchValue,
    setSearchValue,
    handleCloseAddBuilding,
  } = useAddBuilding({ campaign, handleClose });

  return (
    <AddBuildingSearchInput
      value={searchValue}
      enabled={enabled}
      handleClose={handleCloseAddBuilding}
      setValue={setSearchValue}
      suggestions={buildings}
      isLoading={isLoading}
      handleAddBuilding={handleAddBuilding}
    />
  );
};
