import { APP_NAVBAR_WIDTH, INFO_PANEL_WIDTH, CHAT_CONTAINER_PADDING } from '../constants';
import { getCampaignsSectionWidth } from './getCampaignsSectionWidth';

export function countWidth(pageSections?: string[]): number {
  const isCampaignsVisible = Boolean(pageSections?.includes('campaigns'));
  const isInfoPanelVisible = Boolean(pageSections?.includes('infoPanel'));

  const infoPanelWidth = isInfoPanelVisible ? INFO_PANEL_WIDTH : 0;
  const campaignsWidth = getCampaignsSectionWidth({ isInfoPanelVisible, isCampaignsVisible });

  const width =
    window.innerWidth - APP_NAVBAR_WIDTH - CHAT_CONTAINER_PADDING - infoPanelWidth - campaignsWidth;

  return width;
}
