import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import {
  BuildingSearchItem,
  Campaign,
  CampaignBuilding,
  CampaignFormValues,
  Contact,
  DashboardItems,
  DashboardTotalCounters,
  ApplyInquiry,
  TourInquiry,
  TourFormValues,
  TourDetailsFormValues,
  ApplyDetailsFormValues,
  InquiryAssignee,
  BuildingNotesFormValues,
  InquiryStatus,
  EmployeeTeam,
  Employee,
  FilterPresetList,
  FilterDescriptors,
  CampaignStatus,
  EmployeeRole,
  MessageTemplatesList,
  MessageType,
  CloseCampaignReasons,
  BuildingChat,
  TextMessageType,
  BuildingChatMessage,
  TourInquiryTableItem,
  ApplyInquiryTableItem,
} from 'types';

export interface ClientConfig extends AxiosRequestConfig {
  authorizeRequest?: boolean;
}

export type AxiosResponseSuccess<Data> = Promise<AxiosResponse<Data>>;

interface ListMetaData {
  currentPage: number;
  totalItems: number;
}

export interface ContactsRequestProps {
  pageNumber: number;
  limit: number;
  search?: string;
  contactOwner: string;
  filterId: string;
}

export interface ContactsResponseSuccess {
  items: Contact[];
  meta: ListMetaData;
}

export type ContactDetailsResponseSuccess = Contact;

export type EmployeesListResponseSuccess = {
  items: Employee[];
  meta: ListMetaData;
};

export interface EmployeeListRequestProps {
  pageNumber: number;
  limit: number;
}

export type ContactCreateRequest = Partial<Contact>;

export type ContactOwnerEditRequest = {
  ownerId: string;
  contactId: string;
};

export type ContactOwnerEditResponseSuccess = {
  owner: Contact['owner'];
  assignee: Contact['assignee'];
};

export type ContactAssigneeEditRequest = {
  assigneeId: string;
  contactId: string;
};

export interface ContactCreateParams {
  employeeId: string;
  data: Partial<Contact>;
}

export type ContactCreateResponseSuccess = Contact;

export interface AxiosResponseError extends AxiosError {
  isHandledGlobally?: boolean;
}

export interface CampaignCreateParams {
  contactId: string;
  data: CampaignCreateRequest;
}

export type CampaignCreateRequest = Omit<
  CampaignFormValues,
  'rentPrice' | 'campaignName' | 'bedrooms' | 'servicePreference' | 'amenities' | 'location'
> & {
  contactId: string;
  rentPrice: number;
  title: string;
  bedroomsCount: string;
  amenities: string;
  location?: string;
};

export type CampaignCreateResponseSuccess = Campaign;

export type CampaignsListResponseSuccess = Campaign[];

export interface ContactEditParams {
  contactId: string;
  data: Partial<Contact>;
}

export type ContactEditRequest = Partial<Contact>;

export type ContactEditResponseSuccess = Contact;

export interface CampaignEditParams {
  campaignId: string;
  data: CampaignEditRequest;
}

export type CampaignEditRequest = Partial<CampaignCreateRequest>;

export type CampaignEditResponseSuccess = Campaign;

export type BuildingListResponseSuccess = { items: BuildingSearchItem[] };

export type GetBuildingsParams = {
  search: string;
  city?: string;
  bedrooms?: string;
  minPrice?: string;
  maxPrice?: string;
};

export type AddBuildingToCampaignParams = {
  campaignId: Campaign['id'];
  buildingId: CampaignBuilding['id'];
};

export type EditBuildingParams = {
  data: BuildingNotesFormValues;
  buildingId: CampaignBuilding['id'];
  contactId: string;
};

export type DeleteBuildingParams = {
  buildingId: CampaignBuilding['id'];
  campaignId: string;
};

export type CreateTourParams = {
  data: TourFormValues;
  buildingId: CampaignBuilding['id'];
  campaignId: string;
};

export type EditTourParams = {
  data: Partial<TourFormValues>;
  id: TourInquiry['id'];
};

export type EditInquiryDetailsParams = {
  data: Partial<TourDetailsFormValues>;
  id: TourInquiry['id'];
};

export type EditApplyDetailsParams = {
  data: Partial<ApplyDetailsFormValues>;
  id: ApplyInquiry['id'];
};

export type GetInquiryTransitionStatusesParams = { id: string };

export type PatchInquiryTransitionStatusParams = { id: string; statusId: string };

export type GetInquiryTransitionStatusesSuccess = { to: InquiryStatus[] };

export type PatchCampaignTransitionStatusParams = {
  campaignId: string;
  status: CampaignStatus | CloseCampaignReasons;
};

export type GetCampaignTransitionStatusesSuccess = {
  to: Array<CampaignStatus | CloseCampaignReasons>;
};

export type CreateApplyParams = {
  campaignId: string;
  buildingId: CampaignBuilding['id'];
};

export type ChangeInquiryTransitionStatusSuccess = {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: InquiryStatus;
};

export type ChangeCampaignTransitionStatusSuccess = {
  id: string;
  updatedAt: string;
  state: CampaignStatus;
};

export type CreateTourResponseSuccess = TourInquiry & {
  building: CampaignBuilding;
  assignee: InquiryAssignee;
};

export type CreateApplyResponseSuccess = ApplyInquiry & {
  assignee: InquiryAssignee;
};

export type EditTourResponseSuccess = TourInquiry;

export type EditBuilding = CampaignBuilding;

export type DeleteBuilding = { building: CampaignBuilding | null };

export type SendBuilding = { buildings: CampaignBuilding[] };

export type EditApplyResponseSuccess = Partial<ApplyInquiry>;

export interface AssignInquiryParams {
  inquiryId: string;
  assignee: { assigneeId: string } | { teamId: string };
}

export type EmployeesTeamsListResponseSuccess = { items: EmployeeTeam[] };

export type AssignApplyResponseSuccess = InquiryAssignee;

export type AssignTourResponseSuccess = InquiryAssignee;

export type GetFilterDescriptorsResponseSuccess = FilterDescriptors;

export interface GetFiltersPresets {
  filterPresets: FilterPresetList;
}

export type PostMessageResponse = { id: string };

export interface GetCampaignBuildingDetailsParams {
  buildingId: string;
  campaignId: string;
}

export interface GetContactMessagesProps {
  contactId: string;
  targetMessageId: string | null;
  direction?: MessageRequestDirection;
  sources: MessageType[];
}

export interface DeleteEmployeeParams {
  employeeId: Employee['id'];
}

export interface DeleteEmployeeResponse extends Employee {
  teamId: string | null;
  userId: string;
}

export interface ChangeEmployeeRoleParams {
  employeeId: Employee['id'];
  roles: EmployeeRole[];
}

export type ChangeEmployeeRoleResponse = Employee;

export interface ChangeEmployeeTeamParams {
  employeeId: Employee['id'];
  teamName: EmployeeRole | null;
}

export type ChangeEmployeeTeamResponse = Employee;

export interface GetMessageTemplatesParams {
  page: number;
  limit: number;
}

export interface GetMessageTemplatesResponseSuccess {
  items: MessageTemplatesList;
  meta: ListMetaData;
}

export interface GetTemplateFieldsResponseSuccess {
  items: string[];
}

export interface SearchContactDetailsProps {
  contactId: string;
  content: string;
}

export interface GetBuildingChatsResponseSuccess {
  buildingChats: BuildingChat[];
  unreadMessages: number;
}

export interface BuildingChatMessagesRequestParams {
  contactId: string;
  buildingId: string;
  targetMessageId: string | null;
  direction?: MessageRequestDirection;
}

export interface CommunicationSearchResponseSuccess {
  messages: TextMessageType[];
}

export enum MessageRequestDirection {
  UP = 'UP',
  DOWN = 'DOWN',
}

export interface GetBuildingChatMessagesResponseSuccess {
  messages: BuildingChatMessage[];
}

export interface DashboardResponseSuccess {
  items: DashboardItems;
  counters: DashboardTotalCounters;
}

export interface DashboardRequestParams {
  search?: string;
}

export type NotificationsCountResponseSuccess = {
  notificationCount: number;
};

export type SettingsEmailSetupProps = {
  redirectTo: string;
};

export type CommunicationEmailSuccessResponse = {
  communicationEmail: string;
};

export interface InquiriesRequestParams {
  page: number;
  limit: number;
  search?: string;
  assigneeId?: string;
  statusId?: string;
}

export type TourInquiriesRequestParams = InquiriesRequestParams;

export interface TourInquiriesResponseSuccess {
  items: TourInquiryTableItem[];
  totalCount: number;
}

export interface InquiryStatesResponseSuccess {
  states: Pick<InquiryStatus, 'id' | 'name' | 'color' | 'group'>[];
}

export type TourInquiryStatesResponseSuccess = InquiryStatesResponseSuccess;

export type ApplyInquiryStatesResponseSuccess = InquiryStatesResponseSuccess;

export type ApplyInquiriesRequestParams = InquiriesRequestParams;

export interface ApplyInquiriesResponseSuccess {
  items: ApplyInquiryTableItem[];
  totalCount: number;
}
