import { useContext } from 'react';

import { SelectedBuildingIdState, useSelectBuilding } from 'features/buildings';
import {
  SelectedUnassignedBuildingIdState,
  useSelectUnassignedBuilding,
} from 'features/unassignedBuildings';
import { SelectedWishlistBuildingIdState, useSelectWishlistBuilding } from 'features/wishlist';
import { CampaignBuilding } from 'types';

export function useSelectedBuilding(): CampaignBuilding | undefined {
  const buildingId = useContext(SelectedBuildingIdState);
  const selectedBuilding = useSelectBuilding(buildingId || '');

  const selectedWishlistBuildingId = useContext(SelectedWishlistBuildingIdState);
  const selectedWishlistBuilding = useSelectWishlistBuilding(selectedWishlistBuildingId || '');

  const selectedUnassignedBuildingId = useContext(SelectedUnassignedBuildingIdState);
  const selectedUnassignedBuilding = useSelectUnassignedBuilding(
    selectedUnassignedBuildingId || '',
  );

  return (
    selectedBuilding ?? (selectedWishlistBuilding as CampaignBuilding) ?? selectedUnassignedBuilding
  );
}
