import { EMPTY_VALUE } from 'constants/index';

interface GetFullNameProps {
  firstName?: string;
  lastName?: string | null;
}

export function getFullName({ firstName, lastName }: GetFullNameProps): string {
  return `${firstName || EMPTY_VALUE} ${lastName || EMPTY_VALUE}`;
}
