import { RadioGroup, Radio, RadioGroupProps } from '@mui/material';
import { ChangeEvent, ReactElement } from 'react';

import { Campaign } from 'types';

import { ActiveCampaignItem } from '../ActiveCampaignItem';
import { FormControlLabel, CampaignsList } from './styled';

interface ActiveCampaignsListProps extends RadioGroupProps {
  activeCampaigns: Campaign[];
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

export function ActiveCampaignsList({
  activeCampaigns,
  onChange,
}: ActiveCampaignsListProps): ReactElement {
  return (
    <CampaignsList>
      <RadioGroup name="active-campaigns" onChange={onChange}>
        {activeCampaigns.map(({ id, title, createdAt }) => (
          <FormControlLabel
            key={id}
            value={id}
            control={<Radio />}
            label={<ActiveCampaignItem title={title} createdAt={createdAt} />}
          />
        ))}
      </RadioGroup>
    </CampaignsList>
  );
}
