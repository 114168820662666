import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import { ReactElement, useState } from 'react';

import { EMPTY_VALUE } from 'constants/index';
import { useAuth } from 'hooks';

import { arrowIconStyles, editIconStyles } from '../constants';
import {
  DataW,
  IconButtonW,
  SectionHeader,
  SectionTitle,
  SectionW,
  Title,
  Value,
  ValueItemsW,
} from '../styled';
import { LinkW } from './styled';

interface DetailsSectionProps<T> {
  title: string;
  initialOpen: boolean;
  data: T;
  labelsConfig: { [key: string]: string };
  isEditable?: boolean;
  openModal?: () => void;
  lighthouseId?: string;
}

export function DetailsSection<T>({
  title,
  initialOpen,
  data,
  labelsConfig,
  isEditable,
  openModal,
  lighthouseId,
}: DetailsSectionProps<T>): ReactElement {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const toggleSectionOpen = () => setIsOpen(!isOpen);

  const { isReadOnlyAccess } = useAuth();

  const isLinkDisabled = lighthouseId === EMPTY_VALUE;

  function handleAnalyticLinkClick(e: React.MouseEvent<HTMLElement>) {
    if (isLinkDisabled) {
      e.preventDefault();
    }
  }

  return (
    <SectionW>
      <SectionHeader>
        <SectionTitle>{title}</SectionTitle>

        {isEditable && !isReadOnlyAccess && (
          <IconButtonW onClick={openModal}>
            <CreateRoundedIcon sx={editIconStyles} />
          </IconButtonW>
        )}
      </SectionHeader>

      {isOpen && (
        <ValueItemsW>
          {Object.keys(labelsConfig).map((key) => (
            <DataW key={key}>
              <Title>{labelsConfig[key]}</Title>
              <Value>{data[key as keyof T]}</Value>
            </DataW>
          ))}
          {title === 'Contact info' && (
            <DataW key="links">
              <Title>Analytics</Title>
              <Value>
                <LinkW
                  isDisabled={isLinkDisabled}
                  onClick={handleAnalyticLinkClick}
                  href={`https://analytics.amplitude.com/lightinc/project/287961/search/${lighthouseId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Amplitude
                </LinkW>
                <LinkW
                  isDisabled={isLinkDisabled}
                  onClick={handleAnalyticLinkClick}
                  href={`https://analytics.june.so/a/2922/contact/${lighthouseId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  June
                </LinkW>
              </Value>
            </DataW>
          )}
        </ValueItemsW>
      )}

      <ArrowRightRoundedIcon
        sx={{
          ...arrowIconStyles,
          position: 'absolute',
          transform: isOpen ? 'rotate(90deg)' : 'rotate(0)',
        }}
        onClick={toggleSectionOpen}
      />
    </SectionW>
  );
}
