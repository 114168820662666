import { ReactElement, useState } from 'react';

import { BuildingChat as BuildingChatType } from 'types';

import { BuildingChatsList, BuildingChat } from '../../components';
import { ChatInfo } from '../../types';

interface BuildingChatsContainerProps {
  buildingChats: BuildingChatType[];
  handleRefetch: () => void;
  contactId: string;
  readMessage: (messageId: string) => void;
}

export function BuildingChatsContainer({
  buildingChats,
  handleRefetch,
  contactId,
  readMessage,
}: BuildingChatsContainerProps): ReactElement {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [chatInfo, setChatInfo] = useState<ChatInfo>(null);

  function handleOpenChat(chatInfo: ChatInfo) {
    setIsChatOpen(true);
    setChatInfo(chatInfo);
  }

  function handleCloseChatItem() {
    setIsChatOpen(false);
    handleRefetch();
  }

  return (
    <>
      {isChatOpen && chatInfo ? (
        <BuildingChat
          chatInfo={chatInfo}
          handleCloseChatItem={handleCloseChatItem}
          contactId={contactId}
          readMessage={readMessage}
        />
      ) : (
        <BuildingChatsList buildingChats={buildingChats} handleOpenChat={handleOpenChat} />
      )}
    </>
  );
}
