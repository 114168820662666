import { Box, styled, Typography } from '@mui/material';

export const ListW = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.light,
  fontSize: '12px',
  lineHeight: '16px',
  marginBottom: '8px',
}));
