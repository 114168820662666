import { Box, styled } from '@mui/material';

export const ListWrapper = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '32px',
    height: '1px',
    width: 'calc(100% - 32px)',
  },
}));
