import { ContactOwnerAndAssigneeFormValues, Contact } from 'types';

interface DefaultValuesAdapterProps {
  contact?: Contact;
}

export function defaultValuesAdapter({
  contact,
}: DefaultValuesAdapterProps): ContactOwnerAndAssigneeFormValues {
  return {
    assigneeId: contact?.assignee?.id || '',
    ownerId: contact?.owner?.id || '',
  };
}
