import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Picker, { DatePickerProps as PickerProps } from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ReactElement, useState } from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';

import { TextField } from 'components';

import { textFieldStyles } from './stylesConfig';

interface DatePickerProps<T = FieldValues>
  extends UseControllerProps<T>,
    Omit<PickerProps, 'onChange' | 'value' | 'renderInput'> {
  label?: string;
  placeholder?: string;
  showAllErrors?: boolean;
}

export function DatePicker<T>({
  label,
  placeholder,
  name,
  control,
  minDate,
  rules,
  showAllErrors,
  disabled,
  ...datePickerProps
}: DatePickerProps<T>): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, ref, onBlur, value }, fieldState: { error, isTouched } }) => (
          <Picker
            {...datePickerProps}
            minDate={minDate}
            inputFormat="MM/dd/yyyy"
            mask="__/__/____"
            label={label}
            value={value}
            onChange={onChange}
            onClose={() => setIsOpen(false)}
            open={isOpen}
            disabled={disabled}
            renderInput={({ ...otherProps }) => {
              return (
                <TextField
                  {...otherProps}
                  disabled
                  forwardRef={ref}
                  name={name}
                  size="small"
                  onBlur={onBlur}
                  onClick={disabled ? undefined : () => setIsOpen(true)}
                  error={(showAllErrors || isTouched) && error ? true : false}
                  helperText={(showAllErrors || isTouched) && error?.message}
                  placeholder={placeholder}
                  sx={textFieldStyles(disabled)}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  onKeyPress={(e) => {
                    e.preventDefault();
                  }}
                />
              );
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
