import TabContext from '@mui/lab/TabContext';
import { Box, ButtonProps, TabProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

import { Tab, TabList } from './styled';

interface TabItem {
  label: ReactNode;
  value: string;
}

interface TabsProps {
  handleChange: (value: string) => void;
  value: string;
  tabs: TabItem[];
  tabSx?: TabProps['sx'];
  tabListSx?: ButtonProps['sx'];
}

export function Tabs({ handleChange, value, tabs, tabSx, tabListSx }: TabsProps): ReactElement {
  const changeTab = (_: unknown, newValue: string) => {
    handleChange(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={changeTab} sx={tabListSx}>
            {tabs.map(({ label, value }) => (
              <Tab key={value} label={label} value={value} sx={tabSx} />
            ))}
          </TabList>
        </Box>
      </TabContext>
    </Box>
  );
}
