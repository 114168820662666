import { ReactElement, useCallback, useContext } from 'react';

import { selectBuilding } from 'features/buildings';
import { OpenCreateTourContext } from 'features/inquiry/context';
import { useDispatch } from 'hooks';
import { CampaignBuilding, Campaign } from 'types';

import { BuildingListItem } from '../../components';
import { ListW } from './styled';

interface BuildingsListProps {
  campaignId: Campaign['id'];
  buildings: CampaignBuilding[];
}

export function BuildingsList({ campaignId, buildings }: BuildingsListProps): ReactElement {
  const dispatch = useDispatch();

  const handleOpenCreateTourModal = useContext(OpenCreateTourContext);

  const handleSelectBuilding = useCallback(
    (buildingId: CampaignBuilding['id']) => dispatch(selectBuilding({ campaignId, buildingId })),
    [campaignId, dispatch],
  );

  return (
    <ListW showBorder={!!buildings.length}>
      {buildings.map((building, idx) => (
        <BuildingListItem
          key={building.id}
          campaignId={campaignId}
          handleOpenCreateTourModal={() => handleOpenCreateTourModal(building.id, campaignId)}
          handleSelectBuilding={handleSelectBuilding}
          isFirstInArray={idx === 0}
          {...building}
          parentId={campaignId}
        />
      ))}
    </ListW>
  );
}
