import { styled } from '@mui/material';

export const CommunicationsBoxW = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TabsW = styled('div')`
  padding-left: 24px;
`;
