import { TourDetailsFormValues, TourInquiry, UnassignedBuildingScheduleTourInquiry } from 'types';

export function getTourDetailsDefaultValues(
  tour: TourInquiry | UnassignedBuildingScheduleTourInquiry,
): Partial<TourDetailsFormValues> {
  return {
    guestCardSent: tour.guestCardSent || false,
    confirmedTourDate: tour.confirmedTourDate || '',
    confirmedTourTime: tour.confirmedTourTime || '',
    pricingAndAvailability: tour.pricingAndAvailability || '',
    commentResponse: tour.commentResponse || '',
    notes: tour.notes || '',
  };
}
