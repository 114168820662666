import { AxiosResponse } from 'axios';
import { UseMutateAsyncFunction, useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { ContactOwnerEditRequest, ContactOwnerEditResponseSuccess } from 'api/types';

interface UseEditContactOwnerMutationReturn {
  editContactOwnerMutation: UseMutateAsyncFunction<
    AxiosResponse<ContactOwnerEditResponseSuccess, unknown>,
    unknown,
    ContactOwnerEditRequest,
    unknown
  >;
}

export function useEditContactOwnerMutation(): UseEditContactOwnerMutationReturn {
  const { mutateAsync: editContactOwnerMutation } = useMutation(
    API_HANDLERS.CONTACT.EDIT_CONTACT_OWNER,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contact');
      },
    },
  );

  return {
    editContactOwnerMutation,
  };
}
