import { ReactElement, useContext, useMemo } from 'react';

import { CampaignsState } from 'features/campaigns';
import { EventPayloadBuildingDismissed } from 'types';

import { Link, TextBold } from './styled';

interface BuildingDismissedContentProps {
  data: EventPayloadBuildingDismissed;
  onBuildingClick: (buildingId: string, campaignId?: string) => void;
  onCampaignClick: (campaignId: string) => void;
  isLinkToPage?: boolean;
}

export function BuildingDismissedContent({
  data,
  onBuildingClick,
  onCampaignClick,
  isLinkToPage = false,
}: BuildingDismissedContentProps): ReactElement {
  const { building, campaign } = data;

  const campaigns = useContext(CampaignsState);

  const { buildings } = useMemo(() => campaigns?.[campaign.id] || [], [campaign.id, campaigns]);

  const isBuildingExisting = useMemo(
    () => buildings?.some(({ id }) => id === building.id),
    [building.id, buildings],
  );

  return (
    <span>
      {isBuildingExisting || isLinkToPage ? (
        <Link onClick={() => onBuildingClick(building.id, campaign.id)}>{building.name}</Link>
      ) : (
        <TextBold>{building.name}</TextBold>
      )}{' '}
      was dismissed in <Link onClick={() => onCampaignClick(campaign.id)}>{campaign.name}</Link>
    </span>
  );
}
