import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { FunctionComponent } from 'react';

import { ReactComponent as AllSourcesIcon } from 'assets/icons/dashboardFilters/allInclusive.svg';
import { ReactComponent as BuildingIcon } from 'assets/icons/dashboardFilters/building.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/dashboardFilters/email.svg';
import { ReactComponent as PrivateNoteIcon } from 'assets/icons/dashboardFilters/privateMessage.svg';
import { ReactComponent as SystemMessageIcon } from 'assets/icons/dashboardFilters/systemMessage.svg';
import { ReactComponent as TextMessageIcon } from 'assets/icons/dashboardFilters/textMessage.svg';

import { DashboardFilterValues } from './../../config';

export const SOURCE_ICONS: Record<DashboardFilterValues, FunctionComponent> = {
  [DashboardFilterValues.ALL]: AllSourcesIcon,
  [DashboardFilterValues.SMS]: TextMessageIcon,
  [DashboardFilterValues.EMAIL]: EmailIcon,
  [DashboardFilterValues.PRIVATE_NOTES]: PrivateNoteIcon,
  [DashboardFilterValues.BUILDING_CHATS]: BuildingIcon,
  [DashboardFilterValues.REQUEST_MESSAGES]: AssignmentTurnedInOutlinedIcon,
  [DashboardFilterValues.OTHER_SYSTEM_MESSAGES]: SystemMessageIcon,
};
