import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { queryClient } from 'api';
import {
  AxiosResponseSuccess,
  GetInquiryTransitionStatusesSuccess,
  PatchInquiryTransitionStatusParams,
} from 'api/types';
import { $FixTypeLater, InquiryStateGroup, InquiryStatus } from 'types';

interface UseChangeInquiryStatusParams<R> {
  requestFn: (params: { id: string }) => AxiosResponseSuccess<GetInquiryTransitionStatusesSuccess>;
  changeRequestFn: (params: PatchInquiryTransitionStatusParams) => AxiosResponseSuccess<R>;
  inquiryId: string;
  status: string;
}

interface UseChangeInquiryStatusReturn {
  closeTransitions: InquiryStatus[];
  defaultTransitions: InquiryStatus[];
  handleChangeStatus: (status: InquiryStatus['id']) => void;
  showOptions: boolean;
  setFocused: (focused: boolean) => void;
  isLoading: boolean;
}

export function useChangeInquiryStatus<R>({
  inquiryId,
  changeRequestFn,
  requestFn,
  status,
}: UseChangeInquiryStatusParams<R>): UseChangeInquiryStatusReturn {
  const [focused, setFocused] = useState(false);

  const { data, isLoading: isGetStatusesListLoading } = useQuery(
    [`inquiry/status-transitions/${inquiryId}/${status}`, inquiryId],
    () =>
      requestFn({
        id: inquiryId,
      }),
    {
      enabled: focused,
      cacheTime: 0,
    },
  );

  const { mutateAsync, isLoading: isStatusChangeLoading } = useMutation(
    changeRequestFn as (args: $FixTypeLater) => AxiosResponseSuccess<R>,
    {
      onSuccess() {
        queryClient.invalidateQueries('contact');
      },
    },
  );

  const availableTransitions = data?.data;

  const showOptions = focused && !isGetStatusesListLoading && !!availableTransitions?.to?.length;

  const handleChangeStatus = (statusId: InquiryStatus['id']) => {
    mutateAsync({ id: inquiryId, statusId });
  };

  const closeTransitions = useMemo(
    () => availableTransitions?.to?.filter((to) => to.group === InquiryStateGroup.TERMINAL) || [],
    [availableTransitions],
  );

  const defaultTransitions = useMemo(
    () => availableTransitions?.to?.filter((to) => to.group !== InquiryStateGroup.TERMINAL) || [],
    [availableTransitions],
  );

  return {
    closeTransitions,
    defaultTransitions,
    handleChangeStatus,
    showOptions,
    setFocused,
    isLoading: isGetStatusesListLoading || isStatusChangeLoading,
  };
}
