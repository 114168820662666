import { useState } from 'react';

export interface UseSearchReturn {
  search: string;
  onSetSearch: (newValue: string) => void;
  onClear: (changeOffset: changeOffsetFn) => void;
}

type changeOffsetFn = (offset: number) => void;

export function useSearch(initialState: string): UseSearchReturn {
  const [search, setSearch] = useState(initialState);

  const handleClearSearch = (changeOffset: changeOffsetFn) => {
    setSearch('');
    changeOffset(0);
  };

  return {
    search,
    onSetSearch: setSearch,
    onClear: handleClearSearch,
  };
}
