import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, MenuItem, MenuList, styled, Typography } from '@mui/material';

export const FieldW = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: '15px 24px 17px 40px',
}));

export const CloseIcon = styled(CloseRoundedIcon)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.secondary.main,
}));

export const OptionsContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: 'calc(100% - 64px)',
  maxHeight: '256px',
  overflowY: 'auto',
  top: `calc(100% - ${theme.spacing(2)})`,

  zIndex: 5000,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[6],
}));

export const OptionsList = styled(MenuList)(({ theme }) => ({
  padding: `${theme.spacing(1)} 0`,
}));

export const BuildingItem = styled(MenuItem)(({ theme }) => ({
  display: 'block',
  padding: '10px 10px 10px 16px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.p010,
  },
}));

export const BuildingTitle = styled(Typography)(() => ({
  wordBreak: 'break-all',
  whiteSpace: 'break-spaces',
}));

export const BuildingAddress = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.light,
  wordBreak: 'break-all',
  whiteSpace: 'break-spaces',
}));
