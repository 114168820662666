import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { styled, Typography } from '@mui/material';

export const FileItemW = styled('div')`
  width: 300px;
  overflow: hidden;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;
  border-radius: 4px;

  &:hover {
    background: ${({ theme }) => theme.palette.secondary.p010};
  }
`;

export const Thumb = styled('div')`
  width: 44px;
  height: 44px;
  overflow: hidden;
  border-radius: 4px;
`;

export const StyledImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const FileName = styled(Typography)`
  max-width: 150px;
  line-height: 16px;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.black.main};
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
`;

export const FileSize = styled('span')`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.black.p050};
`;

export const CancelIcon = styled(CancelRoundedIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const SectionLeft = styled('div')`
  display: flex;
  align-items: center;
`;

export const MetaSection = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
`;
