import { EMPTY_VALUE } from 'constants/index';
import { CAMPAIGN_ISSUES } from 'features/campaigns/constants';
import { Campaign, CampaignDetails } from 'types';
import { getFormattedDate, toCapitalize } from 'utils';

import { arrayValuesToStringWithComma } from '../utils';

export function campaignDetailsAdapter(data?: Campaign): CampaignDetails {
  return {
    title: data?.title || EMPTY_VALUE,
    id: data?.id || '',
    servicePreference: 'Guided search',
    city: data?.city || EMPTY_VALUE,
    location: data?.location || EMPTY_VALUE,
    bedroomsCount: data?.bedroomsCount ? toCapitalize(data.bedroomsCount) : EMPTY_VALUE,
    rentPrice: data?.rentPrice ? `$${data?.rentPrice}` : EMPTY_VALUE,
    moveInDate: data?.moveInDate ? getFormattedDate(new Date(data.moveInDate)) : EMPTY_VALUE,
    issues: data?.issues
      ? arrayValuesToStringWithComma(CAMPAIGN_ISSUES, data?.issues)
      : EMPTY_VALUE,
    amenities: data?.amenities || EMPTY_VALUE,
    notes: data?.notes || EMPTY_VALUE,
  };
}
