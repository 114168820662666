import { Socket, io } from 'socket.io-client';

import { API_HOST } from 'settings';

import { WS_STREAMS } from './constants';

interface CreateSocketConnectionProps {
  stream: WS_STREAMS;
  params?: {
    contactId: string;
  };
}

export function createSocketConnection({ stream, params }: CreateSocketConnectionProps): Socket {
  const ACCESS_TOKEN = 'accessToken';

  const WEB_SOCKET_HOST = `${API_HOST}/${stream}`;

  const socket = io(WEB_SOCKET_HOST, {
    reconnectionDelayMax: 10000,
    extraHeaders: {
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
    },
  });

  socket.on('open', () => {
    console.log(`WS CLIENT ${stream}: Open on ${WEB_SOCKET_HOST}`);
  });
  socket.on('reconnect', (attempt) => {
    console.log(`WS CLIENT ${stream}: Reconnected`, attempt);
  });
  socket.on('reconnect_attempt', (attempt) => {
    console.log(`WS CLIENT ${stream}: Attempting to reconnect`, attempt);
  });

  socket.emit('stream', params);

  return socket;
}
