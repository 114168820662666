import { EMPTY_VALUE } from 'constants/index';

interface GetFullNameShortProps {
  firstName: string;
  lastName?: string;
}

export function getFullNameShort({ firstName, lastName }: GetFullNameShortProps): string {
  return `${firstName} ${lastName ? `${lastName.split('')[0]}.` : EMPTY_VALUE}`;
}
