import { useEffect, useRef } from 'react';

export function useWatcher(subs: Array<() => unknown>, dep: unknown): void {
  const depRef = useRef(dep);

  useEffect(() => {
    if (depRef.current !== dep) {
      subs.forEach((sub) => sub());
    }

    depRef.current = dep;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...subs, dep]);
}
