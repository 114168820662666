import { useCallback, useState } from 'react';

import {
  InquiryStateGroup,
  UnassignedBuildingApplyInquiry,
  UnassignedBuildingScheduleTourInquiry,
} from 'types';

interface UseUnassignedBuildingInquiryItemProps {
  inquiry: UnassignedBuildingApplyInquiry | UnassignedBuildingScheduleTourInquiry;
  handleSelectInquiry: (id: string) => void;
}

interface UseUnassignedBuildingInquiryItemReturn {
  isExpanded: boolean;
  isTour: boolean;
  statusName: string;
  reason: string;
  handleToggle: () => void;
}

export function useUnassignedBuildingInquiryItem({
  inquiry,
  handleSelectInquiry,
}: UseUnassignedBuildingInquiryItemProps): UseUnassignedBuildingInquiryItemReturn {
  const [isExpanded, setIsExpanded] = useState(false);

  const isTour = inquiry.inquiryType === 'tour';
  const reason = inquiry.status?.group === InquiryStateGroup.TERMINAL ? inquiry.status?.name : '';
  const statusName =
    inquiry.status.group === InquiryStateGroup.TERMINAL ? 'Closed' : inquiry.status.name;

  const handleToggle = useCallback(() => {
    if (!isExpanded) {
      handleSelectInquiry(inquiry.id);
    }

    setIsExpanded((state) => !state);
  }, [handleSelectInquiry, inquiry.id, isExpanded]);

  return { isExpanded, isTour, statusName, reason, handleToggle };
}
