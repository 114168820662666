import { SelectSub } from 'types';

type Subs<T> = Record<string, T>;

class EntitySelectHandler {
  private scrollQueue: string[] = [];

  private subs: Subs<SelectSub> = {};

  setQueue(queue: string[]) {
    this.scrollQueue = queue;
    this.performScroll();
  }

  clearQueue() {
    this.scrollQueue = [];
  }

  subscribe(sub: SelectSub) {
    this.subs[sub.id] = sub;

    this.performScroll();

    return () => this.unsubscribe(sub.id);
  }

  private unsubscribe(subId: string) {
    delete this.subs[subId];
  }

  private findSub(queueItem: string): SelectSub | undefined {
    return this.subs[queueItem];
  }

  private performScroll() {
    if (!this.scrollQueue.length) {
      return;
    }

    for (let id = 0; id < this.scrollQueue.length; id++) {
      const queueItem = this.scrollQueue[id];

      const sub = this.findSub(queueItem);

      if (sub) {
        this.setQueue(this.scrollQueue.slice(0, id));

        id === 0 ? sub.select() : sub.open();

        break;
      }
    }
  }
}

const entitySelectHandler = new EntitySelectHandler();

export * from './utils';
export default entitySelectHandler;
