import { TableRow } from '@mui/material';
import { ReactElement } from 'react';

import { StatusButton, TableComponents } from 'components';
import {
  DATE_FORMAT_ONLY_TIME,
  DATE_FORMAT_WITH_TIME_AND_COMMA_SHORT,
  DEFAULT_DATE_FORMAT_SHORT,
  EMPTY_VALUE_SHORT,
} from 'constants/index';
import { getContactOwnerTableValue } from 'features/contacts/utils';
import { tourTimeOptions } from 'features/inquiry/components/TourDetails/components/DetailsCard/constants';
import { TourInquiryTableItem } from 'types';
import { getFormattedDate } from 'utils';

type TourRowProps = TourInquiryTableItem;

export function TourRow({
  id,
  contact,
  building,
  tourDate,
  tourTime,
  status,
  confirmedTourDate,
  confirmedTourTime,
  user,
  createdAt,
  updatedAt,
}: TourRowProps): ReactElement {
  const { Td } = TableComponents;

  return (
    <TableRow key={id}>
      <Td>
        {contact.firstName} {contact.lastName}
      </Td>
      <Td>{building.name}</Td>
      <Td>{getFormattedDate(new Date(tourDate), DEFAULT_DATE_FORMAT_SHORT)}</Td>
      <Td>{tourTimeOptions[tourTime]}</Td>
      <Td>
        <StatusButton title={status.name} withIcon={false} colorScheme={status.color} isDisabled />
      </Td>
      <Td>
        {confirmedTourDate
          ? getFormattedDate(new Date(confirmedTourDate), DEFAULT_DATE_FORMAT_SHORT)
          : EMPTY_VALUE_SHORT}
      </Td>
      <Td>
        {confirmedTourTime
          ? getFormattedDate(new Date(confirmedTourTime), DATE_FORMAT_ONLY_TIME)
          : EMPTY_VALUE_SHORT}
      </Td>
      <Td>
        {getContactOwnerTableValue({
          picture: user.picture,
          familyName: user.familyName || undefined,
          givenName: user.givenName,
        })}
      </Td>
      <Td>{getFormattedDate(new Date(createdAt), DATE_FORMAT_WITH_TIME_AND_COMMA_SHORT)}</Td>
      <Td>{getFormattedDate(new Date(updatedAt), DATE_FORMAT_WITH_TIME_AND_COMMA_SHORT)}</Td>
    </TableRow>
  );
}
