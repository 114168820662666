import { ReactElement } from 'react';

import { DATE_FORMAT_WITH_TIME_AND_COMMA } from 'constants/index';
import { servicePreferenceProps } from 'features/campaigns/components/CampaignListItem/config';
import { Campaign, ServicePreferences } from 'types';
import { getFormattedDateWithTime } from 'utils';

import { DescriptionWrapper, Title, DateCaption, ServicePreferencesBlock } from './styled';

interface ActiveCampaignItemProps {
  title: Campaign['title'];
  createdAt: Campaign['createdAt'];
}

export function ActiveCampaignItem({ title, createdAt }: ActiveCampaignItemProps): ReactElement {
  return (
    <>
      <Title variant="body2">{title}</Title>

      <DescriptionWrapper>
        <ServicePreferencesBlock {...servicePreferenceProps[ServicePreferences.GuidedSearch]} />
        <DateCaption variant="body2">
          {getFormattedDateWithTime(new Date(createdAt), DATE_FORMAT_WITH_TIME_AND_COMMA)}
        </DateCaption>
      </DescriptionWrapper>
    </>
  );
}
