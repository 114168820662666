import { Box, styled } from '@mui/material';

export const Preview = styled(Box)<{ previewUrl?: string }>(({ theme }) => ({
  width: '68px',
  height: '68px',
  borderRadius: '4px',
  marginRight: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.p020,
  position: 'relative',
  overflow: 'hidden',
}));

export const SourceIconW = styled(Box)`
  position: absolute;
  bottom: -3px;
  right: -3px;
  background: #fff;
  width: 24px;
  height: 24px;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageW = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;
