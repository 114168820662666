import { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { API_HANDLERS } from 'api/apiHandlers';
import { AxiosResponseError } from 'api/types';
import { EmptyStateArgs } from 'components/PageStatus';
import { SelectedCampaignIdState, useSelectedCampaign } from 'features/campaigns';
import { loadContact } from 'features/contactDetails/context/actions';
import { getPageTitle } from 'features/contactDetails/utils';
import { useDispatch, useModal } from 'hooks';
import { ModalControl } from 'hooks/useModal';
import { Campaign, CampaignBuilding, Contact } from 'types';

import { ContactState } from '../../../context/ContactDetailsPageContext';
import { getEmptyStateData } from '../utils';

interface UseContactDetailsReturn {
  handleGoBack: () => void;
  isLoading: boolean;
  emptyState: EmptyStateArgs;
  pageTitle: string;
  contact?: Contact;
  selectedCampaign?: Campaign;
  selectedBuilding?: CampaignBuilding;
  editCampaignModalControl: ModalControl;
  editContactModalControl: ModalControl;
}

export function useContactDetails(): UseContactDetailsReturn {
  const navigate = useNavigate();

  const contact = useContext(ContactState);

  const campaignId = useContext(SelectedCampaignIdState) || '';

  const selectedCampaign = useSelectedCampaign(campaignId || '');

  const dispatch = useDispatch();

  const { contactId = '' } = useParams<string>();

  const editContactModalControl = useModal();

  const editCampaignModalControl = useModal();

  const {
    isLoading: isContactDetailsLoading,
    error: contactError,
    data: contactData,
  } = useQuery(['contact', contactId], () => API_HANDLERS.CONTACT.DETAILS.GET(contactId));

  useEffect(() => {
    if (!contactData?.data) {
      return;
    }

    dispatch(loadContact(contactData.data));
  }, [contactData, dispatch]);

  const handleGoBack = () => navigate(-1);

  const emptyState = getEmptyStateData(contactError as AxiosResponseError);

  return {
    handleGoBack,
    isLoading: isContactDetailsLoading,
    emptyState,
    pageTitle: getPageTitle({
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      phone: contact?.phone,
    }),
    editCampaignModalControl,
    editContactModalControl,
    contact,
    selectedCampaign,
  };
}
