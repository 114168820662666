import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { MenuItem, styled } from '@mui/material';

export const MenuItemW = styled(MenuItem)`
  padding: 10px 16px;
  font-size: 14px;
`;

export const ListSubheaderW = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  padding: '10px 16px',
  fontSize: '14px',

  '&:hover': {
    background: theme.palette.secondary.p010,
  },
}));

export const GroupNameW = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const FolderName = styled('div')(() => ({
  width: '115px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const OptionsList = styled('div')(() => ({
  top: '0',
  left: '10px',
}));

export const ArrowIcon = styled(ArrowForwardIosRoundedIcon)<{ isOpen: boolean }>(
  ({ theme, isOpen }) => ({
    width: '13px',
    color: theme.palette.secondary.main,
    transform: `rotate(${isOpen ? '90' : '0'}deg)`,
  }),
);
