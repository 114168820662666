import ChatIcon from '@mui/icons-material/Chat';
import { ReactElement } from 'react';

import { ToggleButton } from './styled';

interface OpenChatButtonProps {
  isSelected: boolean;
  onChange: () => void;
}

export function OpenChatButton({ isSelected, onChange }: OpenChatButtonProps): ReactElement {
  return (
    <ToggleButton value="selected" selected={isSelected} onChange={onChange} centerRipple>
      <ChatIcon />
    </ToggleButton>
  );
}
