import { ClickAwayListener } from '@mui/material';
import { ReactElement, useState } from 'react';

import { TextField, EmptyStateSearch } from 'components';
import { BuildingSearchItem, CampaignBuilding } from 'types';

import { BuildingSearchSuggestion } from './BuildingSearchSuggestion';
import { CloseIcon, FieldW, OptionsContainer, OptionsList } from './styled';

interface AddBuildingSearchInputProps {
  value: string;
  setValue: (v: string) => void;
  suggestions: BuildingSearchItem[];
  handleAddBuilding: (id: CampaignBuilding['id']) => void;
  isLoading: boolean;
  enabled: boolean;
  handleClose: () => void;
}

export const AddBuildingSearchInput = ({
  value,
  setValue,
  suggestions,
  handleAddBuilding,
  isLoading,
  enabled,
  handleClose,
}: AddBuildingSearchInputProps): ReactElement => {
  const [isFocused, setFocused] = useState(false);

  const showSuggestions = isFocused && enabled && !isLoading;

  return (
    <ClickAwayListener onClickAway={() => setFocused(false)}>
      <FieldW>
        <TextField
          label={value || isFocused ? 'Building' : ''}
          placeholder="Enter building name or address"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onFocus={() => setFocused(true)}
          autoComplete="off"
        />

        {showSuggestions && (
          <OptionsContainer>
            {suggestions.length ? (
              <OptionsList>
                {suggestions.map((building) => (
                  <BuildingSearchSuggestion
                    key={building.id}
                    building={building}
                    handleAddBuilding={handleAddBuilding}
                  />
                ))}
              </OptionsList>
            ) : (
              <EmptyStateSearch title="Couldn't find anything" description="Please try again" />
            )}
          </OptionsContainer>
        )}

        <CloseIcon onClick={handleClose} />
      </FieldW>
    </ClickAwayListener>
  );
};
