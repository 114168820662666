import { Divider, styled } from '@mui/material';

export const DividerW = styled(Divider)`
  width: 100%;
`;

export const IconsW = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const ThreadW = styled('div')`
  position: relative;
  width: 100%;
`;
