import { theme } from 'styles';

export const MenuProps = {
  PaperProps: {
    style: {
      width: 180,
    },
  },
};

export const inputProps = {
  style: { fontSize: 14 },
};

export const selectStyles = {
  color: theme.palette.black.main,
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '.MuiSelect-select': {
    padding: '6px 10px 6px 0',
    fontSize: '14px',
  },
  '.Mui-disabled': {
    cursor: 'not-allowed',
  },
};
