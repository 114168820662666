import { Box, styled, Typography } from '@mui/material';

export const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const IconW = styled(Box)`
  margin-bottom: 24px;
`;

export const Title = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.palette.black.main};
`;

export const Description = styled(Typography)`
  max-width: 575px;
  color: ${({ theme }) => theme.palette.black.main};
`;

export const EmptySearchStateW = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

export const TitleSearch = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.palette.black.main};
`;

export const DescriptionSearch = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.black.light};
`;
