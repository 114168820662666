import { styled } from '@mui/material';
import { Box } from '@mui/system';

export const selectFieldSX = {
  minWidth: '100px',
  width: '376px',

  input: {
    height: '30px',
    padding: '5px 0',
  },
};

export const OptionsContainer = styled(Box)<{ largePaddings: boolean }>(
  ({ theme, largePaddings }) => ({
    position: 'absolute',
    width: '476px',
    top: '100%',
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '28px',
    maxHeight: '554px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: largePaddings ? '20px 24px' : '5px 8px',
    zIndex: 5000,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[6],
  }),
);
