import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { API_HANDLERS } from 'api/apiHandlers';
import { useDispatch } from 'hooks';
import { UnassignedBuilding } from 'types';

import { setUnassignedBuildings } from '../context';

interface UseUnassignedBuildingsQueryReturn {
  unassignedBuildings: UnassignedBuilding[];
  isUnassignedBuildingsLoading: boolean;
}

export function useUnassignedBuildingsQuery(): UseUnassignedBuildingsQueryReturn {
  const { contactId = '' } = useParams<string>();
  const dispatch = useDispatch();

  const { data, isLoading } = useQuery(
    ['unassigned-buildings', contactId],
    () => API_HANDLERS.UNASSIGNED_BUILDINGS.GET(contactId),
    {
      onSuccess: ({ data }) => {
        if (!!data.items.length) {
          dispatch(setUnassignedBuildings({ buildings: data.items }));
        }
      },
    },
  );

  return {
    unassignedBuildings: data?.data.items || [],
    isUnassignedBuildingsLoading: isLoading,
  };
}
