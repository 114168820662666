import { ListItem as ListItemMUI, List as ListMUI, styled } from '@mui/material';

export const ListItem = styled(ListItemMUI)(({ theme }) => ({
  marginBottom: '16px',
  padding: 0,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.black.main,
}));

export const List = styled(ListMUI)(() => ({
  width: '100%',
  maxHeight: '120px',
  marginTop: '16px',
  padding: '0 32px 0 0',
  overflowY: 'auto',
}));
