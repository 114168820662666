import * as yup from 'yup';

import {
  getMaxLengthText,
  NAME_PATTER_SYMBOLS,
  NO_MULTIPLE_SPACES_ALLOWED,
} from 'validations/messages';
import { LETTERS_DIGITS_SYMBOLS } from 'validations/regexps';
import { noMultipleSpaces } from 'validations/tests';

export const campaignNotesFormSchema = yup.object().shape({
  notes: yup
    .string()
    .matches(LETTERS_DIGITS_SYMBOLS, NAME_PATTER_SYMBOLS)
    .test('notes', NO_MULTIPLE_SPACES_ALLOWED, noMultipleSpaces)
    .max(1000, getMaxLengthText(1000))
    .nullable(),
});
