import isToday from 'date-fns/isToday';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import * as yup from 'yup';

import { INVALID_PHONE_NUMBER, PHONE_FORMAT } from './messages';
import { MULTIPLE_SPACES_REGEX } from './regexps';

export const isMoreThanStartYear = (value: Date): boolean => value.getFullYear() > 1899;

export const isLessThanYearLimit = (value: Date): boolean => value.getFullYear() < 2100;

export const isInYearLimits = (value: Date): boolean =>
  isLessThanYearLimit(value) && isMoreThanStartYear(value);

export const isValidDate = (value?: Date): boolean => {
  if (value && (isNaN(value?.getTime()) || !isInYearLimits(value))) {
    return false;
  }

  return true;
};

export const onlyFutureDate = (value?: Date): boolean => {
  if (!value || ((value >= new Date() || isToday(value)) && isValidDate(value))) {
    return true;
  }
  return false;
};

export const requiredArray = (v: unknown[] = []): boolean => (v?.length ? true : false);

export function validatePhoneNumber(
  value: string | undefined,
  { createError }: yup.TestContext,
): yup.ValidationError | boolean {
  const phoneUtil = PhoneNumberUtil.getInstance();

  try {
    const phoneNumber = phoneUtil.parse(value);
    const formattedPhone = phoneUtil.format(phoneNumber, PhoneNumberFormat.E164);

    const ifValidPhoneNumberForRegion = phoneUtil.isValidNumber(phoneNumber);
    const isValidE164 = value === formattedPhone;

    if (!ifValidPhoneNumberForRegion) {
      return createError({ message: INVALID_PHONE_NUMBER });
    }

    if (!isValidE164) {
      return createError({ message: PHONE_FORMAT });
    }

    return true;
  } catch (e) {
    return createError({ message: INVALID_PHONE_NUMBER });
  }
}

export function noMultipleSpaces(value: string | undefined): boolean {
  return !MULTIPLE_SPACES_REGEX.test(value || '');
}
