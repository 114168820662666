import { ReactElement } from 'react';

import { Button, DropdownButton } from 'components';
import { ButtonSizes } from 'components/Button/interface';

import { NESTING_LEVEL_LIMIT } from '../FilterForm/config';
import { AddFilterBtnW } from './styled';

interface AddFilterButtonProps {
  nestingLevel: number;
  appendFilterFields: () => void;
  appendFilterGroup: () => void;
}

export function AddFilterButton({
  nestingLevel,
  appendFilterFields,
  appendFilterGroup,
}: AddFilterButtonProps): ReactElement {
  const dropdownMenuItems = [
    {
      label: 'Add filter',
      id: 'filter',
      onClick: appendFilterFields,
    },
    {
      label: 'Add filter group',
      id: 'group',
      onClick: appendFilterGroup,
    },
  ];

  return (
    <AddFilterBtnW>
      {NESTING_LEVEL_LIMIT > nestingLevel ? (
        <DropdownButton
          color="secondary"
          buttonSize={ButtonSizes.xSmall}
          menuItems={dropdownMenuItems}
        >
          Add filter preset or filter group
        </DropdownButton>
      ) : (
        <Button color="secondary" buttonSize={ButtonSizes.xSmall} onClick={appendFilterFields}>
          Add filter
        </Button>
      )}
    </AddFilterBtnW>
  );
}
