import { ReactElement } from 'react';

import { PageWrap, Tabs } from 'components';

import { CrmIntegrations } from '../CrmIntegrations';
import { FiltersPreset } from '../FiltersPreset';
import { MessageTemplates } from '../MessageTemplates';
import { SettingsTabs, SettingsTabsValues } from './config';
import { useSettingsPage } from './hooks';

export function SettingsPage(): ReactElement {
  const {
    tab,
    currentPage,
    pageLimit,
    handleTabChange,
    setCurrentPage,
    setPageLimit,
    setEmailConfigParam,
  } = useSettingsPage();

  return (
    <PageWrap helmet={<title>Settings</title>} title="Settings">
      <Tabs tabs={SettingsTabs} value={tab} handleChange={handleTabChange} />

      {tab === SettingsTabsValues.FILTERS && <FiltersPreset />}

      {tab === SettingsTabsValues.INTEGRATIONS && (
        <CrmIntegrations setEmailConfigParam={setEmailConfigParam} />
      )}

      {tab === SettingsTabsValues.TEMPLATES && (
        <MessageTemplates
          currentPage={currentPage}
          pageLimit={pageLimit}
          setCurrentPage={setCurrentPage}
          setPageLimit={setPageLimit}
        />
      )}
    </PageWrap>
  );
}
