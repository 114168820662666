import { ReactElement } from 'react';

import { TourInquiry, UnassignedBuildingScheduleTourInquiry } from 'types';

import { TourDetailsForm, DetailsCard } from './components';

interface TourDetailsProps {
  tour: TourInquiry | UnassignedBuildingScheduleTourInquiry;
  handleOpenEditTourModal?: () => void;
  isUnassignedBuilding?: boolean;
}

export function TourDetails({
  tour,
  handleOpenEditTourModal,
  isUnassignedBuilding = false,
}: TourDetailsProps): ReactElement {
  return (
    <>
      <DetailsCard
        guestCardRequested={tour.guestCardRequested}
        pricingAndAvailabilityRequested={tour.pricingAndAvailabilityRequested}
        tourDate={tour.tourDate.toString()}
        tourTime={tour.tourTime}
        tourType={tour.tourType}
        comment={tour.comment || undefined}
        handleOpenEditTourModal={handleOpenEditTourModal}
      />
      <TourDetailsForm tour={tour} isDisabled={isUnassignedBuilding} />
    </>
  );
}
