import { ReactComponent as LockIcon } from 'assets/icons/communication/lock.svg';
import { ReactComponent as BuildingChatIcon } from 'assets/icons/communication/privateMessage.svg';
import { createStyledMessageIcon } from 'components/MessageItem/styled';
import { $FixTypeLater, BuildingChatItemType } from 'types';

export const MESSAGE_TYPE_LABELS: Partial<Record<BuildingChatItemType, string>> = {
  [BuildingChatItemType.OUTGOING_MESSAGE]: 'Building chat',
  [BuildingChatItemType.PRIVATE_NOTES]: 'Private note',
};

export const MESSAGE_TYPE_ICONS: Partial<Record<BuildingChatItemType, $FixTypeLater>> = {
  [BuildingChatItemType.OUTGOING_MESSAGE]: createStyledMessageIcon(BuildingChatIcon),
  [BuildingChatItemType.PRIVATE_NOTES]: createStyledMessageIcon(LockIcon),
};
