import { ReactElement, useState } from 'react';
import PlacesAutocomplete, { Suggestion } from 'react-places-autocomplete';

import { TextInputProps } from 'components/Fields/TextField';

import { searchOptions } from './config';
import PlacesSearchField from './PlacesSearchField';
import { getFormattedCityString } from './utils';

export const PlacesSearchBar = ({
  onChange,
  value,
  onBlur,
  disabled,
  ...props
}: TProps): ReactElement => {
  const [fieldValue, setFieldValue] = useState(value || '');

  const fieldOnChange = (v: string): void => setFieldValue(v);

  const fieldOnBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    onBlur && onBlur(e);
    setFieldValue(value);
  };

  const onSelect = (_: string, __: unknown, suggestion: Suggestion): void => {
    const city = getFormattedCityString(suggestion);
    onChange(city);
    setFieldValue(city);
  };

  return (
    <PlacesAutocomplete
      value={fieldValue as string}
      onChange={fieldOnChange}
      onSelect={onSelect as (...args: unknown[]) => void}
      searchOptions={searchOptions}
    >
      {({ suggestions, getInputProps, getSuggestionItemProps }) => (
        <PlacesSearchField
          {...props}
          suggestions={suggestions}
          getInputProps={getInputProps}
          getSuggestionItemProps={getSuggestionItemProps}
          disabled={disabled}
          onBlur={fieldOnBlur}
        />
      )}
    </PlacesAutocomplete>
  );
};

type TProps = TextInputProps & {
  onChange: (event: React.ChangeEvent<HTMLInputElement> | string) => void;
  value: string;
};
