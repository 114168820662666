import { Box, IconButton } from '@mui/material';
import { ReactElement } from 'react';

import { ReactComponent as ConfigViewIcon } from 'assets/icons/setupView.svg';
import { CheckboxGroup } from 'components';
import Popover from 'components/Popover';

import { sectionOptions } from './constants';
import { useSetPageSectionsForm } from './useSetPageSectionsForm';

export function PageSectionsForm(): ReactElement {
  const { control } = useSetPageSectionsForm();

  return (
    <Popover
      popupId="page-views-config"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchor={
        <IconButton>
          <ConfigViewIcon />
        </IconButton>
      }
      closeOnContainerClick={false}
    >
      {() => (
        <Box sx={{ padding: '29px 15px', borderRadius: '4px' }}>
          <CheckboxGroup control={control} name="sections" options={sectionOptions} />
        </Box>
      )}
    </Popover>
  );
}
