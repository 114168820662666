import { ReactElement, useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Socket } from 'socket.io-client';

import { createSocketConnection } from 'api';
import { WS_STREAMS } from 'api/webSocket/constants';
import { GeneralChatState } from 'features/communications/context';
import { useDispatch } from 'hooks';
import {
  Message,
  BuildingChatMessage,
  MessageSourceType,
  WsCommunicationChannel as WsChannel,
  WsCommunicationChannel,
} from 'types';

import { ChatTabs } from '../../components';
import { ChatTabsValues } from '../../config';
import { putBuildingChatMessage, putMessage } from '../../context/actions';
import { getMessageSourceArrayFromString } from '../../utils';
import { BuildingChatsContainer } from '../BuildingChatsContainer';
import { GeneralChat } from '../GeneralChat';
import { TabsConfig } from './config';
import { useBuildingsChatQuery } from './hooks';
import { CommunicationsBoxW, TabsW } from './styled';
import { isMessageTypeShown } from './utils';

interface BuildingChatsCount {
  count: number;
}

interface CommunicationsBoxProps {
  dashboardContactId?: string;
}

export function CommunicationsBox({ dashboardContactId }: CommunicationsBoxProps): ReactElement {
  const [tab, setTab] = useState<string>(ChatTabsValues.GENERAL);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [messageTypes, setMessageTypes] = useState<MessageSourceType[]>([]);
  const [buildingChatsCounter, setBuildingChatsCounter] = useState(0);
  const { sources } = useContext(GeneralChatState);

  const { contactId: contactIdFromParams = '' } = useParams<string>();

  const contactId = dashboardContactId || contactIdFromParams;
  const prevContactId = useRef(contactId);

  const dispatch = useDispatch();

  const { buildingChats, unreadMessages, handleRefetch } = useBuildingsChatQuery({
    contactId,
  });

  useEffect(() => {
    setBuildingChatsCounter(unreadMessages);
  }, [unreadMessages]);

  useEffect(() => {
    setMessageTypes(getMessageSourceArrayFromString(sources) || []);
  }, [sources]);

  const handleNewGeneralChatMessage = useCallback(
    (e: Message) => {
      if (isMessageTypeShown(e.type, messageTypes)) {
        dispatch(putMessage({ message: e }));
      }
    },
    [dispatch, messageTypes],
  );

  const handleNewBuildingChatMessage = useCallback(
    (e: BuildingChatMessage) => {
      dispatch(putBuildingChatMessage({ message: e }));
    },
    [dispatch],
  );

  const markAsReadGeneralChatMessage = useCallback(
    (messageId: string) => {
      socket?.emit(WsCommunicationChannel.ACKNOWLEDGE_GENERAL_CHAT_ITEMS, {
        communicationItemIds: [messageId],
      });
    },
    [socket],
  );

  const markAsReadBuildingChatMessage = useCallback(
    (messageId: string) => {
      socket?.emit(WsCommunicationChannel.ACKNOWLEDGE_BUILDING_CHAT_ITEMS, {
        buildingChatItemIds: [messageId],
      });
    },
    [socket],
  );

  const initSocketConnection = useCallback(
    (contactId: string) => {
      const newSocket = createSocketConnection({
        stream: WS_STREAMS.communicationStream,
        params: { contactId },
      });
      setSocket(newSocket);

      newSocket.on(WsChannel.NEW_COMMUNICATION_ITEM, (e: Message) =>
        handleNewGeneralChatMessage(e),
      );

      newSocket.on(WsChannel.NEW_BUILDING_CHAT_ITEM, (e: BuildingChatMessage) =>
        handleNewBuildingChatMessage(e),
      );

      newSocket.on(WsChannel.BUILDING_CHATS_COUNTER_UPDATED, (e: BuildingChatsCount) => {
        setBuildingChatsCounter(e.count);
      });
    },
    [handleNewBuildingChatMessage, handleNewGeneralChatMessage],
  );

  useEffect(() => {
    if (!socket && Boolean(contactId)) {
      initSocketConnection(contactId);
    }

    if (socket && contactId !== prevContactId.current) {
      socket.close();
      setSocket(null);

      initSocketConnection(contactId);
      prevContactId.current = contactId;
    }

    return () => {
      socket?.close();
    };
  }, [contactId, initSocketConnection, socket]);

  return (
    <CommunicationsBoxW>
      <TabsW>
        <ChatTabs
          tabs={TabsConfig}
          value={tab}
          handleChange={setTab}
          unreadMessagesCount={buildingChatsCounter}
        />
      </TabsW>

      {tab === ChatTabsValues.GENERAL && socket && (
        <GeneralChat contactId={contactId} readMessage={markAsReadGeneralChatMessage} />
      )}
      {tab === ChatTabsValues.BUILDINGS && socket && (
        <BuildingChatsContainer
          buildingChats={buildingChats}
          handleRefetch={handleRefetch}
          contactId={contactId}
          readMessage={markAsReadBuildingChatMessage}
        />
      )}
    </CommunicationsBoxW>
  );
}
