import { ReactComponent as BuildingIcon } from 'assets/icons/communication/building.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/communication/item-email.svg';
import { ReactComponent as LockIcon } from 'assets/icons/communication/lock.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/communication/message.svg';
import { $FixTypeLater, BuildingChatItemType, MessageType, SystemMessageCampaignKeys } from 'types';

import { createStyledMessageIcon } from './styled';

export const MESSAGE_TYPE_LABELS: Partial<Record<MessageType, string>> = {
  [MessageType.OUTGOING_SMS]: 'SMS',
  [MessageType.OUTGOING_EMAIL]: 'Email',
  [MessageType.PRIVATE_NOTES]: 'Private note',
};

export const MESSAGE_TYPE_ICONS: Partial<Record<MessageType, $FixTypeLater>> = {
  [MessageType.PRIVATE_NOTES]: createStyledMessageIcon(LockIcon),
  [MessageType.OUTGOING_SMS]: createStyledMessageIcon(MessageIcon),
  [MessageType.OUTGOING_EMAIL]: createStyledMessageIcon(EmailIcon),
};

export const CAMPAIGN_FIELDS_DISPLAY_NAMES: Record<SystemMessageCampaignKeys, string> = {
  title: 'Campaign name',
  moveInDate: 'Move in date',
  city: 'City',
  location: 'Location',
  bedroomsCount: 'Bedrooms',
  rentPrice: 'Rent price',
  amenities: 'Amenities',
  notes: 'Notes',
  issues: 'Issues',
};

export const BUILDING_CHAT_MESSAGE_ICONS: Partial<Record<BuildingChatItemType, $FixTypeLater>> = {
  [BuildingChatItemType.PRIVATE_NOTES]: createStyledMessageIcon(LockIcon),
  [BuildingChatItemType.INCOMING_MESSAGE]: createStyledMessageIcon(BuildingIcon),
  [BuildingChatItemType.OUTGOING_MESSAGE]: createStyledMessageIcon(BuildingIcon),
};

export const BUILDING_MESSAGE_TITLE: Record<BuildingChatItemType, string> = {
  [BuildingChatItemType.PRIVATE_NOTES]: 'Private note',
  [BuildingChatItemType.OUTGOING_MESSAGE]: 'Buildings chat',
  [BuildingChatItemType.INCOMING_MESSAGE]: 'Buildings chat',
};
