import {
  FormControl,
  styled,
  MenuItem as MenuItemMUI,
  ListItemText as ListItemTextMUI,
} from '@mui/material';

import { Checkbox as CheckboxOrigin } from 'components';

export const SelectLabel = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  position: 'absolute',
  left: 8,
  top: '50%',
  transform: 'translateY(-50%)',
  fontSize: 14,
}));

export const FormControlW = styled(FormControl)(({ theme }) => ({
  width: 200,
  position: 'relative',
  marginLeft: '20px',
  background: theme.palette.secondary.p010,
  borderRadius: 4,
  '& div[aria-expanded="true"], div[aria-expanded="true"] > span': {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const MenuItem = styled(MenuItemMUI)(() => ({
  padding: '10px 16px',
}));

export const Checkbox = styled(CheckboxOrigin)(() => ({
  padding: '0 9px 0 0',
}));

export const ListItemText = styled(ListItemTextMUI)(() => ({
  fontSize: '14px',
}));
