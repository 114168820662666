import { CircularProgress } from '@mui/material';
import { createContext, ReactElement, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { LoaderWrapper } from 'components';
import { CONTACT_DETAILS_PAGE_SECTIONS } from 'constants/index';
import { useSessionState } from 'hooks';
import { Links, Paths } from 'settings';
import { ContactOwner, Employee } from 'types';
import { getURLParams } from 'utils';

import { Page } from './styled';
import { exchangeGoogleToken, isReadOnlyRole } from './utils';
import { isAdminRole } from './utils/isAdminRole';

interface User {
  picture: string;
  name: string;
  givenName: string;
  familyName?: string;
  email: string;
}

export interface AuthContextValues {
  user: User | null;
  logout: () => void;
  isAuthorized: boolean;
  employee: Employee | null;
  isReadOnlyAccess: boolean;
  isAdmin?: boolean;
}

const initialAuthContext = {
  user: null,
  logout: () => {},
  isAuthorized: false,
  employee: null,
  isReadOnlyAccess: false,
};

export const AuthContext = createContext<AuthContextValues>(initialAuthContext);

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps): ReactElement | null {
  const urlParams = getURLParams();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);

  const [token, setToken] = useSessionState('accessToken');
  const [user, setUser] = useSessionState<null | User>('user', null);
  const [employee, setEmployee] = useSessionState<null | ContactOwner>('employee');
  const [_, setContactDetailsSections] = useSessionState<string[]>('sections');

  // We've just been redirected from Google OAuth page
  // get token from URL
  const googleAccessToken = urlParams.access_token;
  const isRedirectedFromOAuth = !token && !!googleAccessToken;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isRedirectPage = pathname === Links.redirect;

  const logout = () => {
    setUser(null);
    setToken(null);
    setIsAuthorized(false);
    setContactDetailsSections(CONTACT_DETAILS_PAGE_SECTIONS);
    window.location.replace(Paths.login);
  };

  useEffect(() => {
    if (isRedirectedFromOAuth) {
      setLoading(true);
      // authorize client by making a call to API
      exchangeGoogleToken(googleAccessToken)
        .then((res) => {
          // this is an API issued Bearer token
          setUser(res.user);
          setToken(res.accessToken);
          setEmployee(res.employee);
          navigate(Links.index, { replace: true });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [googleAccessToken, isRedirectedFromOAuth, navigate, setEmployee, setToken, setUser]);

  if (loading || isRedirectPage) {
    return (
      <Page>
        <LoaderWrapper>
          <CircularProgress size={48} />
        </LoaderWrapper>
      </Page>
    );
  }

  if (user) {
    return (
      <AuthContext.Provider
        value={{
          user,
          isAuthorized,
          logout,
          employee,
          isReadOnlyAccess: employee ? isReadOnlyRole(employee) : true,
          isAdmin: isAdminRole(employee),
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }

  return <AuthContext.Provider value={initialAuthContext}>{children}</AuthContext.Provider>;
}
