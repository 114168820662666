import { ReactElement } from 'react';

import { EventPayloadListSent } from 'types';

import { Link } from './styled';

interface ListSentContentProps {
  data: EventPayloadListSent;
  onCampaignClick: (campaignId: string) => void;
}

export function ListSentContent({ data, onCampaignClick }: ListSentContentProps): ReactElement {
  const { campaign } = data;

  return (
    <span>
      List was sent for <Link onClick={() => onCampaignClick(campaign.id)}>{campaign.name}</Link>
    </span>
  );
}
