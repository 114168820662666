import { Chip, styled } from '@mui/material';

import { MessageType } from 'types';

import { getMessageBgColor } from './utils';

export const NewMessageBlockW = styled('div', {
  shouldForwardProp: (propname) => propname !== 'messageType',
})<{ messageType: MessageType }>(({ theme, messageType }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '16px 16px 18px',
  borderTop: `1px solid ${theme.palette.secondary.p020}`,
  transition: 'background 0.2s',
  background: getMessageBgColor(theme, messageType),
}));

export const ChangeMessageTypeW = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: '5px',
}));

export const ChipsW = styled(ChangeMessageTypeW)`
  width: 96px;
`;

export const StyledChip = styled(Chip, {
  shouldForwardProp: (propname) => propname !== 'isSelected',
})<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  color: isSelected ? theme.palette.white.main : theme.palette.black.main,
  background: isSelected ? theme.palette.primary.main : theme.palette.secondary.p020,

  '&:hover': {
    background: isSelected ? theme.palette.primary.main : theme.palette.secondary.p030,
  },
}));
