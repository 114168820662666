import { Employee, EmployeeRole } from 'types';

export function filterEmployeesByRole(
  employees: Employee[],
  allowedRoles: EmployeeRole[],
): Employee[] {
  return (
    employees?.filter((employee) => employee.roles.some((role) => allowedRoles.includes(role))) ||
    []
  );
}
