import { Box, styled, Typography } from '@mui/material';

export const Wrap = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 40px;
`;

export const PageText = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.light,
  marginTop: theme.spacing(0.5),
}));
