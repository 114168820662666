import { Box, styled } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as LogoIcon } from 'assets/icons/logoWithText.svg';
import { ReactComponent as SignInImage } from 'assets/images/signIn.svg';
import { alert, Button } from 'components';
import { API_ERRORS } from 'constants/index';
import { useAuth } from 'hooks';
import { Links, OAUTH_CLIENT_ID, REDIRECT_URI } from 'settings';

const SignInPageW = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const ImageW = styled(Box)`
  margin: 50px 0;
`;

export function SignInPage(): ReactElement {
  const { user } = useAuth();

  const [disabled, setDisabled] = useState(false);

  const { state } = useLocation();

  const navigate = useNavigate();

  if (user) {
    navigate(Links.contacts.index);
  }

  useEffect(() => {
    setDisabled(false);

    if (state && state.unauthorized) {
      alert.error(API_ERRORS['401']);
    }

    navigate('', { state: null });
  }, [navigate, state]);

  const redirectToAuthPage = () => {
    const SCOPES = 'email profile';
    const RESPONSE_TYPE = 'token';

    window.location.assign(
      `https://accounts.google.com/o/oauth2/auth?client_id=${OAUTH_CLIENT_ID}&response_type=${RESPONSE_TYPE}&redirect_uri=${REDIRECT_URI}&scope=${SCOPES}&prompt=select_account%20consent`,
    );
    setDisabled(true);
  };

  return (
    <SignInPageW>
      <LogoIcon />
      <ImageW>
        <SignInImage />
      </ImageW>
      <div>
        <Button disabled={disabled} onClick={redirectToAuthPage}>
          Login with Google
        </Button>
      </div>
    </SignInPageW>
  );
}
