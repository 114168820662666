import { Box, Grid, MenuItem, styled } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';

import {
  Form,
  FormGroup,
  TextField,
  Button,
  DatePicker,
  CheckboxGroup,
  PlacesSearchBar,
  ControlledTextField,
  SideModal,
} from 'components';
import { AMENITIES_LIST } from 'features/campaigns/constants';
import { useAuth } from 'hooks';
import { BaseFormMode, CampaignFormDefaultValues, CampaignFormValues } from 'types';
import { getOptionsArrayFromStringArray, preventEmptySpaceInput, stripNonDigits } from 'utils';

import { AmenitiesSelect } from '../AmenitiesSelect';
import { issuesOptions, bedroomsOptions } from './constants';
import { useCampaignForm } from './hooks';

const SubmitButton = styled(Button)`
  margin-bottom: 40px;
`;

interface CampaignFormProps {
  title: string;
  defaultValues: CampaignFormDefaultValues;
  handleClose: () => void;
  onSubmit: (values: CampaignFormValues) => Promise<void>;
  mode: BaseFormMode;
  isOpen: boolean;
}

export function CampaignForm({
  title,
  defaultValues,
  onSubmit,
  handleClose,
  mode,
  isOpen,
}: CampaignFormProps): ReactElement {
  const isEditMode = mode === 'edit';

  const {
    handleOnSubmit,
    control,
    disableSubmit,
    isSubmitting,
    destroyForm,
    withConfirmClose,
    handleSetValue,
  } = useCampaignForm({
    defaultValues,
    onSubmit,
    handleClose,
    isEditMode,
  });

  const { isReadOnlyAccess } = useAuth();

  return (
    <SideModal
      isOpen={isOpen}
      onClose={destroyForm}
      title={title}
      withConfirmClose={withConfirmClose}
    >
      <Form onSubmit={handleOnSubmit}>
        <div>
          <FormGroup marginbottom={24}>
            <ControlledTextField
              control={control}
              name="campaignName"
              id="campaignName"
              label="Campaign name"
              placeholder="Campaign name"
              showAllErrors={isEditMode}
              disabled={isReadOnlyAccess}
            />
          </FormGroup>
          <Grid sx={{ width: 176 }}>
            <FormGroup>
              <DatePicker
                name="moveInDate"
                label="Move in date"
                minDate={new Date()}
                control={control}
                showAllErrors={isEditMode}
                disabled={isReadOnlyAccess}
              />
            </FormGroup>
          </Grid>
          <FormGroup>
            <Controller
              name="city"
              control={control}
              render={({
                field: { ref, onChange, value, ...otherProps },
                fieldState: { error, isTouched },
              }) => (
                <PlacesSearchBar
                  id="city"
                  label="City"
                  placeholder="City name"
                  onChange={onChange}
                  value={value}
                  forwardRef={ref}
                  error={!!error}
                  helperText={(isEditMode || isTouched) && !!error ? error.message : ''}
                  disabled={isReadOnlyAccess}
                  {...otherProps}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <ControlledTextField
              name="location"
              id="location"
              label="Location"
              placeholder="Location"
              control={control}
              showAllErrors={isEditMode}
              disabled={isReadOnlyAccess}
            />
          </FormGroup>
          <FormGroup marginbottom={24} row={true}>
            <Box sx={{ width: '176px' }}>
              <ControlledTextField
                select
                id="bedrooms"
                label="Bedrooms"
                placeholder="Bedrooms"
                control={control}
                name="bedrooms"
                showAllErrors={isEditMode}
                disabled={isReadOnlyAccess}
              >
                {bedroomsOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value} sx={{ fontSize: '14px' }}>
                    {label}
                  </MenuItem>
                ))}
              </ControlledTextField>
            </Box>
            <Box sx={{ width: '176px' }}>
              <Controller
                name="rentPrice"
                control={control}
                render={({
                  field: { ref, onChange, value, ...otherProps },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="price"
                    label="Rent price"
                    placeholder="Rent price"
                    onChange={(e) => {
                      onChange(stripNonDigits(e.target.value));
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'e' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                    value={value}
                    error={!!error}
                    helperText={error?.message}
                    forwardRef={ref}
                    InputLabelProps={{
                      shrink: !!value,
                      // TODO: move styles inside parent component (and to use colors from theme)
                      style: {
                        fontSize: 14,
                        marginLeft: value ? '0' : '15px',
                      },
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      style: { fontSize: 14 },
                    }}
                    disabled={isReadOnlyAccess}
                    {...otherProps}
                  />
                )}
              />
            </Box>
          </FormGroup>

          <FormGroup>
            <CheckboxGroup
              control={control}
              label="Issues"
              name="issues"
              options={issuesOptions}
              showAllErrors={isEditMode}
              disabled={isReadOnlyAccess}
            />
          </FormGroup>

          <FormGroup>
            <Controller
              name="amenities"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <AmenitiesSelect
                  label="Amenities"
                  name="amenities"
                  placeholder="Search for specific amenities"
                  inputRef={ref}
                  options={getOptionsArrayFromStringArray(AMENITIES_LIST)}
                  onChange={onChange}
                  value={value}
                  handleDelete={(clickedValue) => () =>
                    handleSetValue(
                      'amenities',
                      value.filter((amenity) => amenity.value !== clickedValue),
                    )}
                  isDisabled={isReadOnlyAccess}
                />
              )}
            />
          </FormGroup>

          <FormGroup>
            <ControlledTextField
              name="notes"
              multiline
              id="notes"
              label="Notes"
              placeholder="Notes"
              control={control}
              showAllErrors={isEditMode}
              disabled={isReadOnlyAccess}
              onChange={(event) =>
                handleSetValue('notes', preventEmptySpaceInput(event.target.value))
              }
            />
          </FormGroup>
        </div>

        <Box display="flex" justifyContent="flex-end">
          <SubmitButton
            type="submit"
            disabled={disableSubmit || isReadOnlyAccess}
            loading={isSubmitting}
          >
            {isEditMode ? 'Save changes' : 'Create campaign'}
          </SubmitButton>
        </Box>
      </Form>
    </SideModal>
  );
}
