import { EMPTY_VALUE } from 'constants/index';
import { AcquisitionData } from 'types';

import { ACQUISITION_CHANNEL } from '../constants';

export function acquisitionDataAdapter(
  acquisitionData?: AcquisitionData,
  cameFrom?: string,
  externalId?: string,
): AcquisitionData {
  return {
    signUpLink: acquisitionData?.signUpLink || EMPTY_VALUE,
    acquisitionChannel: cameFrom ? ACQUISITION_CHANNEL[cameFrom] : EMPTY_VALUE,
    lighthouseId: acquisitionData?.lighthouseId || externalId || EMPTY_VALUE,
    stytchId: acquisitionData?.stytchId || EMPTY_VALUE,
    referredBy: acquisitionData?.referredBy || EMPTY_VALUE,
    gAId: acquisitionData?.gAId || EMPTY_VALUE,
    source: acquisitionData?.source || EMPTY_VALUE,
    campaign: acquisitionData?.campaign || EMPTY_VALUE,
    term: acquisitionData?.term || EMPTY_VALUE,
    medium: acquisitionData?.medium || EMPTY_VALUE,
    userIP: acquisitionData?.userIP || EMPTY_VALUE,
    device: acquisitionData?.device || EMPTY_VALUE,
    gclId: acquisitionData?.gclId || EMPTY_VALUE,
  };
}
