import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  padding: '0 0 24px 24px',
}));

export const ButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
  marginTop: '12px',
}));
