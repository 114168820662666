import { styled } from '@mui/material';
import { ReactElement } from 'react';
import { Props } from 'react-select';

import { AmenityChipItem } from '../AmenityChipItem';

export const ListWrapper = styled('div')`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

interface AmenitiesListProps {
  amenities: Props['value'];
  handleDelete: (value: string) => () => void;
}

export function AmenitiesChipsList({
  amenities,
  handleDelete,
}: AmenitiesListProps): ReactElement | null {
  if (Array.isArray(amenities)) {
    return (
      <ListWrapper>
        {amenities?.map(({ value, label }) => (
          <AmenityChipItem key={value} value={value} label={label} onDelete={handleDelete} />
        ))}
      </ListWrapper>
    );
  }

  return null;
}
