export function debounceFunction<
  P extends Parameters<() => void>,
  T extends (...args: P) => unknown,
>(fn: T, delay: number): (...args: P) => void {
  let timer: NodeJS.Timeout;

  const debouncedFn = (...args: P) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };

  return debouncedFn;
}
