import { BoxProps } from '@mui/material';
import { ReactElement, FunctionComponent, SVGProps, ReactNode } from 'react';

import { ReactComponent as InquirySourceIcon } from 'assets/icons/buildingSource/inquiry.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/buildingSource/logo.svg';
import { ReactComponent as ManualCreationSourceIcon } from 'assets/icons/buildingSource/manualCreation.svg';
import { ReactComponent as SadListSourceIcon } from 'assets/icons/buildingSource/sadList.svg';
import { ReactComponent as WishlistSourceIcon } from 'assets/icons/buildingSource/wishlist.svg';
import { BuildingSource } from 'types';

import { ImageW, Preview, SourceIconW } from './styled';

const sourceIconsVariant: Record<BuildingSource, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  [BuildingSource.MANUAL_CREATION]: ManualCreationSourceIcon,
  [BuildingSource.SAD_LIST]: SadListSourceIcon,
  [BuildingSource.WISHLIST]: WishlistSourceIcon,
  [BuildingSource.INQUIRY]: InquirySourceIcon,
  [BuildingSource.DEFAULT]: LogoIcon,
};

interface BuildingPreviewProps {
  source: BuildingSource;
  photo?: string;
  previewSx?: BoxProps['sx'];
  icon?: ReactNode;
}

export function BuildingPreview({
  source,
  photo,
  previewSx,
  icon,
}: BuildingPreviewProps): ReactElement {
  const Icon = sourceIconsVariant[source];

  return (
    <Preview previewUrl={photo} sx={previewSx}>
      {photo && <ImageW src={photo} alt="building" />}
      {source && !icon && (
        <SourceIconW>
          <Icon />
        </SourceIconW>
      )}
      {icon && <SourceIconW>{icon}</SourceIconW>}
    </Preview>
  );
}
