import { styled, Box } from '@mui/material';

export const PageW = styled(Box)`
  display: grid;
  grid-template-areas:
    'header'
    'content';
  grid-template-columns: 100%;
  grid-template-rows: 60px minmax(0, 1fr);
  height: 100%;
`;

export const MainContentW = styled(Box)`
  grid-area: content;
  display: flex;
  justify-content: flex-end;
`;

export const CommunicationsW = styled('div')`
  flex-grow: 1;
`;

export const CampaignsSection = styled(Box)<{ hasExtendedWidth: boolean }>(
  ({ theme, hasExtendedWidth }) => ({
    display: 'flex',
    flexFlow: 'column',

    width: hasExtendedWidth ? '524px' : '400px',
    height: '100%',
    borderLeft: `1px solid ${theme.palette.secondary.p020}`,
    background: theme.palette.background.paper,
    overflowY: 'auto',
    overflowX: 'hidden',
  }),
);
