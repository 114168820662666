import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { API_HANDLERS } from 'api/apiHandlers';
import { BuildingsState } from 'features/buildings';
import { ApplyInquiryState, ToursState } from 'features/inquiry';
import { ApplyInquiryP, CampaignBuildingP, TourInquiryP, TextMessageType } from 'types';
import { sortByKey } from 'utils';

import { searchBuildings, searchInquiry } from '../utils';

interface UseDetailsSearchReturn {
  inquiries: (TourInquiryP | ApplyInquiryP)[];
  buildings: CampaignBuildingP[];
  withFoundItems: boolean;
  messages: TextMessageType[];
}

export function useDetailsSearch(search: string): UseDetailsSearchReturn {
  const { allItems } = useContext(BuildingsState);
  const applyInquiriesState = useContext(ApplyInquiryState);
  const toursState = useContext(ToursState);

  const [messages, setMessage] = useState<TextMessageType[]>([]);

  const { contactId = '' } = useParams<string>();

  const { mutateAsync: searchContactDetails } = useMutation(API_HANDLERS.CONTACT.SEARCH_DETAILS);

  useEffect(() => {
    if (search) {
      async function fetchData() {
        const result = await searchContactDetails({ contactId, content: search });
        setMessage(result.data.messages);
      }

      fetchData();
    }
  }, [contactId, search, searchContactDetails]);

  const inquiries = useMemo(() => {
    if (!search) {
      return [];
    }

    const inquiriesList = (
      Object.values(applyInquiriesState) as Array<TourInquiryP | ApplyInquiryP>
    ).concat(Object.values(toursState));

    const filtered = searchInquiry(inquiriesList, search);

    return sortByKey(filtered, 'createdAt', 'desc');
  }, [toursState, applyInquiriesState, search]);

  const buildings = useMemo(() => {
    if (!search) {
      return [];
    }

    const filtered = searchBuildings(allItems, search);

    const reducedByCampaign = filtered.reduce((acc, building) => {
      acc[`${building.parentId}/${building.id}`] = building;
      return acc;
    }, {} as { [key: string]: CampaignBuildingP });

    return sortByKey(Object.values(reducedByCampaign), 'createdAt', 'desc');
  }, [allItems, search]);

  const withFoundItems = !!inquiries.length || !!buildings.length || !!messages.length;

  return {
    inquiries,
    buildings,
    messages,
    withFoundItems,
  };
}
