import { MessageType } from 'types';

export const getMessageWidth = (pageSections: string[], type: MessageType): string => {
  const isCampaignsVisible = Boolean(pageSections?.includes('campaigns'));
  const isInfoPanelVisible = Boolean(pageSections?.includes('infoPanel'));

  const isEmail = type === MessageType.OUTGOING_EMAIL || type === MessageType.INCOMING_EMAIL;

  if (isCampaignsVisible && !isInfoPanelVisible) {
    return isEmail ? '680px' : '520px';
  }

  if (!isCampaignsVisible && isInfoPanelVisible) {
    return '720px';
  }

  if (!isCampaignsVisible && !isInfoPanelVisible) {
    return '1044px';
  }

  return isEmail ? '680px' : '320px';
};
