import {
  InquiryAssignee,
  InquiryStateColor,
  InquiryStateGroup,
  InquiryStatus,
  InquiryType,
} from 'types';

import { WithParent } from './utility';

export interface TourFormValues {
  guestCardRequested: boolean;
  pricingAndAvailabilityRequested: boolean;
  tourType: TourTypes;
  tourDate: string;
  tourTime: TourTimes;
  comment: string;
}

export enum TourTypes {
  Personal = 'personal',
  Virtual = 'virtual',
}

export enum TourTimes {
  Morning = 'morning',
  Afternoon = 'afternoon',
  Evening = 'evening',
}

export interface TourInquiry {
  comment?: string;
  commentResponse?: string;
  confirmedTourDate?: string;
  confirmedTourTime?: string;
  createdAt: string;
  guestCardRequested: boolean;
  guestCardSent: false;
  id: string;
  notes?: string;
  pricingAndAvailability?: string;
  pricingAndAvailabilityRequested: boolean;
  status: InquiryStatus;
  tourDate: string;
  tourTime: TourTimes;
  tourType: TourTypes;
  updatedAt: string;
  inquiryType: InquiryType;
  assignee: InquiryAssignee;
  campaignId: string;
}

export type TourInquiryP = WithParent<TourInquiry>;

export interface TourDetailsFormValues {
  guestCardSent: boolean;
  pricingAndAvailability: string;
  commentResponse: string;
  notes: string;
  confirmedTourDate: string;
  confirmedTourTime: string;
}

interface TourInquiryTableItemContact {
  id: string;
  firstName: string;
  lastName: string;
}

interface TourInquiryTableItemBuilding {
  id: string;
  name: string;
}

interface TourInquiryTableItemUser {
  givenName: string;
  familyName: string | null;
  picture: string;
}

interface TourInquiryTableItemStatus {
  id: string;
  name: string;
  group: InquiryStateGroup;
  color: InquiryStateColor;
}

export interface TourInquiryTableItem {
  id: string;
  contact: TourInquiryTableItemContact;
  building: TourInquiryTableItemBuilding;
  user: TourInquiryTableItemUser;
  status: TourInquiryTableItemStatus;
  confirmedTourDate: string | null;
  confirmedTourTime: string | null;
  tourType: TourTypes;
  tourDate: string;
  tourTime: TourTimes;
  createdAt: string;
  updatedAt: string;
}
