import { ReactElement, useEffect, useState } from 'react';
import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
  UseFormSetValue,
} from 'react-hook-form';

import { TextField } from 'components';
import Popover from 'components/Popover';
import { ContactFiltersValues } from 'features/contacts/hooks/useContactFilters';
import { FilterPresetOptions } from 'features/contacts/types';
import { FilterPresetList, SelectOption } from 'types';

import { FilterOptionsList } from './components';
import { ArrowIcon } from './styled';

const textFieldStyles = (isOpen: boolean) => ({
  input: {
    color: isOpen ? '#EFF1F2' : '#191C1E',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  '.MuiInputLabel-root': {
    color: isOpen ? '#EFF1F2' : '#5E6062',
  },
  '.MuiFilledInput-input': {
    padding: '6px 10px',
    paddingLeft: '102px',
    textOverflow: 'ellipsis',
  },
  '.MuiFilledInput-root': {
    paddingRight: '5px',
  },
  '.MuiInputBase-root': {
    background: '#EFF1F2',
    borderRadius: '4px',
    marginTop: 0,
    width: '200px',
    maxWidth: '250px',
    backgroundColor: isOpen ? '#65787E' : 'none',
  },
  '.MuiFormLabel-root': {
    top: '50%',
    left: '8px',
    transform: 'translateY(-50%)',

    '&.Mui-focused': {
      color: isOpen ? '#EFF1F2' : '#5E6062',
    },
  },
});

interface TextFieldControllerProps<T = FieldValues> extends UseControllerProps<T> {
  options: FilterPresetOptions;
  control: Control<T>;
  error?: boolean;
  helperText?: string;
  label: string;
  id: string;
  defaultOptionText?: string;
  filtersList: FilterPresetList;
  setFilterPresetValue: UseFormSetValue<ContactFiltersValues>;
}

const inputProps = {
  style: { fontSize: 14 },
  disableUnderline: true,
  readOnly: true,
};

export function FilterPresetsSelect<T>({
  label,
  options,
  control,
  name,
  defaultOptionText,
  filtersList,
  setFilterPresetValue,
  ...selectFieldProps
}: TextFieldControllerProps<T>): ReactElement {
  const { groups, withNoFolder } = options;

  const [filterValue, setFilterValue] = useState('');

  const [isOpen, setIsOpen] = useState(false);

  const {
    field: { onChange, value },
  } = useController({ control, name });

  const handleChange = (option: SelectOption) => {
    onChange(option.value);
    setFilterValue(option.label as string);
    close();
  };

  useEffect(() => {
    if (value) {
      setFilterValue(() => filtersList.find((option) => option.id === value)?.name || '');
    }
  }, [value, filtersList, isOpen]);

  return (
    <Popover
      popupId="filter-block"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      closeOnContainerClick={false}
      anchor={
        <TextField
          {...selectFieldProps}
          variant="filled"
          fullWidth={false}
          id={name}
          size="small"
          name={name}
          value={filterValue}
          label={!filterValue ? label : `${label}: `}
          sx={textFieldStyles(isOpen)}
          InputProps={{ ...inputProps, endAdornment: <ArrowIcon isMenuOpen={isOpen} /> }}
        />
      }
    >
      {({ close, isOpen }) => {
        setIsOpen(isOpen);

        return (
          <FilterOptionsList
            close={close}
            handleChange={handleChange}
            groups={groups}
            withNoFolder={withNoFolder}
            value={value as string}
            isOpen={isOpen}
          />
        );
      }}
    </Popover>
  );
}
