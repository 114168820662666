import { Campaign, Contact } from 'types';
import { actionCreator } from 'utils';

import actionTypes from './actionTypes';

export const loadContact = actionCreator<Contact>(actionTypes.loadContact);

export const selectCampaign = actionCreator<Campaign['id']>(actionTypes.selectCampaign);

export const openCampaignInfo = actionCreator<{ isOpen: boolean }>(actionTypes.openCampaignInfo);

export const openBuildingInfo = actionCreator<{ isOpen: boolean }>(actionTypes.openBuildingInfo);

export const editPageSections = actionCreator<{ pageSections: string[] }>(
  actionTypes.editPageSections,
);
