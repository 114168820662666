import { getFullName } from 'utils';

interface GetContactInfoProps {
  firstName?: string;
  lastName?: string;
  phone?: string;
}

export function getPageTitle({ firstName, lastName, phone }: GetContactInfoProps): string {
  if (!firstName && !lastName && phone) {
    return phone;
  }

  return getFullName({ firstName, lastName });
}
