import { Box, ListItem, styled } from '@mui/material';
import { forwardRef, ReactElement, ReactNode, useMemo } from 'react';
import { NavLink as RouterLink, NavLinkProps } from 'react-router-dom';

interface LinkProps extends NavLinkProps {
  to: string;
  icon: ReactNode;
  disabled?: boolean;
}

const IconW = styled(Box)`
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
`;

export function NavLink({ to, icon, disabled }: LinkProps): ReactElement {
  const RenderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<NavLinkProps, 'to'>>(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} />;
      }),
    [to],
  );

  return (
    <ListItem
      sx={{
        p: '8px 11px',
        borderRadius: '4px',
        width: 36,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&.active': {
          background: '#D6EAE2',
          color: '#34966D',
        },
        marginBottom: '12px',
      }}
      button
      component={RenderLink}
      disabled={disabled}
    >
      <IconW>{icon}</IconW>
    </ListItem>
  );
}
