import { FilterPresetList } from 'types';

import { FilterPresetOptions } from '../types';

export function filterPresetOptionsAdapter(filtersList: FilterPresetList): FilterPresetOptions {
  return filtersList.reduce(
    (acc, filterPreset) => {
      const { id, name, group } = filterPreset;

      if (Boolean(filterPreset.group)) {
        if (acc.groups[group]) {
          acc.groups[group].push({ value: id, label: name });
        } else {
          acc.groups[group] = [{ value: id, label: name }];
        }
      } else {
        acc.withNoFolder.push({ value: id, label: name });
      }

      return acc;
    },
    { groups: {}, withNoFolder: [] } as FilterPresetOptions,
  );
}
