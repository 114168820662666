import { yupResolver } from '@hookform/resolvers/yup';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ConfirmModal } from 'components';
import { addToCampaignModalConfig } from 'features/unassignedBuildings/config';
import { Campaign } from 'types';

import { AddToCampaignModalContent } from '../AddToCampaignModalContent';

const validationSchema = yup.object().shape({
  campaign: yup.string().required(),
});

interface AddToCampaignModalProps {
  activeCampaigns: Campaign[];
  handleCloseModal: () => void;
  handleSubmit: (campaignId: string) => void;
}

export function AddToCampaignModal({
  activeCampaigns,
  handleCloseModal,
  handleSubmit: onSubmit,
}: AddToCampaignModalProps): ReactElement {
  const {
    control,
    formState: { isValid, isSubmitting },
    handleSubmit,
  } = useForm<{ campaign: string }>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const handleSubmitAddBuilding = handleSubmit(({ campaign }) => {
    onSubmit(campaign);
  });

  return (
    <ConfirmModal
      title={addToCampaignModalConfig.title}
      cancelText={addToCampaignModalConfig.cancelText}
      confirmText={addToCampaignModalConfig.confirmText}
      content={<AddToCampaignModalContent control={control} activeCampaigns={activeCampaigns} />}
      onClose={handleCloseModal}
      onSubmit={handleSubmitAddBuilding}
      highlightSubmit
      highlightSubmitColor="success"
      isSubmitDisabled={!isValid || isSubmitting}
      maxWidth="400px"
    />
  );
}
