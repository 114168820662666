import { useCallback, useState } from 'react';

import { UnassignedBuilding } from 'types';

import { useUnassignedBuildingsQuery } from './useUnassignedBuildingsQuery';

interface UseUnassignedBuildingsReturn {
  isOpen: boolean;
  buildingsCount: number;
  unassignedBuildings: UnassignedBuilding[];
  handleToggle: () => void;
}

export function useUnassignedBuildings(): UseUnassignedBuildingsReturn {
  const [isOpen, setIsOpen] = useState(false);

  const { unassignedBuildings } = useUnassignedBuildingsQuery();

  const buildingsCount = unassignedBuildings.length;

  const handleToggle = useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  return {
    isOpen,
    buildingsCount,
    unassignedBuildings,
    handleToggle,
  };
}
