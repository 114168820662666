import { MessageSender } from 'types';

export enum BuildingChatItemType {
  INCOMING_MESSAGE = 'INCOMING_MESSAGE',
  OUTGOING_MESSAGE = 'OUTGOING_MESSAGE',
  PRIVATE_NOTES = 'PRIVATE_NOTES',
}

interface BuildingChatItemMessageBase {
  id: string;
  createdAt: string;
  buildingId: string;
  acknowledged: boolean;
}

export interface BuildingChatIncomingMessage extends BuildingChatItemMessageBase {
  payload: {
    text: string;
  };
  type: BuildingChatItemType.INCOMING_MESSAGE;
}

export interface NewBuildingChatOutgoingMessage extends BuildingChatItemMessageBase {
  payload: {
    text: string;
    sender: MessageSender;
  };
  type: BuildingChatItemType.OUTGOING_MESSAGE;
}

export interface BuildingChatPrivateNotes extends BuildingChatItemMessageBase {
  payload: {
    text: string;
    sender: MessageSender;
  };
  type: BuildingChatItemType.PRIVATE_NOTES;
}

export type BuildingChatMessage =
  | BuildingChatIncomingMessage
  | NewBuildingChatOutgoingMessage
  | BuildingChatPrivateNotes;

export interface BuildingChatFormValues {
  content: string;
  type: BuildingChatItemType;
}

export type ChatInfo = { buildingName: string; chatId: string };
