import TabContext from '@mui/lab/TabContext';
import { Box } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

import { ChatTabsValues } from '../../config';
import { MessagesTypeSelect } from './components';
import {
  StyledBadge,
  Tab,
  TabList,
  TabsBottomBorder,
  TabsBottomHighlighter,
  TabsWrapper,
} from './styled';

interface TabItem {
  label: ReactNode;
  value: string;
}

interface TabsProps {
  handleChange: (value: string) => void;
  value: string;
  tabs: TabItem[];
  unreadMessagesCount: number;
}

export function ChatTabs({
  handleChange,
  value,
  tabs,
  unreadMessagesCount,
}: TabsProps): ReactElement {
  const changeTab = (_: unknown, newValue: string) => {
    handleChange(newValue);
  };

  const filterTabs = tabs.map((tab) => {
    const tabLabel = (
      <StyledBadge
        badgeContent={tab.value === ChatTabsValues.BUILDINGS ? unreadMessagesCount : 0}
        color="error"
      >
        {tab.label}
      </StyledBadge>
    );

    return { ...tab, label: tabLabel };
  });

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <TabsWrapper>
          <TabList onChange={changeTab}>
            {filterTabs.map(({ label, value }) => {
              return <Tab key={value} label={label} value={value} />;
            })}
          </TabList>
          <TabsBottomBorder />
          {value === ChatTabsValues.GENERAL && <MessagesTypeSelect />}
        </TabsWrapper>
        <TabsBottomHighlighter />
      </TabContext>
    </Box>
  );
}
