import { Divider } from '@mui/material';
import { ReactElement } from 'react';

import { ReactComponent as InquiryIcon } from 'assets/icons/inquiry.svg';
import { DATE_FORMAT_WITH_TIME_AND_COMMA } from 'constants/index';
import { BuildingPreview } from 'features/buildings/components';
import { theme } from 'styles';
import { DashboardItemActiveInquiryRequestsItem, InquiryType } from 'types';
import { getFormattedDateWithTime } from 'utils';

import {
  InquiryRequestCardWrapper,
  CardContent,
  StyledBadge,
  Title,
  DateCaption,
  StyledLink,
} from '../styled';

interface InquiryRequestCardProps {
  inquiryType: InquiryType;
  inquiry: DashboardItemActiveInquiryRequestsItem;
  contactId: string;
}

export function InquiryRequestCard({
  inquiryType,
  inquiry,
  contactId,
}: InquiryRequestCardProps): ReactElement {
  const { building, createdAt, id, notificationCount, campaignId } = inquiry;

  const iconColor =
    inquiryType === 'tour'
      ? theme.palette.additionalColors.cards2
      : theme.palette.additionalColors.cards1;

  return (
    <>
      <InquiryRequestCardWrapper>
        <BuildingPreview
          source={building.source}
          photo={building.photo}
          icon={<InquiryIcon width="24px" height="24px" style={{ color: iconColor }} />}
        />

        <CardContent>
          <div>
            <StyledBadge badgeContent={notificationCount} color="error">
              <StyledLink
                to={`/contacts/${contactId}?campaignId=${campaignId}&buildingId=${building.id}&inquiryId=${id}`}
                target="_blank"
              >
                <Title variant="body2">{building.name}</Title>
              </StyledLink>
            </StyledBadge>

            <DateCaption variant="body2">
              {getFormattedDateWithTime(new Date(createdAt), DATE_FORMAT_WITH_TIME_AND_COMMA)}
            </DateCaption>
          </div>
        </CardContent>
      </InquiryRequestCardWrapper>

      <Divider />
    </>
  );
}
