import { styled, Box, Typography } from '@mui/material';

export const PageW = styled(Box)`
  padding: 24px 28px 42px 24px;
`;

export const PageHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageTitle = styled(Typography)`
  font-weight: 600;
  font-size: 28px;
  color: #191c1e;
`;

export const TableW = styled(Box)`
  margin-top: 24px;
`;

export const SearchAndFilterW = styled(Box)`
  display: flex;
  margin-top: 24px;
`;

export const SearchW = styled(Box)`
  width: 276px;
  margin-right: 12px;
`;
