import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { useDebouncedValue } from 'hooks';
import { BuildingSearchItem, Campaign } from 'types';
import { arraySorter } from 'utils';

interface UseAddBuildingReturn {
  handleAddBuilding: (buildingId: string) => void;
  buildings: BuildingSearchItem[];
  isLoading: boolean;
  searchValue: string;
  setSearchValue: (v: string) => void;
  enabled: boolean;
  handleCloseAddBuilding: () => void;
}

interface UseAddBuildingParams {
  campaign: Campaign;
  handleClose: () => void;
}

export default function useAddBuilding({
  campaign,
  handleClose,
}: UseAddBuildingParams): UseAddBuildingReturn {
  const [searchValue, setSearchValue] = useState('');

  const debouncedSearchValue = useDebouncedValue(searchValue, 500);

  const enabled = !!debouncedSearchValue;

  const { data: buildingsResp, isLoading } = useQuery(
    ['buildings/search', debouncedSearchValue],
    () => {
      return API_HANDLERS.BUILDINGS.GET({
        search: debouncedSearchValue,
      });
    },
    {
      enabled,
    },
  );

  const { mutateAsync: addBuildingMutation } = useMutation(API_HANDLERS.BUILDINGS.ADD_TO_CAMPAIGN, {
    onSuccess: () => {
      queryClient.invalidateQueries('contact');
    },
  });

  const handleAddBuilding = async (buildingId: string) => {
    await addBuildingMutation({ campaignId: campaign.id, buildingId });
    setSearchValue('');
  };

  const handleCloseAddBuilding = () => {
    handleClose();
    setSearchValue('');
  };

  const buildings = useMemo(
    () => arraySorter(buildingsResp?.data.items || [], debouncedSearchValue, ['name', 'address']),
    [debouncedSearchValue, buildingsResp?.data],
  );

  return {
    handleAddBuilding,
    handleCloseAddBuilding,
    buildings,
    isLoading,
    searchValue,
    setSearchValue,
    enabled,
  };
}
