import { Collapse } from '@mui/material';
import { ReactElement } from 'react';

import { BuildingCard } from 'features/buildings/components';
import { UnassignedBuilding } from 'types';
import { toCapitalize } from 'utils';

import { UnassignedBuildingContent } from '../UnassignedBuildingContent';
import { UnassignedBuildingMenu } from '../UnassignedBuildingMenu';
import { useUnassignedBuildingItem } from './hooks';
import { CardWrapper, ItemWrapper } from './styled';

interface UnassignedBuildingItemProps {
  building: UnassignedBuilding;
  isSelected: boolean;
  disableAddToCampaignButton: boolean;
  handleSelectBuilding: (id: string) => void;
  handleAddToCampaign: (buildingId: string) => void;
}

export function UnassignedBuildingItem({
  building,
  isSelected,
  disableAddToCampaignButton,
  handleAddToCampaign,
  handleSelectBuilding: onSelect,
}: UnassignedBuildingItemProps): ReactElement {
  const { address, status, source, data, id, notes } = building;

  const { isExpanded, inquiries, handleToggle, handleSelectBuilding, handleClickAddToCampaign } =
    useUnassignedBuildingItem({
      building,
      onSelect,
      handleAddToCampaign,
    });

  return (
    <>
      <ItemWrapper isSelected={isSelected}>
        <CardWrapper>
          <BuildingCard
            isExpanded={isExpanded}
            toggleExpanded={handleToggle}
            id={id || ''}
            address={address}
            city={data.city || ''}
            name={data.name || ''}
            handleSelectBuilding={handleSelectBuilding}
            status={toCapitalize(status)}
            source={source}
            photo={data.photo}
          />
          <UnassignedBuildingMenu
            isDisabled={disableAddToCampaignButton}
            handleClickAddToCampaign={handleClickAddToCampaign}
          />
        </CardWrapper>
      </ItemWrapper>

      <Collapse orientation="vertical" in={isExpanded}>
        <UnassignedBuildingContent
          buildingId={id}
          notes={notes || ''}
          buildingStatus={status}
          inquiries={inquiries}
        />
      </Collapse>
    </>
  );
}
