import { GroupBase, StylesConfig } from 'react-select';

import { theme } from 'styles';
import { SelectOptionWithLabelString } from 'types';

import { setInputLabelText } from './utils';

export const customStyles = (
  label: string,
): StylesConfig<SelectOptionWithLabelString, boolean, GroupBase<SelectOptionWithLabelString>> => ({
  container: (provided, state) => ({
    ...provided,
    padding: '0',
    width: '100%',
    fontSize: '14px',
    ...(state.hasValue && setInputLabelText(label)),
  }),
  control: (provided, state) => ({
    ...provided,
    paddingLeft: '0',
    width: '100%',
    paddingRight: state.hasValue ? '4px' : '12px',
    border: 0,
    boxShadow: 'none',
    height: 'auto',
    boxSizing: 'border-box',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.palette.black.p050,
    fontSize: '14px',
  }),
  menu: (provided) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
    fontSize: '14px',
  }),
  multiValue: (provided) => ({
    ...provided,
    padding: '0',
    fontSize: '14px',
    background: theme.palette.secondary.p020,
    borderRadius: '15px',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: theme.palette.black.p050,
    ':hover': {
      color: theme.palette.black.light,
      cursor: 'pointer',
    },
  }),
});
