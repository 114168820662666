import { Box, Divider } from '@mui/material';
import { ReactElement, useContext, useState } from 'react';

import { ConfirmModal } from 'components';
import { CustomCollapse } from 'components/CustomCollapse';
import { SelectedBuildingIdState } from 'features/buildings/context';
import { SelectedCampaignIdState } from 'features/campaigns';
import { SelectedItemState } from 'features/contactDetails/context/ContactDetailsPageContext';
import { useCreateApply } from 'features/inquiry';
import { useAuth, useModal } from 'hooks';
import { useScrollQueue } from 'hooks/useScrollQueue';
import {
  CampaignBuildingP,
  BuildingStatus as Status,
  BuildingSource,
  ContactDetailsSelectItem,
} from 'types';
import { toCapitalize } from 'utils';

import { inquiriesListAdapter } from './adapters';
import { BuildingCard } from './BuildingCard';
import { BuildingItemMenu } from './components';
import { BuildingCollapseContent } from './components/BuildingCollapseContent';
import { deleteBuildingModalConfig } from './config';
import { useDeleteBuilding } from './hooks/useDeleteBuilding';
import { ListItemW, BuildingCardW } from './styled';

interface BuildingListItemProps extends CampaignBuildingP {
  handleSelectBuilding: (id: string) => void;
  isFirstInArray: boolean;
  handleOpenCreateTourModal: () => void;
  campaignId: string;
  source: BuildingSource;
}

export function BuildingListItem({
  name,
  address,
  data,
  id,
  handleSelectBuilding,
  handleOpenCreateTourModal,
  isFirstInArray,
  notes,
  calculatedStatus,
  status,
  campaignId,
  externalId,
  source,
  scheduleTourInquiries = [],
  applyInquiries = [],
}: BuildingListItemProps): ReactElement {
  const selectedBuildingId = useContext(SelectedBuildingIdState);
  const selectedCampaignId = useContext(SelectedCampaignIdState);
  const selectedItemType = useContext(SelectedItemState);

  const { handleCreateApply, isLoading } = useCreateApply(id, campaignId);

  const [isExpanded, setIsExpanded] = useState(false);

  const deleteBuildingModal = useModal();

  const { isReadOnlyAccess } = useAuth();

  const isHardDelete = status === Status.NEW;

  const { handleSubmit } = useDeleteBuilding({
    buildingId: id,
    campaignId,
    isHardDelete,
    close: deleteBuildingModal.handleCloseModal,
  });

  const { photo, city } = data;

  const buildingStatus = calculatedStatus || toCapitalize(status);

  const isDeleteIconVisible =
    buildingStatus !== toCapitalize(Status.DISMISSED) && !isReadOnlyAccess;

  const toggleExpanded = () => {
    if (!isExpanded) {
      handleSelectBuilding(id);
    }

    setIsExpanded(!isExpanded);
  };

  const { scrollRef } = useScrollQueue({
    id,
    open: setIsExpanded,
    select: handleSelectBuilding,
    scrollDelay: 400,
    parentId: campaignId,
  });

  const deleteModalConfig = deleteBuildingModalConfig(isHardDelete);

  const inquiries = inquiriesListAdapter({ scheduleTourInquiries, applyInquiries });

  const isSelected =
    selectedCampaignId === campaignId &&
    selectedBuildingId === id &&
    selectedItemType === ContactDetailsSelectItem.Building;

  return (
    <>
      <ListItemW isSelected={isSelected} ref={scrollRef}>
        {!isFirstInArray && <Divider />}
        <BuildingCardW>
          <BuildingCard
            address={address}
            city={city}
            id={id}
            name={name}
            photo={photo}
            isExpanded={isExpanded}
            handleSelectBuilding={handleSelectBuilding}
            toggleExpanded={toggleExpanded}
            status={buildingStatus}
            source={source}
          />
          <BuildingItemMenu
            openDeleteModal={deleteBuildingModal.handleOpenModal}
            isDeleteIconVisible={isDeleteIconVisible}
            buildingLink={`https://www.lighthouse.app/building/${externalId}`}
          />
        </BuildingCardW>
      </ListItemW>

      <Box>
        <CustomCollapse orientation="vertical" in={isExpanded}>
          {() => (
            <BuildingCollapseContent
              buildingId={id}
              handleCreateApply={handleCreateApply}
              handleOpenCreateTourModal={handleOpenCreateTourModal}
              isLoading={isLoading}
              notes={notes}
              buildingStatus={status}
              inquiries={inquiries}
            />
          )}
        </CustomCollapse>
      </Box>

      {deleteBuildingModal.isOpen && (
        <ConfirmModal
          title={deleteModalConfig.title}
          cancelText="Cancel"
          confirmText="Remove"
          content={deleteModalConfig.description}
          onClose={deleteBuildingModal.handleCloseModal}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
}
