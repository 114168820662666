import { CAME_FROM_LABELS } from 'dictionary';
import { FilterEntity } from 'types';

export const FILTER_CHILDREN_NAME = 'value';
export const FILTERS_NAME_PREFIX = 'conditions';
export const NESTING_LEVEL_LIMIT = 2;

export const ENTITY_NAME_LABELS: Record<string, string> = {
  [FilterEntity.CONTACT]: 'Contact',
  [FilterEntity.CAMPAIGN]: 'Campaign',
  [FilterEntity.BUILDING]: 'Building',
  [FilterEntity.TOUR_INQUIRY]: 'Tour inquiry',
  [FilterEntity.APPLY_INQUIRY]: 'Apply inquiry',
};

// TODO: delete - OPTION_LABELS
export const OPTION_LABELS: Record<string, string> = {
  ...CAME_FROM_LABELS,
};
