import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { Box, Collapse, styled, Typography } from '@mui/material';

export const DateCaption = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.light,
  fontSize: '12px',
  lineHeight: '16px',
  whiteSpace: 'nowrap',
}));

export const CloseReasonWrapper = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.light,
  fontSize: '12px',
  lineHeight: '16px',
}));

export const Reason = styled('span')(({ theme }) => ({
  color: theme.palette.black.main,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 600,
  marginLeft: '2px',
}));

export const ContainerWrapper = styled('div')<{ expanded: boolean }>(({ theme, expanded }) => ({
  position: 'relative',

  ...(expanded
    ? {
        '&::after': {
          content: '""',
          display: 'block',
          borderBottom: `1px solid ${theme.palette.secondary.p020}`,
          marginLeft: '24px',
        },
      }
    : undefined),
}));

export const InquiryItemWrapper = styled(Box)<{ isSelected?: boolean }>(
  ({ theme, isSelected }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 24px 12px 56px',
    borderLeft: `2px solid ${isSelected ? theme.palette.primary.main : 'transparent'}`,
    background: isSelected ? theme.palette.secondary.p005 : theme.palette.background.paper,
    transition: 'border-color 0.25s ease-out',
    position: 'relative',

    '&::after': {
      content: '""',
      background: theme.palette.secondary.p020,
      position: 'absolute',
      bottom: 0,
      right: 0,
      height: '1px',
      width: 'calc(100% - 56px)',
    },
  }),
);

export const SideBlockWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const RightSideBlockWrapper = styled(SideBlockWrapper)(() => ({
  maxWidth: 'calc(100% - 148px)',
}));

export const InquiryName = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
  margin-left: 6px;
  cursor: pointer;
`;

export const ArrowIcon = styled(ArrowRightRoundedIcon, {
  shouldForwardProp: (propname) => propname !== '$isOpen',
})<{ $isOpen: boolean }>(({ $isOpen, theme }) => ({
  position: 'absolute',
  cursor: 'pointer',
  color: theme.palette.secondary.main,
  top: 'calc(50% - 12px)',
  left: '-28px',
  transform: `rotate(${$isOpen ? '90' : '0'}deg)`,
}));

export const CollapseWrapper = styled(Collapse)`
  padding: 0 24px 0 56px;
`;
