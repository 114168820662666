import { useCallback, useState } from 'react';

interface UseDashboardChatReturn {
  selectedContactChat: string | undefined;
  handleSelectChat: (contactId: string) => void;
}

export function useDashboardChat(): UseDashboardChatReturn {
  const [selectedContactChat, setSelectedContactChat] = useState<string | undefined>(undefined);

  const handleSelectChat = useCallback(
    (contactId: string) => {
      setSelectedContactChat(contactId);
    },
    [setSelectedContactChat],
  );

  return { selectedContactChat, handleSelectChat };
}
