import { Dialog, DialogTitle, styled, DialogActions } from '@mui/material';

import { Button } from 'components';

export const DialogTitleW = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 0,
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px',
  color: theme.palette.black.main,
  marginBottom: '22px',
}));

export const DialogW = styled(Dialog)<{ width?: string }>(({ width }) => ({
  '.MuiDialog-paper': {
    minWidth: width ? width : '376px',
    padding: '14px 20px 0',
    margin: 0,
    overflowY: 'visible',
  },
}));

export const DialogActionsW = styled(DialogActions)(() => ({
  padding: '8px 0',
}));

const ButtonW = styled(Button)`
  font-weight: 600;
  font-size: 14px;
  padding: 6px 8px;
  text-transform: capitalize;
`;

export const CancelBtn = styled(ButtonW)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const ConfirmBtn = styled(ButtonW)(({ theme }) => ({
  color: theme.palette.success.main,
}));
