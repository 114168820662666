import { getMaxLengthText, getMinLengthText, NAME_PATTER_SYMBOLS, REQUIRED } from './messages';
import { LETTERS_DIGITS_SYMBOLS } from './regexps';

type ValidateFn = (value?: string | undefined) => string | undefined;

export const validationTest = (testFn: ValidateFn, isTransform = false): ValidateFn => {
  return (value?: string) => {
    const res = testFn(value);

    if (isTransform) {
      return res;
    }

    if (res) {
      throw new Error(res);
    }

    return value;
  };
};

export const composeValidateRule =
  (validationTests: ValidateFn[]): ValidateFn =>
  (value?: string) => {
    try {
      validationTests.reduce((value, fn) => fn(value), value);
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      throw new Error('composeValidateRule: validation error must me an error type');
    }
  };

export const requiredValidate: ValidateFn = validationTest((value) => {
  if (!value) {
    return REQUIRED;
  }
});

export const trimTransformValidate: ValidateFn = validationTest((value) => value?.trim(), true);

export const getMinLengthValidate = (length: number): ValidateFn =>
  validationTest((value?: string) => {
    if (value && value.length < length) {
      return getMinLengthText(length);
    }
  });

export const getMaxLengthValidate = (length: number): ValidateFn =>
  validationTest((value) => {
    if (value && value.length > length) {
      return getMaxLengthText(length);
    }
  });

export const textDigitSymbolsValidate: ValidateFn = validationTest((value = '') => {
  if (!LETTERS_DIGITS_SYMBOLS.test(value)) {
    return NAME_PATTER_SYMBOLS;
  }
});
