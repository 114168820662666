import {
  UnassignedBuilding,
  UnassignedBuildingApplyInquiry,
  UnassignedBuildingScheduleTourInquiry,
} from 'types';

import { unassignedBuildingInquiryItemAdapter } from './unassignedBuildingInquiryItemAdapter';

interface InquiriesListAdapterProps {
  scheduleTourInquiries: UnassignedBuilding['scheduleTourInquiries'];
  applyInquiries: UnassignedBuilding['applyInquiries'];
}

export function unassignedBuildingInquiriesListAdapter({
  scheduleTourInquiries = [],
  applyInquiries = [],
}: InquiriesListAdapterProps): (
  | UnassignedBuildingScheduleTourInquiry
  | UnassignedBuildingApplyInquiry
)[] {
  const tourList = unassignedBuildingInquiryItemAdapter(scheduleTourInquiries, 'tour');
  const applyInquiriesList = unassignedBuildingInquiryItemAdapter(applyInquiries, 'application');

  return [...tourList, ...applyInquiriesList];
}
