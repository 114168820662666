import { StylesConfig } from 'react-select';

import { theme } from 'styles';
import { SelectOptionWithLabelString } from 'types';

export const customStyles: StylesConfig<SelectOptionWithLabelString, boolean> = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    maxWidth: '376px',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: '336px',
    paddingLeft: '10px',
  }),

  control: (provided, state) => ({
    ...provided,
    paddingLeft: '12px',
    paddingRight: state.hasValue ? '4px' : '12px',
    backgroundColor: theme.palette.secondary.p010,
    border: 0,
    boxShadow: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.palette.black.p050,
    fontSize: '14px',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '2px',
    zIndex: 1300,
  }),
  option: (provided) => ({
    ...provided,
    padding: '10px 16px',
    backgroundColor: theme.palette.white.main,
    color: theme.palette.black.main,
    wordBreak: 'break-all',
    '&:hover': {
      backgroundColor: theme.palette.secondary.p010,
    },
    '&:focused': {
      backgroundColor: theme.palette.white.main,
    },
  }),
};
