export function getFileSizeDisplayValue(bytesSize: number): string | null {
  if (!bytesSize) {
    return null;
  }

  if (bytesSize < 1000000) {
    return `${(bytesSize / 1000).toFixed()}Kb`;
  } else {
    return `${(bytesSize / 1000000).toFixed(1)}Mb`;
  }
}
