import { Box, styled, Typography } from '@mui/material';

export const BuildingName = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: 600,
  cursor: 'pointer',
  lineHeight: '1.1rem',
  color: theme.palette.black.main,
}));

export const BuildingAddress = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.light,
  fontSize: '12px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  lineHeight: '16px',
  textOverflow: 'ellipsis',
}));

export const BuildingDetailsW = styled(Box)`
  display: flex;
  flex-direction: column;
`;
