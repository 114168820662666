import { EditMessageTemplateData, MessageTemplateFormData } from 'types';

interface EditMessageTemplateAdapterProps {
  id: string;
  values: MessageTemplateFormData;
}

export function editMessageTemplateAdapter({
  id,
  values,
}: EditMessageTemplateAdapterProps): EditMessageTemplateData {
  const { name, subject, body } = values;

  return { id, name, subject, body };
}
