import { Collapse } from '@mui/material';
import { ReactElement } from 'react';

import { UnassignedBuildingsList } from 'features/unassignedBuildings/components';
import { useUnassignedBuildings } from 'features/unassignedBuildings/hooks';

import { ArrowIcon, BucketWrapper, BuildingsCount, Divider, Title, TitleWrapper } from './styled';

export function UnassignedBuildings(): ReactElement | null {
  const { isOpen, buildingsCount, unassignedBuildings, handleToggle } = useUnassignedBuildings();

  if (!buildingsCount) {
    return null;
  }

  return (
    <div>
      <BucketWrapper>
        <TitleWrapper onClick={handleToggle}>
          <Title variant="body2">Unassigned buildings</Title>
          <BuildingsCount>
            {buildingsCount} {buildingsCount === 1 ? 'building' : 'buildings'}
          </BuildingsCount>
        </TitleWrapper>
        <ArrowIcon open={isOpen} onClick={handleToggle} />
      </BucketWrapper>

      <Collapse in={isOpen}>
        <UnassignedBuildingsList buildings={unassignedBuildings} />
      </Collapse>

      <Divider />
    </div>
  );
}
