import { ReactElement } from 'react';

import {
  buildingDetailsAdapter,
  campaignDetailsAdapter,
  contactDetailsAdapter,
} from 'features/contactDetails/adapters';
import { acquisitionDataAdapter } from 'features/contactDetails/adapters/acquisitionDataAdapter';
import { Campaign, CampaignBuilding, Contact } from 'types';

import { ContactOwnersForm, BuildingInfo, CampaignInfo, DetailsSection } from './components';
import { acquisitionLabels, campaignLabels, contactInfoLabels } from './components/config';
import { useSelectedBuilding } from './hooks';
import { InfoPanelW } from './styled';

interface InfoPanelProps {
  handleOpenContactForm: () => void;
  handleOpenCampaignForm: () => void;
  contact?: Contact;
  selectedCampaign?: Campaign;
  selectedBuilding?: CampaignBuilding;
}

export function InfoPanel({
  handleOpenContactForm,
  handleOpenCampaignForm,
  contact,
  selectedCampaign,
}: InfoPanelProps): ReactElement {
  const selectedBuilding = useSelectedBuilding();

  const contactDetails = contactDetailsAdapter(contact);
  const campaignDetails = campaignDetailsAdapter(selectedCampaign);
  const buildingDetails = buildingDetailsAdapter(selectedBuilding);

  const acquisitionData = acquisitionDataAdapter(
    contact?.acquisitionData,
    contact?.cameFrom,
    contactDetails.externalId,
  );

  function handleOpenCampaignModal(): void {
    if (!campaignDetails.id) {
      return;
    }
    handleOpenCampaignForm();
  }

  return (
    <InfoPanelW>
      <ContactOwnersForm contact={contact} />
      <DetailsSection
        title="Contact info"
        initialOpen
        isEditable
        data={contactDetails}
        labelsConfig={contactInfoLabels}
        openModal={handleOpenContactForm}
        lighthouseId={acquisitionData.lighthouseId}
      />
      <DetailsSection
        title="Acquisition data"
        initialOpen
        data={acquisitionData}
        labelsConfig={acquisitionLabels}
      />
      {selectedCampaign && (
        <CampaignInfo
          title="Campaign info"
          data={campaignDetails}
          openModal={handleOpenCampaignModal}
          isEditable
          labelsConfig={campaignLabels}
        />
      )}

      {buildingDetails && <BuildingInfo title="Building info" buildingDetails={buildingDetails} />}
    </InfoPanelW>
  );
}
