import { CampaignIssues } from 'types';

export const CAMPAIGN_ISSUES: Record<CampaignIssues, string> = {
  noneApply: 'None apply',
  brokenStuff: 'Broken leases, evictions, unpaid balances',
  noCreditHistory: 'No credit history',
  lowCreditScore: 'Credit score below 550',
  lowIncome: 'Gross income below $2,100 per month',
  bankruptcies: 'Bankruptcies, foreclosures',
  misdemeanors: 'Misdemeanors, felonies',
};

export const AMENITIES_LIST: string[] = [
  'Air conditioner',
  'All bills paid',
  'Balcony',
  'Bike storage',
  'Billiards',
  'Business center',
  'Carpet floor',
  'Ceiling fans',
  'Concierge',
  'Elevator',
  'Ev charging',
  'Fast internet',
  'Fireplace',
  'Fitness center',
  'Furnished available',
  'Gameroom',
  'Gater',
  'Grilling area',
  'Hardwood floor',
  'Laundry facilities',
  'Laundry hookups',
  'Laundry in unit',
  'Lofts',
  'Media center',
  'Package service',
  'Parking',
  'Patio',
  'Playground',
  'Pool',
  'Rooftop',
  'Storage space',
  'Tile floor',
  'Trash service',
  'Views',
  'Vinyl floor',
  'Walk-in closet',
  'Wheelchair access',
];
