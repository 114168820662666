import { Divider } from '@mui/material';
import { ReactElement, useContext } from 'react';
import { useInView } from 'react-intersection-observer';

import { BuildingChatState } from 'features/communications/context';
import { ChatInfo, BuildingChatItemType } from 'types';

import { BuildingChatMessageContainer } from '../BuildingChatMessageContainer';
import { BuildingChatMessageForm } from '../BuildingChatMessageForm';
import { useBuildingChatMessagesQuery } from './hooks';
import { BuildingTitle, CancelIcon, ChatHeader, MessagesListW } from './styled';

interface BuildingChatProps {
  chatInfo: ChatInfo;
  handleCloseChatItem: () => void;
  contactId: string;
  readMessage: (messageId: string) => void;
}

export function BuildingChat({
  chatInfo,
  handleCloseChatItem,
  contactId,
  readMessage,
}: BuildingChatProps): ReactElement {
  const { chatId, buildingName } = chatInfo;

  const { ref, inView } = useInView();

  useBuildingChatMessagesQuery({ buildingId: chatId, contactId, inView });

  const { messages } = useContext(BuildingChatState);

  return (
    <>
      <ChatHeader>
        <BuildingTitle variant="body2">{buildingName}</BuildingTitle>
        <CancelIcon onClick={handleCloseChatItem} />
      </ChatHeader>
      <Divider sx={{ marginLeft: '24px' }} />

      <MessagesListW>
        {messages.map((message, index) => (
          <BuildingChatMessageContainer
            key={message.id}
            message={message}
            buildingName={buildingName}
            readMessage={readMessage}
            lastItemRef={index === messages.length - 1 ? ref : undefined}
          />
        ))}
      </MessagesListW>

      <BuildingChatMessageForm
        defaultValues={{
          type: BuildingChatItemType.OUTGOING_MESSAGE,
          content: '',
        }}
        buildingId={chatId}
        contactId={contactId}
      />
    </>
  );
}
