import { Table as MuiTable } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CSSProperties, Dispatch, ReactElement, SetStateAction, useCallback } from 'react';

import { PageStatus, TablePagination } from 'components';
import { EmptyStateArgs } from 'components/PageStatus';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS, PAGE_LIMIT } from 'constants/index';
import { ContactsTable } from 'types';
import { clickAndHoldListener } from 'utils';

import { DataW, Td, Th } from './styled';

interface TableProps {
  data: ContactsTable[];
  tableHeight: CSSProperties['height'];
  currentPage: number;
  totalCount: number;
  pageLimit: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setPageLimit: Dispatch<SetStateAction<number>>;
  isTableVisible: boolean;
  onRowClick?(row: string): void;
  loading: boolean;
  emptyState: EmptyStateArgs;
}

export function Table({
  data,
  tableHeight,
  currentPage,
  setCurrentPage,
  totalCount,
  pageLimit,
  setPageLimit,
  isTableVisible = true,
  onRowClick,
  loading,
  emptyState,
}: TableProps): ReactElement {
  const showPagination = totalCount > (pageLimit < PAGE_LIMIT ? pageLimit : PAGE_LIMIT);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageLimit(parseInt(event.target.value));
    setCurrentPage(0);
  };

  const handleOnRowClick = useCallback(
    (row: string) => {
      onRowClick?.(row);
    },
    [onRowClick],
  );

  return (
    <>
      <PageStatus loading={loading} emptyState={emptyState} withCover>
        <TableContainer style={{ height: tableHeight }}>
          {isTableVisible && (
            <MuiTable aria-label="table" stickyHeader>
              <TableHead>
                <TableRow>
                  <Th align="left">First name</Th>
                  <Th align="left">Last name</Th>
                  <Th align="left">Email</Th>
                  <Th align="left">Phone number</Th>
                  <Th align="left">Campaign summary</Th>
                  <Th align="left">Contact owner</Th>
                  <Th align="left">Created on</Th>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => {
                  const clickProps = clickAndHoldListener(() => handleOnRowClick(row.id), 150);
                  return (
                    <TableRow
                      {...clickProps}
                      key={row.id}
                      sx={{ '&:last-child th': { border: 0 }, cursor: 'pointer' }}
                      hover
                    >
                      <Td align="left" sx={{ maxWidth: '170px' }}>
                        <DataW>{row.firstName}</DataW>
                      </Td>
                      <Td align="left" sx={{ maxWidth: '170px' }}>
                        <DataW>{row.lastName}</DataW>
                      </Td>
                      <Td align="left" sx={{ maxWidth: '170px' }}>
                        <DataW>{row.email}</DataW>
                      </Td>
                      <Td align="left">
                        <DataW>{row.phone}</DataW>
                      </Td>
                      <Td align="left">
                        <DataW>{row.campaignSummary}</DataW>
                      </Td>
                      <Td align="left">
                        <DataW sx={{ maxWidth: '170px' }}>{row.contactOwner}</DataW>
                      </Td>
                      <Td align="left">
                        <DataW>{row.createdAt}</DataW>
                      </Td>
                    </TableRow>
                  );
                })}
              </TableBody>
            </MuiTable>
          )}
        </TableContainer>
      </PageStatus>
      {showPagination && (
        <TablePagination
          rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          count={totalCount}
          rowsPerPage={pageLimit}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}
