import { EMPTY_VALUE } from 'constants/index';
import { TourTimes } from 'types';

import { tourTimeOptions } from './constants';

export function getTimeValue(dayTime?: TourTimes): string {
  switch (dayTime) {
    case TourTimes.Morning:
      return tourTimeOptions[TourTimes.Morning];
    case TourTimes.Afternoon:
      return tourTimeOptions[TourTimes.Afternoon];
    case TourTimes.Evening:
      return tourTimeOptions[TourTimes.Evening];
    default:
      return EMPTY_VALUE;
  }
}
