import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { debugBase64, handleFileDownload } from 'components/MessageItem/utils';

interface UseMessageAttachmentProps {
  fileId: string;
  fileName: string;
  extension: string;
}

interface UseMessageAttachmentReturn {
  previewSrc: string | undefined;
  handlePreviewClick: () => void;
  handleDownloadClick: () => void;
  isLoading: boolean;
  thumbnailPreview: string;
}

export function useMessageAttachment({
  fileId,
  fileName,
  extension,
}: UseMessageAttachmentProps): UseMessageAttachmentReturn {
  const { data, isLoading } = useQuery(['email-attachment', fileId], () =>
    API_HANDLERS.COMMUNICATIONS.GET_ATTACHMENT(fileId),
  );

  const [previewSrc, setPreviewSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (data?.data) {
      blobToBase64(data?.data).then((res) => {
        const previewFileLink = (res as string).replace(
          'data:application/octet-stream;',
          `data:image/${extension};`,
        );
        return setPreviewSrc(previewFileLink);
      });
    }
  }, [data, extension]);

  const blobToBase64 = (blob: Blob) => {
    const reader = new FileReader();

    reader.readAsDataURL(blob);

    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const thumbnailPreview = window.URL.createObjectURL(new Blob([data?.data]));

  const handlePreviewClick = () => {
    if (previewSrc) {
      debugBase64(previewSrc);
    }
  };

  const handleDownloadClick = () => {
    handleFileDownload(thumbnailPreview, undefined, fileName);
  };

  return {
    thumbnailPreview,
    previewSrc,
    handlePreviewClick,
    handleDownloadClick,
    isLoading,
  };
}
