import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { EmployeeTeam } from 'types';

interface UseTeamsListReturn {
  teams: EmployeeTeam[];
}

export function useTeamsList(): UseTeamsListReturn {
  const { data: employeeTeamsData } = useQuery('employeeTeams', () =>
    API_HANDLERS.EMPLOYEES.TEAMS.GET_ALL(),
  );

  return {
    teams: employeeTeamsData?.data.items || [],
  };
}
