import { Box, styled, Typography, ToggleButton as ToggleButtonMUI } from '@mui/material';
import { Link } from 'react-router-dom';

import { Badge } from 'components';

export const InquiryRequestCardWrapper = styled(Box)(() => ({
  display: 'flex',
  padding: '12px 20px',
}));

export const ActiveListRequestCardWrapper = styled(Box)(() => ({
  padding: '24px 20px',
}));

export const CardContent = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '264px',
}));

export const ActiveListRequestCardContent = styled(CardContent)(() => ({
  justifyContent: 'space-between',
  width: '344px',
}));

export const Title = styled(Typography)(({ theme }) => ({
  maxWidth: '256px',
  marginBottom: '2px',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '600',
  color: theme.palette.black.main,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const DateCaption = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.light,
  fontSize: '12px',
  lineHeight: '16px',
  whiteSpace: 'nowrap',
}));

export const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: '-12px',
    top: '10px',
  },
}));

export const ToggleButton = styled(ToggleButtonMUI, {
  shouldForwardProp: (propname) => propname !== 'selected',
})(({ selected, theme }) => ({
  color: selected ? theme.palette.primary.main : theme.palette.secondary.main,
  border: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const NotesWrapper = styled(Box)(() => ({
  width: '328px',
  marginTop: '32px',
}));

export const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',
}));
