import { styled } from '@mui/material';

import { ReactComponent as ArrowDropDownIcon } from 'assets/icons/arrow-drop-down.svg';

import { ButtonSizes } from '../interface';
import { wrapIconSizes } from './config';

export const Wrap = styled('div')`
  position: relative;
  display: inline-block;
`;

export const ArrowIcon = styled(ArrowDropDownIcon)<{ size?: ButtonSizes; open: boolean }>(
  ({ theme, size, open, color }) => ({
    transform: `rotate(${open ? 180 : 0}deg) scale(${size ? wrapIconSizes[size] : 1})`,
    transition: 'all 0.3s',

    '& .path1': {
      fill: color === 'secondary' ? theme.palette.secondary.main : theme.palette.white.main,
    },
  }),
);
