import { AssignInquiryParams } from 'api/types';
import { AssignInquiryFormValues } from 'types';

export function formValuesAdapter({
  team,
  assignee,
}: AssignInquiryFormValues): AssignInquiryParams['assignee'] {
  if (assignee) {
    return { assigneeId: assignee };
  }

  return { teamId: team };
}
