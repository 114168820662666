import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Icon404 } from 'assets/icons/404.svg';
import { Button } from 'components';
import { Paths } from 'settings';

import { PageText, Wrap } from './styled';

export function Page404(): ReactElement {
  const navigate = useNavigate();

  return (
    <Wrap>
      <Icon404 />

      <PageText variant="body1">Page doesn’t exist :(</PageText>
      <div>
        <Button onClick={() => navigate(Paths.index)}>Go to main page</Button>
      </div>
    </Wrap>
  );
}
