import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { MessageTemplateData } from 'types';

interface UseMessageTemplateByIdQueryReturn {
  isTemplateLoading: boolean;
  isError: boolean;
  templateData?: MessageTemplateData;
}

export function useMessageTemplateByIdQuery(templateId: string): UseMessageTemplateByIdQueryReturn {
  const {
    data: { data } = {},
    isFetching,
    isLoading,
    isError,
  } = useQuery(
    ['message-template', templateId],
    () => API_HANDLERS.MESSAGE_TEMPLATE.GET_BY_ID(templateId),
    { enabled: !!templateId },
  );

  return {
    isTemplateLoading: isFetching || isLoading,
    isError,
    templateData: data,
  };
}
