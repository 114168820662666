import { ReactElement, useMemo } from 'react';

import { campaignDefaultValuesAdapter } from 'features/contactDetails/adapters';
import { Campaign } from 'types';

import { CampaignForm } from '../CampaignForm';
import { useCampaignEditForm } from './useCampaignEditForm';

interface CampaignEditFormProps {
  handleClose: () => void;
  isOpen: boolean;
  campaign?: Campaign;
}

export function CampaignEditForm({
  isOpen,
  handleClose,
  campaign,
}: CampaignEditFormProps): ReactElement {
  const defaultValues = useMemo(() => campaignDefaultValuesAdapter(campaign), [campaign]);
  const campaignId = campaign?.id || '';

  const { handleSubmit } = useCampaignEditForm({
    defaultValues,
    campaignId,
  });

  return (
    <CampaignForm
      title="Edit campaign"
      mode="edit"
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      handleClose={handleClose}
      isOpen={isOpen}
    />
  );
}
