import { createTheme } from '@mui/material/styles';

import { $FixTypeLater } from 'types';

declare module '@mui/material/styles' {
  interface PaletteColor {
    main: string;
    light: string;
    p050: string;
    p040: string;
    p030: string;
    p020: string;
    p010: string;
    p005: string;
  }

  interface PaletteColorOptions {
    main: string;
    light: string;
    p050: string;
    p030: string;
    p020: string;
    p010: string;
    p005: string;
  }

  interface Palette {
    primary: PaletteColor;
    secondary: PaletteColor;
    black: PaletteColor;
    white: PaletteColor;
    success: PaletteColor;
    error: PaletteColor;
    warning: PaletteColor;
    info: PaletteColor;
    additionalColors: {
      cards1: string;
      cards2: string;
      cards3: string;
      cards4: string;
    };
    overlay: string;
  }
  interface PaletteOptions {
    primary?: PaletteColorOptions;
    secondary?: PaletteColorOptions;
    black?: PaletteColorOptions;
    white?: PaletteColorOptions;
    success?: PaletteColorOptions;
    error?: PaletteColorOptions;
    warning?: PaletteColorOptions;
    info?: PaletteColorOptions;
    additionalColors: {
      cards1: string;
      cards2: string;
      cards3: string;
      cards4: string;
    };
    overlay: string;
  }
}

export const customPalette = {
  primary: {
    main: '#34966D',
    light: '#71B699',
    p050: '#99CAB6',
    p030: '#C2E0D3',
    p020: '#D6EAE2',
    p010: '#EAF4F0',
    p005: '#F3FAF7',
  },
  secondary: {
    main: '#65787E',
    light: '#849398',
    p050: '#A3AEB2',
    p030: '#C1C9CB',
    p020: '#E0E4E5',
    p010: '#EFF1F2',
    p005: '#F7F8F8',
  },
  black: {
    main: '#191C1E',
    light: '#5E6062',
    p050: '#8C8D8E',
    p030: '#BABBBC',
    p020: '#D1D2D2',
    p010: '#E8E8E8',
    p005: '#F3F3F4',
  },
  white: {
    main: '#FFFFFF',
    light: '#FFFFFFCC',
    p050: '#FFFFFF99',
    p030: '#FFFFFF66',
    p020: '#FFFFFF33',
    p010: '#FFFFFF1A',
    p005: '#FFFFFF0D',
  },
  success: {
    main: '#34966D',
    light: '#71B699',
    p050: '#99CAB6',
    p030: '#C2E0D3',
    p020: '#D6EAE2',
    p010: '#EAF4F0',
    p005: '#F3FAF7',
  },
  error: {
    main: '#D13D3E',
    light: '#DF7778',
    p050: '#E89E9E',
    p030: '#F1C5C5',
    p020: '#F6D8D8',
    p010: '#FAECEC',
    p005: '#FDF5F5',
  },
  warning: {
    main: '#FCAD33',
    light: '#FCA218B2',
    p050: '#FCA21880',
    p030: '#FCA2184D',
    p020: '#FCA21833',
    p010: '#FFF6E8',
    p005: '#FCA2180D',
  },
  info: {
    main: '#357FD1',
    light: '#72A5DF',
    p050: '#9ABFE8',
    p030: '#C2D9F1',
    p020: '#D7E5F6',
    p010: '#EBF2FA',
    p005: '#F5F9FD',
  },
  additionalColors: {
    cards1: '#A2A9E1',
    cards2: '#69B1C3',
    cards3: '#FFBD8F',
    cards4: '#75BC9F',
  },
  overlay: '#191C1E99',
};

const theme = createTheme({
  palette: customPalette,
  typography: {
    fontFamily: "'IBM Plex Sans', sans-serif",
  },
  components: {
    MuiClockPicker: {
      styleOverrides: {
        arrowSwitcher: {
          '& :nth-child(2)': {
            width: '0 !important',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          'div[role="listbox"]:focus': {
            outline: 'none',
          },
        },
      },
    },
  } as $FixTypeLater,
});

export default theme;
