import {
  AxiosResponseSuccess,
  ContactsRequestProps,
  ContactsResponseSuccess,
  ContactDetailsResponseSuccess,
  EmployeesListResponseSuccess,
  ContactCreateRequest,
  ContactCreateResponseSuccess,
  CampaignCreateRequest,
  CampaignCreateResponseSuccess,
  CampaignsListResponseSuccess,
  ContactEditRequest,
  CampaignEditRequest,
  CampaignEditResponseSuccess,
  BuildingListResponseSuccess,
  AddBuildingToCampaignParams,
  GetBuildingsParams,
  CreateTourParams,
  CreateApplyParams,
  EditTourParams,
  CreateTourResponseSuccess,
  EditTourResponseSuccess,
  CreateApplyResponseSuccess,
  EditInquiryDetailsParams,
  EditApplyResponseSuccess,
  EditApplyDetailsParams,
  EditBuildingParams,
  EditBuilding,
  GetInquiryTransitionStatusesParams,
  GetInquiryTransitionStatusesSuccess,
  PatchInquiryTransitionStatusParams,
  ChangeInquiryTransitionStatusSuccess,
  AssignInquiryParams,
  EmployeesTeamsListResponseSuccess,
  AssignApplyResponseSuccess,
  AssignTourResponseSuccess,
  GetFilterDescriptorsResponseSuccess,
  PostMessageResponse,
  DeleteBuildingParams,
  DeleteBuilding,
  ContactAssigneeEditRequest,
  ContactOwnerEditRequest,
  ContactOwnerEditResponseSuccess,
  SendBuilding,
  GetFiltersPresets,
  GetCampaignBuildingDetailsParams,
  GetContactMessagesProps,
  GetCampaignTransitionStatusesSuccess,
  PatchCampaignTransitionStatusParams,
  ChangeCampaignTransitionStatusSuccess,
  DeleteEmployeeParams,
  DeleteEmployeeResponse,
  ChangeEmployeeRoleParams,
  ChangeEmployeeRoleResponse,
  ChangeEmployeeTeamParams,
  ChangeEmployeeTeamResponse,
  EmployeeListRequestProps,
  GetMessageTemplatesResponseSuccess,
  GetTemplateFieldsResponseSuccess,
  GetMessageTemplatesParams,
  SearchContactDetailsProps,
  GetBuildingChatsResponseSuccess,
  BuildingChatMessagesRequestParams,
  CommunicationSearchResponseSuccess,
  DashboardResponseSuccess,
  GetBuildingChatMessagesResponseSuccess,
  DashboardRequestParams,
  NotificationsCountResponseSuccess,
  SettingsEmailSetupProps,
  CommunicationEmailSuccessResponse,
  TourInquiriesRequestParams,
  TourInquiriesResponseSuccess,
  TourInquiryStatesResponseSuccess,
  ApplyInquiriesRequestParams,
  ApplyInquiriesResponseSuccess,
  ApplyInquiryStatesResponseSuccess,
} from 'api/types';
import {
  CampaignBuilding,
  CreateFilterInput,
  EntityFilterPayload,
  $FixTypeLater,
  WishlistBuildings,
  Message,
  MessageTemplateData,
  MessageTemplateFormData,
  EditMessageTemplateData,
  UnassignedBuildingsResponseSuccess,
} from 'types';

import API_PATHS from './apiPaths';
import { deleteData, getData, patchData, postData, putData } from './crudHandlers';

export const API_HANDLERS = {
  CONTACTS: {
    GET(data: ContactsRequestProps): AxiosResponseSuccess<ContactsResponseSuccess> {
      return getData<ContactsResponseSuccess>(API_PATHS.CONTACTS._(data));
    },
  },
  CONTACT: {
    CREATE(
      contactOwnerId: string,
      data: ContactCreateRequest,
    ): AxiosResponseSuccess<ContactCreateResponseSuccess> {
      return postData<ContactCreateResponseSuccess>(API_PATHS.CONTACT.CREATE(contactOwnerId), {
        data,
      });
    },
    EDIT(
      contactOwnerId: string,
      data: ContactEditRequest,
    ): AxiosResponseSuccess<ContactCreateResponseSuccess> {
      return patchData<ContactCreateResponseSuccess>(API_PATHS.CONTACT.EDIT(contactOwnerId), {
        data,
      });
    },
    EDIT_ASSIGNEE(
      data: ContactAssigneeEditRequest,
    ): AxiosResponseSuccess<ContactCreateResponseSuccess> {
      return postData<ContactCreateResponseSuccess>(
        API_PATHS.CONTACT.EDIT_ASSIGNEE(data.contactId),
        {
          data: {
            assigneeId: data.assigneeId,
          },
        },
      );
    },
    EDIT_CONTACT_OWNER(
      data: ContactOwnerEditRequest,
    ): AxiosResponseSuccess<ContactOwnerEditResponseSuccess> {
      return postData<ContactOwnerEditResponseSuccess>(
        API_PATHS.CONTACT.EDIT_CONTACT_OWNER(data.contactId),
        {
          data: {
            ownerId: data.ownerId,
          },
        },
      );
    },
    DETAILS: {
      GET(contactId: string): AxiosResponseSuccess<ContactDetailsResponseSuccess> {
        return getData<ContactDetailsResponseSuccess>(API_PATHS.CONTACT.DETAILS(contactId));
      },
    },
    SEARCH_DETAILS({
      contactId,
      content,
    }: SearchContactDetailsProps): AxiosResponseSuccess<CommunicationSearchResponseSuccess> {
      return getData<CommunicationSearchResponseSuccess>(
        API_PATHS.CONTACT.SEARCH_DETAILS(contactId),
        {
          params: {
            content,
          },
        },
      );
    },
  },
  EMPLOYEES: {
    GET_ALL(): AxiosResponseSuccess<EmployeesListResponseSuccess> {
      return getData(API_PATHS.EMPLOYEES.GET_ALL());
    },
    GET_BY_PAGE({
      pageNumber,
      limit,
    }: EmployeeListRequestProps): AxiosResponseSuccess<EmployeesListResponseSuccess> {
      return getData(API_PATHS.EMPLOYEES.GET_BY_PAGE({ pageNumber, limit }));
    },
    DELETE({ employeeId }: DeleteEmployeeParams): AxiosResponseSuccess<DeleteEmployeeResponse> {
      return getData(API_PATHS.EMPLOYEES.DELETE(employeeId));
    },
    CHANGE_ROLE({
      employeeId,
      roles,
    }: ChangeEmployeeRoleParams): AxiosResponseSuccess<ChangeEmployeeRoleResponse> {
      return postData<ChangeEmployeeRoleResponse>(API_PATHS.EMPLOYEES.CHANGE_ROLE(), {
        data: {
          employeeId,
          roles,
        },
      });
    },
    CHANGE_TEAM({
      employeeId,
      teamName,
    }: ChangeEmployeeTeamParams): AxiosResponseSuccess<ChangeEmployeeTeamResponse> {
      return postData<ChangeEmployeeTeamResponse>(API_PATHS.EMPLOYEES.CHANGE_TEAM(), {
        data: {
          employeeId,
          teamName,
        },
      });
    },
    TEAMS: {
      GET_ALL(): AxiosResponseSuccess<EmployeesTeamsListResponseSuccess> {
        return getData<EmployeesTeamsListResponseSuccess>(API_PATHS.EMPLOYEES.TEAMS.GET_ALL());
      },
    },
    ME: {
      NOTIFICATIONS_COUNT(): AxiosResponseSuccess<NotificationsCountResponseSuccess> {
        return getData(API_PATHS.EMPLOYEES.ME.NOTIFICATIONS_COUNT());
      },
    },
  },
  CAMPAIGN: {
    CREATE(data: CampaignCreateRequest): AxiosResponseSuccess<CampaignCreateResponseSuccess> {
      return postData<CampaignCreateResponseSuccess>(API_PATHS.CAMPAIGN.CREATE(), {
        data,
      });
    },
    EDIT(
      campaignId: string,
      data: CampaignEditRequest,
    ): AxiosResponseSuccess<CampaignEditResponseSuccess> {
      return patchData<CampaignEditResponseSuccess>(API_PATHS.CAMPAIGN.EDIT(campaignId), {
        data,
      });
    },
    GET_CAMPAIGN_STATUS_TRANSITION(
      campaignId: string,
    ): AxiosResponseSuccess<GetCampaignTransitionStatusesSuccess> {
      return getData(API_PATHS.CAMPAIGN.CAMPAIGN_STATUS_TRANSITION(campaignId));
    },
    CHANGE_CAMPAIGN_STATUS({
      campaignId,
      status,
    }: PatchCampaignTransitionStatusParams): AxiosResponseSuccess<ChangeCampaignTransitionStatusSuccess> {
      return patchData(API_PATHS.CAMPAIGN.CAMPAIGN_STATUS_TRANSITION(campaignId), {
        data: { status },
      });
    },
  },
  CAMPAIGNS: {
    GET(contactId: string): AxiosResponseSuccess<CampaignsListResponseSuccess> {
      return getData<CampaignsListResponseSuccess>(API_PATHS.CAMPAIGNS._(contactId));
    },
  },
  BUILDING: {
    GET_BY_ID({
      buildingId,
      campaignId,
    }: GetCampaignBuildingDetailsParams): AxiosResponseSuccess<CampaignBuilding> {
      return getData<CampaignBuilding>(API_PATHS.BUILDING.GET_BY_ID({ buildingId, campaignId }));
    },
  },
  BUILDINGS: {
    GET(buildingParams: GetBuildingsParams): AxiosResponseSuccess<BuildingListResponseSuccess> {
      return getData(API_PATHS.BUILDINGS.GET(buildingParams));
    },
    ADD_TO_CAMPAIGN({
      campaignId,
      buildingId,
    }: AddBuildingToCampaignParams): AxiosResponseSuccess<CampaignBuilding> {
      return postData(API_PATHS.BUILDINGS.ADD(campaignId), {
        data: { externalBuildingId: buildingId },
      });
    },
    EDIT({ contactId, buildingId, data }: EditBuildingParams): AxiosResponseSuccess<EditBuilding> {
      return patchData(API_PATHS.BUILDINGS.EDIT(buildingId, contactId), { data });
    },
    DISMISS({
      buildingId,
      campaignId,
    }: DeleteBuildingParams): AxiosResponseSuccess<DeleteBuilding> {
      return postData(API_PATHS.BUILDINGS.DISMISS(buildingId, campaignId));
    },
    SEND({
      campaignId,
      buildingIds,
    }: {
      campaignId: string;
      buildingIds: string[];
    }): AxiosResponseSuccess<SendBuilding> {
      return postData(API_PATHS.BUILDINGS.SEND(campaignId), { data: { buildingIds } });
    },
  },
  INQUIRIES: {
    CREATE_TOUR({
      data,
      campaignId,
      buildingId,
    }: CreateTourParams): AxiosResponseSuccess<CreateTourResponseSuccess> {
      return postData(API_PATHS.INQUIRIES.CREATE_TOUR, {
        data: { ...data, campaignId, buildingId },
      });
    },
    CREATE_APPLY({
      campaignId,
      buildingId,
    }: CreateApplyParams): AxiosResponseSuccess<CreateApplyResponseSuccess> {
      return postData(API_PATHS.INQUIRIES.CREATE_APPLY, {
        data: { campaignId, buildingId },
      });
    },
    EDIT_TOUR({ id, data }: EditTourParams): AxiosResponseSuccess<EditTourResponseSuccess> {
      return patchData(API_PATHS.INQUIRIES.EDIT_TOUR(id), { data });
    },
    EDIT_TOUR_DETAILS({
      id,
      data,
    }: EditInquiryDetailsParams): AxiosResponseSuccess<EditTourResponseSuccess> {
      return patchData(API_PATHS.INQUIRIES.EDIT_TOUR(id), { data });
    },
    EDIT_APPLY_DETAILS({
      id,
      data,
    }: EditApplyDetailsParams): AxiosResponseSuccess<EditApplyResponseSuccess> {
      return patchData(API_PATHS.INQUIRIES.EDIT_APPLY(id), { data });
    },
    GET_APPLY_STATUS_TRANSITION({
      id,
    }: GetInquiryTransitionStatusesParams): AxiosResponseSuccess<GetInquiryTransitionStatusesSuccess> {
      return getData(API_PATHS.INQUIRIES.APPLY_STATUS_TRANSITION(id));
    },
    GET_TOUR_STATUS_TRANSITION({
      id,
    }: GetInquiryTransitionStatusesParams): AxiosResponseSuccess<GetInquiryTransitionStatusesSuccess> {
      return getData(API_PATHS.INQUIRIES.TOUR_STATUS_TRANSITION(id));
    },
    CHANGE_APPLY_STATUS({
      id,
      statusId,
    }: PatchInquiryTransitionStatusParams): AxiosResponseSuccess<ChangeInquiryTransitionStatusSuccess> {
      return patchData(API_PATHS.INQUIRIES.APPLY_STATUS_TRANSITION(id), {
        params: { stateId: statusId },
      });
    },
    CHANGE_TOUR_STATUS({
      id,
      statusId,
    }: PatchInquiryTransitionStatusParams): AxiosResponseSuccess<ChangeInquiryTransitionStatusSuccess> {
      return patchData(API_PATHS.INQUIRIES.TOUR_STATUS_TRANSITION(id), {
        params: { stateId: statusId },
      });
    },
    ASSIGN_TOUR({
      inquiryId,
      assignee,
    }: AssignInquiryParams): AxiosResponseSuccess<AssignTourResponseSuccess> {
      return patchData(API_PATHS.INQUIRIES.ASSIGN_TOUR(inquiryId), {
        data: { ...assignee },
      });
    },
    ASSIGN_APPLY({
      inquiryId,
      assignee,
    }: AssignInquiryParams): AxiosResponseSuccess<AssignApplyResponseSuccess> {
      return patchData(API_PATHS.INQUIRIES.ASSIGN_APPLY(inquiryId), {
        data: { ...assignee },
      });
    },
    GET_TOUR_INQUIRIES(
      params: TourInquiriesRequestParams,
    ): AxiosResponseSuccess<TourInquiriesResponseSuccess> {
      return getData(API_PATHS.INQUIRIES.GET_TOUR_INQUIRIES, {
        params,
      });
    },
    GET_TOUR_INQUIRIES_STATES(): AxiosResponseSuccess<TourInquiryStatesResponseSuccess> {
      return getData(API_PATHS.INQUIRIES.GET_TOUR_INQUIRIES_STATES);
    },
    GET_APPLY_INQUIRIES(
      params: ApplyInquiriesRequestParams,
    ): AxiosResponseSuccess<ApplyInquiriesResponseSuccess> {
      return getData(API_PATHS.INQUIRIES.GET_APPLY_INQUIRIES, {
        params,
      });
    },
    GET_APPLY_INQUIRIES_STATES(): AxiosResponseSuccess<ApplyInquiryStatesResponseSuccess> {
      return getData(API_PATHS.INQUIRIES.GET_APPLY_INQUIRIES_STATES);
    },
  },
  COMMUNICATIONS: {
    POST_MESSAGE(formData: FormData): AxiosResponseSuccess<PostMessageResponse> {
      return postData(API_PATHS.COMMUNICATIONS.POST_MESSAGE, {
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    GET_MESSAGES({
      contactId,
      targetMessageId,
      direction,
      sources,
    }: GetContactMessagesProps): AxiosResponseSuccess<{ messages: Message[] }> {
      return getData(API_PATHS.COMMUNICATIONS.GET_MESSAGES, {
        params: { contactId, targetMessageId, direction, sources },
      });
    },
    BUILDING_CHATS(contactId: string): AxiosResponseSuccess<GetBuildingChatsResponseSuccess> {
      return getData<GetBuildingChatsResponseSuccess>(API_PATHS.COMMUNICATIONS.BUILDING_CHATS, {
        params: { contactId },
      });
    },
    BUILDING_CHAT_MESSAGES(
      params: BuildingChatMessagesRequestParams,
    ): AxiosResponseSuccess<GetBuildingChatMessagesResponseSuccess> {
      return getData<GetBuildingChatMessagesResponseSuccess>(
        API_PATHS.COMMUNICATIONS.BUILDING_CHAT_MESSAGES,
        {
          params: { ...params },
        },
      );
    },
    POST_BUILDING_CHAT_MESSAGE(formData: FormData): AxiosResponseSuccess<$FixTypeLater> {
      return postData(API_PATHS.COMMUNICATIONS.POST_BUILDING_CHAT_MESSAGE, {
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    GET_ATTACHMENT(attachmentId: string): AxiosResponseSuccess<$FixTypeLater> {
      return getData(API_PATHS.COMMUNICATIONS.GET_ATTACHMENT(attachmentId), {
        responseType: 'blob',
      });
    },
  },
  MESSAGE_TEMPLATES: {
    GET_MESSAGE_TEMPLATES(
      params: GetMessageTemplatesParams,
    ): AxiosResponseSuccess<GetMessageTemplatesResponseSuccess> {
      return getData(API_PATHS.MESSAGE_TEMPLATES.GET_MESSAGE_TEMPLATES(params));
    },
  },
  MESSAGE_TEMPLATE: {
    GET_BY_ID(templateId: string): AxiosResponseSuccess<MessageTemplateData> {
      return getData(API_PATHS.MESSAGE_TEMPLATE.GET_BY_ID(templateId));
    },
    CREATE(data: MessageTemplateData): AxiosResponseSuccess<GetMessageTemplatesResponseSuccess> {
      return postData<GetMessageTemplatesResponseSuccess>(API_PATHS.MESSAGE_TEMPLATE.CREATE(), {
        data,
      });
    },
    EDIT(data: EditMessageTemplateData): AxiosResponseSuccess<GetMessageTemplatesResponseSuccess> {
      return putData<GetMessageTemplatesResponseSuccess>(API_PATHS.MESSAGE_TEMPLATE.EDIT(), {
        data,
      });
    },
    DELETE(templateId: string): AxiosResponseSuccess<$FixTypeLater> {
      return deleteData(API_PATHS.MESSAGE_TEMPLATE.DELETE(templateId));
    },
    GET_MESSAGE_TEMPLATE_FIELDS(
      event: MessageTemplateFormData['event'],
    ): AxiosResponseSuccess<GetTemplateFieldsResponseSuccess> {
      return getData(API_PATHS.MESSAGE_TEMPLATE.GET_MESSAGE_TEMPLATE_FIELDS(event));
    },
  },
  FILTERS: {
    GET_DESCRIPTORS(): AxiosResponseSuccess<GetFilterDescriptorsResponseSuccess> {
      return getData(API_PATHS.FILTERS.DESCRIPTORS);
    },
    GET(): AxiosResponseSuccess<GetFiltersPresets> {
      return getData(API_PATHS.FILTERS._);
    },
    CREATE({ data }: { data: EntityFilterPayload }): AxiosResponseSuccess<CreateFilterInput> {
      return postData(API_PATHS.FILTERS._, { data });
    },
    DELETE(filterId: string): AxiosResponseSuccess<$FixTypeLater> {
      return deleteData(API_PATHS.FILTERS.DELETE(filterId));
    },
  },
  WISHLIST: {
    GET(contactId: string): AxiosResponseSuccess<WishlistBuildings> {
      return getData<WishlistBuildings>(API_PATHS.WISHLIST._(contactId));
    },
  },
  UNASSIGNED_BUILDINGS: {
    GET(contactId: string): AxiosResponseSuccess<UnassignedBuildingsResponseSuccess> {
      return getData<UnassignedBuildingsResponseSuccess>(
        API_PATHS.UNASSIGNED_BUILDINGS._(contactId),
      );
    },
  },
  DASHBOARD: {
    GET(params: DashboardRequestParams): AxiosResponseSuccess<DashboardResponseSuccess> {
      return getData<DashboardResponseSuccess>(API_PATHS.DASHBOARD._(), {
        params,
      });
    },
  },
  SETTINGS: {
    COMMUNICATION_EMAIL: {
      GET(): AxiosResponseSuccess<CommunicationEmailSuccessResponse> {
        return getData<CommunicationEmailSuccessResponse>(API_PATHS.SETTINGS._());
      },
    },
    SETUP: {
      GET(params: SettingsEmailSetupProps): AxiosResponseSuccess<string> {
        return getData<string>(API_PATHS.SETTINGS.COMMUNICATION_EMAIL_SETUP(), {
          params,
        });
      },
    },
  },
};
