import { EMPTY_VALUE, DATE_FORMAT_WITH_TIME_AND_COMMA } from 'constants/index';
import { getContactOwnerTableValue, transformSummaryValues } from 'features/contacts/utils';
import { Contact, ContactsTable } from 'types';
import { getFormattedDateWithTime } from 'utils';

export function contactsTableAdapter(contacts: Contact[] | undefined): ContactsTable[] {
  if (!contacts) {
    return [];
  }

  return contacts.map(({ id, firstName, lastName, phone, email, owner, createdAt, summary }) => ({
    id: id,
    firstName: firstName || EMPTY_VALUE,
    lastName: lastName || EMPTY_VALUE,
    phone: phone || EMPTY_VALUE,
    email: email || EMPTY_VALUE,
    campaignSummary: transformSummaryValues(summary),
    contactOwner: owner
      ? getContactOwnerTableValue({
          picture: owner.user.picture,
          familyName: owner.user.familyName,
          givenName: owner.user.givenName,
        })
      : 'owner',
    createdAt: getFormattedDateWithTime(new Date(createdAt), DATE_FORMAT_WITH_TIME_AND_COMMA),
  }));
}
