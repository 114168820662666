import { ReactElement, useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { PageStatus } from 'components';
import { CustomCollapse } from 'components/CustomCollapse';
import { CampaignEditForm } from 'features/campaigns/components/CampaignEditForm';
import { CampaignsSectionContent } from 'features/campaigns/containers';
import { CommunicationsBox } from 'features/communications';
import {
  ContactDetailsProvider,
  PageSectionsState,
} from 'features/contactDetails/context/ContactDetailsPageContext';
import { ContactEditFormModal } from 'features/contacts';
import { CreateTour, EditTour } from 'features/inquiry/components';
import { useEditTourModal } from 'features/inquiry/hooks/useEditTourModal';
import { useWatcher } from 'hooks';

import { OpenCreateTourContext, OpenEditTourContext } from '../../../inquiry/context';
import { useCreateTourModal } from '../../../inquiry/hooks';
import { contactDetailsAdapter } from '../../adapters';
import { ContactPageHeader, InfoPanel } from '../../components';
import { useContactDetails, useSelectDetailsItem } from './hooks';
import { CampaignsSection, CommunicationsW, MainContentW, PageW } from './styled';
import { getMainContentWidth } from './utils';

function ContactDetails(): ReactElement {
  const {
    handleGoBack,
    isLoading,
    emptyState,
    pageTitle,
    contact,
    selectedCampaign,
    editContactModalControl,
    editCampaignModalControl,
  } = useContactDetails();

  const {
    buildingId: createTourBuildingId,
    isOpen: isCreateTourOpen,
    handleCloseModal: handleCloseCreateTour,
    handleOpenModal: handleOpenCreateTour,
    campaignId,
  } = useCreateTourModal();

  const {
    handleOpenModal: handleOpenEditTour,
    handleCloseModal: handleCloseEditTour,
    isOpen: isEditTourOpen,
    editTourId,
    defaultValues: editTourDefaultValues,
  } = useEditTourModal();

  useWatcher(
    [
      handleCloseEditTour,
      handleCloseCreateTour,
      editContactModalControl.handleCloseModal,
      editCampaignModalControl.handleCloseModal,
    ],
    contact?.id,
  );

  useSelectDetailsItem();

  const contactFullName =
    contact?.firstName && contact?.lastName
      ? `${contact?.firstName} ${contact?.lastName}`
      : undefined;

  const helmetTitle = isLoading ? undefined : contactFullName || contact?.email || contact?.phone;

  const pageSections = useContext(PageSectionsState);

  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>

      <PageStatus loading={isLoading} emptyState={emptyState}>
        <PageW>
          <ContactPageHeader
            contactFullName={contactDetailsAdapter(contact).fullName}
            handleGoBack={handleGoBack}
            pageTitle={pageTitle}
          />
          <MainContentW>
            <CommunicationsW sx={{ width: getMainContentWidth(pageSections) }}>
              <CommunicationsBox />
            </CommunicationsW>

            <CustomCollapse
              orientation="horizontal"
              in={Boolean(pageSections?.includes('campaigns'))}
            >
              {() => (
                <CampaignsSection hasExtendedWidth={!Boolean(pageSections?.includes('infoPanel'))}>
                  <OpenEditTourContext.Provider value={handleOpenEditTour}>
                    <OpenCreateTourContext.Provider value={handleOpenCreateTour}>
                      <CampaignsSectionContent campaigns={contact?.campaigns || []} />
                    </OpenCreateTourContext.Provider>
                  </OpenEditTourContext.Provider>
                </CampaignsSection>
              )}
            </CustomCollapse>

            <CustomCollapse
              orientation="horizontal"
              in={Boolean(pageSections?.includes('infoPanel'))}
            >
              {() => (
                <InfoPanel
                  contact={contact}
                  selectedCampaign={selectedCampaign}
                  handleOpenContactForm={editContactModalControl.handleOpenModal}
                  handleOpenCampaignForm={editCampaignModalControl.handleOpenModal}
                />
              )}
            </CustomCollapse>
          </MainContentW>
          {editContactModalControl.isOpen && (
            <ContactEditFormModal
              isOpen={editContactModalControl.isOpen}
              handleClose={editContactModalControl.handleCloseModal}
              contact={contact}
            />
          )}
          <CampaignEditForm
            isOpen={editCampaignModalControl.isOpen}
            handleClose={editCampaignModalControl.handleCloseModal}
            campaign={selectedCampaign}
          />
          <CreateTour
            campaignId={campaignId}
            buildingId={createTourBuildingId}
            handleClose={handleCloseCreateTour}
            isOpen={isCreateTourOpen}
          />
          <EditTour
            tourId={editTourId}
            handleClose={handleCloseEditTour}
            isOpen={isEditTourOpen}
            defaultValues={editTourDefaultValues}
          />
        </PageW>
      </PageStatus>
    </>
  );
}

export const ContactDetailsPage = (): ReactElement => (
  <ContactDetailsProvider>
    <ContactDetails />
  </ContactDetailsProvider>
);
