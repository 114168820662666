import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { ApplyInquiriesRequestParams, ApplyInquiriesResponseSuccess } from 'api/types';

type UsApplyInquiriesQueryParams = ApplyInquiriesRequestParams;

interface UseApplyInquiriesQueryReturn {
  applyInquiriesData: ApplyInquiriesResponseSuccess | undefined;
  isApplyInquiriesLoading: boolean;
  isApplyInquiriesError: boolean;
}

export function useApplyInquiriesQuery({
  page,
  limit,
  search,
  assigneeId,
  statusId,
}: UsApplyInquiriesQueryParams): UseApplyInquiriesQueryReturn {
  const { data, isLoading, isFetching, isError } = useQuery(
    ['apply-table', page, limit, search, assigneeId, statusId],
    () =>
      API_HANDLERS.INQUIRIES.GET_APPLY_INQUIRIES({
        page,
        limit,
        ...(search && { search }),
        ...(assigneeId && { assigneeId }),
        ...(statusId && { statusId }),
      }),
  );

  return {
    applyInquiriesData: data?.data,
    isApplyInquiriesLoading: isLoading || isFetching,
    isApplyInquiriesError: isError,
  };
}
