import { ReactElement } from 'react';

import { EventPayloadTourRequested } from 'types';

import { Link } from './styled';

interface TourRequestedContentProps {
  data: EventPayloadTourRequested;
  onInquiryClick: (inquiryId: string, buildingId?: string, campaignId?: string) => void;
  onBuildingClick: (buildingId: string, campaignId?: string) => void;
  onCampaignClick: (campaignId: string) => void;
}

export function TourRequestedContent({
  data,
  onInquiryClick,
  onBuildingClick,
  onCampaignClick,
}: TourRequestedContentProps): ReactElement {
  const { tourInquiryId, building, campaign } = data;

  return (
    <span>
      <Link onClick={() => onInquiryClick(tourInquiryId, building.id, campaign?.id)}>Tour</Link>{' '}
      created for building{' '}
      <Link onClick={() => onBuildingClick(building.id, campaign?.id)}>{building.name}</Link>
      {!!campaign && (
        <>
          {' '}
          in <Link onClick={() => onCampaignClick(campaign.id)}>{campaign.name}</Link>
        </>
      )}
    </span>
  );
}
