import Select from '@mui/material/Select';
import { ReactElement } from 'react';

import { messageSourceLabels, messageSourceValues } from 'features/communications/config';
import { MessageSourceType } from 'types';

import { inputProps, selectStyles, MenuProps } from './config';
import { useMessagesTypeSelect } from './hooks';
import { Checkbox, FormControlW, ListItemText, MenuItem, SelectLabel } from './styled';

export function MessagesTypeSelect(): ReactElement {
  const { isAllTypesSelected, messageTypes, handleChange } = useMessagesTypeSelect();

  return (
    <FormControlW variant="outlined">
      <Select
        labelId="message-types"
        id="message-types-checkbox"
        multiple
        value={messageTypes}
        onChange={handleChange}
        renderValue={(selected) => (
          <>
            <SelectLabel>Message type:</SelectLabel>
            {isAllTypesSelected
              ? 'All'
              : selected.map((type) => messageSourceLabels[type]).join(', ')}
          </>
        )}
        MenuProps={MenuProps}
        inputProps={inputProps}
        sx={selectStyles}
      >
        <MenuItem key="All" value="ALL" disabled={isAllTypesSelected}>
          <Checkbox checked={isAllTypesSelected} />
          <ListItemText primary="All" />
        </MenuItem>
        {messageSourceValues.map((value: MessageSourceType) => (
          <MenuItem
            key={value}
            value={value}
            disabled={messageTypes.length === 1 && messageTypes.includes(value)}
          >
            <Checkbox checked={messageTypes.indexOf(value) > -1 || isAllTypesSelected} />
            <ListItemText primary={messageSourceLabels[value]} />
          </MenuItem>
        ))}
      </Select>
    </FormControlW>
  );
}
