import { ReactElement, useEffect, useState } from 'react';

import { getFormattedDateLabel } from 'utils';

interface TimeAgoDateCounter {
  date: string;
  refreshRateMs?: number;
}

export function TimeAgoDateCounter({
  date,
  refreshRateMs = 60000,
}: TimeAgoDateCounter): ReactElement {
  const [dateLabel, setDateLabel] = useState(() => getFormattedDateLabel(new Date(date)));

  useEffect(() => {
    const timer = setTimeout(
      () => setDateLabel(getFormattedDateLabel(new Date(date))),
      refreshRateMs,
    );
    return () => clearTimeout(timer);
  }, [refreshRateMs, date]);

  return <>{dateLabel}</>;
}
