import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { FilterPresetList } from 'types';

interface UseFiltersListReturn {
  filtersListError: unknown;
  filtersList: FilterPresetList;
}

export function useFiltersList(): UseFiltersListReturn {
  const { error: filtersListError, data: filtersData } = useQuery(
    'filter-presets',
    API_HANDLERS.FILTERS.GET,
  );

  return {
    filtersListError,
    filtersList: filtersData?.data.filterPresets || [],
  };
}
