import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Avatar, Box, styled } from '@mui/material';

import { ContactAssignee, SelectOption } from 'types';
import { getFullName, determineIfIsError, getFullNameShort } from 'utils';

interface EmployeesOptionsAdapterProps {
  employeesData?: ContactAssignee[];
  contactOwner: string;
}

const AvatarW = styled(Box)`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
`;

const AvatarOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #34966d;
`;

const IconW = styled(CheckRoundedIcon)`
  color: #fff;
  width: 13px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export function employeesOptionsAdapter({
  employeesData,
  contactOwner,
}: EmployeesOptionsAdapterProps): SelectOption[] {
  if (!Array.isArray(employeesData) || !employeesData || determineIfIsError(employeesData)) {
    return [];
  }

  return employeesData.map(({ id, firstName, lastName, photo }) => ({
    label: (
      <>
        <AvatarW>
          <Avatar alt="contact owner avatar" src={photo} sx={{ width: 24, height: 24 }} />
          {contactOwner === id && (
            <>
              <AvatarOverlay />
              <IconW />
            </>
          )}
        </AvatarW>
        {getFullName({ firstName, lastName })}
      </>
    ),
    value: id,
    displayLabel: getFullNameShort({ firstName, lastName }),
  }));
}
