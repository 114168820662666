import { Theme } from '@mui/material';

import { ButtonSizes } from './interface';

export const buttonSizes = {
  [ButtonSizes.xSmall]: {
    padding: '6px 10px',
    gap: '4.5px',
    fontSize: '14px',
  },
  [ButtonSizes.small]: {
    padding: '8px 14px',
    gap: '4.5px',
  },
  [ButtonSizes.base]: {
    padding: '10px 18px',
    gap: '8.5px',
  },
};

export const buttonLoaderSizes = {
  [ButtonSizes.xSmall]: 16,
  [ButtonSizes.small]: 16,
  [ButtonSizes.base]: 24,
};

export const getSecondaryButtonStyles = (
  theme: Theme,
): { [key: string]: string | { [key: string]: string } } => ({
  color: theme.palette.black.main,
  background: theme.palette.secondary.p020,
  '&:hover': {
    backgroundColor: theme.palette.secondary.p020,
  },
});
