import { Box, styled, Typography } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
}));

export const IconWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const Counter = styled(Typography)(() => ({
  fontSize: '14px',
  marginLeft: '4px',
}));
