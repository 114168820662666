import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { styled, Typography } from '@mui/material';

export const FileItemW = styled('div')`
  width: 300px;
  overflow: hidden;
  background: ${({ theme }) => theme.palette.secondary.p010};
  padding: 8px 10px 8px 16px;
  border-radius: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FileName = styled(Typography)`
  width: 150px;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.black.main};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CancelIcon = styled(CancelRoundedIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.secondary.main};
`;
