import * as yup from 'yup';

import { getMaxLengthText, USE_DATE_FORMAT, NAME_PATTER_SYMBOLS } from 'validations/messages';
import { LETTERS_DIGITS_SYMBOLS } from 'validations/regexps';
import { onlyFutureDate } from 'validations/tests';

export const inquiryDetailsFormSchema = yup.object().shape({
  guestCardSent: yup.boolean(),
  confirmedTourDate: yup.mixed().when({
    is: (v: unknown) => v instanceof Date,
    then(sch) {
      return sch.test('confirmedTourDate', USE_DATE_FORMAT, onlyFutureDate);
    },
  }),
  pricingAndAvailability: yup
    .string()
    .matches(LETTERS_DIGITS_SYMBOLS, NAME_PATTER_SYMBOLS)
    .max(1000, getMaxLengthText(1000))
    .nullable(),
  commentResponse: yup
    .string()
    .matches(LETTERS_DIGITS_SYMBOLS, NAME_PATTER_SYMBOLS)
    .max(1000, getMaxLengthText(1000))
    .nullable(),
  notes: yup
    .string()
    .matches(LETTERS_DIGITS_SYMBOLS, NAME_PATTER_SYMBOLS)
    .max(1000, getMaxLengthText(1000))
    .nullable(),
});
