import { MessageType } from 'types';

// in MB
const EMAILS_MAX_FILES_SIZE = 25;
const SMS_MAX_FILES_SIZE = 4.5;

// count MB to Bytes
export const TOTAL_SIZE_LIMIT: Record<string, number> = {
  [MessageType.OUTGOING_EMAIL]: EMAILS_MAX_FILES_SIZE * 1000000,
  [MessageType.OUTGOING_SMS]: SMS_MAX_FILES_SIZE * 1000000,
};

export const LIMIT_SIZE: Record<string, number> = {
  [MessageType.OUTGOING_EMAIL]: EMAILS_MAX_FILES_SIZE,
  [MessageType.OUTGOING_SMS]: SMS_MAX_FILES_SIZE,
};
