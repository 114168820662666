export const PROPERTY_NAME_LABELS: Record<string, string> = {
  updatedAt: 'Updated at',
  createdAt: 'Creation date',
  firstName: 'Name',
  lastName: 'Last name',
  email: 'Email',
  phone: 'Phone',
  contactStyle: 'Contact style',
  cameFrom: 'Came from',
  externalId: 'External id',
  rentPrice: 'Rent price',
};

export const CAME_FROM_LABELS: Record<string, string> = {
  default: 'Default',
  facebook: 'Facebook groups',
  'lighthouse-web-site': 'Lighthouse website',
  'personal-reference': 'Personal referral',
  'secondary-contact': 'Secondary contact',
};
