import { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { MessageRequestDirection } from 'api/types';
import { loadBuildingChatMessages } from 'features/communications';
import { useDispatch } from 'hooks';
import { BuildingChatMessage } from 'types';

interface UseBuildingChatMessagesProps {
  targetMessageId?: string | null;
  buildingId: string;
  contactId: string;
  inView: boolean;
}

interface UseBuildingChatMessagesReturn {
  messages: BuildingChatMessage[];
  isLoadingMessages: boolean;
}

export function useBuildingChatMessagesQuery({
  buildingId,
  contactId,
  inView,
}: UseBuildingChatMessagesProps): UseBuildingChatMessagesReturn {
  const dispatch = useDispatch();

  const {
    data: chatData,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    [`building-chat-messages/${buildingId}`, buildingId],
    ({ pageParam = undefined }) =>
      API_HANDLERS.COMMUNICATIONS.BUILDING_CHAT_MESSAGES({
        contactId,
        buildingId,
        ...(pageParam && { targetMessageId: pageParam, direction: MessageRequestDirection.DOWN }),
      }),
    {
      cacheTime: 0,
      onSuccess: (resp) => {
        dispatch(
          loadBuildingChatMessages({
            messages: resp.pages.map((page) => page.data.messages).flat() || [],
          }),
        );
      },
      getNextPageParam: (lastPage) => {
        return lastPage.data.messages.length === 20
          ? lastPage.data.messages[lastPage.data.messages.length - 1].id
          : undefined;
      },
    },
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  return {
    messages: chatData?.pages.map((page) => page.data.messages).flat() || [],
    isLoadingMessages: isLoading,
  };
}
