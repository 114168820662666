import * as yup from 'yup';

import {
  EMAIL_FORMAT,
  getMaxLengthText,
  getMinLengthText,
  NAME_PATTERN,
  REQUIRED,
} from 'validations/messages';
import { LETTERS_DIGITS_DASHES_SPACES_SINGLE_QUOTE_REGEX, EMAIL_REGEX } from 'validations/regexps';
import { validatePhoneNumber } from 'validations/tests';

export const contactFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required(REQUIRED)
    .matches(LETTERS_DIGITS_DASHES_SPACES_SINGLE_QUOTE_REGEX, NAME_PATTERN)
    .min(2, getMinLengthText(2))
    .max(50, getMaxLengthText(50))
    .nullable(),
  lastName: yup
    .string()
    .trim()
    .required(REQUIRED)
    .matches(LETTERS_DIGITS_DASHES_SPACES_SINGLE_QUOTE_REGEX, NAME_PATTERN)
    .min(2, getMinLengthText(2))
    .max(50, getMaxLengthText(50))
    .nullable(),
  email: yup
    .string()
    .required(REQUIRED)
    .matches(EMAIL_REGEX, EMAIL_FORMAT)
    .max(66, getMaxLengthText(66))
    .nullable(),
  phone: yup.string().required(REQUIRED).test(validatePhoneNumber).nullable(),
});
