import { ReactElement, useState } from 'react';

import { ShowMoreButton } from 'components';
import { EMPTY_VALUE } from 'constants/index';
import { EventPayloadCampaignUpdated, SystemMessageCampaignKeys } from 'types';

import { CAMPAIGN_FIELDS_DISPLAY_NAMES } from '../config';
import { CampaignUpdatedItem, CampaignUpdatedList, Link, TextBold } from './styled';
import { getDisplayFieldValue } from './utils';

interface CampaignUpdatedContentProps {
  data: EventPayloadCampaignUpdated;
  onCampaignClick: (campaignId: string) => void;
}

export function CampaignUpdatedContent({
  data,
  onCampaignClick,
}: CampaignUpdatedContentProps): ReactElement {
  const { campaignId, campaignName, from, to } = data;
  const [isDetailsShown, setIsDetailsShown] = useState<boolean>(false);

  const handleShowDetails = () => {
    setIsDetailsShown(true);
  };

  const updatedFields = Object.keys(to).filter(
    (field) =>
      from[field as SystemMessageCampaignKeys]?.toString().trim() !==
      to[field as SystemMessageCampaignKeys]?.toString().trim(),
  );

  return (
    <span>
      <Link onClick={() => onCampaignClick(campaignId)}>{campaignName}</Link> information was
      updated
      {!isDetailsShown && <ShowMoreButton onClick={handleShowDetails}></ShowMoreButton>}
      {isDetailsShown && (
        <CampaignUpdatedList>
          {updatedFields.map((field) => {
            const fromValue = getDisplayFieldValue(field, from[field as SystemMessageCampaignKeys]);
            const toValue = getDisplayFieldValue(field, to[field as SystemMessageCampaignKeys]);

            return (
              <CampaignUpdatedItem key={field}>
                <TextBold sx={{ marginRight: '4px' }}>
                  {CAMPAIGN_FIELDS_DISPLAY_NAMES[field as SystemMessageCampaignKeys]}:
                </TextBold>

                {fromValue ? fromValue : EMPTY_VALUE}
                {' \u2192 '}
                {toValue ? toValue : EMPTY_VALUE}
              </CampaignUpdatedItem>
            );
          })}
        </CampaignUpdatedList>
      )}
    </span>
  );
}
