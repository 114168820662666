import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import { CampaignBuilding } from 'types';

interface UseCreateApplyReturn {
  isLoading: boolean;
  handleCreateApply: () => Promise<void>;
}

export function useCreateApply(
  buildingId: CampaignBuilding['id'],
  campaignId: string,
): UseCreateApplyReturn {
  const { mutateAsync: createApplyMutation, isLoading } = useMutation(
    API_HANDLERS.INQUIRIES.CREATE_APPLY,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contact');
      },
    },
  );

  const handleCreateApply = async () => {
    await createApplyMutation({ buildingId, campaignId });

    alert.success('Application successfully created');
  };

  return { isLoading, handleCreateApply };
}
