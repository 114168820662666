import { styled } from '@mui/material';

import { ReactComponent as AttachmentIcon } from 'assets/icons/attachment.svg';

export const Attachment = styled(AttachmentIcon)`
  width: 23px;
`;

export const DropZoneW = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 12px;
`;

export const FilesContainer = styled('div')`
  max-height: 120px;
  overflow: hidden auto;
  padding-right: 5px;
`;
