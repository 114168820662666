import { useEffect, useMemo } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { queryClient } from 'api/queryClient';
import { alert } from 'components';
import { CampaignBuilding, SendBuildingsFormValues } from 'types';

interface UseSendBuildingsFormReturn {
  control: Control<SendBuildingsFormValues>;
  handleSubmit: () => void;
  isDisabled: boolean;
  isLoading: boolean;
}

interface UseSendBuildingsFormProps {
  campaignId: string;
  buildings: CampaignBuilding[];
  close: () => void;
}

export function useSendBuildingsForm({
  campaignId,
  buildings,
  close,
}: UseSendBuildingsFormProps): UseSendBuildingsFormReturn {
  const defaultValues = useMemo(
    () => ({
      buildings: buildings.map((building) => building.id),
    }),
    [buildings],
  );

  const {
    control,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm<SendBuildingsFormValues>({
    defaultValues,
    mode: 'all',
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const selectedValues = watch('buildings') || [];

  const { mutateAsync: sendBuildingsMutation, isLoading } = useMutation(
    API_HANDLERS.BUILDINGS.SEND,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contact');
        alert.success(`Buildings sent!`);
        close();
      },
      onError: () => {
        close();
      },
    },
  );

  async function handleSubmit() {
    await sendBuildingsMutation({ campaignId, buildingIds: selectedValues });
  }

  return {
    control,
    handleSubmit,
    isDisabled: selectedValues?.length === 0,
    isLoading: isSubmitting || isLoading,
  };
}
