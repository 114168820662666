import { Box, styled, Typography } from '@mui/material';

export const MessageW = styled('div', {
  shouldForwardProp: (propname) => propname !== 'isPrivateNote' && propname !== 'isReceived',
})<{
  isReceived: boolean;
  isPrivateNote: boolean;
  width?: string;
}>(({ theme, isReceived, width = '320px', isPrivateNote }) => ({
  alignSelf: isReceived ? 'flex-start' : 'flex-end',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  alignItems: 'flex-start',
  width,
  padding: '12px 16px',
  border: `1px solid ${theme.palette.secondary.p050}`,
  borderRadius: `12px`,
  borderBottomRightRadius: isReceived ? '12px' : 0,
  borderBottomLeftRadius: isReceived ? 0 : '12px',
  background: isPrivateNote ? theme.palette.warning.p010 : theme.palette.info.p005,
  borderColor: isPrivateNote ? theme.palette.warning.main : theme.palette.info.p050,
}));

export const Footer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const Content = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '16px',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  color: theme.palette.black.main,
}));
