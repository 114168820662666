import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { CommunicationAvatar, MessageItem } from 'components';
import { EmployeesContext } from 'context';
import { GeneralChatState } from 'features/communications/context';
import { PageSectionsState } from 'features/contactDetails/context/ContactDetailsPageContext';
import { useAuth, useMergedRefs, useScrollQueue } from 'hooks';
import { InViewItemRef, MessageType, ReplyToEmailParams, TextMessageType } from 'types';
import { parseUserMentions } from 'utils';
import entitySelectHandler from 'utils/entitySelectHandler';

import { ItemW } from './styled';
import { getMessageWidth, getIsReceived } from './utils';

interface CommunicationItemProps {
  data: TextMessageType;
  handleReplyToEmail: (data: ReplyToEmailParams) => void;
  lastItemRef?: InViewItemRef;
  firstItemRef?: InViewItemRef;
  readMessage: (messageId: string) => void;
  isFirstUnread: boolean;
  canScrollToFirstUnread: boolean;
}

const handleSelectCommunicationItem = () => {};

export function CommunicationItem({
  data,
  handleReplyToEmail,
  lastItemRef,
  firstItemRef,
  readMessage,
  isFirstUnread,
  canScrollToFirstUnread,
}: CommunicationItemProps): ReactElement {
  const pageSections = useContext(PageSectionsState);
  const { employeesList } = useContext(EmployeesContext);
  const { targetMessageId } = useContext(GeneralChatState);

  const [isRead, setIsRead] = useState(data.acknowledged);

  const defaultRef = useRef(null);

  const { employee } = useAuth();

  const [inViewRef, inView] = useInView({ triggerOnce: true });

  const { acknowledged, id, payload, type, createdAt } = data;

  const { subject, emailAttachments, threadMessages, media, state, errorCode, text } = payload;

  const received = getIsReceived({
    senderId: payload?.sender?.id,
    employeeId: employee?.id,
    messageType: type,
  });

  const sender = payload?.sender ?? null;

  const { scrollRef } = useScrollQueue({
    id,
    open: handleSelectCommunicationItem,
    select: handleSelectCommunicationItem,
  });

  const handleTargetMessageScroll = useCallback(() => {
    if (id === targetMessageId) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [id, scrollRef, targetMessageId]);

  useEffect(() => {
    if (inView && !acknowledged) {
      readMessage(id);
      setIsRead(true);
    }
  }, [id, inView, readMessage, acknowledged]);

  useEffect(() => handleTargetMessageScroll(), [handleTargetMessageScroll]);

  useEffect(() => {
    if (canScrollToFirstUnread && isFirstUnread) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isFirstUnread, scrollRef, canScrollToFirstUnread]);

  const refs = useMergedRefs([defaultRef, scrollRef]);

  const messageText =
    type === MessageType.PRIVATE_NOTES && text && employeesList.length > 0
      ? parseUserMentions(text, employeesList)
      : text;

  const isIncomingSms = type === MessageType.INCOMING_SMS;
  const isIncomingEmail = type === MessageType.INCOMING_EMAIL;

  return (
    <div ref={inViewRef}>
      <div ref={refs}>
        <ItemW
          isReceived={received}
          ref={lastItemRef || firstItemRef}
          onClick={() => entitySelectHandler.setQueue([id])}
          isRead={isRead}
        >
          <CommunicationAvatar
            image={received && (isIncomingSms || isIncomingEmail) ? '' : sender.photoUrl}
          >
            {(isIncomingSms || isIncomingEmail) && <PersonOutlineRoundedIcon fontSize="small" />}

            {received && sender && isIncomingSms && (
              <>
                {sender?.firstName && sender?.firstName[0]?.toUpperCase()}
                {sender?.lastName && sender?.lastName[0]?.toUpperCase()}
              </>
            )}
          </CommunicationAvatar>
          <MessageItem
            id={id}
            received={received}
            messageType={type}
            width={getMessageWidth(pageSections, type)}
            messageText={messageText}
            date={createdAt}
            attachments={emailAttachments}
            subject={subject}
            threads={threadMessages}
            handleReplyToEmail={handleReplyToEmail}
            media={media}
            twilioState={state}
            errorCode={errorCode}
          />
        </ItemW>
      </div>
    </div>
  );
}
