import { styled, Box, Typography, Avatar } from '@mui/material';
import { ReactElement } from 'react';

import { UserOwner } from 'types';
import { getFullNameShort } from 'utils';

const AvatarW = styled(Box)`
  display: flex;
  align-items: center;
`;

const ContactNameW = styled(Typography)`
  margin-left: 8px;
`;

export function getContactOwnerTableValue(
  userInfo: Pick<UserOwner, 'familyName' | 'givenName' | 'picture'>,
): ReactElement {
  return (
    <AvatarW>
      <Avatar alt="contact owner avatar" src={userInfo.picture} sx={{ width: 24, height: 24 }} />
      <ContactNameW variant="body2">
        {getFullNameShort({ lastName: userInfo.familyName, firstName: userInfo.givenName })}
      </ContactNameW>
    </AvatarW>
  );
}
