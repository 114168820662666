import { MenuItem } from '@mui/material';
import { ReactElement, useContext, useEffect, useRef } from 'react';
import { Control, useController } from 'react-hook-form';

import { ControlledTextField } from 'components';
import { FilterDescriptorsContext } from 'features/settings/containers/FiltersPreset/context';
import { $FixTypeLater, Comparator, FilterDescriptors, FilterEntity, FilterType } from 'types';
import { replaceUnderscoreAndCapitalize } from 'utils/replaceUnderscoreAndCapitalize';

import { ENTITY_NAME_LABELS, OPTION_LABELS } from '../FilterForm/config';
import { requiredField } from '../FilterForm/rules';
import { FilterFieldsW } from './styled';
import { ValueField } from './ValueField';

interface FilterBaseFieldsProps {
  control: Control<$FixTypeLater>;
  namePrefix?: string;
  removeFields: () => void;
}

export function FilterBaseFields({
  control,
  namePrefix = '',
}: FilterBaseFieldsProps): ReactElement {
  const descriptors: FilterDescriptors = useContext(FilterDescriptorsContext);

  const {
    field: { value: sectionValues, onChange },
  } = useController({ control, name: namePrefix });

  const prevValues = useRef(sectionValues);

  const entityDescriptors = descriptors[sectionValues.entityName as FilterEntity];

  const propertyDescriptors = entityDescriptors?.find(
    (property) => property.field === sectionValues.propertyName,
  );

  useEffect(() => {
    if (prevValues.current === sectionValues) {
      return;
    }

    let newValues: typeof sectionValues;

    if (prevValues.current.entityName !== sectionValues.entityName) {
      newValues = {
        ...sectionValues,
        type: '',
        propertyName: '',
        operator: '',
        value: '',
      };
    } else if (prevValues.current.propertyName !== sectionValues.propertyName) {
      newValues = {
        ...sectionValues,
        operator: '',
        value: '',
      };
    } else if (prevValues.current.operator !== sectionValues.operator) {
      newValues = {
        ...sectionValues,
        value: '',
      };
    }

    if (newValues) {
      onChange({ ...newValues, type: propertyDescriptors?.type });
    }

    prevValues.current = sectionValues;
  }, [onChange, propertyDescriptors?.type, propertyDescriptors, sectionValues]);

  const entityNameOptions = Object.keys(descriptors).map((entityName) => (
    <MenuItem key={entityName} value={entityName} sx={{ fontSize: '14px' }}>
      {ENTITY_NAME_LABELS[entityName] || entityName}
    </MenuItem>
  ));

  const propertyNameOptions = entityDescriptors?.map((entityDescriptor) => (
    <MenuItem key={entityDescriptor.field} value={entityDescriptor.field} sx={{ fontSize: '14px' }}>
      {replaceUnderscoreAndCapitalize(entityDescriptor.field)}
    </MenuItem>
  ));

  const operatorOptions = propertyDescriptors?.comparators.map((comparator: string) => (
    <MenuItem key={comparator} value={comparator} sx={{ fontSize: '14px' }}>
      {replaceUnderscoreAndCapitalize(comparator)}
    </MenuItem>
  ));

  const valueOptions = propertyDescriptors?.options?.map(({ text, value }) => {
    return (
      <MenuItem key={value} value={value} sx={{ fontSize: '14px' }}>
        {text ?? OPTION_LABELS[value] ?? replaceUnderscoreAndCapitalize(value)}
      </MenuItem>
    );
  });

  return (
    <FilterFieldsW>
      <ControlledTextField
        select
        name={`${namePrefix}.entityName`}
        label="Entity"
        id="entity"
        placeholder="Entity"
        control={control}
        sx={{ maxWidth: '220px' }}
        rules={requiredField}
      >
        {entityNameOptions}
      </ControlledTextField>
      <ControlledTextField
        select
        name={`${namePrefix}.propertyName`}
        id="propertyName"
        label="Property"
        placeholder="Property"
        control={control}
        sx={{ maxWidth: '160px' }}
        rules={requiredField}
        disabled={Boolean(!propertyNameOptions)}
      >
        {propertyNameOptions}
      </ControlledTextField>
      <ControlledTextField
        select
        name={`${namePrefix}.operator`}
        id="operator"
        label="Condition"
        placeholder="Condition"
        control={control}
        sx={{ maxWidth: '120px' }}
        rules={requiredField}
        disabled={Boolean(!operatorOptions)}
      >
        {operatorOptions}
      </ControlledTextField>

      {propertyDescriptors?.type !== FilterType.BOOLEAN &&
        sectionValues.operator !== Comparator.IS_EMPTY &&
        sectionValues.operator !== Comparator.IS_NOT_EMPTY && (
          <ValueField
            name={`${namePrefix}.value`}
            control={control}
            type={propertyDescriptors?.type}
            options={valueOptions}
            disabled={!sectionValues.operator}
            propertyName={sectionValues.propertyName}
          />
        )}
    </FilterFieldsW>
  );
}
