import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { AxiosResponseError, CampaignEditParams, CampaignEditResponseSuccess } from 'api/types';
import { useValidationTrigger } from 'hooks';
import { CampaignNotesFormValues } from 'types';
import { debounceFunction } from 'utils';

import { campaignNotesFormSchema } from './config';

interface UseCampaignNotesFormProps {
  campaignId: string;
  notes: string;
}

interface UseCampaignNotesFormReturn {
  control: Control<CampaignNotesFormValues>;
}

export function useCampaignNotesForm({
  campaignId,
  notes,
}: UseCampaignNotesFormProps): UseCampaignNotesFormReturn {
  const { mutateAsync: editCampaignMutation } = useMutation<
    AxiosResponse<CampaignEditResponseSuccess>,
    AxiosResponseError,
    CampaignEditParams
  >(({ campaignId, data }: CampaignEditParams) => API_HANDLERS.CAMPAIGN.EDIT(campaignId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries('dashboard');
    },
  });

  const {
    handleSubmit,
    reset,
    watch,
    control,
    getValues,
    trigger,
    formState: { isDirty },
  } = useForm<CampaignNotesFormValues>({
    resolver: yupResolver(campaignNotesFormSchema),
    defaultValues: { notes },
    mode: 'all',
  });

  useValidationTrigger(trigger, true, notes);

  useEffect(() => {
    if (getValues().notes === notes || isDirty) {
      return;
    }

    reset({ notes });
  }, [reset, getValues, notes, isDirty]);

  useEffect(() => {
    const mutate = async (values: CampaignNotesFormValues) => {
      await editCampaignMutation({
        campaignId,
        data: values,
      });
    };

    const submitForm = handleSubmit(mutate);
    const debouncedSubmit = debounceFunction(submitForm, 500);
    const subscription = watch(() => debouncedSubmit());

    return subscription.unsubscribe;
  }, [watch, handleSubmit, editCampaignMutation, campaignId]);

  return { control };
}
