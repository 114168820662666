import { ReactElement, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { TimeAgoDateCounter } from 'components';
import { useMergedRefs, useScrollQueue } from 'hooks';
import { InViewItemRef, SystemMessageType } from 'types';

import { SystemMessageContent } from '../SystemMessageContent';
import { DateCaption, MessageBody, SystemMessageWrapper } from './styled';

interface SystemMessageItemProps {
  data: SystemMessageType;
  contactId: string;
  lastItemRef?: InViewItemRef;
  firstItemRef?: InViewItemRef;
  readMessage: (messageId: string) => void;
  isFirstUnread: boolean;
  canScrollToFirstUnread: boolean;
}

const handleSelectCommunicationItem = () => {};

export function SystemMessageItem({
  data,
  contactId,
  lastItemRef,
  firstItemRef,
  readMessage,
  isFirstUnread,
  canScrollToFirstUnread,
}: SystemMessageItemProps): ReactElement {
  const defaultRef = useRef(null);
  const [ref, inView] = useInView({ triggerOnce: true });

  const { scrollRef } = useScrollQueue({
    id: data.id,
    open: handleSelectCommunicationItem,
    select: handleSelectCommunicationItem,
  });

  const refs = useMergedRefs([defaultRef, scrollRef]);

  useEffect(() => {
    if (canScrollToFirstUnread && isFirstUnread) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isFirstUnread, scrollRef, canScrollToFirstUnread]);

  useEffect(() => {
    if (inView && !data.acknowledged) {
      readMessage(data.id);
    }
  }, [data.id, data.acknowledged, inView, readMessage]);

  return (
    <div ref={ref}>
      <div ref={refs}>
        <SystemMessageWrapper ref={lastItemRef || firstItemRef}>
          <MessageBody>
            <SystemMessageContent message={data} contactId={contactId} />
          </MessageBody>
          <DateCaption>
            <TimeAgoDateCounter date={data.createdAt} />
          </DateCaption>
        </SystemMessageWrapper>
      </div>
    </div>
  );
}
