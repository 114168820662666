export enum TourStatus {
  New = 'Tour: New',
  Requested = 'Tour: Requested',

  Scheduled = 'Tour: Scheduled',
  ConfirmedWithRenter = 'Tour: Confirmed with renter',
  PreTourFollowUp = 'Tour: Pre-tour follow up',
  TourCompletedWon = 'Tour: Tour completed won',
  ReadyToApply = 'Tour: Ready to apply',

  MoreInfo = 'Tour: More info',
  NeedToReschedule = 'Tour: Need to reschedule',

  Closed = 'Tour: Closed',
}

export enum ApplicationStatus {
  New = 'Application: New',
  Requested = 'Application: Requested',

  Qualified = 'Application: Qualified',
  AwaitingReply = 'Application: Awaiting reply',
  Applied = 'Application: Applied',
  ConfirmReferralWithBuilding = 'Application: Confirm referral with building',
  ReferralConfirmed = 'Application: Referral confirmed',
  ApprovedForLease = 'Application: Approved for lease',
  ConfirmMoveIn = 'Application: Confirm move in',
  Leased = 'Application: Leased',

  MoreInfo = 'Application: More info',
  Rescue = 'Application: Rescue',

  Closed = 'Application: Closed',
}
