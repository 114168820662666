import { ReactElement, useContext } from 'react';

import { MessageDateCaption, MessageHeader } from 'components';
import { EmployeesContext } from 'context';
import { BuildingChatItemType, BuildingChatMessage as BuildingChatMessageType } from 'types';
import { parseUserMentions } from 'utils';

import { BUILDING_CHAT_MESSAGE_ICONS, BUILDING_MESSAGE_TITLE } from '../config';
import { Content, Footer, MessageW } from './styled';

interface BuildingChatMessageProps {
  message: BuildingChatMessageType;
  isReceived: boolean;
  buildingName: string;
}

export function BuildingChatMessage({
  message,
  isReceived,
  buildingName,
}: BuildingChatMessageProps): ReactElement {
  const {
    type,
    payload: { text },
    createdAt,
  } = message;
  const { employeesList = [] } = useContext(EmployeesContext);

  const Icon = BUILDING_CHAT_MESSAGE_ICONS[type];
  const title =
    type === BuildingChatItemType.PRIVATE_NOTES ? BUILDING_MESSAGE_TITLE[type] : buildingName;

  const parsedText =
    type === BuildingChatItemType.PRIVATE_NOTES && text && Boolean(employeesList.length)
      ? parseUserMentions(text, employeesList)
      : text;

  return (
    <MessageW isPrivateNote={type === BuildingChatItemType.PRIVATE_NOTES} isReceived={isReceived}>
      <MessageHeader title={title} icon={<Icon variant="black" />} />

      <Content>{parsedText}</Content>

      <Footer>
        <MessageDateCaption date={createdAt} />
      </Footer>
    </MessageW>
  );
}
