import { useEffect, useRef } from 'react';

import { scrollToElement } from 'utils';
import entitySelectHandler from 'utils/entitySelectHandler';

import { useScrollContainer } from './useScrollContainer';

interface UseScrollQueueParams {
  id: string;
  open: (open: boolean) => void;
  select: (id: string) => void;
  scrollDelay?: number;
  parentId?: string;
}

interface UseScrollQueueReturn {
  scrollRef: React.MutableRefObject<HTMLElement | HTMLDivElement | null>;
}

export function useScrollQueue({
  id,
  open,
  select,
  scrollDelay,
  parentId,
}: UseScrollQueueParams): UseScrollQueueReturn {
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollContainer = useScrollContainer();

  useEffect(() => {
    const unsubscribe = entitySelectHandler.subscribe({
      id: parentId ? `${id}${parentId}` : id,
      open: () => open(true),
      select: () => {
        select(id);

        const handleScroll = () => {
          scrollRef.current &&
            scrollContainer?.current &&
            scrollToElement(scrollRef.current, scrollContainer.current);
        };

        if (scrollDelay) {
          setTimeout(() => {
            handleScroll();
          }, scrollDelay);
        } else {
          handleScroll();
        }
      },
    });

    return unsubscribe;
  }, [id, open, select, scrollContainer, scrollDelay, parentId]);

  return {
    scrollRef,
  };
}
