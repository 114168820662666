import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material';

export const ActionCellW = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const MoreIcon = styled(MoreVertIcon)(({ theme }) => ({
  color: theme.palette.black.main,
}));
