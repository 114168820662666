import { InquiryType } from 'types';

import { singleInquiryAdapter } from './singleInquiryAdapter';

export function inquiryAdapter<T extends { inquiryType: InquiryType }>(
  inquiries: T[],
  type: InquiryType,
): T[] {
  return inquiries.map((inquiry) => singleInquiryAdapter(inquiry, type));
}
