import { ReactElement, useCallback, useContext } from 'react';

import { EmptyState } from 'components';
import { SelectedCampaignIdState } from 'features/campaigns';
import { selectCampaign } from 'features/contactDetails/context/actions';
import { SelectedItemState } from 'features/contactDetails/context/ContactDetailsPageContext';
import { useDispatch } from 'hooks';
import { Campaign, EmptyStateIcons } from 'types';

import { CampaignListItem } from '../../components';

interface CampaignsListProps {
  campaigns: Campaign[];
}

export function CampaignsList({ campaigns }: CampaignsListProps): ReactElement {
  const selectedCampaignId = useContext(SelectedCampaignIdState);

  const dispatch = useDispatch();

  const handleSelectCampaign = useCallback(
    (campaignId: Campaign['id']) => dispatch(selectCampaign(campaignId)),
    [dispatch],
  );

  const selectedItemType = useContext(SelectedItemState);

  return (
    <>
      {campaigns.length ? (
        <div>
          {campaigns.map((campaign) => (
            <CampaignListItem
              key={campaign.id}
              campaign={campaign}
              selected={selectedCampaignId === campaign.id}
              handleSelectCampaign={handleSelectCampaign}
              selectedItemType={selectedItemType}
            />
          ))}
        </div>
      ) : (
        <EmptyState
          variant={EmptyStateIcons.EMPTY_CAMPAIGNS}
          description="No campaigns"
          descriptionSx={{ fontWeight: 550, fontSize: '20px', marginBottom: '40px' }}
          iconSx={{ marginBottom: '16px', height: '48px', marginTop: '40px' }}
        />
      )}
    </>
  );
}
