import { ReactElement, ReactNode } from 'react';

import { CloseButton } from 'components';

import { TitleWrapper, Wrapper } from './styled';

interface ModalProps {
  onClose: () => void;
  isOpen: boolean;
  children: ReactNode;
}

export function PreviewMessageModal({ onClose, isOpen, children }: ModalProps): ReactElement {
  return (
    <Wrapper open={isOpen}>
      <TitleWrapper>
        Preview
        <CloseButton onClick={onClose} />
      </TitleWrapper>
      {children}
    </Wrapper>
  );
}
