import { ReactElement, RefObject } from 'react';
import { Control, useController } from 'react-hook-form';
import { Mention, MentionsInput } from 'react-mentions';

import { MessageTemplateFormData } from 'types';

import { ClearButton } from '../ClearButton';
import { DisplayLabelText, mentionStyles } from './styled';

interface MessageTemplateBodyFieldProps {
  data: string[];
  control: Control<MessageTemplateFormData>;
  editorRef: RefObject<HTMLElement>;
  disabled: boolean;
}

export function MessageTemplateBodyInput({
  data,
  control,
  editorRef,
  disabled,
}: MessageTemplateBodyFieldProps): ReactElement {
  const {
    field: { onChange, value, ref },
  } = useController({
    control,
    name: 'body',
  });

  const displayData = data.map((field) => ({
    id: field,
    display: field,
    displayLabel: <DisplayLabelText>{field}</DisplayLabelText>,
  }));

  return (
    <div style={{ position: 'relative', height: '100%', padding: '10px 0' }}>
      <MentionsInput
        inputRef={ref}
        value={value}
        onChange={onChange}
        suggestionsPortalHost={editorRef.current as Element}
        style={mentionStyles}
        spellCheck={false}
        disabled={disabled}
        required
      >
        <Mention
          markup="{{{__display__}}}"
          trigger="/"
          data={displayData}
          displayTransform={(_id, display) => `{{{${display}}}}`}
          appendSpaceOnAdd
        />
      </MentionsInput>
      {!!value.length && <ClearButton disabled={disabled} onClick={() => onChange('')} />}
    </div>
  );
}
