import { Control, useForm } from 'react-hook-form';

export type ContactOwnerDefaultValues = ContactOwnerFilterValues;

export interface ContactOwnerFilterValues {
  contactOwner: string;
}

export interface UseContactOwnerFilterReturn {
  contactOwnerFilterControl: Control<ContactOwnerFilterValues>;
  contactOwner: string;
}

export function useContactOwnerFilter(initialValue: string): UseContactOwnerFilterReturn {
  const { control, watch } = useForm<ContactOwnerFilterValues>({
    defaultValues: { contactOwner: initialValue },
    mode: 'onTouched',
  });

  const contactOwner = watch('contactOwner');

  return {
    contactOwnerFilterControl: control,
    contactOwner,
  };
}
