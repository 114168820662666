export { default as buildingActionTypes } from './actionTypes';
export * from './actions';

import { createContext } from 'react';

import { CampaignBuilding, CampaignBuildingP, Normalized } from 'types';

export interface IBuildingsState {
  byId: Normalized<CampaignBuildingP>;
  allItems: CampaignBuildingP[];
}

export const BuildingsState = createContext<IBuildingsState>({ byId: {}, allItems: [] });

export const SelectedBuildingIdState = createContext<CampaignBuilding['id'] | undefined>(undefined);
