import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { InputAdornment } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface InputProps {
  isFocused: boolean;
  inputLabel: string;
  inputIcon: ReactNode | null;
  onChange: (value: string) => void;
  disabled?: boolean;
  withClearIcon?: boolean;
}

interface InputPropsReturn {
  startAdornment?: ReactNode | ReactElement;
  endAdornment?: ReactElement;
}

export function getInputProps({
  isFocused,
  onChange,
  inputLabel,
  inputIcon,
  disabled,
  withClearIcon,
}: InputProps): InputPropsReturn {
  return {
    startAdornment: !isFocused && inputLabel ? inputIcon : null,
    endAdornment:
      withClearIcon && inputLabel ? (
        <InputAdornment position="end" onClick={() => onChange('')}>
          <CancelRoundedIcon sx={{ cursor: disabled ? 'default' : 'pointer' }} />
        </InputAdornment>
      ) : undefined,
  };
}
