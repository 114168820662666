import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { Collapse } from '@mui/material';
import { ReactElement, useState } from 'react';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ButtonSizes } from 'components/Button/interface';
import { CustomCollapse } from 'components/CustomCollapse';
import { DATE_FORMAT_WITH_TIME_AND_COMMA } from 'constants/index';
import { BuildingsList, AddBuilding } from 'features/buildings';
import { useAuth, useModal } from 'hooks';
import { useScrollQueue } from 'hooks/useScrollQueue';
import {
  BuildingStatus,
  Campaign,
  CloseCampaignReasons,
  CloseCampaignReasonsMap,
  ContactDetailsSelectItem,
  ServicePreferences,
} from 'types';
import { getFormattedDateWithTime } from 'utils';

import CampaignStatusSwitch from '../CampaignStatusSwitch';
import { SendBuildingsModal } from '../SendBuildingsModal';
import { buttonIconStyles, servicePreferenceProps } from './config';
import {
  ArrowIcon,
  BuildingActionBtn,
  BuildingButtonsContainer,
  CampaignDescriptionWrap,
  CampaignDivider,
  CampaignListItemWrap,
  CampaignTitle,
  CampaignTitleWrap,
  CloseReasonWrapper,
  DateCaption,
  Reason,
  ServicePreferencesBlock,
  SmallDivider,
} from './styled';

interface CampaignListItemProps {
  campaign: Campaign;
  selected?: boolean;
  handleSelectCampaign: (id: string) => void;
  selectedItemType: ContactDetailsSelectItem;
}

function CampaignListItem({
  campaign,
  selected = false,
  handleSelectCampaign,
  selectedItemType,
}: CampaignListItemProps): ReactElement {
  const [isAddBuildingMode, setAddBuildingMode] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const sendBuildingsModal = useModal();

  const { isReadOnlyAccess } = useAuth();

  // TODO: remove useGetCampaignBuildings hook
  const buildings = campaign.buildings || [];

  const buildingsWithStatusNew =
    buildings.filter((building) => building.status === BuildingStatus.NEW) || [];

  const closeAddBuilding = () => setAddBuildingMode(false);

  const toggleExpanded = () => {
    if (!isExpanded) {
      handleSelectCampaign(campaign.id);
    } else {
      closeAddBuilding();
    }

    setIsExpanded(!isExpanded);
  };

  const { scrollRef } = useScrollQueue({
    id: campaign.id,
    open: setIsExpanded,
    select: handleSelectCampaign,
  });

  const closeReason: string | null =
    campaign.status in CloseCampaignReasons
      ? CloseCampaignReasonsMap[campaign.status as CloseCampaignReasons]
      : null;

  return (
    <>
      <CampaignListItemWrap
        ref={scrollRef}
        isSelected={selected && selectedItemType === 'Campaign'}
        open={isExpanded}
      >
        <ArrowIcon open={isExpanded} onClick={toggleExpanded} />
        <div>
          <CampaignTitleWrap>
            <CampaignTitle variant="body2" onClick={() => handleSelectCampaign(campaign.id)}>
              {campaign.title}
            </CampaignTitle>
            <CampaignStatusSwitch status={campaign.status} campaignId={campaign.id} />
          </CampaignTitleWrap>
          <CampaignDescriptionWrap>
            <ServicePreferencesBlock {...servicePreferenceProps[ServicePreferences.GuidedSearch]} />
            <DateCaption variant="body2">
              {getFormattedDateWithTime(
                new Date(campaign.createdAt),
                DATE_FORMAT_WITH_TIME_AND_COMMA,
              )}
            </DateCaption>
          </CampaignDescriptionWrap>
          {closeReason && (
            <CloseReasonWrapper variant="body2">
              Reason to close: <Reason>{closeReason}</Reason>
            </CloseReasonWrapper>
          )}
        </div>
      </CampaignListItemWrap>

      <CustomCollapse orientation="vertical" in={isExpanded}>
        {() => (
          <>
            <BuildingsList campaignId={campaign.id} buildings={buildings} />

            <Collapse orientation="vertical" in={isAddBuildingMode}>
              <AddBuilding campaign={campaign} handleClose={closeAddBuilding} />
              <SmallDivider />
            </Collapse>

            {!isReadOnlyAccess && (
              <>
                <SmallDivider />
                <BuildingButtonsContainer>
                  <BuildingActionBtn
                    color="secondary"
                    onClick={() => setAddBuildingMode(true)}
                    buttonSize={ButtonSizes.xSmall}
                    disabled={isAddBuildingMode}
                  >
                    <PlusIcon style={buttonIconStyles.plusIcon(isAddBuildingMode)} />
                    Add building
                  </BuildingActionBtn>

                  {buildingsWithStatusNew.length > 0 && (
                    <BuildingActionBtn
                      color="secondary"
                      onClick={sendBuildingsModal.handleOpenModal}
                      buttonSize={ButtonSizes.xSmall}
                    >
                      <SendRoundedIcon style={buttonIconStyles.sendIcon} />
                      Send buildings
                    </BuildingActionBtn>
                  )}
                </BuildingButtonsContainer>
              </>
            )}

            <SendBuildingsModal
              isOpen={sendBuildingsModal.isOpen}
              handleClose={sendBuildingsModal.handleCloseModal}
              campaignId={campaign.id}
              buildings={buildingsWithStatusNew}
            />
          </>
        )}
      </CustomCollapse>

      <CampaignDivider />
    </>
  );
}

export default CampaignListItem;
