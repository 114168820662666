import { ReactElement, useMemo } from 'react';

import { contactDefaultValuesAdapter } from 'features/contactDetails/adapters';
import { Contact } from 'types';

import { ContactEditForm } from '../ContactEditForm';

interface ContactEditFormModalProps {
  isOpen: boolean;
  handleClose: () => void;
  contact?: Contact;
}

export function ContactEditFormModal({
  isOpen,
  handleClose,
  contact,
}: ContactEditFormModalProps): ReactElement {
  const defaultValues = useMemo(() => contactDefaultValuesAdapter(contact), [contact]);
  const contactId = contact?.id || '';
  return (
    <ContactEditForm
      isOpen={isOpen}
      handleClose={handleClose}
      defaultValues={defaultValues}
      contactId={contactId}
    />
  );
}
