import { Suggestion } from 'react-places-autocomplete';

export function getFormattedCityString(suggestion: Suggestion): string {
  return (
    suggestion?.terms
      .slice(0, -1)
      .map((option) => option.value)
      .join(', ') || ''
  );
}
