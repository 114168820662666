import { useContext } from 'react';

import { UnassignedBuildingsState } from 'features/unassignedBuildings';
import { UnassignedBuilding } from 'types';

export function useSelectUnassignedBuilding(
  buildingId: UnassignedBuilding['id'],
): UnassignedBuilding | undefined {
  const buildings = useContext(UnassignedBuildingsState);

  return buildings[buildingId];
}
