import { styled, Typography } from '@mui/material';

export const Wrapper = styled('div')`
  padding-top: 24px;
`;

export const Caption = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.light,
  lineHeight: '16px',
  fontSize: '12px',
  marginTop: '8px',
}));

export const UserEmail = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  lineHeight: '20px',
  fontWeight: 600,
}));
