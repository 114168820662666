import { ReactElement } from 'react';

import { Button } from 'components';
import { useAuth, useModal } from 'hooks';

import { CampaignCreateForm } from '../CampaignCreateForm';

interface CreateCampaignProps {
  contactFullName: string;
}

export function CreateCampaign({ contactFullName }: CreateCampaignProps): ReactElement {
  const modalControl = useModal();

  const { isReadOnlyAccess } = useAuth();

  return (
    <div>
      {!isReadOnlyAccess && <Button onClick={modalControl.handleOpenModal}>New Campaign</Button>}
      <CampaignCreateForm
        isOpen={modalControl.isOpen}
        handleClose={modalControl.handleCloseModal}
        contactFullName={contactFullName}
      />
    </div>
  );
}
