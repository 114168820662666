import { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { AddBuildingToCampaignParams } from 'api/types';
import { alert } from 'components';
import { CampaignsState } from 'features/campaigns';
import { useModal } from 'hooks';
import { Campaign, CampaignStatus, CloseCampaignReasons } from 'types';

interface UseAddUnassignedBuildingToCampaignReturn {
  isAddToCampaignModalOpen: boolean;
  activeCampaigns: Campaign[];
  activeCampaignsCount: number;
  handleCloseAddToCampaignModal: () => void;
  handleAddToCampaign: (buildingId: string) => void;
  submitAddUnassignedBuildingToCampaign: (campaignId: string) => void;
}

export function useAddUnassignedBuildingToCampaign(): UseAddUnassignedBuildingToCampaignReturn {
  const [buildingId, setsBuildingId] = useState<string | undefined>(undefined);
  const { contactId = '' } = useParams<string>();

  const {
    isOpen: isAddToCampaignModalOpen,
    handleOpenModal,
    handleCloseModal: handleCloseAddToCampaignModal,
  } = useModal();

  const addUnassignedBuildingToCampaign = useMutation(
    async ({ campaignId, buildingId }: AddBuildingToCampaignParams) =>
      API_HANDLERS.BUILDINGS.ADD_TO_CAMPAIGN({ campaignId, buildingId }),
    {
      onSuccess: (response) => {
        if (response.data) {
          queryClient.invalidateQueries(['contact']);
          queryClient.invalidateQueries(['unassigned-buildings', contactId]);

          handleCloseAddToCampaignModal();

          alert.success('Building was added to a campaign');
        }
      },
    },
  );

  const campaigns = useContext(CampaignsState);
  const campaignsIds = Object.keys(campaigns);

  const activeCampaigns: Campaign[] = useMemo(
    () =>
      campaignsIds
        .map((id) => campaigns[id])
        .filter(
          ({ status }) =>
            !Object.values(CloseCampaignReasons).includes(status as CloseCampaignReasons) &&
            status !== CampaignStatus.CLOSED_WON,
        ),
    [campaigns, campaignsIds],
  );

  const activeCampaignsCount = activeCampaigns.length || 0;

  const submitAddUnassignedBuildingToCampaign = useCallback(
    (campaignId: string): void => {
      if (!buildingId) return;

      addUnassignedBuildingToCampaign.mutate({ buildingId, campaignId });
    },
    [addUnassignedBuildingToCampaign, buildingId],
  );

  const handleOpenAddToCampaignModal = useCallback(
    (buildingId: string) => {
      if (!buildingId) return;

      setsBuildingId(buildingId);
      handleOpenModal();
    },
    [handleOpenModal],
  );

  const handleAddToCampaign = useCallback(
    (buildingId: string) => {
      if (activeCampaignsCount === 1) {
        const campaignId = activeCampaigns[0].id;
        addUnassignedBuildingToCampaign.mutate({ buildingId, campaignId });
      } else {
        handleOpenAddToCampaignModal(buildingId);
      }
    },
    [
      activeCampaigns,
      activeCampaignsCount,
      addUnassignedBuildingToCampaign,
      handleOpenAddToCampaignModal,
    ],
  );

  return {
    isAddToCampaignModalOpen,
    activeCampaigns,
    activeCampaignsCount,
    handleCloseAddToCampaignModal,
    handleAddToCampaign,
    submitAddUnassignedBuildingToCampaign,
  };
}
