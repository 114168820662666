import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { EmployeeListRequestProps, EmployeesListResponseSuccess } from 'api/types';

interface UseEmployeesListReturn {
  employeesContextListError: unknown;
  employeesListData?: EmployeesListResponseSuccess;
  employeesListLoading: boolean;
}

export function useEmployeesList({
  pageNumber,
  limit,
}: EmployeeListRequestProps): UseEmployeesListReturn {
  const {
    error: employeesContextListError,
    data: employeesData,
    isLoading: employeesListLoading,
  } = useQuery(['employees', pageNumber, limit], () =>
    API_HANDLERS.EMPLOYEES.GET_BY_PAGE({ pageNumber, limit }),
  );

  return {
    employeesContextListError,
    employeesListData: employeesData?.data,
    employeesListLoading,
  };
}
