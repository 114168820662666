import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

export const DetailsCardW = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.secondary.p005,
  position: 'relative',
  padding: '16px 14px 16px 12px',
  fontSize: '12px',
  lineHeight: '16px',
  marginTop: '8px',
  borderRadius: '4px',
  color: theme.palette.black.light,
}));

export const SideBlockW = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const Row = styled('div')`
  display: flex;
  align-items: center;
`;

export const RowValue = styled(Typography)`
  font-weight: 600;
  font-size: 12px;
  margin-left: 4px;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
`;
