import * as yup from 'yup';

import { MessageType, $FixTypeLater } from 'types';
import { EMAIL_FORMAT, getMaxLengthText, getMinLengthText } from 'validations/messages';
import { EMAIL_REGEX } from 'validations/regexps';

export const newMessageFormSchema = (textLimit: number): $FixTypeLater =>
  yup.object().shape({
    type: yup.string().required(),
    content: yup
      .string()
      .trim()
      .min(1, getMinLengthText(1))
      .when('type', {
        is: (msgType: MessageType) => msgType === MessageType.OUTGOING_SMS,
        then: (schema) => schema.max(1400, getMaxLengthText(1400)),
        otherwise: (schema) => schema.max(1600, getMaxLengthText(1600)),
      })
      .max(textLimit, getMaxLengthText(textLimit))
      .nullable(),
    subject: yup.string().trim().max(78, getMaxLengthText(78)),
    cc: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            label: yup.string(),
            value: yup.string().matches(EMAIL_REGEX, EMAIL_FORMAT).max(66, getMaxLengthText(66)),
          })
          .nullable(),
      )
      .nullable(),
    bcc: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            label: yup.string(),
            value: yup.string().matches(EMAIL_REGEX, EMAIL_FORMAT).max(66, getMaxLengthText(66)),
          })
          .nullable(),
      )
      .nullable(),
  });
