import { ReactElement } from 'react';

import { RelatedToText } from 'features/contactDetails/containers/DetailsSearch/components/InquiriesContainer/styled';
import { getFormattedBuildingAddress } from 'utils';

import { BuildingStatus } from '../BuildingStatus';
import {
  BuildingAddress,
  BuildingDetailsW,
  BuildingName,
  BuildingW,
  ImageW,
  Preview,
  SearchContentW,
} from './styled';

interface BuildingCardProps {
  photo?: string;
  address: string;
  city: string;
  name: string;
  campaignName?: string;
  status: string;
}

export function BuildingSearchCard({
  address,
  city,
  name,
  photo,
  campaignName,
  status,
}: BuildingCardProps): ReactElement {
  return (
    <BuildingW>
      <Preview previewUrl={photo} sx={{ width: 44, height: 44, marginRight: '8px' }}>
        {photo && <ImageW src={photo} alt="" />}
      </Preview>
      <BuildingDetailsW>
        <SearchContentW>
          <div>
            <BuildingName sx={{ lineHeight: '16px', fontSize: '12px' }} variant="body2">
              {name}
            </BuildingName>
            <BuildingAddress sx={{ lineHeight: '16px', fontSize: '12px' }}>
              {getFormattedBuildingAddress(address, city)}
            </BuildingAddress>
          </div>
          <BuildingStatus status={status} />
        </SearchContentW>

        {campaignName && (
          <RelatedToText variant="body2">
            Related to <b>{campaignName}</b>
          </RelatedToText>
        )}
      </BuildingDetailsW>
    </BuildingW>
  );
}
