import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useQuery } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';

import { API_HANDLERS } from 'api/apiHandlers';
import { EmptyStateArgs } from 'components/PageStatus';
import { PAGE_LIMIT } from 'constants/index';
import { EmployeesContext } from 'context';
import { useSetQueryParams, useDebouncedValue, useSearch, UseSearchReturn } from 'hooks';
import { Links } from 'settings';
import { Contact, ContactsTable, EmptyStateIcons, FilterPresetList, SelectOption } from 'types';
import { getQueryParams } from 'utils';

import {
  contactsTableAdapter,
  employeesOptionsAdapter,
  filterPresetOptionsAdapter,
} from '../adapters';
import { FilterPresetOptions } from '../types';
import { getContactOwnerDisplayValue } from '../utils';
import { QUERY_SEARCH_KEYS } from './config';
import {
  ContactFiltersValues,
  useContactFilters,
  UseContactFiltersValuesReturn,
} from './useContactFilters';
import { useContactOwnerFilter, UseContactOwnerFilterReturn } from './useContactOwnerFilter';
import { useFiltersList } from './useFiltersList';

interface UseContactsReturn
  extends Pick<UseSearchReturn, 'search'>,
    Pick<UseContactOwnerFilterReturn, 'contactOwnerFilterControl'>,
    Pick<UseContactFiltersValuesReturn, 'contactsFilterControl'> {
  onClearSearch: () => void;
  onSetSearch: (search: string) => void;
  currentPage: number;
  isContactsLoading: boolean;
  tableContacts: ContactsTable[];
  contactsCount: number;
  pageLimit: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setPageLimit: Dispatch<SetStateAction<number>>;
  emptyState: EmptyStateArgs;
  handleRowClick: (id: string) => void;
  contactOwnerOptions: SelectOption[];
  filterDisplayValue: string | undefined;
  filterPresetOptions: FilterPresetOptions;
  filtersList: FilterPresetList;
  setFilterPresetValue: UseFormSetValue<ContactFiltersValues>;
}

export function useContacts(): UseContactsReturn {
  const navigate = useNavigate();

  const querySearch = useMemo(() => getQueryParams(QUERY_SEARCH_KEYS), []);

  const [currentPage, setCurrentPage] = useState(Number(querySearch.page) || 0);

  const [pageLimit, setPageLimit] = useState(Number(querySearch.limit) || PAGE_LIMIT);

  const { search, onClear, onSetSearch } = useSearch(querySearch.search?.toString() || '');

  const debouncedSearch = useDebouncedValue(search, 500);

  const searchParam = search.length ? debouncedSearch : '';

  const [contacts, setContacts] = useState<Contact[]>([]);

  const { contactsFilterControl, contactFilter, setValue } = useContactFilters(
    querySearch.filterId?.toString() || '',
  );

  const { contactOwnerFilterControl, contactOwner } = useContactOwnerFilter(
    querySearch.ownerId?.toString() || '',
  );

  useSetQueryParams(
    {
      page: currentPage,
      limit: pageLimit,
      search: searchParam,
      ownerId: contactOwner,
      filterId: contactFilter,
    },
    QUERY_SEARCH_KEYS,
    true,
  );

  const {
    data: contactsData,
    isLoading: isContactsLoading,
    error: contactsListRequestError,
    isFetching,
  } = useQuery(
    ['contacts', currentPage, pageLimit, searchParam, contactOwner, contactFilter],
    () =>
      API_HANDLERS.CONTACTS.GET({
        limit: pageLimit,
        pageNumber: currentPage,
        search: searchParam,
        contactOwner,
        filterId: contactFilter,
      }),
    {
      onSuccess(resp) {
        setContacts(resp.data.items);
      },
    },
  );

  const { employeesList, employeesContextListError } = useContext(EmployeesContext);

  const { filtersList, filtersListError } = useFiltersList();

  const contactsCount = contactsData?.data.meta?.totalItems || 0;

  const handleRowClick = (id: string) => {
    navigate(
      generatePath(Links.contacts.contact.index, {
        contactId: id,
      }),
    );
  };

  const handleSearchChange = (search: string) => {
    onSetSearch(search);
    setCurrentPage(0);
  };

  const emptyState: EmptyStateArgs = useMemo(() => {
    if (contactsListRequestError || employeesContextListError || filtersListError) {
      return {
        shouldAppear: true,
        title: 'Oops, something went wrong',
        description: 'Please try to reload this page',
        variant: EmptyStateIcons.ERROR,
      };
    }
    if (
      (searchParam.length || Boolean(contactOwner) || Boolean(contactFilter)) &&
      !isContactsLoading &&
      contactsCount === 0
    ) {
      return {
        shouldAppear: true,
        title: "Couldn't find anyone",
        variant: EmptyStateIcons.EMPTY_SEARCH,
      };
    }
    return {
      shouldAppear: !searchParam.length && !isContactsLoading && contactsCount === 0,
      title: `Your contacts will be here`,
      variant: EmptyStateIcons.EMPTY_LIST,
    };
  }, [
    contactsListRequestError,
    employeesContextListError,
    filtersListError,
    searchParam.length,
    contactOwner,
    contactFilter,
    isContactsLoading,
    contactsCount,
  ]);

  const contactOwnerOptions = employeesOptionsAdapter({
    employeesData: employeesList.map((employee) => ({
      firstName: employee.user.givenName,
      id: employee.id,
      lastName: employee.user.familyName ?? '',
      photo: employee.user.picture,
    })),
    contactOwner,
  });

  const filterPresetOptions = filterPresetOptionsAdapter(filtersList);

  const tableContacts = contactsTableAdapter(contacts);

  return {
    search,
    onSetSearch: handleSearchChange,
    onClearSearch: () => onClear(setCurrentPage),
    currentPage,
    setCurrentPage,
    isContactsLoading: isFetching || isContactsLoading,
    tableContacts,
    contactsCount,
    setPageLimit,
    pageLimit,
    emptyState,
    handleRowClick,
    contactOwnerOptions,
    contactOwnerFilterControl,
    contactsFilterControl,
    filterDisplayValue: getContactOwnerDisplayValue({ contactOwner, options: contactOwnerOptions }),
    filterPresetOptions,
    filtersList,
    setFilterPresetValue: setValue,
  };
}
