import { ReactElement, useMemo } from 'react';
import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { EmptyStateArgs } from 'components/PageStatus';
import { AddFilter, FiltersTable } from 'features/settings/components';
import { EmptyStateIcons } from 'types';

import { FilterDescriptorsContext, FilterGroupsContext } from './context';
import { useDeleteFilter } from './hooks';
import { AddFilterWrap, Wrap } from './styled';
import { getFilterGroups } from './utils';

export function FiltersPreset(): ReactElement {
  const { data: descriptorsData, isError: isDescriptorsListError } = useQuery(
    'filter-descriptors',
    API_HANDLERS.FILTERS.GET_DESCRIPTORS,
  );

  const {
    data,
    isLoading,
    isError: isFilterPresetsError,
  } = useQuery('filters', API_HANDLERS.FILTERS.GET);

  const { handleSubmit, isLoadingDelete } = useDeleteFilter();

  const filterPresetsCount = data?.data.filterPresets?.length || 0;

  const filterGroups = getFilterGroups(data?.data.filterPresets);

  const emptyState: EmptyStateArgs = useMemo(() => {
    if (isFilterPresetsError || isDescriptorsListError) {
      return {
        shouldAppear: true,
        title: 'Oops, something went wrong',
        description: 'Please try to reload this page',
        variant: EmptyStateIcons.ERROR,
      };
    }
    return {
      shouldAppear: Boolean(filterPresetsCount === 0 && !isLoading),
      title: `Filter presets will be here`,
      variant: EmptyStateIcons.EMPTY_LIST,
    };
  }, [filterPresetsCount, isDescriptorsListError, isFilterPresetsError, isLoading]);

  const handleDeleteFilter = (filterId: string) => {
    handleSubmit(filterId);
  };

  return (
    <FilterDescriptorsContext.Provider value={descriptorsData?.data}>
      <FilterGroupsContext.Provider value={filterGroups}>
        <Wrap>
          <AddFilterWrap>
            <AddFilter />
          </AddFilterWrap>
          <FiltersTable
            loading={isLoading || isLoadingDelete}
            emptyState={emptyState}
            filters={data?.data.filterPresets || []}
            handleDeleteFilter={handleDeleteFilter}
          />
        </Wrap>
      </FilterGroupsContext.Provider>
    </FilterDescriptorsContext.Provider>
  );
}
