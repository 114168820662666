import { MenuItem, MenuList } from '@mui/material';
import { ReactElement } from 'react';

import { PopperMenu } from 'components';
import Popover from 'components/Popover';

import { MoreIcon } from './styled';

interface MoreBlockProps {
  menuItems: Parameters<typeof PopperMenu>['0']['menuItems'];
}

export function MoreBlock({ menuItems }: MoreBlockProps): ReactElement {
  return (
    <Popover
      popupId="more-block"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchor={<MoreIcon sx={{ cursor: 'pointer' }} />}
    >
      {() => (
        <MenuList>
          {menuItems.map((item) => (
            <MenuItem
              key={item.key}
              onClick={() => item.onClick()}
              sx={{ typography: 'body2', height: '40px' }}
            >
              {item.label}
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Popover>
  );
}
