import { yupResolver } from '@hookform/resolvers/yup';
import { ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { ControlledTextField } from 'components';
import { useValidationTrigger } from 'hooks';
import { BuildingNotesFormValues, CampaignBuilding } from 'types';
import { debounceFunction } from 'utils';

import { buildingNotesFormSchema } from './validationSchema';

interface BuildingNotesFormProps {
  buildingId: CampaignBuilding['id'];
  notes: string;
  isDisabled: boolean;
}

export function BuildingNotesForm({
  buildingId,
  notes,
  isDisabled,
}: BuildingNotesFormProps): ReactElement {
  const { contactId = '' } = useParams<string>();

  const { mutateAsync: buildingNotesMutation } = useMutation(API_HANDLERS.BUILDINGS.EDIT, {
    onSuccess: () => {
      queryClient.invalidateQueries('contact');
    },
  });

  const {
    handleSubmit,
    reset,
    watch,
    control,
    getValues,
    trigger,
    formState: { isDirty },
  } = useForm<BuildingNotesFormValues>({
    resolver: yupResolver(buildingNotesFormSchema),
    defaultValues: { notes },
    mode: 'all',
  });

  useValidationTrigger(trigger, true, notes);

  useEffect(() => {
    if (getValues().notes === notes || isDirty) {
      return;
    }

    reset({ notes });
  }, [reset, getValues, notes, isDirty]);

  useEffect(() => {
    const mutate = async (values: BuildingNotesFormValues) => {
      await buildingNotesMutation({
        buildingId,
        contactId,
        data: values,
      });
    };

    const submitForm = handleSubmit(mutate);
    const debouncedSubmit = debounceFunction(submitForm, 500);
    const subscription = watch(() => debouncedSubmit());

    return subscription.unsubscribe;
  }, [watch, handleSubmit, buildingNotesMutation, buildingId, contactId]);

  return (
    <ControlledTextField
      name="notes"
      control={control}
      multiline
      id="notes"
      label="Building notes"
      placeholder="Building notes"
      showAllErrors
      disabled={isDisabled}
    />
  );
}
