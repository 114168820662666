import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { TourFormValues } from 'types';

import { UseTourFormParams, UseTourFormReturn } from './types';
import { tourFormSchema } from './validationSchema';

export function useTourForm({
  defaultValues,
  handleClose,
  onSubmit,
  isEditMode,
}: UseTourFormParams): UseTourFormReturn {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm<TourFormValues>({
    resolver: yupResolver(tourFormSchema),
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    if (!isEditMode) {
      return;
    }
    reset(defaultValues);
  }, [defaultValues, isEditMode, reset]);

  const destroyForm = () => {
    reset(defaultValues);
    handleClose();
  };

  const submitForm = handleSubmit((values) => onSubmit(values).then(destroyForm));

  const submitDisabled = !isDirty || !isValid || isSubmitting;

  const withConfirmClose = isEditMode && isDirty;

  return { control, destroyForm, submitForm, isSubmitting, submitDisabled, withConfirmClose };
}
