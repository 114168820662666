import { SelectOptionWithLabelString } from 'types';

import { getOptionsArrayFromStringArray } from './getOptionsArrayFromStringArray';

export function getOptionsArrayFromString(
  string: string | null | undefined,
): SelectOptionWithLabelString[] {
  if (!string) return [];

  const stringArray = string.split(',').map((value) => value.trim());

  return getOptionsArrayFromStringArray(stringArray);
}
