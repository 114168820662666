import { Box, Divider } from '@mui/material';
import { ReactElement } from 'react';

import { BuildingNotesForm } from 'components';
import { useAuth } from 'hooks';
import {
  BuildingStatus,
  UnassignedBuildingApplyInquiry,
  UnassignedBuildingScheduleTourInquiry,
} from 'types';

import { UnassignedBuildingInquiriesList } from '../UnassignedBuildingInquiriesList';

interface BuildingCollapseContentProps {
  buildingId: string;
  notes: string;
  buildingStatus: BuildingStatus;
  inquiries: (UnassignedBuildingScheduleTourInquiry | UnassignedBuildingApplyInquiry)[];
}

export function UnassignedBuildingContent({
  buildingId,
  inquiries,
  notes,
}: BuildingCollapseContentProps): ReactElement {
  const { isReadOnlyAccess } = useAuth();

  return (
    <>
      <Divider sx={{ marginLeft: '32px' }} />
      <UnassignedBuildingInquiriesList inquiries={inquiries} />
      <Box sx={{ padding: '24px 24px 24px 40px' }}>
        <BuildingNotesForm isDisabled={isReadOnlyAccess} buildingId={buildingId} notes={notes} />
      </Box>
    </>
  );
}
