import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { ReactElement } from 'react';

import { ReactComponent as BuildingIcon } from 'assets/icons/dashboard/buildingSmall.svg';
import { ReactComponent as PrivateChatIcon } from 'assets/icons/dashboard/privateChatSmall.svg';
import { DashboardItemCounters } from 'types';

import { IconWrapper, Wrapper, Counter } from './styled';

interface CardSummaryProps {
  counters: DashboardItemCounters;
}

export function CardSummary({ counters }: CardSummaryProps): ReactElement {
  const { sms, email, privateNotes, buildingMessages, requestMessages, otherSystemMessages } =
    counters;

  return (
    <Wrapper>
      {!!sms && (
        <IconWrapper>
          <SmsOutlinedIcon sx={{ fontSize: '16px' }} color="secondary" />
          <Counter variant="body1">{sms}</Counter>
        </IconWrapper>
      )}

      {!!email && (
        <IconWrapper>
          <EmailOutlinedIcon sx={{ fontSize: '16px' }} color="secondary" />
          <Counter variant="body1">{email}</Counter>
        </IconWrapper>
      )}

      {!!privateNotes && (
        <IconWrapper>
          <PrivateChatIcon color="secondary" />
          <Counter variant="body1">{privateNotes}</Counter>
        </IconWrapper>
      )}

      {!!buildingMessages && (
        <IconWrapper>
          <BuildingIcon color="secondary" />
          <Counter variant="body1">{buildingMessages}</Counter>
        </IconWrapper>
      )}

      {!!requestMessages && (
        <IconWrapper>
          <AssignmentTurnedInOutlinedIcon sx={{ fontSize: '16px' }} color="secondary" />
          <Counter variant="body1">{requestMessages}</Counter>
        </IconWrapper>
      )}

      {!!otherSystemMessages && (
        <IconWrapper>
          <FolderSpecialOutlinedIcon sx={{ fontSize: '16px' }} color="secondary" />
          <Counter variant="body1">{otherSystemMessages}</Counter>
        </IconWrapper>
      )}
    </Wrapper>
  );
}
