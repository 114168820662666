import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { Box, styled, Typography } from '@mui/material';

import { Button } from 'components';

export const ListItemW = styled(Box)<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  paddingLeft: '40px',
  borderLeft: `2px solid ${isSelected ? theme.palette.primary.main : 'transparent'}`,
  background: isSelected ? theme.palette.secondary.p005 : theme.palette.background.paper,
  transition: 'border-color 0.25s ease-out',
}));

export const BuildingW = styled(Box)(() => ({
  position: 'relative',
  alignItems: 'start',
  display: 'grid',
  gridTemplateColumns: 'auto minmax(100px, 1fr);',
  padding: '12px 0',
}));

export const BuildingDetailsW = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const Preview = styled(Box)<{ previewUrl?: string }>(({ theme }) => ({
  width: '68px',
  height: '68px',
  borderRadius: '4px',
  marginRight: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.p020,
  position: 'relative',
  overflow: 'hidden',
}));

export const BuildingName = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: 600,
  cursor: 'pointer',
  lineHeight: '1.1rem',
  color: theme.palette.black.main,
}));

export const BuildingAddress = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.light,
  fontSize: '12px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  lineHeight: '16px',
  textOverflow: 'ellipsis',
}));

export const ArrowIcon = styled(ArrowRightRoundedIcon, {
  shouldForwardProp: (propname) => propname !== '$isOpen',
})<{ $isOpen: boolean }>(({ $isOpen, theme }) => ({
  position: 'absolute',
  cursor: 'pointer',
  color: theme.palette.secondary.main,
  top: 'calc(50% - 12px)',
  left: '-27px',
  transform: `rotate(${$isOpen ? '90' : '0'}deg)`,
}));

export const AddInquiryButton = styled(Button)(({ theme }) => ({
  width: 'fit-content',
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: 600,
  margin: '16px 8px 16px 0',
  padding: '6px 14px',
  color: theme.palette.black.main,
}));

export const ButtonsW = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  paddingLeft: '61px',
}));

export const NotesWrap = styled(Box)`
  padding: 24px 24px 24px 40px;
`;

export const SearchContentW = styled('div')`
  display: grid;
  grid-template-columns: minmax(10px, auto) auto;
  grid-gap: 4px;
  justify-content: space-between;
  align-items: flex-start;
`;

export const BuildingCardW = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 24px;
`;

export const ImageW = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;
