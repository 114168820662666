import { Collapse, CollapseProps } from '@mui/material';
import { FunctionComponent, ReactElement, useEffect, useState } from 'react';

interface CustomCollapseProps {
  in: boolean;
  children: FunctionComponent;
  orientation: CollapseProps['orientation'];
}

export const CustomCollapse = ({
  in: open,
  children,
  orientation,
}: CustomCollapseProps): ReactElement => {
  const [renderChildren, setRenderChildren] = useState(open);

  useEffect(() => {
    if (open) {
      setRenderChildren(true);
      return;
    }
    const timeout = setTimeout(() => setRenderChildren(false), 1000);
    return () => clearTimeout(timeout);
  }, [open]);

  return (
    <Collapse orientation={orientation} in={open}>
      {renderChildren || open ? children({}) : null}
    </Collapse>
  );
};
