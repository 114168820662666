export const MenuProps = {
  PaperProps: {
    style: {
      width: 185,
    },
  },
};

export const inputProps = {
  style: { fontSize: 14 },
};

export const selectStyles = {
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '.MuiSelect-select': {
    padding: '6px 10px 6px 105px',
    fontSize: '14px',
  },
};
