import * as yup from 'yup';

import { MessageType } from 'types';
import { getStringSizeInMb } from 'utils';
import {
  getMaxLengthText,
  getMaxSizeText,
  getMinLengthText,
  NO_MULTIPLE_SPACES_ALLOWED,
  REQUIRED,
} from 'validations/messages';
import { noMultipleSpaces } from 'validations/tests';

export const messageTemplateFormSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(2, getMinLengthText(2))
    .max(50, getMaxLengthText(50))
    .required(REQUIRED),
  type: yup.string().trim().required(REQUIRED),
  event: yup.string().trim().required(REQUIRED),
  subject: yup
    .string()
    .trim()
    .nullable()
    .when('type', {
      is: MessageType.OUTGOING_EMAIL,
      then: yup.string().trim().max(78, getMaxLengthText(78)).required(REQUIRED),
    }),
  body: yup
    .string()
    .trim()
    .required(REQUIRED)
    .when('type', {
      is: MessageType.OUTGOING_EMAIL,
      then: (schema) =>
        schema.test(
          'body',
          getMaxSizeText(25, 'MB'),
          (value) => getStringSizeInMb(value || '') < 25,
        ),
      otherwise: (schema) =>
        schema
          .max(1400, getMaxLengthText(1400))
          .test('body', NO_MULTIPLE_SPACES_ALLOWED, noMultipleSpaces),
    }),
});
