import { BuildingSource, MessageType } from 'types';

import { SystemMessagePayloadType } from './communications';

enum BuildingChatItemType {
  INCOMING_MESSAGE = 'INCOMING_MESSAGE',
  OUTGOING_MESSAGE = 'OUTGOING_MESSAGE',
  PRIVATE_NOTES = 'PRIVATE_NOTES',
}

export interface DashboardItemActiveListRequestItem {
  id: string;
  title: string;
  createdAt: string;
  notes: string | null;
  notificationCount: number;
}

interface DashboardItemActiveListRequests {
  items: DashboardItemActiveListRequestItem[];
  totalNotificationCount: number;
}

interface DashboardItemActiveInquiryRequestBuilding {
  id: string;
  name: string;
  photo: string;
  source: BuildingSource;
}

export interface DashboardItemActiveInquiryRequestsItem {
  building: DashboardItemActiveInquiryRequestBuilding;
  id: string;
  createdAt: string;
  notificationCount: number;
  campaignId: string | null;
}

interface DashboardItemActiveInquiryRequests {
  items: DashboardItemActiveInquiryRequestsItem[];
  totalNotificationCount: number;
}

interface DashboardItemBuildingChatItemPayload {
  text: string;
}

export interface DashboardItemBuildingChatItem {
  payload: DashboardItemBuildingChatItemPayload;
  type: BuildingChatItemType;
}

interface CommunicationItemTextMessagePayload {
  text: string;
}

interface DashboardItemCommunicationItem<T, P> {
  payload: P;
  type: T;
}

export type DashboardItemCommunicationItemType =
  | DashboardItemCommunicationItem<MessageType.SYSTEM_MESSAGE, SystemMessagePayloadType>
  | DashboardItemCommunicationItem<
      Exclude<MessageType, MessageType.SYSTEM_MESSAGE>,
      CommunicationItemTextMessagePayload
    >;

export interface DashboardItemLastMessage {
  id: string;
  createdAt: string;
  communicationItem: DashboardItemCommunicationItemType | null;
  buildingChatItem: DashboardItemBuildingChatItem | null;
}

export interface DashboardItemCounters {
  sms: number;
  email: number;
  privateNotes: number;
  buildingMessages: number;
  requestMessages: number;
  otherSystemMessages: number;
}

export interface DashboardItem {
  id: string;
  firstName: string;
  lastName: string;
  lastMessage: DashboardItemLastMessage;
  activeListRequests: DashboardItemActiveListRequests;
  activeTourRequests: DashboardItemActiveInquiryRequests;
  activeApplicationRequests: DashboardItemActiveInquiryRequests;
  counters: DashboardItemCounters;
}

export type DashboardItems = DashboardItem[];

export interface DashboardTotalCounters extends DashboardItemCounters {
  total: number;
}

interface ActiveRequestsModalState<T> {
  requests: T[] | undefined;
  contactId: string;
}

export type ActiveListRequestsModalState =
  ActiveRequestsModalState<DashboardItemActiveListRequestItem>;

export type ActiveInquiryRequestsModalState =
  ActiveRequestsModalState<DashboardItemActiveInquiryRequestsItem>;

export type ActiveRequestsModalStateType =
  | ActiveListRequestsModalState
  | ActiveInquiryRequestsModalState;
