import { styled } from '@mui/material';

export const FieldContainer = styled('div')({
  position: 'relative',
});

export const OptionsContainer = styled('div')((props) => ({
  position: 'absolute',
  width: '100%',
  top: '100%',
  zIndex: 5000,
  backgroundColor: props.theme.palette.background.paper,
  boxShadow: props.theme.shadows[6],
}));
