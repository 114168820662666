import { FILTERS_NAME_PREFIX } from '../FilterForm/config';
import { getBaseFilterFieldsSet, getBaseFilterGroupSet } from '../FilterForm/utils';

export const FILTER_DEFAULT_VALUES = {
  group: '',
  [FILTERS_NAME_PREFIX]: {
    ...getBaseFilterGroupSet(),
    value: [getBaseFilterFieldsSet()],
  },
};

export const GROUP_DEFAULT_VALUES = {
  group: '',
  [FILTERS_NAME_PREFIX]: {
    ...getBaseFilterGroupSet(),
    value: [getBaseFilterGroupSet()],
  },
};
