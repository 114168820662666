import { BuildingChatMessage, Message } from 'types';
import { actionCreator } from 'utils';

import actionTypes from './actionTypes';

export const loadMessages = actionCreator<{
  messages: Message[];
  targetMessageId: string | null;
  sources: string | null;
}>(actionTypes.loadMessages);

export const putMessage = actionCreator<{ message: Message }>(actionTypes.putMessage);

export const loadBuildingChatMessages = actionCreator<{
  messages: BuildingChatMessage[];
}>(actionTypes.loadBuildingChatMessages);

export const putBuildingChatMessage = actionCreator<{ message: BuildingChatMessage }>(
  actionTypes.putBuildingChatMessage,
);
