import { ReactElement } from 'react';

import { Campaign, EmployeeRole, SelectOption } from 'types';

import { CommunicationType } from './communications';

export interface Contact {
  campaigns?: Campaign[];
  cameFrom: string;
  contactStyle?: string[];
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
  owner?: ContactOwner;
  externalId: string;
  assignee?: ContactAssignee;
  acquisitionData?: AcquisitionData;
  communication: CommunicationType;
  emailIsConfirmed: boolean;
  phoneIsConfirmed: boolean;
  summary: CampaignsSummary;
}

export interface CampaignsSummary {
  activeCampaigns: number;
  activeTourInquires: number;
  activeApplyInquires: number;
}

export interface ContactAssignee {
  photo: string;
  firstName: string;
  lastName: string;
  id: string;
}

// TODO: need to be deleted?
export interface ContactOwner {
  createdAt: string;
  id: string;
  isArchived: boolean;
  isAvailable: boolean;
  maxCapacity: number;
  roles: EmployeeRole[];
  updatedAt: string;
  user: UserOwner;
  firstName: string;
  lastName?: string;
  photo: string;
}

export interface UserOwner {
  createdAt: string;
  email: string;
  externalId: string;
  familyName?: string;
  givenName: string;
  id: string;
  isActive: boolean;
  name: string;
  picture: string;
  updatedAt: string;
}

export interface ContactsTable {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  campaignSummary: ReactElement | string;
  contactOwner: ReactElement | string;
  createdAt: string;
}

export enum ContactStyle {
  PhoneCall = 'phoneCall',
  Texting = 'texting',
  Email = 'phoneCall',
  Facebook = 'facebook',
}

export interface ContactFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  contactStyle?: string[];
  cameFrom?: string;
}

export type AcquisitionChannelOptions = SelectOption[];

export type ContactFormDefaultValues = Partial<ContactFormValues> | undefined;

export type ContactDetails = Omit<
  Contact,
  | 'firstName'
  | 'lastName'
  | 'contactStyle'
  | 'updatedAt'
  | 'owner'
  | 'emailIsConfirmed'
  | 'phoneIsConfirmed'
  | 'email'
  | 'phone'
  | 'communication'
  | 'summary'
> & {
  contactStyle: string;
  fullName: string;
  email: ReactElement;
  phone: ReactElement;
};

export interface AcquisitionData {
  signUpLink: string;
  acquisitionChannel: string;
  lighthouseId: string;
  stytchId: string;
  referredBy: string;
  gAId: string;
  source: string;
  campaign: string;
  term: string;
  medium: string;
  userIP: string;
  device: string;
  gclId: string;
}

export type ContactInfoPanelDetails = Omit<
  Contact,
  'firstName' | 'lastName' | 'contactStyle' | 'updatedAt' | 'owner'
> & {
  contactStyle: string;
  fullName: string;
};

export type ContactOwnerAndAssigneeFormValues = {
  ownerId: string;
  assigneeId: string;
};
