import { Mentions, MessageType, NewMessageFormValues } from 'types';
import { removeExtraSpaces, parseTextWithMentions } from 'utils';

interface FormValues
  extends Pick<NewMessageFormValues, 'content' | 'cc' | 'bcc' | 'type' | 'subject'> {
  mentions?: Mentions;
  replyTo?: string;
}

interface FormValuesAdapterReturn {
  text: string;
  type: MessageType;
  cc?: string[];
  bcc?: string[];
  subject?: string;
  replyTo?: string;
}

export function newMessageValuesAdapter({
  content,
  type,
  mentions,
  cc,
  bcc,
  subject,
  replyTo,
}: FormValues): FormValuesAdapterReturn {
  const textValue = mentions ? parseTextWithMentions(mentions, content) : content;
  const textWithoutExtraSpaces = removeExtraSpaces(textValue);
  const textWithBrTags = textWithoutExtraSpaces.replaceAll('\n', '<br>');

  return {
    type,
    text: textWithBrTags,
    ...(cc.length > 0 && { cc: cc.map((email) => email.value) }),
    ...(bcc.length > 0 && { bcc: bcc.map((email) => email.value) }),
    ...(subject && { subject }),
    ...(replyTo && { replyTo }),
  };
}
