import { CampaignBuilding, MessageTemplateFormData } from 'types';

import {
  ContactsRequestProps,
  EmployeeListRequestProps,
  GetBuildingsParams,
  GetCampaignBuildingDetailsParams,
  GetMessageTemplatesParams,
} from './types';
import { getParamsString } from './utils';

const API_PATHS = {
  CONTACT: {
    CREATE: (contactOwnerId: string): string => `/v1/contacts?ownerId=${contactOwnerId}`,
    DETAILS: (id: string): string => `/v1/contacts/${id}`,
    EDIT: (id: string): string => `/v1/contacts/${id}`,
    EDIT_ASSIGNEE: (contactId: string): string => `/v1/contacts/${contactId}/change-assignee`,
    EDIT_CONTACT_OWNER: (contactId: string): string => `/v1/contacts/${contactId}/change-owner`,
    SEARCH_DETAILS: (contactId: string): string => `/v1/contacts/${contactId}/search-content`,
  },
  CONTACTS: {
    _: ({
      pageNumber,
      limit,
      search = '',
      contactOwner,
      filterId,
    }: ContactsRequestProps): string => {
      let url = `/v1/contacts?page=${pageNumber}&limit=${limit}`;

      if (!!search) {
        url += `&search=${encodeURIComponent(search)}`;
      }

      if (!!contactOwner) {
        url += `&ownerId=${encodeURIComponent(contactOwner)}`;
      }

      if (!!filterId) {
        url += `&filterId=${encodeURIComponent(filterId)}`;
      }

      return url;
    },
  },
  EMPLOYEES: {
    GET_ALL: (): string => `/v1/employees`,
    GET_BY_PAGE: ({ pageNumber, limit }: EmployeeListRequestProps): string =>
      `/v1/employees?page=${pageNumber}&limit=${limit}`,
    DELETE: (employeeId: string): string => `/v1/employees/mark-inactive/${employeeId}`,
    CHANGE_ROLE: (): string => `/v1/employees/change-role`,
    CHANGE_TEAM: (): string => `/v1/employees/change-team`,
    TEAMS: {
      GET_ALL: (): string => `v1/employees/teams`,
    },
    ME: {
      NOTIFICATIONS_COUNT: (): string => 'v1/employees/me/notifications-count',
    },
  },
  CAMPAIGN: {
    CREATE: (): string => `/v1/campaigns`,
    EDIT: (campaignId: string): string => `/v1/campaigns/${campaignId}`,
    CAMPAIGN_STATUS_TRANSITION: (campaignId: string): string =>
      `/v1/campaigns/${campaignId}/state-transitions`,
  },
  CAMPAIGNS: {
    _: (contactId: string): string => `/v1/campaigns?id=${contactId}`,
  },
  BUILDING: {
    GET_BY_ID: ({ buildingId, campaignId }: GetCampaignBuildingDetailsParams): string =>
      `v1/campaigns/${campaignId}/buildings/${buildingId}`,
  },
  BUILDINGS: {
    _: 'v1/buildings',
    GET: (buildingParams: GetBuildingsParams): string =>
      `v1/buildings${getParamsString(buildingParams)}`,
    ADD: (campaignId: string): string => `v1/campaigns/${campaignId}/buildings`,
    EDIT: (buildingId: CampaignBuilding['id'], contactId: string): string =>
      `v1/contacts/${contactId}/buildings/${buildingId}`,
    DISMISS: (buildingId: CampaignBuilding['id'], campaignId: string): string =>
      `v1/campaigns/${campaignId}/buildings/${buildingId}/dismiss`,
    SEND: (campaignId: string): string => `v1/campaigns/${campaignId}/recommend-buildings`,
  },
  INQUIRIES: {
    CREATE_TOUR: 'v1/tour-inquiries',
    CREATE_APPLY: 'v1/apply-inquiries',
    EDIT_TOUR: (id: string): string => `v1/tour-inquiries/${id}`,
    EDIT_APPLY: (id: string): string => `v1/apply-inquiries/${id}`,
    APPLY_STATUS_TRANSITION: (id: string): string => `v1/apply-inquiries/${id}/state-transitions`,
    TOUR_STATUS_TRANSITION: (id: string): string => `v1/tour-inquiries/${id}/state-transitions`,
    ASSIGN_TOUR: (inquiryId: string): string => `v1/tour-inquiries/${inquiryId}/assignee`,
    ASSIGN_APPLY: (inquiryId: string): string => `v1/apply-inquiries/${inquiryId}/assignee`,
    GET_TOUR_INQUIRIES: 'v1/tour-inquiries',
    GET_TOUR_INQUIRIES_STATES: '/v1/tour-inquiries/states',
    GET_APPLY_INQUIRIES: 'v1/apply-inquiries',
    GET_APPLY_INQUIRIES_STATES: '/v1/apply-inquiries/states',
  },
  COMMUNICATIONS: {
    GET_MESSAGES: 'v1/communications',
    POST_MESSAGE: 'v1/communications/messages',
    BUILDING_CHATS: 'v1/communications/building-chats',
    BUILDING_CHAT_MESSAGES: 'v1/communications/building-chats/messages',
    POST_BUILDING_CHAT_MESSAGE: 'v1/communications/building-chats/messages',
    GET_ATTACHMENT: (attachmentId: string): string =>
      `/v1/communications/attachments/${attachmentId}`,
  },
  MESSAGE_TEMPLATES: {
    GET_MESSAGE_TEMPLATES: ({ page, limit }: GetMessageTemplatesParams): string =>
      `v1/message-templates?page=${page}&limit=${limit}`,
  },
  MESSAGE_TEMPLATE: {
    GET_BY_ID: (templateId: string): string => `/v1/message-templates/${templateId}`,
    CREATE: (): string => '/v1/message-templates',
    EDIT: (): string => '/v1/message-templates',
    DELETE: (templateId: string): string => `/v1/message-templates/${templateId}`,
    GET_MESSAGE_TEMPLATE_FIELDS: (event: MessageTemplateFormData['event']): string =>
      `/v1/message-templates/fields/${event}`,
  },
  FILTERS: {
    _: '/v1/filters',
    DELETE: (filterId: string): string => `/v1/filters/${filterId}`,
    DESCRIPTORS: '/v1/filters/descriptors',
  },
  WISHLIST: {
    _: (contactId: string): string => `v1/wishlist/${contactId}`,
  },
  UNASSIGNED_BUILDINGS: {
    _: (contactId: string): string => `/v1/unassigned-buildings?contactId=${contactId}`,
  },
  DASHBOARD: {
    _: (): string => '/v1/dashboard',
  },
  SETTINGS: {
    _: (): string => '/v1/settings',
    COMMUNICATION_EMAIL_SETUP: (): string => '/v1/settings/communication-email/setup',
  },
};

export default API_PATHS;
