import { AcquisitionChannel, CommunicationPreferences } from 'types';

export const ACQUISITION_CHANNEL_OPTIONS = [
  { value: AcquisitionChannel.LH_WEB_SITE, label: 'Lighthouse website' },
  { value: AcquisitionChannel.FACEBOOK, label: 'Facebook groups' },
  { value: AcquisitionChannel.PERSONAL_REFERENCE, label: 'Personal referral' },
  { value: AcquisitionChannel.SECONDARY_CONTACT, label: 'Secondary contact' },
];

export const COMMUNICATION_OPTIONS = [
  {
    value: CommunicationPreferences.PHONE,
    label: 'Phone call',
  },
  {
    value: CommunicationPreferences.TEXTING,
    label: 'Texting',
  },
  {
    value: CommunicationPreferences.EMAIL,
    label: 'Email',
  },
  {
    value: CommunicationPreferences.ZOOM,
    label: 'Zoom',
  },
];
