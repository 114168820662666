import { useContext } from 'react';

import { Campaign } from 'types';

import { CampaignsState } from '..';

export function useSelectedCampaign(campaignId: Campaign['id']): Campaign | undefined {
  const campaigns = useContext(CampaignsState);

  return campaigns[campaignId];
}
