import { API_HOST } from 'settings';
import { ContactOwner, UserOwner } from 'types';

type TokenResponse = {
  accessToken: string;
  employee: ContactOwner;
  user: UserOwner;
};

export const exchangeGoogleToken = async (googleToken: string): Promise<TokenResponse> => {
  return fetch(`${API_HOST}/v1/auth/token`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ accessToken: googleToken }),
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (data.statusCode === 401) {
        throw new Error(data);
      }

      return data;
    });
};
