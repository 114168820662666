import { AxiosResponse } from 'axios';
import { UseMutateAsyncFunction, useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import {
  AssignApplyResponseSuccess,
  AssignInquiryParams,
  AssignTourResponseSuccess,
} from 'api/types';

interface UseAssignInquiryMutationsReturn {
  assignTourMutation: UseMutateAsyncFunction<
    AxiosResponse<AssignTourResponseSuccess, unknown>,
    unknown,
    AssignInquiryParams,
    unknown
  >;
  assignApplyInquiryMutation: UseMutateAsyncFunction<
    AxiosResponse<AssignApplyResponseSuccess, unknown>,
    unknown,
    AssignInquiryParams,
    unknown
  >;
}

export function useAssignInquiryMutations(): UseAssignInquiryMutationsReturn {
  const { mutateAsync: assignTourMutation } = useMutation(API_HANDLERS.INQUIRIES.ASSIGN_TOUR, {
    onSuccess: () => {
      queryClient.invalidateQueries('contact');
    },
  });

  const { mutateAsync: assignApplyInquiryMutation } = useMutation(
    API_HANDLERS.INQUIRIES.ASSIGN_APPLY,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contact');
      },
    },
  );

  return {
    assignTourMutation,
    assignApplyInquiryMutation,
  };
}
