import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material';

import { InquiryStateColor } from 'types';

import { getStatusColors } from './config';

export const StatusBlock = styled(LoadingButton, {
  shouldForwardProp: (propname) => propname !== 'isDisabled' && propname !== 'icon',
})<{
  scheme: InquiryStateColor;
  icon?: boolean;
  isDisabled: boolean;
  loading?: boolean;
}>(({ scheme, icon, isDisabled, theme }) => {
  const colors = getStatusColors(scheme, theme);

  return {
    width: 'auto',
    padding: icon ? '4px 4px 4px 8px' : '4px 8px',
    whiteSpace: 'nowrap',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    gap: '4px',
    textTransform: 'none',
    alignItems: 'center',
    fontWeight: 600,
    lineHeight: '16px',
    fontSize: '12px',
    ...colors,

    svg: {
      path: {
        fill: 'currentColor',
      },
    },

    transition: 'all 0.2s',
  };
});

export const StatusName = styled('span')(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  transition: 'all 0.2s',
}));
