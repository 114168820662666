import { CircularProgress } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

import { EmptyState, Page404 } from 'components';
import { LoaderWrapper } from 'components/LoaderWrapper';
import { EmptyStateIcons } from 'types';

import { PageStatusW, ContentWrap, Cover } from './styled';

export interface EmptyStateArgs {
  shouldAppear: boolean;
  title: string;
  description?: string;
  variant: EmptyStateIcons;
  is404?: boolean;
}

interface PageStatusProps {
  loading: boolean;
  isFirstLoad?: boolean;
  emptyState?: EmptyStateArgs;
  children?: ReactNode;
  withCover?: boolean;
}

export function PageStatus({
  loading,
  emptyState,
  withCover,
  children,
}: PageStatusProps): ReactElement {
  const showError = emptyState?.shouldAppear;

  if (showError && emptyState?.is404) {
    return <Page404 />;
  }

  return (
    <PageStatusW>
      <ContentWrap sx={{ visibility: showError ? 'hidden' : 'visible' }}>{children}</ContentWrap>

      {loading && withCover && <Cover />}

      {loading && !showError && (
        <LoaderWrapper>
          <CircularProgress size={48} />
        </LoaderWrapper>
      )}

      {showError && (
        <LoaderWrapper>
          <EmptyState
            title={emptyState.title}
            description={emptyState.description}
            variant={emptyState.variant}
          />
        </LoaderWrapper>
      )}
    </PageStatusW>
  );
}
