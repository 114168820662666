import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { forwardRef, ReactElement, Ref } from 'react';

import { ReactComponent as CheckboxEmptyIcon } from 'assets/icons/checkboxEmpty.svg';
import { ReactComponent as CheckboxFilledIcon } from 'assets/icons/checkboxFilled.svg';

export type CheckboxInputProps = CheckboxProps & {
  forwardRef?: Ref<HTMLButtonElement>;
};

function CheckboxW({ forwardRef, ...otherProps }: CheckboxInputProps): ReactElement {
  return (
    <Checkbox
      icon={<CheckboxEmptyIcon />}
      checkedIcon={<CheckboxFilledIcon />}
      ref={forwardRef}
      {...otherProps}
      size="small"
      sx={{ padding: '6px' }}
    />
  );
}

const WrappedCheckbox = forwardRef<HTMLInputElement, CheckboxInputProps>((props, ref) => (
  <Checkbox forwardRef={ref} {...props} />
));

WrappedCheckbox.displayName = 'TextField';

export default WrappedCheckbox;

export { CheckboxW as Checkbox };
