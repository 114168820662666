import { Mentions, BuildingChatItemType, BuildingChatFormValues } from 'types';
import { parseTextWithMentions, removeExtraSpaces } from 'utils';

interface FormValues extends BuildingChatFormValues {
  mentions?: Mentions;
}

interface BuildingChatFormValuesAdapterReturn {
  text: string;
  type: BuildingChatItemType;
}

export function newMessageValuesAdapter({
  content,
  type,
  mentions,
}: FormValues): BuildingChatFormValuesAdapterReturn {
  const textValue = mentions ? parseTextWithMentions(mentions, content) : content;

  return {
    type,
    text: removeExtraSpaces(textValue),
  };
}
