import { ReactElement } from 'react';

import { API_HANDLERS } from 'api/apiHandlers';
import { InquiryStatus, TourInquiry } from 'types';

import { InquiryStatusLayout } from './InquiryStatusLayout';
import { useChangeInquiryStatus } from './useChangeInquiryStatus';

interface InquiryStatusProps {
  status: InquiryStatus;
  inquiryId: TourInquiry['id'];
  isDisabled: boolean;
}

export function TourStatusBlock({
  inquiryId,
  status,
  isDisabled,
}: InquiryStatusProps): ReactElement {
  const {
    closeTransitions,
    defaultTransitions,
    handleChangeStatus,
    setFocused,
    showOptions,
    isLoading,
  } = useChangeInquiryStatus({
    inquiryId,
    requestFn: API_HANDLERS.INQUIRIES.GET_TOUR_STATUS_TRANSITION,
    changeRequestFn: API_HANDLERS.INQUIRIES.CHANGE_TOUR_STATUS,
    status: status.name,
  });

  return (
    <InquiryStatusLayout
      status={status}
      closeTransitions={closeTransitions}
      defaultTransitions={defaultTransitions}
      handleChangeStatus={handleChangeStatus}
      setFocused={setFocused}
      showOptions={showOptions}
      isDisabled={isDisabled}
      isLoading={isLoading}
    />
  );
}
