export enum WsCommunicationChannel {
  NEW_COMMUNICATION_ITEM = 'new-communication-item',
  NEW_BUILDING_CHAT_ITEM = 'new-building-chat-item',
  BUILDING_CHATS_COUNTER_UPDATED = 'building-chats-counter-updated',
  ACKNOWLEDGE_BUILDING_CHAT_ITEMS = 'acknowledge-building-chat-items',
  ACKNOWLEDGE_GENERAL_CHAT_ITEMS = 'acknowledge-communication-items',
}

export enum WsGlobalNotificationsChannel {
  GLOBAL_COUNTER_UPDATED = 'global-counter-updated',
}

export enum WsDashboardChannel {
  DASHBOARD_COUNTERS_UPDATED = 'dashboard-counters-updated',
  NEW_DASHBOARD_ITEM = 'new-dashboard-item',
  UPDATE_DASHBOARD_ITEM = 'update-dashboard-item',
}
