import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { FilterSelectController, SearchField } from 'components';
import { TourTable } from 'features/tourTable/components';
import { useTourTable } from 'features/tourTable/hooks';

import { PageTitle, PageWrapper, SearchAndFilterBar, SearchWrapper } from './styled';

export function TourTablePage(): ReactElement {
  const {
    isTourInquiriesLoading,
    tourInquiries,
    tourInquiriesTotal,
    emptyState,
    currentPage,
    pageLimit,
    search,
    setCurrentPage,
    setPageLimit,
    onSetSearch,
    onClearSearch,
    tourAssigneeFilterControl,
    tourAssigneeOptions,
    assigneeDisplayValue,
    tourStatusFilterControl,
    tourStatusOptions,
    statusDisplayValue,
  } = useTourTable();

  return (
    <PageWrapper>
      <Helmet>
        <title>Tour table</title>
      </Helmet>

      <PageTitle variant="h4">Active tours</PageTitle>

      <SearchAndFilterBar>
        <SearchWrapper>
          <SearchField value={search} onChange={onSetSearch} onClear={onClearSearch} />
        </SearchWrapper>

        <FilterSelectController
          control={tourAssigneeFilterControl}
          name="assigneeId"
          placeholder="Assignee"
          label="Assignee"
          options={tourAssigneeOptions}
          id="tour-assignee-filter"
          width="160px"
          labelWidth="72px"
          renderValue={assigneeDisplayValue}
          defaultOptionText="All tours"
        />

        <FilterSelectController
          control={tourStatusFilterControl}
          name="status"
          placeholder="Status"
          label="Status"
          options={tourStatusOptions}
          id="tour-status-filter"
          width="144px"
          labelWidth="56px"
          renderValue={statusDisplayValue}
          defaultOptionText="All tours"
        />
      </SearchAndFilterBar>

      <TourTable
        isLoading={isTourInquiriesLoading}
        tourInquiries={tourInquiries}
        emptyState={emptyState}
        totalCount={tourInquiriesTotal}
        currentPage={currentPage}
        pageLimit={pageLimit}
        setCurrentPage={setCurrentPage}
        setPageLimit={setPageLimit}
      />
    </PageWrapper>
  );
}
