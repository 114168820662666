import { ReactElement } from 'react';

import {
  CancelIcon,
  FileItemW,
  FileName,
  FileSize,
  MetaSection,
  SectionLeft,
  StyledImage,
  Thumb,
} from './styled';

interface FileWithPreviewProps {
  name: string;
  onDelete: () => void;
  imgSrc: string;
  size: number;
}

export function FileWithPreview({
  imgSrc,
  name,
  size,
  onDelete,
}: FileWithPreviewProps): ReactElement {
  return (
    <FileItemW>
      <SectionLeft>
        <Thumb>
          <StyledImage
            alt={name}
            src={imgSrc}
            // Revoke data uri after image is loaded
            onLoad={() => {
              window.URL.revokeObjectURL(imgSrc);
            }}
          />
        </Thumb>

        <MetaSection>
          <FileName>{name}</FileName>
          <FileSize>{size}</FileSize>
        </MetaSection>
      </SectionLeft>

      <CancelIcon onClick={onDelete} />
    </FileItemW>
  );
}
