import { createContext, ReactElement, ReactNode, useReducer } from 'react';

import { DispatchContext } from 'context/Dispatch';
import { BuildingsState, SelectedBuildingIdState } from 'features/buildings';
import { CampaignsState, SelectedCampaignIdState } from 'features/campaigns/context';
import { BuildingChatState, GeneralChatState } from 'features/communications/context';
import { ApplyInquiryState, SelectedInquiryId, ToursState } from 'features/inquiry';
import {
  SelectedUnassignedBuildingIdState,
  UnassignedBuildingsState,
} from 'features/unassignedBuildings';
import { SelectedWishlistBuildingIdState, WishlistBuildingsState } from 'features/wishlist';
import { useSessionState } from 'hooks';

import { contactDetailsReducer, initialState } from './reducer';
import { DetailsPageState } from './types';

export const ContactState = createContext<DetailsPageState['contact']>(initialState.contact);

export const CampaignInfoState = createContext<DetailsPageState['isCampaignInfoOpen']>(
  initialState.isCampaignInfoOpen,
);

export const BuildingInfoState = createContext<DetailsPageState['isBuildingInfoOpen']>(
  initialState.isBuildingInfoOpen,
);

export const SelectedItemState = createContext<DetailsPageState['selectItemType']>(
  initialState.selectItemType,
);

export const InquiryState = createContext<DetailsPageState['inquiry']>(initialState.inquiry);

export const PageSectionsState = createContext<DetailsPageState['pageSections']>(
  initialState.pageSections,
);

interface ContactDetailsProviderProps {
  children: ReactNode;
}

export const ContactDetailsProvider = ({ children }: ContactDetailsProviderProps): ReactElement => {
  const [sections] = useSessionState<string[]>('sections');
  const [state, dispatch] = useReducer(contactDetailsReducer, {
    ...initialState,
    pageSections: sections,
  });

  return (
    <DispatchContext.Provider value={dispatch}>
      <CampaignInfoState.Provider value={state.isCampaignInfoOpen}>
        <BuildingInfoState.Provider value={state.isBuildingInfoOpen}>
          <SelectedItemState.Provider value={state.selectItemType}>
            <ContactState.Provider value={state.contact}>
              <CampaignsState.Provider value={state.campaigns}>
                <WishlistBuildingsState.Provider value={state.wishlistBuildings}>
                  <SelectedWishlistBuildingIdState.Provider
                    value={state.selectedWishlistBuildingId}
                  >
                    <UnassignedBuildingsState.Provider value={state.unassignedBuildings}>
                      <SelectedUnassignedBuildingIdState.Provider
                        value={state.selectedUnassignedBuildingId}
                      >
                        <BuildingsState.Provider value={state.buildings}>
                          <InquiryState.Provider value={state.inquiry}>
                            <ToursState.Provider value={state.tourInquiries}>
                              <ApplyInquiryState.Provider value={state.applyInquiries}>
                                <SelectedCampaignIdState.Provider value={state.selectedCampaignId}>
                                  <SelectedBuildingIdState.Provider
                                    value={state.selectedBuildingId}
                                  >
                                    <SelectedInquiryId.Provider value={state.selectedInquiryId}>
                                      <PageSectionsState.Provider value={state.pageSections}>
                                        <GeneralChatState.Provider
                                          value={state.generalChatMessages}
                                        >
                                          <BuildingChatState.Provider
                                            value={state.buildingChatMessages}
                                          >
                                            {children}
                                          </BuildingChatState.Provider>
                                        </GeneralChatState.Provider>
                                      </PageSectionsState.Provider>
                                    </SelectedInquiryId.Provider>
                                  </SelectedBuildingIdState.Provider>
                                </SelectedCampaignIdState.Provider>
                              </ApplyInquiryState.Provider>
                            </ToursState.Provider>
                          </InquiryState.Provider>
                        </BuildingsState.Provider>
                      </SelectedUnassignedBuildingIdState.Provider>
                    </UnassignedBuildingsState.Provider>
                  </SelectedWishlistBuildingIdState.Provider>
                </WishlistBuildingsState.Provider>
              </CampaignsState.Provider>
            </ContactState.Provider>
          </SelectedItemState.Provider>
        </BuildingInfoState.Provider>
      </CampaignInfoState.Provider>
    </DispatchContext.Provider>
  );
};
