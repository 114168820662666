import { DATE_FORMAT_WITH_TIME_AND_COMMA, EMPTY_VALUE } from 'constants/index';
import { Employee, EmployeeRole, EmployeesTableData } from 'types';
import { getFormattedDateWithTime } from 'utils/dateHelpers';

import { UNASSIGNED } from '../../../constants';
import { fullNameFieldAdapter } from './fullNameFieldAdapter';

const teamNames: { [key: string]: EmployeeRole } = {
  searchlight: EmployeeRole.SEARCHLIGHTS,
  lightkeeper: EmployeeRole.LIGHTKEEPERS,
  dispatch: EmployeeRole.DISPATCHERS,
  admin: EmployeeRole.ADMINISTRATORS,
};

export function employeeDataAdapter(employees: Employee[]): EmployeesTableData[] {
  return employees.map(({ id, team, user, roles, createdAt, isAvailable }) => {
    return {
      id,
      fullName: fullNameFieldAdapter({
        firstName: user?.givenName || EMPTY_VALUE,
        lastName: user?.familyName || EMPTY_VALUE,
        avatarUrl: user.picture,
      }),
      email: user?.email || EMPTY_VALUE,
      roles,
      team: team ? teamNames[team.toLowerCase()] : UNASSIGNED,
      isActive: isAvailable,
      createdAt:
        getFormattedDateWithTime(new Date(createdAt), DATE_FORMAT_WITH_TIME_AND_COMMA) ||
        EMPTY_VALUE,
    };
  });
}
