import { Box, styled, TextFieldProps } from '@mui/material';

import { theme } from 'styles';

export const FlexWrapper = styled(Box)`
  display: flex;
  gap: 24px;
`;

export const FieldWrapper = styled('div')`
  width: 276px;
`;

export const HorizontalDivider = styled('div')`
  margin: 24px 0;
  border-bottom: 1px solid ${theme.palette.secondary.p020};
`;

export const ButtonsGroupWrapper = styled(FlexWrapper)`
  justify-content: flex-end;
  margin-top: 24px;
`;

export const MessageWrapper = styled('div')`
  margin-right: 68px;
  border: 1px solid ${theme.palette.secondary.p050};
  border-radius: 12px 12px 0 12px;
  overflow: hidden;
`;

export const bodySx: TextFieldProps['sx'] = {
  color: theme.palette.black.main,

  '.MuiInputBase-root': {
    display: 'block',
    height: '428px',
    padding: 0,
  },

  '& textarea:disabled': {
    cursor: 'not-allowed',
  },
};
