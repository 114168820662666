import ReplyIcon from '@mui/icons-material/Reply';
import { Button, styled } from '@mui/material';
import { MouseEvent, ReactElement } from 'react';

const StyledButton = styled(Button)(() => ({
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '20px',
  textTransform: 'none',
  '&:hover': {
    background: 'none',
  },
}));

interface ReplyButtonProps {
  onClick: () => void;
}

export function ReplyButton({ onClick }: ReplyButtonProps): ReactElement {
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <StyledButton
      onClick={handleClick}
      startIcon={<ReplyIcon fontSize="inherit" />}
      disableElevation
      disableRipple
    >
      Reply
    </StyledButton>
  );
}
