import { styled, Typography, Box } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '4px',
  marginTop: '10px',
}));

export const DateCaption = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '16px',
  whiteSpace: 'nowrap',
  color: theme.palette.black.light,
}));

export const MessageWrapper = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '16px',
  color: theme.palette.black.main,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));
