import { ReactElement } from 'react';

import { EventPayloadAssigneeChanged } from 'types';

import { TextBold } from './styled';

interface AssigneeChangedContentProps {
  data: EventPayloadAssigneeChanged;
}

export function AssigneeChangedContent({ data }: AssigneeChangedContentProps): ReactElement {
  const { newAssignee } = data;

  return (
    <span>
      <TextBold>
        {newAssignee.firstName} {newAssignee.lastName}
      </TextBold>{' '}
      was assigned as Assignee
    </span>
  );
}
