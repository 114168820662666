import { ReactElement } from 'react';

import { EventPayloadNewCampaignCreated } from 'types';

import { Link } from './styled';

interface NewCampaignCreatedContentProps {
  data: EventPayloadNewCampaignCreated;
  onCampaignClick: (campaignId: string) => void;
}

export function NewCampaignCreatedContent({
  data,
  onCampaignClick,
}: NewCampaignCreatedContentProps): ReactElement {
  const { campaignId, campaignName } = data;

  return (
    <span>
      Campaign <Link onClick={() => onCampaignClick(campaignId)}>{campaignName}</Link> was created
    </span>
  );
}
