import MuiTabList from '@mui/lab/TabList';
import { styled, Tab as MuiTab } from '@mui/material';

export const TabList = styled(MuiTabList)(() => ({
  '& .MuiTabs-indicator': {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    height: '4px',
  },
}));

export const Tab = styled(MuiTab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  color: theme.palette.black.main,

  '&.Mui-selected': {
    color: theme.palette.black.main,
  },
}));
