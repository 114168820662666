import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { TourInquiriesRequestParams, TourInquiriesResponseSuccess } from 'api/types';

type UseTourInquiriesQueryParams = TourInquiriesRequestParams;

interface UseTourInquiriesQueryReturn {
  tourInquiriesData: TourInquiriesResponseSuccess | undefined;
  isTourInquiriesLoading: boolean;
  isTourInquiriesError: boolean;
}

export function useTourInquiriesQuery({
  page,
  limit,
  search,
  assigneeId,
  statusId,
}: UseTourInquiriesQueryParams): UseTourInquiriesQueryReturn {
  const { data, isLoading, isFetching, isError } = useQuery(
    ['tour-table', page, limit, search, assigneeId, statusId],
    () =>
      API_HANDLERS.INQUIRIES.GET_TOUR_INQUIRIES({
        page,
        limit,
        ...(search && { search }),
        ...(assigneeId && { assigneeId }),
        ...(statusId && { statusId }),
      }),
  );

  return {
    tourInquiriesData: data?.data,
    isTourInquiriesLoading: isLoading || isFetching,
    isTourInquiriesError: isError,
  };
}
