import { Box, styled } from '@mui/material';

export const ItemWrapper = styled(Box)<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  position: 'relative',
  paddingLeft: '40px',
  borderLeft: `2px solid ${isSelected ? theme.palette.primary.main : 'transparent'}`,
  background: isSelected ? theme.palette.secondary.p005 : theme.palette.background.paper,
  transition: 'border-color 0.25s ease-out',

  '&::before': {
    content: '""',
    background: theme.palette.secondary.p020,
    position: 'absolute',
    top: 0,
    left: '32px',
    height: '1px',
    width: 'calc(100% - 32px)',
  },

  '&:last-of-type': {
    '&::before': {
      content: 'none',
    },
  },
}));

export const CardWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 24px;
`;
