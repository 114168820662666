import { SelectOption } from 'types';
import { TourTypes, TourTimes } from 'types/tours';

export const tourTypesOptions = [
  { value: TourTypes.Personal, label: 'In-person' },
  { value: TourTypes.Virtual, label: 'Virtual' },
];

export const tourTimeOptions = [
  { value: TourTimes.Morning, label: 'Morning (9-12)' },
  { value: TourTimes.Afternoon, label: 'Afternoon (12-3)' },
  { value: TourTimes.Evening, label: 'Evening (3-6)' },
];

export const cardSentOptions: SelectOption<boolean>[] = [
  { label: 'No', value: false },
  {
    label: 'Yes',
    value: true,
  },
];
