import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import { ReactElement } from 'react';

import { CreateCampaign } from 'features/campaigns/components/CreateCampaignModal';
import { PageSectionsForm } from 'features/contactDetails/containers';
import { DetailsSearch } from 'features/contactDetails/containers/DetailsSearch';

import { PageHeader, ContactName, ControlW, SearchW } from './styled';

interface ContactPageHeaderProps {
  handleGoBack: () => void;
  pageTitle: string;
  contactFullName: string;
}

export function ContactPageHeader({
  handleGoBack,
  pageTitle,
  contactFullName,
}: ContactPageHeaderProps): ReactElement {
  return (
    <PageHeader>
      <IconButton onClick={handleGoBack}>
        <ArrowBackIcon />
      </IconButton>
      <ContactName>{pageTitle}</ContactName>
      <ControlW>
        <CreateCampaign contactFullName={contactFullName} />
        <SearchW>
          <DetailsSearch />
        </SearchW>

        <PageSectionsForm />
      </ControlW>
    </PageHeader>
  );
}
