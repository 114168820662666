import { Control, useForm } from 'react-hook-form';

export interface TourStatusFilterValues {
  status: string;
}

export type TourStatusDefaultValues = TourStatusFilterValues;

export interface UseTourStatusFilterReturn {
  tourStatusFilterControl: Control<TourStatusFilterValues>;
  statusId: string;
}

export function useTourStatusFilter(initialValue: string): UseTourStatusFilterReturn {
  const { control, watch } = useForm<TourStatusFilterValues>({
    defaultValues: { status: initialValue },
    mode: 'onTouched',
  });

  const statusId = watch('status');

  return {
    tourStatusFilterControl: control,
    statusId,
  };
}
