import { alert } from 'components';
import { buildingActionTypes } from 'features/buildings';
import { inquiryAdapter } from 'features/buildings/components/BuildingListItem/adapters';
import {
  communicationActionTypes,
  putMessage,
  loadMessages,
  loadBuildingChatMessages,
  putBuildingChatMessage,
} from 'features/communications';
import { initialBuildingChatState, initialGeneralChatState } from 'features/communications/context';
import { inquiryActionTypes } from 'features/inquiry';
import {
  selectUnassignedBuilding,
  setUnassignedBuildings,
  unassignedBuildingsActionTypes,
} from 'features/unassignedBuildings';
import {
  wishlistActionTypes,
  setWishlistBuildings,
  selectWishlistBuilding,
} from 'features/wishlist';
import {
  ApplyInquiry,
  ApplyInquiryP,
  Campaign,
  CampaignBuilding,
  CampaignBuildingP,
  Contact,
  ContactDetailsSelectItem,
  MessageType,
  TextMessageType,
  TourInquiry,
  TourInquiryP,
} from 'types';
import { addParentToArray, crudOperations } from 'utils';
import { insertOrUpdateOne } from 'utils/crudOperations';

import { editPageSections } from './actions';
import actionTypes from './actionTypes';
import { DetailsPageState, ContactDetailsAction } from './types';

export const initialState: DetailsPageState = {
  selectItemType: ContactDetailsSelectItem.Campaign,
  isCampaignInfoOpen: true,
  isBuildingInfoOpen: true,
  campaigns: {},
  buildings: {
    byId: {},
    allItems: [],
  },
  applyInquiries: {},
  tourInquiries: {},
  wishlistBuildings: {},
  unassignedBuildings: {},
  generalChatMessages: initialGeneralChatState,
  buildingChatMessages: initialBuildingChatState,
  pageSections: [],
};

export function contactDetailsReducer(
  state: DetailsPageState,
  action: ContactDetailsAction,
): DetailsPageState {
  switch (action.type) {
    case actionTypes.loadContact: {
      const contact = action.payload as Contact;

      const campaigns = contact.campaigns || [];

      const normalizedCampaigns = crudOperations.setAll(campaigns);

      const allBuildingItems = campaigns.reduce((list, campaign) => {
        return list.concat(addParentToArray(campaign?.buildings || [], campaign.id));
      }, [] as CampaignBuildingP[]);

      const normalizedBuildings = crudOperations.setAll(allBuildingItems);

      // TODO: think if possible to remove inquiries from state
      // used for search and scroll?
      const tours = inquiryAdapter(
        allBuildingItems.reduce((list, building) => {
          return list.concat(addParentToArray(building.scheduleTourInquiries || [], building.id));
        }, [] as TourInquiryP[]),
        'tour',
      );

      const normalizedTours = crudOperations.setAll(tours);

      const applyInquiries = inquiryAdapter(
        allBuildingItems.reduce((list, building) => {
          return list.concat(addParentToArray(building.applyInquiries || [], building.id));
        }, [] as ApplyInquiryP[]),
        'application',
      );

      const normalizedApplyInquiries = crudOperations.setAll(applyInquiries);

      return {
        ...state,
        contact,
        campaigns: normalizedCampaigns,
        buildings: {
          byId: normalizedBuildings,
          allItems: allBuildingItems,
        },
        tourInquiries: normalizedTours,
        applyInquiries: normalizedApplyInquiries,
      };
    }

    case actionTypes.selectCampaign: {
      const campaignId = action.payload as Campaign['id'];

      return {
        ...state,
        selectedCampaignId: campaignId,
        selectedBuildingId: undefined,
        selectedInquiryId: undefined,
        selectItemType: ContactDetailsSelectItem.Campaign,
        isCampaignInfoOpen: true,
        selectedWishlistBuildingId: undefined,
        selectedUnassignedBuildingId: undefined,
      };
    }

    case buildingActionTypes.selectBuilding: {
      const { buildingId, campaignId } = action.payload as {
        campaignId: Campaign['id'];
        buildingId: CampaignBuilding['id'];
      };

      return {
        ...state,
        selectedCampaignId: campaignId,
        selectedBuildingId: buildingId,
        selectedInquiryId: undefined,
        selectItemType: ContactDetailsSelectItem.Building,
        isBuildingInfoOpen: true,
        selectedWishlistBuildingId: undefined,
        selectedUnassignedBuildingId: undefined,
      };
    }

    case actionTypes.openCampaignInfo: {
      const { isOpen } = action.payload as { isOpen: boolean };
      return {
        ...state,
        isCampaignInfoOpen: isOpen,
      };
    }

    case actionTypes.openBuildingInfo: {
      const { isOpen } = action.payload as { isOpen: boolean };
      return {
        ...state,
        isBuildingInfoOpen: isOpen,
      };
    }

    case inquiryActionTypes.selectInquiry: {
      const inquiryId = action.payload as TourInquiry['id'] | ApplyInquiry['id'];

      const inquiry = state.applyInquiries[inquiryId] || state.tourInquiries[inquiryId];

      const building = state.buildings.byId[inquiry?.parentId];

      const campaign = state.campaigns[building?.parentId];

      return {
        ...state,
        selectedCampaignId: campaign?.id,
        selectedBuildingId: building?.id,
        selectedInquiryId: inquiryId,
        selectItemType: ContactDetailsSelectItem.Inquiry,
      };
    }

    case actionTypes.editPageSections: {
      const { pageSections } = action.payload as ReturnType<typeof editPageSections>['payload'];

      return {
        ...state,
        pageSections,
      };
    }

    case wishlistActionTypes.setWishlistBuildings: {
      const { buildings } = action.payload as ReturnType<typeof setWishlistBuildings>['payload'];

      const normalizedWishlistBuildings = crudOperations.setAll(buildings);

      return {
        ...state,
        wishlistBuildings: { ...normalizedWishlistBuildings },
      };
    }

    case wishlistActionTypes.selectWishlistBuilding: {
      const { buildingId } = action.payload as ReturnType<typeof selectWishlistBuilding>['payload'];

      return {
        ...state,
        selectedWishlistBuildingId: buildingId,
        selectedCampaignId: undefined,
        selectedBuildingId: undefined,
        selectedInquiryId: undefined,
        selectItemType: ContactDetailsSelectItem.Building,
        isBuildingInfoOpen: true,
        selectedUnassignedBuildingId: undefined,
      };
    }

    case unassignedBuildingsActionTypes.setUnassignedBuildings: {
      const { buildings } = action.payload as ReturnType<typeof setUnassignedBuildings>['payload'];
      const normalizedUnassignedBuildings = crudOperations.setAll(buildings);

      return {
        ...state,
        unassignedBuildings: { ...normalizedUnassignedBuildings },
      };
    }

    case unassignedBuildingsActionTypes.selectUnassignedBuilding: {
      const { buildingId } = action.payload as ReturnType<
        typeof selectUnassignedBuilding
      >['payload'];
      return {
        ...state,
        selectedUnassignedBuildingId: buildingId,
        selectedWishlistBuildingId: undefined,
        selectedCampaignId: undefined,
        selectedBuildingId: undefined,
        selectedInquiryId: undefined,
        selectItemType: ContactDetailsSelectItem.Building,
        isBuildingInfoOpen: true,
      };
    }

    case communicationActionTypes.loadMessages: {
      const { messages, targetMessageId, sources } = action.payload as ReturnType<
        typeof loadMessages
      >['payload'];

      const normalizedMessages = crudOperations.setAll(messages);

      return {
        ...state,
        generalChatMessages: {
          messages: normalizedMessages,
          targetMessageId,
          sources,
        },
      };
    }

    case communicationActionTypes.putMessage: {
      const { message } = action.payload as ReturnType<typeof putMessage>['payload'];

      const storedMessage = state.generalChatMessages.messages[message.id];
      const isOutgoingSms = message.type === MessageType.OUTGOING_SMS;
      const isAlreadyStoredMessage = Boolean(storedMessage);

      if (isOutgoingSms && isAlreadyStoredMessage) {
        const isStatusChanged =
          message.payload.state !== (storedMessage as TextMessageType).payload.state;
        const isFailedToSend = Boolean(message.payload.errorCode);

        if (isStatusChanged && isFailedToSend) {
          alert.error(
            `Failed to send message! Twilio returned error code - ${message.payload.errorCode}`,
          );
        }
      }

      const updatedMessages = insertOrUpdateOne(state.generalChatMessages.messages, message);

      return {
        ...state,
        generalChatMessages: {
          messages: updatedMessages,
          targetMessageId: null,
          sources: state.generalChatMessages.sources,
        },
      };
    }

    case communicationActionTypes.loadBuildingChatMessages: {
      const { messages } = action.payload as ReturnType<typeof loadBuildingChatMessages>['payload'];

      return {
        ...state,
        buildingChatMessages: {
          messages,
        },
      };
    }

    case communicationActionTypes.putBuildingChatMessage: {
      const { message } = action.payload as ReturnType<typeof putBuildingChatMessage>['payload'];

      const prevMessage = state.buildingChatMessages.messages;

      return {
        ...state,
        buildingChatMessages: {
          messages: [...prevMessage, message],
        },
      };
    }

    default: {
      throw new Error('you must provide valid action for ContactDetailReducer');
    }
  }
}
