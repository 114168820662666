import { Theme } from '@mui/material';

import { $FixTypeLater } from 'types';

export const getMenuWrapStyles = (theme: Theme): $FixTypeLater => ({
  padding: '8px 0',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[6],
});

export const getMenuItemsStyles = (theme: Theme): $FixTypeLater => ({
  display: 'block',
  padding: '10px 10px 10px 16px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.p010,
  },
  wordBreak: 'break-word',
});
