import { requiredRule } from 'validations/rules';
import {
  composeValidateRule,
  getMaxLengthValidate,
  getMinLengthValidate,
  requiredValidate,
  textDigitSymbolsValidate,
  trimTransformValidate,
} from 'validations/validate';

export const filterNameRules = {
  validate: composeValidateRule([
    trimTransformValidate,
    requiredValidate,
    textDigitSymbolsValidate,
    getMinLengthValidate(2),
    getMaxLengthValidate(50),
  ]),
  // validate: (value?: string) => {
  //   const trimmedValue = value?.trim();

  //   if (!trimmedValue) {
  //     return REQUIRED;
  //   }

  //   if (!LETTERS_DIGITS_SYMBOLS.test(trimmedValue)) {
  //     return NAME_PATTER_SYMBOLS;
  //   }

  //   if (trimmedValue.length < 2) {
  //     return getMinLengthText(2);
  //   }

  //   if (trimmedValue.length > 50) {
  //     return getMaxLengthText(50);
  //   }
  // },
};

export const filterGroupRules = {
  validate: composeValidateRule([
    trimTransformValidate,
    textDigitSymbolsValidate,
    getMinLengthValidate(2),
    getMaxLengthValidate(50),
  ]),
};

export const requiredField = {
  required: requiredRule,
};
