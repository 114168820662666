import { ReactElement } from 'react';
import { Control } from 'react-hook-form';

import { ControlledTextField, DatePicker, TimePicker } from 'components';
import { $FixTypeLater, FilterType } from 'types';
import { isValidDateRule, requiredRule } from 'validations/rules';

import { requiredField } from '../FilterForm/rules';
import { getInputTypeProps } from './utils';

interface ValueFieldProps {
  type?: FilterType;
  name: string;
  options?: JSX.Element[];
  control: Control<$FixTypeLater>;
  disabled: boolean;
  propertyName: string;
}

const fieldStyles = {
  maxWidth: '140px',
};

export function ValueField({
  type = FilterType.STRING,
  name,
  control,
  options,
  disabled,
  propertyName,
}: ValueFieldProps): ReactElement {
  if (type === FilterType.DATE && propertyName === 'CONFIRMED_TOUR_TIME') {
    return (
      <div style={fieldStyles}>
        <TimePicker
          name={name}
          label="Value"
          placeholder="Value"
          control={control}
          showAllErrors
          disabled={disabled}
        />
      </div>
    );
  }

  if (type === FilterType.DATE) {
    return (
      <div style={fieldStyles}>
        <DatePicker
          name={name}
          control={control}
          label="Value"
          placeholder="Value"
          rules={{
            required: requiredRule,
            validate: isValidDateRule,
          }}
          disabled={disabled}
        />
      </div>
    );
  }

  if (type === FilterType.SELECT) {
    return (
      <ControlledTextField
        {...getInputTypeProps(type)}
        select
        name={name}
        control={control}
        label="Value"
        placeholder="Value"
        sx={fieldStyles}
        rules={requiredField}
        disabled={disabled}
      >
        {options}
      </ControlledTextField>
    );
  }

  return (
    <ControlledTextField
      {...getInputTypeProps(type)}
      name={name}
      control={control}
      label="Value"
      placeholder="Value"
      sx={fieldStyles}
      rules={requiredField}
      disabled={disabled}
    />
  );
}
