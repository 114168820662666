import { Control, useForm } from 'react-hook-form';

export interface TourAssigneeFilterValues {
  assigneeId: string;
}

export type TourAssigneeDefaultValues = TourAssigneeFilterValues;

export interface UseTourAssigneeFilterReturn {
  tourAssigneeFilterControl: Control<TourAssigneeFilterValues>;
  assigneeId: string;
}

export function useTourAssigneeFilter(initialValue: string): UseTourAssigneeFilterReturn {
  const { control, watch } = useForm<TourAssigneeFilterValues>({
    defaultValues: { assigneeId: initialValue },
    mode: 'onTouched',
  });

  const assigneeId = watch('assigneeId');

  return {
    tourAssigneeFilterControl: control,
    assigneeId,
  };
}
