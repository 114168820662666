import { ReactElement, useCallback, useContext } from 'react';

import { SelectedItemState } from 'features/contactDetails/context/ContactDetailsPageContext';
import { OpenEditTourContext, SelectedInquiryId, selectInquiry } from 'features/inquiry';
import { InquiryListItem } from 'features/inquiry/components';
import { useDispatch } from 'hooks';
import { ApplyInquiry, BuildingStatus, ContactDetailsSelectItem, TourInquiry } from 'types';

interface InquiryListProps {
  inquiries: Array<TourInquiry | ApplyInquiry>;
  buildingStatus: BuildingStatus;
}

export function InquiryList({ inquiries, buildingStatus }: InquiryListProps): ReactElement {
  const dispatch = useDispatch();

  const selectedInquiryId = useContext(SelectedInquiryId);

  const selectedItemType = useContext(SelectedItemState);

  const handleOpenEditTourModal = useContext(OpenEditTourContext);

  const handleSelectInquiry = useCallback(
    (inquiryId: ApplyInquiry['id'] | TourInquiry['id']) => dispatch(selectInquiry(inquiryId)),
    [dispatch],
  );

  return (
    <div>
      {inquiries.map((inquiry) => (
        <InquiryListItem
          key={inquiry.id}
          inquiry={inquiry}
          isSelected={
            selectedInquiryId === inquiry.id &&
            selectedItemType === ContactDetailsSelectItem.Inquiry
          }
          handleSelectInquiry={handleSelectInquiry}
          handleOpenEditTourModal={handleOpenEditTourModal}
          buildingStatus={buildingStatus}
        />
      ))}
    </div>
  );
}
