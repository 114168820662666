import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { AxiosResponseError, GetMessageTemplatesResponseSuccess } from 'api/types';
import { alert } from 'components';
import { EditMessageTemplateData } from 'types';

interface UseEditMessageTemplateMutationReturn {
  handleEditMessageTemplate: (
    template: EditMessageTemplateData,
    onSuccessAction: () => void,
  ) => void;
}

export function useEditMessageTemplateMutation(): UseEditMessageTemplateMutationReturn {
  const editMessageTemplate = useMutation<
    AxiosResponse<GetMessageTemplatesResponseSuccess>,
    AxiosResponseError,
    EditMessageTemplateData
  >(async (template: EditMessageTemplateData) => API_HANDLERS.MESSAGE_TEMPLATE.EDIT(template));

  const handleEditMessageTemplate = useCallback(
    (template: EditMessageTemplateData, onSuccessAction?: () => void): void => {
      editMessageTemplate.mutate(template, {
        onSuccess: () => {
          queryClient.invalidateQueries('message-templates');
          queryClient.invalidateQueries(['message-template', template.id]);
          alert.success('Message template was edited successfully ');
          onSuccessAction?.();
        },
      });
    },
    [editMessageTemplate],
  );

  return { handleEditMessageTemplate };
}
