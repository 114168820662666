import { Box, MenuItem } from '@mui/material';
import { ReactElement } from 'react';
import { Control } from 'react-hook-form';

import { ControlledTextField } from 'components';
import { CloseCampaignReasons, CloseCampaignReasonsMap } from 'types';
import { requiredRule } from 'validations/rules';

interface CloseCampaignSelectProps {
  closeReasons: CloseCampaignReasons[];
  control: Control<{ closeReason: CloseCampaignReasons }>;
}

export function CloseCampaignReasonsSelect({
  closeReasons,
  control,
}: CloseCampaignSelectProps): ReactElement {
  return (
    <Box sx={{ padding: '8px 0' }}>
      <ControlledTextField
        select
        name="closeReason"
        label="Reason to close"
        placeholder="Select reason"
        control={control}
        rules={{
          required: requiredRule,
        }}
        showAllErrors
      >
        {closeReasons.map((reason) => (
          <MenuItem key={reason} value={reason} sx={{ fontSize: '14px' }}>
            {CloseCampaignReasonsMap[reason]}
          </MenuItem>
        ))}
      </ControlledTextField>
    </Box>
  );
}
