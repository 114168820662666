import { WishlistBuilding } from 'types';
import { actionCreator } from 'utils';

import actionTypes from './actionTypes';

export const setWishlistBuildings = actionCreator<{
  buildings: WishlistBuilding[];
}>(actionTypes.setWishlistBuildings);

export const selectWishlistBuilding = actionCreator<{
  buildingId: WishlistBuilding['id'];
}>(actionTypes.selectWishlistBuilding);
