import { Table as MuiTable, TableHead, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { ReactElement } from 'react';

import { EmptyStateArgs, PageStatus } from 'components/PageStatus';
import { TableComponents } from 'components/TableComponents';
import { FilterPresetList } from 'types';

import { MoreBlock } from '../MoreBlock';
import { ThDataW } from './styled';

interface FiltersTableProps {
  filters: FilterPresetList;
  loading: boolean;
  emptyState: EmptyStateArgs;
  handleDeleteFilter: (filterId: string) => void;
}

export function FiltersTable({
  loading,
  emptyState,
  handleDeleteFilter,
  filters,
}: FiltersTableProps): ReactElement {
  return (
    <PageStatus loading={loading} emptyState={emptyState} withCover>
      <TableContainer sx={{ flex: '1 0 auto', height: 'calc(100vh - 260px)' }}>
        <MuiTable aria-label="table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableComponents.Th align="left" style={{ width: 250 }}>
                <ThDataW>
                  Filter name
                  {/* <SortOrderIcon asc /> */}
                </ThDataW>
              </TableComponents.Th>
              <TableComponents.Th align="left">
                <ThDataW>
                  Folder
                  {/* <SortOrderIcon asc={false} /> */}
                </ThDataW>
              </TableComponents.Th>
              <TableComponents.Th align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {filters.map((filter) => (
              <TableRow key={filter.id}>
                <TableComponents.Td>{filter.name}</TableComponents.Td>
                <TableComponents.Td>{filter.group}</TableComponents.Td>
                <TableComponents.Td align="right">
                  <MoreBlock
                    menuItems={[
                      {
                        label: 'Delete',
                        key: 'del',
                        onClick: () => handleDeleteFilter(filter.id),
                      },
                    ]}
                  />
                </TableComponents.Td>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </PageStatus>
  );
}
