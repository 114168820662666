import { useEffect } from 'react';
import { useForm, Control } from 'react-hook-form';

import { $FixTypeLater, CreateFilterInput } from 'types';

import { filterFormAdapter } from './adapters';

interface UseFilterFormParams {
  onSubmit: (values: CreateFilterInput) => Promise<unknown>;
  handleClose: () => void;
  defaultValues: $FixTypeLater;
}

interface UseFilterFormReturn {
  control: Control<$FixTypeLater>;
  submitForm: () => void;
  disableSubmit: boolean;
  isSubmitting: boolean;
  destroyForm: () => void;
}

export function useFilterForm({
  onSubmit,
  defaultValues,
  handleClose,
}: UseFilterFormParams): UseFilterFormReturn {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, isDirty },
  } = useForm({
    defaultValues,
    mode: 'all',
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const destroyForm = () => {
    handleClose();
    reset(defaultValues);
  };

  const disableSubmit = !isValid || isSubmitting || !isDirty;

  const submitForm = handleSubmit((values) => {
    const transformedFormValues = filterFormAdapter(values);
    onSubmit(transformedFormValues);
    destroyForm();
  });

  return {
    control,
    submitForm,
    disableSubmit,
    isSubmitting,
    destroyForm,
  };
}
