import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { styled } from '@mui/material';

export const SendButton = styled(SendRoundedIcon, {
  shouldForwardProp: (propname) => propname !== 'isDisabled',
})<{ isDisabled: boolean }>(({ theme, isDisabled }) => ({
  cursor: 'pointer',
  transform: 'rotate(-45deg) translate(2px,0px)',
  color: !isDisabled ? theme.palette.secondary.main : theme.palette.secondary.p030,
}));
