import { Action } from 'types';

export function actionCreator<PayloadType = void>(
  type: string,
): (payload: PayloadType) => Action<PayloadType> {
  return (payload: PayloadType) => ({
    type,
    payload,
  });
}
