import { EmployeeRole, SelectOption } from 'types';
import { toCapitalize } from 'utils';

export const SEARCHLIGHT_ROLE: SelectOption<EmployeeRole> = {
  value: EmployeeRole.SEARCHLIGHTS,
  label: toCapitalize(EmployeeRole.SEARCHLIGHTS),
};

export const LIGHTKEEPER_ROLE: SelectOption<EmployeeRole> = {
  value: EmployeeRole.LIGHTKEEPERS,
  label: toCapitalize(EmployeeRole.LIGHTKEEPERS),
};

export const DISPATCHER_ROLE: SelectOption<EmployeeRole> = {
  value: EmployeeRole.DISPATCHERS,
  label: toCapitalize(EmployeeRole.DISPATCHERS),
};

export const COLLABORATOR_ROLE: SelectOption<EmployeeRole> = {
  value: EmployeeRole.COLLABORATORS,
  label: toCapitalize(EmployeeRole.COLLABORATORS),
};

export const ADMIN_ROLE: SelectOption<EmployeeRole> = {
  value: EmployeeRole.ADMINISTRATORS,
  label: 'Admin',
};

export const ROLE_OPTIONS = [
  SEARCHLIGHT_ROLE,
  LIGHTKEEPER_ROLE,
  DISPATCHER_ROLE,
  COLLABORATOR_ROLE,
  ADMIN_ROLE,
];
