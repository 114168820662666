import { Employee } from 'types';
import { getFullName } from 'utils';
import { setAll } from 'utils/crudOperations';

export function parseUserMentions(text: string, employees: Employee[]): (string | JSX.Element)[] {
  const msgItems = text.split(' ');
  const normalizedEmployees = setAll(employees);

  const transformedItems = msgItems.map((textItem) => {
    if (textItem.startsWith('${') && textItem.endsWith('}')) {
      const selectedUser = normalizedEmployees[textItem.replace('${', '').replace('}', '')];

      const userFullName = getFullName({
        firstName: selectedUser.user.givenName,
        lastName: selectedUser.user.familyName,
      });

      const element = <span style={{ color: '#0055D3', fontWeight: 500 }}>@{userFullName}</span>;

      return (
        <>
          {element}
          <> </>
        </>
      );
    }
    return (
      <>
        <>{textItem}</>
        <> </>
      </>
    );
  });

  return transformedItems;
}
