import { DATE_FORMAT_WITH_TIME_AND_COMMA, EMPTY_VALUE } from 'constants/index';
import { Contact, ContactDetails } from 'types';
import { getContactStyle, getFormattedDateWithTime, getFullName } from 'utils';

import { transformConfirmedValues } from '../utils';

function getContactStyleList(values: string[]): string {
  return values.map((contactStyle) => getContactStyle(contactStyle)).join(', ');
}

export function contactDetailsAdapter(data?: Contact): ContactDetails {
  return {
    cameFrom: data?.cameFrom || EMPTY_VALUE,
    contactStyle:
      data?.contactStyle && data?.contactStyle?.length > 0
        ? getContactStyleList(data?.contactStyle)
        : EMPTY_VALUE,
    createdAt: data?.createdAt
      ? getFormattedDateWithTime(new Date(data.createdAt), DATE_FORMAT_WITH_TIME_AND_COMMA)
      : EMPTY_VALUE,
    email: transformConfirmedValues({
      value: data?.email,
      isConfirmed: Boolean(data?.emailIsConfirmed),
    }),
    externalId: data?.externalId || '',
    fullName: getFullName({ firstName: data?.firstName, lastName: data?.lastName }),
    id: data?.id || '',
    phone: transformConfirmedValues({
      value: data?.phone,
      isConfirmed: Boolean(data?.phoneIsConfirmed),
    }),
  };
}
