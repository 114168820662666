import { Box, styled } from '@mui/material';

export const Preview = styled(Box)<{ previewUrl?: string }>(({ theme }) => ({
  width: '68px',
  height: '68px',
  borderRadius: '4px',
  marginRight: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.p020,
  overflow: 'hidden',
}));

export const BuildingItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '11px 0',
  marginLeft: '13px',
}));

export const ImageW = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;
