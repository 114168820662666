import { styled } from '@mui/material';

export const FileError = styled('div')(({ theme }) => ({
  maxWidth: '450px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: '12px',
  color: theme.palette.error.main,
  marginLeft: '5px',
}));
