import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';

interface UseDeleteFilterReturn {
  handleSubmit: (filterId: string) => void;
  isLoadingDelete: boolean;
}

export function useDeleteFilter(): UseDeleteFilterReturn {
  const { mutateAsync: deleteFilterMutation, isLoading } = useMutation(
    API_HANDLERS.FILTERS.DELETE,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('filters');
      },
    },
  );

  async function handleSubmit(filterId: string) {
    await deleteFilterMutation(filterId);
    alert.success('Filter successfully deleted');
  }

  return {
    handleSubmit,
    isLoadingDelete: isLoading,
  };
}
