import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';

interface UseDeleteMessageTemplateMutationReturn {
  handleDeleteMessageTemplate: (templateId: string, onSuccessAction?: () => void) => void;
}

export function useDeleteMessageTemplateMutation(): UseDeleteMessageTemplateMutationReturn {
  const deleteMessageTemplate = useMutation(async (templateId: string) =>
    API_HANDLERS.MESSAGE_TEMPLATE.DELETE(templateId),
  );

  const handleDeleteMessageTemplate = useCallback(
    (templateId: string, onSuccessAction?: () => void): void => {
      deleteMessageTemplate.mutate(templateId, {
        onSuccess: () => {
          queryClient.invalidateQueries('message-templates');
          alert.success('Message template  was deleted successfully');
          onSuccessAction?.();
        },
      });
    },
    [deleteMessageTemplate],
  );

  return { handleDeleteMessageTemplate };
}
