import { Box, MenuItem } from '@mui/material';
import { ReactElement } from 'react';

import {
  Button,
  ControlledChips,
  ControlledTextField,
  DatePicker,
  Form,
  FormGroup,
  RadioButtonsGroup,
  SideModal,
} from 'components';

import { chipsOptions, tourTimeOptions, tourTypesOptions } from './config';
import { useTourForm } from './hooks';
import { TourFormProps } from './types';

export const TourForm = ({
  title,
  handleClose,
  onSubmit,
  defaultValues,
  isOpen,
  isEditMode = false,
}: TourFormProps): ReactElement => {
  const { control, destroyForm, submitForm, isSubmitting, submitDisabled, withConfirmClose } =
    useTourForm({
      handleClose,
      onSubmit,
      defaultValues,
      isEditMode,
    });

  return (
    <SideModal
      title={title}
      isOpen={isOpen}
      onClose={destroyForm}
      withConfirmClose={withConfirmClose}
    >
      <Form onSubmit={submitForm}>
        <FormGroup marginbottom={16}>
          <ControlledChips
            name="guestCardRequested"
            label="Guest card requested?"
            control={control}
            options={chipsOptions}
          />
        </FormGroup>

        <FormGroup marginbottom={16}>
          <ControlledChips
            name="pricingAndAvailabilityRequested"
            label="Pricing and availability requested?"
            control={control}
            options={chipsOptions}
          />
        </FormGroup>

        <FormGroup>
          <RadioButtonsGroup
            name="tourType"
            control={control}
            label="Tour type"
            options={tourTypesOptions}
          />
        </FormGroup>

        <FormGroup marginbottom={24} row={true}>
          <Box sx={{ width: '176px' }}>
            <DatePicker
              name="tourDate"
              label="Requested date"
              minDate={new Date()}
              control={control}
            />
          </Box>
          <Box sx={{ width: '176px' }}>
            <ControlledTextField
              select
              label="Requested time"
              placeholder="Requested time"
              id="tourTime"
              name="tourTime"
              control={control}
            >
              {tourTimeOptions.map(({ value, label }) => (
                <MenuItem key={value} value={value} sx={{ fontSize: '14px' }}>
                  {label}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Box>
        </FormGroup>

        <FormGroup>
          <ControlledTextField
            name="comment"
            multiline
            id="comment"
            label="Lead's comments"
            placeholder="Lead's comments"
            control={control}
          />
        </FormGroup>

        <Box display="flex" justifyContent="flex-end">
          <Button type="submit" disabled={submitDisabled} loading={isSubmitting}>
            {isEditMode ? 'Save changes' : 'Create tour'}
          </Button>
        </Box>
      </Form>
    </SideModal>
  );
};
