import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { Link } from '@mui/material';
import { ReactElement, useContext } from 'react';

import { EMPTY_VALUE } from 'constants/index';
import { openBuildingInfo } from 'features/contactDetails/context/actions';
import { BuildingInfoState } from 'features/contactDetails/context/ContactDetailsPageContext';
import { useDispatch } from 'hooks';
import { CampaignBuildingDetails } from 'types';

import { arrowIconStyles } from '../constants';
import {
  BuildingImage,
  DataW,
  SectionHeader,
  SectionTitle,
  SectionW,
  Title,
  Value,
  ValueItemsW,
} from '../styled';

interface BuildingInfoProps {
  buildingDetails: CampaignBuildingDetails;
  title: string;
}

export function BuildingInfo({ title, buildingDetails }: BuildingInfoProps): ReactElement {
  const isBuildingInfoOpen = useContext(BuildingInfoState);

  const dispatch = useDispatch();

  const setSectionOpen = () => dispatch(openBuildingInfo({ isOpen: !isBuildingInfoOpen }));

  return (
    <SectionW>
      <SectionHeader>
        <SectionTitle>{title}</SectionTitle>
      </SectionHeader>

      {isBuildingInfoOpen && (
        <ValueItemsW>
          <DataW>
            <BuildingImage src={buildingDetails.photo} alt="building thumb" />
          </DataW>
          <DataW>
            <Title>ID</Title>
            <Value>{buildingDetails.id}</Value>
          </DataW>
          <DataW>
            <Title>Name</Title>
            <Value>{buildingDetails.name}</Value>
          </DataW>
          <DataW>
            <Title>Address</Title>
            <Value>{buildingDetails.address}</Value>
          </DataW>
          <DataW>
            <Title>Website</Title>
            <Value>
              {buildingDetails.website === EMPTY_VALUE ? (
                buildingDetails.website
              ) : (
                <Link href={`${buildingDetails.website}`} target="_blank" rel="noreferrer">
                  {buildingDetails.website}
                </Link>
              )}
            </Value>
          </DataW>
          <DataW>
            <Title>Email</Title>
            <Value>{buildingDetails.email}</Value>
          </DataW>
          <DataW>
            <Title>Phone number</Title>
            <Value>{buildingDetails.phone}</Value>
          </DataW>
          <DataW>
            <Title>Min./Max. rate</Title>
            <Value>{buildingDetails.minMaxRate}</Value>
          </DataW>
        </ValueItemsW>
      )}

      <ArrowRightRoundedIcon
        sx={{
          ...arrowIconStyles,
          position: 'absolute',
          transform: isBuildingInfoOpen ? 'rotate(90deg)' : 'rotate(0)',
        }}
        onClick={setSectionOpen}
      />
    </SectionW>
  );
}
