import { ReactElement } from 'react';

import { TimeAgoDateCounter } from 'components';
import { DashboardItemLastMessage } from 'types';

import { CommunicationItemPreview } from '../CommunicationItemPreview';
import { DateCaption, MessageWrapper, Wrapper } from './styled';

interface CardMetaProps {
  message: DashboardItemLastMessage;
  contactId: string;
  hasNewMessages: boolean;
}

export function CardMeta({ message, contactId, hasNewMessages }: CardMetaProps): ReactElement {
  const { id, createdAt, communicationItem, buildingChatItem } = message;

  return (
    <Wrapper>
      <DateCaption>
        <TimeAgoDateCounter date={createdAt} />:
      </DateCaption>

      <MessageWrapper>
        {communicationItem ? (
          <CommunicationItemPreview
            id={id}
            createdAt={createdAt}
            communicationItem={communicationItem}
            contactId={contactId}
            isNew={hasNewMessages}
          />
        ) : buildingChatItem ? (
          <CommunicationItemPreview
            id={id}
            createdAt={createdAt}
            communicationItem={buildingChatItem}
            contactId={contactId}
            isNew={hasNewMessages}
          />
        ) : null}
      </MessageWrapper>
    </Wrapper>
  );
}
