import { EmployeeRole } from 'types';

export const assigneeAllowedRoles = [
  EmployeeRole.SEARCHLIGHTS,
  EmployeeRole.LIGHTKEEPERS,
  EmployeeRole.DISPATCHERS,
];

export const ownerAllowedRoles = [
  EmployeeRole.LIGHTKEEPERS,
  EmployeeRole.DISPATCHERS,
  EmployeeRole.SEARCHLIGHTS,
];
