import { Box, styled, Typography } from '@mui/material';

import { $FixTypeLater, MessageType } from 'types';

import { getMessageStyles } from './utils';

export const MessageW = styled('div', {
  shouldForwardProp: (propname) =>
    propname !== 'messageType' && propname !== 'isReceived' && propname !== 'hasError',
})<{
  isReceived: boolean;
  messageType: MessageType;
  width?: string;
  hasError: boolean;
}>(({ theme, isReceived, messageType, width = '320px', hasError }) => ({
  alignSelf: isReceived ? 'flex-start' : 'flex-end',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  alignItems: 'flex-start',
  width,
  padding: '12px 16px',
  border: `1px solid ${theme.palette.secondary.p050}`,
  borderRadius: `12px`,
  borderBottomRightRadius: isReceived ? '12px' : 0,
  borderBottomLeftRadius: isReceived ? 0 : '12px',
  ...getMessageStyles(theme, messageType, hasError),
}));

export const Content = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '16px',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  color: theme.palette.black.main,
}));

export const Footer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const createStyledMessageIcon = (
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
): $FixTypeLater =>
  styled(icon)<{ variant?: 'black' | 'primary' | 'secondary' }>(
    ({ variant = 'primary', theme }) => ({
      '& .path1': {
        fill: theme.palette[variant].main,
      },
    }),
  );

export const FailedToSendW = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '16px',
  color: theme.palette.black.light,
}));
