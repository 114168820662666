import { styled, Box } from '@mui/material';

export const AvatarW = styled(Box)`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const DisplayLabelText = styled('div')(() => ({
  whiteSpace: 'break-spaces',
  wordBreak: 'break-word',
}));
