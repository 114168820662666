import { SelectChangeEvent } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { messageSourceValues, QUERY_SEARCH_KEYS } from 'features/communications/config';
import { GeneralChatState } from 'features/communications/context';
import { getMessageSourceArrayFromString } from 'features/communications/utils';
import { useSetQueryParams } from 'hooks';
import { MessageSourceType } from 'types';

interface UseMessagesTypeSelectReturn {
  isAllTypesSelected: boolean;
  messageTypes: MessageSourceType[];
  handleChange: (event: SelectChangeEvent<MessageSourceType[]>) => void;
}

export function useMessagesTypeSelect(): UseMessagesTypeSelectReturn {
  const { sources } = useContext(GeneralChatState);

  const [messageTypes, setMessageTypes] = useState<MessageSourceType[]>(
    getMessageSourceArrayFromString(sources) || messageSourceValues,
  );

  useEffect(() => {
    setMessageTypes(getMessageSourceArrayFromString(sources) || messageSourceValues);
  }, [sources]);

  const isAllTypesSelected = messageTypes.length === messageSourceValues.length;

  useSetQueryParams(
    {
      sources: isAllTypesSelected ? '' : messageTypes.filter((type) => type !== 'ALL').join(','),
    },
    QUERY_SEARCH_KEYS,
    true,
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<MessageSourceType[]>) => {
      const {
        target: { value },
      } = event;

      if (value.includes('ALL')) {
        setMessageTypes(isAllTypesSelected ? [] : messageSourceValues);
        return;
      }

      if (Array.isArray(value)) {
        setMessageTypes(value);
      }
    },
    [isAllTypesSelected],
  );

  return { isAllTypesSelected, messageTypes, handleChange };
}
