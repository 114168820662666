import { createContext } from 'react';

import { Message, BuildingChatMessage } from 'types';
import { getQueryParams } from 'utils';

export interface GeneralChatStateValues {
  messages: Record<string, Message>;
  targetMessageId: string | null;
  sources: string | null;
}

const QUERY_SEARCH_KEYS = ['sources'];

const { sources } = getQueryParams(QUERY_SEARCH_KEYS);

export const initialGeneralChatState = {
  messages: {},
  targetMessageId: null,
  sources,
};

export const GeneralChatState = createContext<GeneralChatStateValues>(initialGeneralChatState);

export interface BuildingChatStateValues {
  messages: BuildingChatMessage[];
}

export const initialBuildingChatState = {
  messages: [],
};

export const BuildingChatState = createContext<BuildingChatStateValues>(initialBuildingChatState);
