import { Chip, styled } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

import { theme } from 'styles';

const StyledChip = styled(Chip)`
  height: auto;
  min-height: 28px;
  padding-right: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: ${theme.palette.primary.main};
  color: ${theme.palette.white.main};
  word-break: break-all;
  & span {
    padding: 4px 12px;
    white-space: normal;
  }
  & svg {
    width: 13.5px;
    height: 13.5px;
    fill: ${theme.palette.white.main};
  }
`;

interface AmenityItemProps {
  value: string;
  label: ReactNode;
  onDelete: (value: string) => () => void;
}

export function AmenityChipItem({ value, label, onDelete }: AmenityItemProps): ReactElement {
  return <StyledChip label={label} onDelete={onDelete(value)} />;
}
