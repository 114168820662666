import { ReactElement } from 'react';

import { Modal } from 'components';
import { CampaignBuilding } from 'types';

import { SendBuildingsForm } from '../SendBuildingsForm';
import { useSendBuildingsForm } from './useSendBuildingsForm';

interface SendBuildingsModalProps {
  isOpen: boolean;
  handleClose: () => void;
  campaignId: string;
  buildings: CampaignBuilding[];
}

export function SendBuildingsModal({
  isOpen,
  handleClose,
  campaignId,
  buildings,
}: SendBuildingsModalProps): ReactElement {
  const { control, handleSubmit, isDisabled, isLoading } = useSendBuildingsForm({
    buildings,
    close: handleClose,
    campaignId,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Send buildings"
      width="400px"
      cancelText="Cancel"
      confirmText="Send Buildings"
      titleSx={{ marginBottom: '14px' }}
      onSubmit={handleSubmit}
      isDisabled={isDisabled}
      isSubmitting={isLoading}
    >
      <SendBuildingsForm campaignId={campaignId} buildings={buildings} control={control} />
    </Modal>
  );
}
