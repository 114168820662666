import MenuItem from '@mui/material/MenuItem';
import { ReactElement } from 'react';
import { Suggestion } from 'react-places-autocomplete';

import { TextField } from 'components';
import { TextInputProps } from 'components/Fields/TextField';

import { FieldContainer, OptionsContainer } from './OptionsStyles';
import { getFormattedCityString } from './utils';

const PlacesSearchField = ({
  suggestions,
  getInputProps,
  getSuggestionItemProps,
  disabled,
  ...restFieldProps
}: TProps): ReactElement => {
  const inputProps = getInputProps();

  const onBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    inputProps.onBlur && inputProps.onBlur(e);
    restFieldProps.onBlur && restFieldProps.onBlur(e);
  };

  return (
    <FieldContainer>
      <TextField {...restFieldProps} {...inputProps} onBlur={onBlur} disabled={disabled} />

      <OptionsContainer>
        {suggestions.map((suggestion) => (
          <MenuItem
            selected={suggestion.active}
            {...getSuggestionItemProps(suggestion)}
            key={suggestion.description}
            sx={{ typography: 'body2', minHeight: '40px' }}
          >
            {getFormattedCityString(suggestion)}
          </MenuItem>
        ))}
      </OptionsContainer>
    </FieldContainer>
  );
};

type TProps = TextInputProps & {
  getInputProps: () => Partial<TextInputProps>;
  getSuggestionItemProps: (suggestion: Suggestion) => unknown;
  suggestions: readonly Suggestion[];
};

export default PlacesSearchField;
