import { ReactElement, useState } from 'react';
import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { DropdownButton } from 'components';
import { ButtonSizes } from 'components/Button/interface';
import { useModal } from 'hooks';
import { EntityFilterPayload } from 'types';

import { FilterForm } from '../FilterForm';
import { FILTER_DEFAULT_VALUES, GROUP_DEFAULT_VALUES } from './config';

export function AddFilter(): ReactElement {
  const [defaultValues, setDefaultValues] =
    useState<Record<string, unknown>>(FILTER_DEFAULT_VALUES);

  const { handleCloseModal, handleOpenModal, isOpen } = useModal();

  const { mutateAsync } = useMutation(API_HANDLERS.FILTERS.CREATE, {
    onSuccess: () => {
      queryClient.invalidateQueries('filters');
    },
  });

  const onSubmit = (values: EntityFilterPayload) => {
    return mutateAsync({ data: values });
  };

  return (
    <>
      <DropdownButton
        buttonSize={ButtonSizes.xSmall}
        menuItems={[
          {
            label: 'Add filter',
            id: 'filter',
            onClick: () => {
              handleOpenModal();
              setDefaultValues(FILTER_DEFAULT_VALUES);
            },
          },
          {
            label: 'Add filter group',
            id: 'group',
            onClick: () => {
              handleOpenModal();
              setDefaultValues(GROUP_DEFAULT_VALUES);
            },
          },
        ]}
      >
        Add filter preset or filter group
      </DropdownButton>

      <FilterForm
        handleClose={handleCloseModal}
        isOpen={isOpen}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
      />
    </>
  );
}
