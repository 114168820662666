import { DialogTitleProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

import { CancelBtn, ConfirmBtn, DialogActionsW, DialogTitleW, DialogW } from './styled';

interface ModalProps {
  isDisabled?: boolean;
  isOpen: boolean;
  children: ReactNode;
  title: string;
  width?: string;
  onClose: () => void;
  cancelText: string;
  confirmText: string;
  titleSx?: DialogTitleProps['sx'];
  isSubmitting?: boolean;
  onSubmit: () => void;
}

export function Modal({
  isDisabled = false,
  isOpen,
  children,
  title,
  onClose,
  width,
  cancelText,
  confirmText,
  titleSx,
  onSubmit,
  isSubmitting,
}: ModalProps): ReactElement {
  return (
    <DialogW open={isOpen} width={width} onClose={onClose}>
      <DialogTitleW sx={titleSx}>{title}</DialogTitleW>

      {children}

      <DialogActionsW>
        <CancelBtn onClick={onClose} variant="text" color="secondary">
          {cancelText}
        </CancelBtn>
        <ConfirmBtn
          disabled={isDisabled}
          type="submit"
          variant="text"
          color="secondary"
          onClick={onSubmit}
          loading={isSubmitting}
        >
          {confirmText}
        </ConfirmBtn>
      </DialogActionsW>
    </DialogW>
  );
}
