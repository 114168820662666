import { Mentions } from 'types';

export function parseTextWithMentions(mentions: Mentions, content: string): string {
  let textContent = '';

  Object.keys(mentions).forEach((id) => {
    textContent = (textContent || content).replaceAll(mentions[id], '${' + `${id}` + '}');
  });

  return textContent;
}
