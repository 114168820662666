import { ReactElement, useContext } from 'react';

import { EmployeesContext } from 'context';
import { SystemMessageContent } from 'features/communications/components/SystemMessageContent';
import {
  DashboardItemBuildingChatItem,
  DashboardItemCommunicationItemType,
  MessageType,
  SystemMessageType,
} from 'types';
import { parseUserMentions } from 'utils';

import { PreviewWrapper } from './styled';

interface CommunicationItemPreviewProps {
  id: string;
  createdAt: string;
  communicationItem: DashboardItemCommunicationItemType | DashboardItemBuildingChatItem;
  contactId: string;
  isNew: boolean;
}

export function CommunicationItemPreview({
  id,
  createdAt,
  communicationItem,
  contactId,
  isNew,
}: CommunicationItemPreviewProps): ReactElement {
  const { employeesList } = useContext(EmployeesContext);

  const messageText =
    communicationItem.type === MessageType.PRIVATE_NOTES &&
    employeesList.length > 0 &&
    communicationItem.payload.text
      ? parseUserMentions(communicationItem.payload.text, employeesList)
      : communicationItem.type !== MessageType.SYSTEM_MESSAGE
      ? communicationItem.payload.text
      : '';

  return (
    <PreviewWrapper isnew={isNew}>
      {communicationItem?.type === MessageType.SYSTEM_MESSAGE ? (
        <SystemMessageContent
          message={
            {
              id,
              createdAt,
              type: communicationItem.type,
              payload: communicationItem.payload,
            } as SystemMessageType
          }
          contactId={contactId}
        />
      ) : (
        <span>{messageText}</span>
      )}
    </PreviewWrapper>
  );
}
