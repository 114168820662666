import { ClickAwayListener, Typography } from '@mui/material';
import { ReactElement } from 'react';

import { StatusButton } from 'components';
import { useModal } from 'hooks';
import { InquiryStateGroup, InquiryStatus } from 'types';

import { CloseInquiryModal } from './CloseInquiryModal';
import { OptionItem, OptionsContainer, StatusContainer } from './styled';

interface InquiryStatusProps {
  setFocused: (p: boolean) => void;
  status: InquiryStatus;
  handleChangeStatus: (status: InquiryStatus['id']) => void;
  showOptions: boolean;
  defaultTransitions: InquiryStatus[];
  closeTransitions: InquiryStatus[];
  isActive?: boolean;
  isDisabled: boolean;
  isLoading?: boolean;
}

export function InquiryStatusLayout({
  setFocused,
  status,
  handleChangeStatus,
  showOptions,
  defaultTransitions,
  closeTransitions,
  isDisabled,
  isLoading,
}: InquiryStatusProps): ReactElement {
  const { handleCloseModal, handleOpenModal, isOpen: isModalOpen } = useModal();

  const statusName = status.group === InquiryStateGroup.TERMINAL ? 'Closed' : status.name;

  const handleClick = (cb: () => void) => {
    return () => {
      cb();
      setFocused(false);
    };
  };

  return (
    <StatusContainer>
      <ClickAwayListener onClickAway={() => setFocused(false)}>
        <StatusButton
          title={statusName}
          onClick={() => (isDisabled ? undefined : setFocused(true))}
          withIcon={!isDisabled}
          isActive={isDisabled}
          colorScheme={status.color}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </ClickAwayListener>

      {showOptions && (
        <ClickAwayListener onClickAway={() => setFocused(false)}>
          <OptionsContainer>
            {defaultTransitions.map((transition) => (
              <OptionItem
                key={transition.id}
                onClick={handleClick(() => handleChangeStatus(transition.id))}
              >
                <Typography variant="subtitle2">{transition.name}</Typography>
              </OptionItem>
            ))}
            {!!closeTransitions.length && (
              <OptionItem key="close" onClick={handleClick(handleOpenModal)}>
                <Typography variant="subtitle2">Close</Typography>
              </OptionItem>
            )}
          </OptionsContainer>
        </ClickAwayListener>
      )}

      {isModalOpen && (
        <CloseInquiryModal
          closeTransitions={closeTransitions}
          handleChangeStatus={handleChangeStatus}
          handleClose={handleCloseModal}
        />
      )}
    </StatusContainer>
  );
}
