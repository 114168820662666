import { ReactElement } from 'react';
import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import { TourFormValues } from 'types';
import { objectsDifferences } from 'utils';

import { TourForm } from '..';

interface EditTourProps {
  isOpen: boolean;
  defaultValues?: TourFormValues;
  handleClose: () => void;
  tourId?: string;
}

export const EditTour = ({
  isOpen,
  defaultValues,
  handleClose,
  tourId,
}: EditTourProps): ReactElement => {
  const { mutateAsync: editTourMutation } = useMutation(API_HANDLERS.INQUIRIES.EDIT_TOUR, {
    onSuccess: () => {
      queryClient.invalidateQueries('contact');
    },
  });

  const handleSubmit = async (values: TourFormValues) => {
    if (!tourId) {
      return;
    }

    const diffs = defaultValues ? objectsDifferences(defaultValues, values) : values;

    await editTourMutation({ id: tourId, data: diffs });

    alert.success('Tour successfully updated');
  };

  return (
    <TourForm
      title="Edit tour"
      isOpen={isOpen}
      defaultValues={defaultValues || {}}
      onSubmit={handleSubmit}
      handleClose={handleClose}
      isEditMode
    />
  );
};
