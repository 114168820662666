import { Box, IconButton, styled, Typography } from '@mui/material';

export const SectionW = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginBottom: '26px',
  paddingBottom: '25px',
  borderBottom: `1px solid ${theme.palette.secondary.p020}`,
}));

export const SectionHeader = styled(Box)`
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SectionTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.black.main,
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.black.light,
}));

export const Value = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 500,
  wordBreak: 'break-word',
  color: theme.palette.black.main,

  '.MuiBox-root': {
    height: 'auto',
  },
}));

export const DataW = styled(Box)`
  margin-bottom: 12px;
  height: auto;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const BuildingImage = styled('img')`
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
`;

export const ValueItemsW = styled(Box)`
  margin-top: 22px;
  visibility: 'visible',
  opacity: isOpen ? '1' : '0',
  backgroundColor: isOpen ? 'red' : 'green',
  transition: 'all 0.2s linear',
`;

export const IconButtonW = styled(IconButton)(() => ({
  '&.Mui-disabled': {
    cursor: 'not-allowed',
    pointerEvents: 'all',
  },
}));
