import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Control, DeepPartial, UnpackNestedValue, useForm } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';

import { debounceFunction } from 'utils';

interface UseEditInquiryDetailsFormParams<FormValues> {
  defaultValues: UnpackNestedValue<DeepPartial<FormValues>>;
  onSubmit: (values: UnpackNestedValue<FormValues>) => Promise<unknown>;
  validationSchema: AnyObjectSchema;
}
interface UseEditInquiryDetailsReturn<FormValues> {
  control: Control<FormValues>;
}

export function useEditInquiryDetailsForm<FormValues>({
  defaultValues,
  onSubmit,
  validationSchema,
}: UseEditInquiryDetailsFormParams<FormValues>): UseEditInquiryDetailsReturn<FormValues> {
  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!isDirty) {
      reset(defaultValues);
    }
  }, [reset, defaultValues, isDirty]);

  useEffect(() => {
    const debouncedSubmit = debounceFunction(handleSubmit(onSubmit), 500);

    const subscription = watch(() => debouncedSubmit());
    return () => subscription.unsubscribe();
  }, [watch, handleSubmit, onSubmit]);

  return {
    control,
  };
}
