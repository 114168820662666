import { styled } from '@mui/system';

export const Wrap = styled('div')`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

export const AddFilterWrap = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: 24px 0 36px 0;
`;
