import { TypographyProps, BoxProps, Box } from '@mui/material';
import { FunctionComponent, ReactElement, SVGProps } from 'react';

import { ReactComponent as EmptyApplyTableIcon } from 'assets/icons/applyTableEmpty.svg';
import { ReactComponent as EmptyMessagesIcon } from 'assets/icons/communication/messageEmpty.svg';
import { ReactComponent as EmptyMessageTemplatesIcon } from 'assets/icons/communication/messageTemplatesEmpty.svg';
import { ReactComponent as EmptyContactsIcon } from 'assets/icons/emptyContacts.svg';
import { ReactComponent as EmptyCampaignsIcon } from 'assets/icons/list.svg';
import { ReactComponent as EmptyTourTableIcon } from 'assets/icons/tourTableEmpty.svg';
import { ReactComponent as EmptyDashboardChatIcon } from 'assets/images/emptyDashboardChat.svg';
import { ReactComponent as EmptySearchIcon } from 'assets/images/emptySearch.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/faceScreamingInFear.svg';
import { EmptyStateIcons } from 'types';

import {
  Description,
  DescriptionSearch,
  EmptySearchStateW,
  IconW,
  Title,
  TitleSearch,
  Wrapper,
} from './styled';

const iconsVariant: Record<EmptyStateIcons, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  emptySearch: EmptySearchIcon,
  emptyList: EmptyContactsIcon,
  emptyCampaigns: EmptyCampaignsIcon,
  error: ErrorIcon,
  emptyMessages: EmptyMessagesIcon,
  emptyMessageTemplates: EmptyMessageTemplatesIcon,
  emptyDashboardChat: EmptyDashboardChatIcon,
  emptyTourTable: EmptyTourTableIcon,
  emptyApplyTable: EmptyApplyTableIcon,
};

export interface EmptyStateProps {
  title?: ReactElement | string;
  description?: ReactElement | string;
  descriptionVariant?: TypographyProps['variant'];
  descriptionSx?: TypographyProps['sx'];
  variant?: EmptyStateIcons;
  iconSx?: BoxProps['sx'];
  wrapperSx?: BoxProps['sx'];
}

export function EmptyState({
  title,
  description,
  descriptionVariant = 'body1',
  descriptionSx,
  iconSx,
  variant = EmptyStateIcons.EMPTY_SEARCH,
}: EmptyStateProps): ReactElement {
  const Icon = iconsVariant[variant];

  return (
    <Wrapper aria-label="empty-state">
      <IconW sx={iconSx}>
        <Icon />
      </IconW>
      {title && <Title>{title}</Title>}
      {description && (
        <Description sx={descriptionSx} variant={descriptionVariant}>
          {description}
        </Description>
      )}
    </Wrapper>
  );
}

export function EmptyStateSearch({
  title,
  description,
  descriptionVariant = 'body1',
  descriptionSx,
  iconSx,
  wrapperSx,
  variant = EmptyStateIcons.EMPTY_SEARCH,
}: EmptyStateProps): ReactElement {
  const Icon = iconsVariant[variant];

  return (
    <EmptySearchStateW aria-label="empty-state" sx={wrapperSx}>
      <Box sx={iconSx}>
        <Icon width={32} height={32} />
      </Box>
      <Box>
        {title && <TitleSearch>{title}</TitleSearch>}
        {description && (
          <DescriptionSearch sx={descriptionSx} variant={descriptionVariant}>
            {description}
          </DescriptionSearch>
        )}
      </Box>
    </EmptySearchStateW>
  );
}
