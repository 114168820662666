import { MenuItem } from '@mui/material';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import { ConfirmModal, ControlledTextField } from 'components';
import { InquiryStatus } from 'types';
import { requiredRule } from 'validations/rules';

import { ContentModalWrap } from './styled';

interface CloseInquiryModalProps {
  closeTransitions: InquiryStatus[];
  handleChangeStatus: (status: InquiryStatus['id']) => void;
  handleClose: () => void;
}

export function CloseInquiryModal({
  closeTransitions,
  handleChangeStatus,
  handleClose,
}: CloseInquiryModalProps): ReactElement {
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<{ statusId: string }>({
    mode: 'all',
  });

  const onSubmit = handleSubmit(({ statusId }) => {
    handleChangeStatus(statusId);
    handleClose();
  });

  return (
    <ConfirmModal
      title="Close inquiry"
      content={
        <ContentModalWrap>
          <ControlledTextField
            select
            name="statusId"
            control={control}
            label="Reason to close"
            placeholder="Select reason"
            showAllErrors
            rules={{
              required: requiredRule,
            }}
            SelectProps={{
              MenuProps: {
                disablePortal: false,
              },
            }}
          >
            {closeTransitions.map(({ name, id }) => (
              <MenuItem key={id} value={id} sx={{ fontSize: '14px' }}>
                {name}
              </MenuItem>
            ))}
          </ControlledTextField>
        </ContentModalWrap>
      }
      onClose={handleClose}
      cancelText="Cancel"
      onSubmit={onSubmit}
      confirmText="Close"
      highlightSubmit
      withCloseIcon
      isSubmitDisabled={!isValid || isSubmitting}
    />
  );
}
