import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { Control, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import { useAuth } from 'hooks';
import { Mentions, MessageType, NewMessageFormValues, FileWithId } from 'types';

import { newMessageValuesAdapter } from '../adapters';
import { getDefaultValues } from './config';
import { LIMIT_SIZE, TOTAL_SIZE_LIMIT } from './constants';
import { newMessageFormSchema } from './validationSchema';

interface UseNewMessageFormReturn {
  control: Control<NewMessageFormValues>;
  isValid: boolean;
  handleFormSubmit: () => void;
  messageType: MessageType;
  handleAddMention: (id: string, display: string) => void;
  withCc: boolean;
  withBcc: boolean;
  handleChipsChange: (fieldName: 'withCc' | 'withBcc') => void;
  setFiles: (files: FileWithPath[]) => void;
  handleFileDelete: (id: string) => void;
  attachedFiles: FileWithId[];
  cancelReplyMode: () => void;
}

interface UseNewMessageFormProps {
  defaultValues: NewMessageFormValues | undefined;
  handleResetReplyMode: () => void;
  contactId: string;
}

export function useNewMessageForm({
  defaultValues,
  handleResetReplyMode,
  contactId,
}: UseNewMessageFormProps): UseNewMessageFormReturn {
  const [mentions, setMentions] = useState<Mentions>();

  const { isReadOnlyAccess } = useAuth();

  const { mutateAsync: postMessageMutation, isLoading } = useMutation(
    API_HANDLERS.COMMUNICATIONS.POST_MESSAGE,
  );

  const defaultValuesInner = defaultValues || getDefaultValues(isReadOnlyAccess);

  const type = isReadOnlyAccess ? MessageType.PRIVATE_NOTES : MessageType.OUTGOING_SMS;
  const textLimit = type === MessageType.OUTGOING_SMS ? 1400 : 1600;

  const {
    control,
    handleSubmit,
    watch,
    resetField,
    setValue,
    clearErrors,
    reset,
    formState: { isValid, errors },
  } = useForm<NewMessageFormValues>({
    mode: 'onChange',
    defaultValues: defaultValuesInner,
    resolver: yupResolver(newMessageFormSchema(textLimit)),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    const subscription = watch((_values, options) => {
      if (!options.type) return;

      if (options.name === 'type') {
        setValue('content', '');
        setValue('subject', '');
        setValue('cc', []);
        setValue('bcc', []);
        setValue('files', []);
        setValue('replyTo', '');
        clearErrors();
      }
    });

    return subscription.unsubscribe;
  }, [setValue, watch, clearErrors, errors]);

  const messageType = watch('type');
  const withCc = watch('withCc');
  const withBcc = watch('withBcc');
  const attachedFiles = watch('files');

  const handleFormSubmit = handleSubmit(async (values) => {
    if (isLoading) {
      return;
    }

    const { type, ...rest } = newMessageValuesAdapter({
      ...values,
      mentions,
    });

    if (contactId) {
      const stringifiedPayload = JSON.stringify({ ...rest });

      const newMessageRequestData: { [key: string]: string } = {
        payload: stringifiedPayload,
        contactId,
        type,
      };

      const formData = new FormData();

      for (const name in newMessageRequestData) {
        formData.append(name, newMessageRequestData[name]);
      }

      attachedFiles.forEach((file) => {
        const newFile = new File([file], file.name);
        formData.append(file.id, newFile, file.name);
      });

      await postMessageMutation(formData);
    }

    reset({ ...getDefaultValues(isReadOnlyAccess), type });

    handleResetReplyMode?.();
  });

  function handleAddMention(id: string, display: string): void {
    const newMentions = mentions || {};
    newMentions[id] = `@[${display}](${id})`;

    setMentions(newMentions);
  }

  function handleChipsChange(fieldName: 'withCc' | 'withBcc') {
    if (fieldName === 'withCc') {
      resetField('cc');
      setValue('withCc', !withCc);
    } else {
      resetField('bcc');
      setValue('withBcc', !withBcc);
    }
  }

  function setFiles(newFiles: FileWithPath[]) {
    const filesWithId = [...attachedFiles, ...newFiles].map((file) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        id: uuidv4(),
      });
    });

    const totalFilesSize = filesWithId.reduce((acc, curr) => acc + curr.size, 0);

    if (totalFilesSize > TOTAL_SIZE_LIMIT[messageType]) {
      alert.error(`Attached files exceed limit (${LIMIT_SIZE[messageType]}MB)`);
      return;
    }

    if (filesWithId.length > 10) {
      alert.error('10 images only can be sent in one MMS message');
      return;
    }

    setValue('files', filesWithId);
  }

  function handleFileDelete(id: string) {
    const newFilesList = attachedFiles.filter((file) => file.id !== id);

    setValue('files', newFilesList);
  }

  function cancelReplyMode() {
    setValue('replyTo', '');
    handleResetReplyMode();
  }

  return {
    control,
    isValid,
    handleFormSubmit,
    messageType,
    handleAddMention,
    withCc,
    handleChipsChange,
    withBcc,
    attachedFiles,
    setFiles,
    handleFileDelete,
    cancelReplyMode,
  };
}
