import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { MessageTemplateFormData } from 'types';

interface UseMessageTemplateFieldsQueryReturn {
  isFieldsLoading: boolean;
  isError: boolean;
  fieldsList: string[];
}

export function useMessageTemplateFieldsQuery(
  event: MessageTemplateFormData['event'],
): UseMessageTemplateFieldsQueryReturn {
  const {
    data: { data } = {},
    isFetching,
    isLoading,
    isError,
  } = useQuery(
    ['message-template-fields', event],
    () => API_HANDLERS.MESSAGE_TEMPLATE.GET_MESSAGE_TEMPLATE_FIELDS(event),
    { enabled: !!event },
  );

  return {
    isFieldsLoading: isFetching || isLoading,
    isError,
    fieldsList: data?.items || [],
  };
}
