import { BadgeProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

import { StyledBadge } from './styled';

interface StyledBadgeProps extends BadgeProps {
  children?: ReactNode;
}

export function Badge({ children, ...otherProps }: StyledBadgeProps): ReactElement {
  return (
    <StyledBadge max={99} {...otherProps}>
      {children}
    </StyledBadge>
  );
}
