import { ReactElement } from 'react';

import { EventPayloadTourStatusChanged } from 'types';

import { Link, TextBold } from './styled';

interface TourStatusChangedContentProps {
  data: EventPayloadTourStatusChanged;
  onInquiryClick: (inquiryId: string, buildingId?: string, campaignId?: string) => void;
  onBuildingClick: (buildingId: string, campaignId?: string) => void;
  onCampaignClick: (campaignId: string) => void;
}

export function TourStatusChangedContent({
  data,
  onInquiryClick,
  onBuildingClick,
  onCampaignClick,
}: TourStatusChangedContentProps): ReactElement {
  const { tourInquiryId, building, campaign, newStatus } = data;

  return (
    <span>
      <Link onClick={() => onInquiryClick(tourInquiryId, building.id, campaign.id)}>Tour</Link>{' '}
      status changed to <TextBold>{newStatus.name}</TextBold> for{' '}
      <Link onClick={() => onBuildingClick(building.id, campaign.id)}>{building.name}</Link> in{' '}
      <Link onClick={() => onCampaignClick(campaign.id)}>{campaign.name}</Link>
    </span>
  );
}
