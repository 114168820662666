import { useAuth } from 'hooks';
import { useModal } from 'hooks/useModal';

interface UseNavigationBarReturn {
  logoutModalControl: ReturnType<typeof useModal>;
  user: ReturnType<typeof useAuth>['user'];
  logout: ReturnType<typeof useAuth>['logout'];
  isAdmin: boolean;
  isReadOnlyAccess: boolean;
}

export function useNavigationBar(): UseNavigationBarReturn {
  const { user, logout, isAdmin, isReadOnlyAccess } = useAuth();

  const logoutModalControl = useModal();

  return {
    user,
    logout,
    logoutModalControl,
    isAdmin: Boolean(isAdmin),
    isReadOnlyAccess,
  };
}
