import { MutableRefObject, Ref, RefCallback, useCallback } from 'react';

export function useMergedRefs<T = null>(
  refs: Array<MutableRefObject<T> | Ref<T> | undefined>,
): RefCallback<T> {
  return useCallback(
    (current) => {
      refs.forEach((ref) => {
        if (typeof ref === 'function') {
          ref(current);
        } else if (ref && !Object.isFrozen(ref)) {
          (ref as React.MutableRefObject<T | null>).current = current;
        }
      });
    },
    [refs],
  );
}
