import { ClickAwayListener } from '@mui/material';
import { ReactElement, useState } from 'react';

import { DropdownMenu, DropdownMenuProps } from 'components/DropdownMenu';

import { Button, CustomButtonProps } from '..';
import { ButtonSizes } from '../interface';
import { ArrowIcon, Wrap } from './styled';

interface DropdownButtonProps extends CustomButtonProps {
  menuItems: DropdownMenuProps['menuItems'];
}

export function DropdownButton({
  menuItems,
  children,
  buttonSize = ButtonSizes.base,
  color,
  ...props
}: DropdownButtonProps): ReactElement {
  const [open, setOpen] = useState(false);

  const handleCloseMenu = () => setOpen(false);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Wrap>
        <Button {...props} color={color} buttonSize={buttonSize} onClick={() => setOpen(true)}>
          {children}
          <ArrowIcon size={buttonSize} open={open} color={color} />
        </Button>

        {open && <DropdownMenu menuItems={menuItems} handleClose={handleCloseMenu} />}
      </Wrap>
    </ClickAwayListener>
  );
}
