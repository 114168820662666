import { styled } from '@mui/material';

export const mentionStyles = {
  width: '100%',
  height: '100%',

  '&multiLine': {
    input: {
      padding: '0 44px 10px 12px',
      outline: 'none',
      border: 'none',
      overflowY: 'auto',
    },
  },

  suggestions: {
    list: {
      minWidth: '280px',
      maxHeight: '200px',
      padding: '8px 0',
      fontSize: '14px',
      overflowY: 'auto',
      borderRadius: '4px',
      boxShadow: '0px 4px 12px rgba(36, 35, 71, 0.15)',
    },

    item: {
      padding: '8px 16px',
      '&focused': {
        backgroundColor: '#EFF1F2',
      },
    },
  },
};

export const DisplayLabelText = styled('div')(() => ({
  whiteSpace: 'break-spaces',
  wordBreak: 'break-word',
}));
