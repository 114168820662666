import { Theme } from '@mui/material';

import { MessageType } from 'types';

export function getMessageBgColor(theme: Theme, messageType: MessageType): string {
  switch (messageType) {
    case MessageType.PRIVATE_NOTES: {
      return theme.palette.warning.p010;
    }

    default:
      return 'inherit';
  }
}
