import { TourInquiry, TourFormValues } from 'types';

export function editTourModalAdapter(tour: TourInquiry): TourFormValues {
  return {
    guestCardRequested: tour.guestCardRequested,
    pricingAndAvailabilityRequested: tour.pricingAndAvailabilityRequested,
    tourType: tour.tourType,
    tourDate: tour.tourDate,
    tourTime: tour.tourTime,
    comment: tour.comment || '',
  };
}
