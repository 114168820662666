import { useEffect } from 'react';
import { useForm, Control } from 'react-hook-form';

import { CONTACT_DETAILS_PAGE_SECTIONS } from 'constants/index';
import { editPageSections } from 'features/contactDetails/context/actions';
import { useDispatch, useSessionState } from 'hooks';

export interface SetPageSectionsFormValues {
  sections: string[];
}

interface UseSetPageSectionsFormReturn {
  control: Control<SetPageSectionsFormValues>;
}

export function useSetPageSectionsForm(): UseSetPageSectionsFormReturn {
  const [sections, setSections] = useSessionState<string[]>('sections');

  const { control, watch } = useForm<SetPageSectionsFormValues>({
    defaultValues: { sections: sections || CONTACT_DETAILS_PAGE_SECTIONS },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!sections) {
      setSections(CONTACT_DETAILS_PAGE_SECTIONS);
      dispatch(editPageSections({ pageSections: CONTACT_DETAILS_PAGE_SECTIONS }));
    }
  }, [setSections, sections, dispatch]);

  useEffect(() => {
    const subscription = watch((values, options) => {
      if (!options.type) return;

      if (options.name === 'sections' && values.sections) {
        setSections([...(values.sections as string[])]);
        dispatch(editPageSections({ pageSections: values.sections as string[] }));
      }
    });

    return subscription.unsubscribe;
  }, [dispatch, setSections, watch]);

  return { control };
}
