import { EmployeeRole } from 'types';
import { toCapitalize } from 'utils';

export function transformEmployeeRole(role: EmployeeRole): string {
  switch (role) {
    case EmployeeRole.LIGHTKEEPERS:
      return 'LK';
    case EmployeeRole.SEARCHLIGHTS:
      return 'SL';
    default:
      return toCapitalize(role);
  }
}
