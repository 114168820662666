import { useState, useEffect } from 'react';

export function useSessionState<T>(key: string, defaultValue?: T): [T, (value: T) => void] {
  let parsedDefaultValue;
  const storedValue = localStorage.getItem(key);

  if (storedValue) {
    try {
      parsedDefaultValue = JSON.parse(storedValue);
    } catch (error) {
      parsedDefaultValue = storedValue || defaultValue;
    }
  }

  const [state, setState] = useState(parsedDefaultValue);

  useEffect(() => {
    if (!state) {
      localStorage.removeItem(key);
    } else if (typeof state === 'string') {
      localStorage.setItem(key, state);
    } else {
      localStorage.setItem(key, JSON.stringify(state));
    }
  }, [key, state]);

  return [state, setState];
}
