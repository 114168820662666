import { $FixTypeLater } from 'types';

export enum Comparator {
  IS_EQUAL = 'IS_EQUAL',
  IS_NOT_EQUAL = 'IS_NOT_EQUAL',
  IS_LESS_THAN = 'IS_LESS_THAN',
  IS_GREATER_THAN = 'IS_GREATER_THAN',
  IS_GREATER_THAN_OR_EQUAL = 'IS_GREATER_THAN_OR_EQUAL',
  IS_LESS_THAN_OR_EQUAL = 'IS_LESS_THAN_OR_EQUAL',
  IS_EMPTY = 'IS_EMPTY',
  IS_NOT_EMPTY = 'IS_NOT_EMPTY',
  IS_TRUE = 'IS_TRUE',
  IS_FALSE = 'IS_FALSE',
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  ENDS_WITH = 'ENDS_WITH',
  STARTS_WITH = 'STARTS_WITH',
}

export enum FilterBooleanExpression {
  AND = 'AND',
  OR = 'OR',
}

export enum FilterType {
  GROUP = 'group',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  DATE = 'DATE',
}

export enum FilterEntity {
  CONTACT = 'CONTACT',
  CAMPAIGN = 'CAMPAIGN',
  BUILDING = 'BUILDING',
  TOUR_INQUIRY = 'TOUR_INQUIRY',
  APPLY_INQUIRY = 'APPLY_INQUIRY',
}

export type FilterDescriptor = {
  comparators: string[];
  field: string;
  options: { text: string | null; value: string }[] | null;
  type: FilterType;
};

export type FilterDescriptors = Record<FilterEntity, FilterDescriptor[]>;

export interface EntityFilterPayload {
  group: string;
  name: string;
  conditions: FilterGroup;
}

export interface FilterGroup {
  type: FilterType;
  operator: FilterBooleanExpression;
  value: Array<FilterValue | FilterGroup>;
}

export interface FilterValue {
  type: FilterType;
  entityName: string;
  propertyName: string;
  operator: Comparator;
  value: string;
}

export interface FilterFormValue {
  type: string;
  entityName: string;
  propertyName: string;
  operator: string;
  value: string;
}

export type FilterPreset = { id: string; conditions: $FixTypeLater; name: string; group: string };

export type FilterPresetList = FilterPreset[];

export interface CreateFilterInput {
  group: string;
  name: string;
  conditions: $FixTypeLater;
}
