import { Box, styled, Typography } from '@mui/material';

export const PageWrapper = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexFlow: 'column',
}));

export const PageHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flex: '0 1 auto',
  padding: '16px 24px',
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '28px',
  color: theme.palette.black.main,
}));

export const PageContent = styled(Box)(() => ({
  display: 'flex',
  height: 'calc(100% - 73px)',
}));

export const LeftSideWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ChatWrapper = styled(Box)(() => ({
  width: '100%',
}));

export const selectFieldSX = {
  minWidth: '100px',
  width: '376px',
  marginLeft: '160px',

  input: {
    height: '30px',
    padding: '5px 0',
  },
};

export const ListW = styled(Box)(() => ({
  minWidth: '725px',
  height: '100%',
  overflowY: 'auto',
}));

export const PreloaderWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '24px',
  marginBottom: '24px',
}));
