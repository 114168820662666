import { EmployeeRole } from 'types';
import { toCapitalize } from 'utils';

export const UNASSIGNED = 'unassigned';

export const NO_TEAM_OPTION = {
  value: UNASSIGNED,
  label: toCapitalize(UNASSIGNED),
};

export const TEAM_OPTIONS = [
  {
    value: EmployeeRole.LIGHTKEEPERS,
    label: toCapitalize(EmployeeRole.LIGHTKEEPERS),
  },
  {
    value: EmployeeRole.SEARCHLIGHTS,
    label: toCapitalize(EmployeeRole.SEARCHLIGHTS),
  },
  {
    value: EmployeeRole.DISPATCHERS,
    label: 'Dispatch',
  },
];
