import { styled } from '@mui/material';

import { ReactComponent as ArrowDropDownIcon } from 'assets/icons/arrow-drop-down.svg';

export const ThDataW = styled('div')`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 6px;
`;

export const SortOrderIcon = styled(ArrowDropDownIcon)<{ asc: boolean }>(({ theme, asc }) => ({
  transform: `scale(1.5) rotate(${asc ? 180 : 0}deg)`,

  '& .path1': {
    fill: theme.palette.secondary.main,
  },
}));
