import { ReactElement } from 'react';

import { EventPayloadApplicationRequested } from 'types';

import { Link } from './styled';

interface ApplicationRequestedContentProps {
  data: EventPayloadApplicationRequested;
  onInquiryClick: (inquiryId: string, buildingId?: string, campaignId?: string) => void;
  onBuildingClick: (buildingId: string, campaignId?: string) => void;
  onCampaignClick: (campaignId: string) => void;
}

export function ApplicationRequestedContent({
  data,
  onInquiryClick,
  onBuildingClick,
  onCampaignClick,
}: ApplicationRequestedContentProps): ReactElement {
  const { applyInquiryId, building, campaign } = data;

  return (
    <span>
      <Link onClick={() => onInquiryClick(applyInquiryId, building.id, campaign?.id)}>
        Application
      </Link>{' '}
      created for building{' '}
      <Link onClick={() => onBuildingClick(building.id, campaign?.id)}>{building.name}</Link>
      {!!campaign && (
        <>
          {' '}
          in <Link onClick={() => onCampaignClick(campaign.id)}>{campaign.name}</Link>
        </>
      )}
    </span>
  );
}
