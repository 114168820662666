import { ReactElement } from 'react';

import { BuildingChat } from 'types';

import { ChatInfo } from '../../types';
import { BuildingChatListItem } from '../BuildingChatListItem';
import { BuildingsListW } from './styled';

interface BuildingChatsListProps {
  buildingChats: BuildingChat[];
  handleOpenChat(chatInfo: ChatInfo): void;
}

export function BuildingChatsList({
  buildingChats,
  handleOpenChat,
}: BuildingChatsListProps): ReactElement {
  return (
    <BuildingsListW>
      {buildingChats.map(({ building, ...rest }) => (
        <BuildingChatListItem
          key={building.id}
          handleOpenChat={() =>
            handleOpenChat({ chatId: building.id, buildingName: building.name })
          }
          building={building}
          {...rest}
        />
      ))}
    </BuildingsListW>
  );
}
