import { Box } from '@mui/system';
import { ReactElement, ReactNode } from 'react';

import { ListW, Title } from './styled';

interface GroupWrapProps {
  title: string;
  children: ReactNode;
}

export function GroupWrap({ title, children }: GroupWrapProps): ReactElement {
  return (
    <Box>
      <Title>{title}</Title>
      <ListW>{children}</ListW>
    </Box>
  );
}
