import { EmployeeRole, SelectOption } from 'types';

import {
  ADMIN_ROLE,
  COLLABORATOR_ROLE,
  DISPATCHER_ROLE,
  LIGHTKEEPER_ROLE,
  ROLE_OPTIONS,
  SEARCHLIGHT_ROLE,
} from '../../constants';

export function getRoleOptions(roles: EmployeeRole[]): SelectOption<EmployeeRole>[] {
  let filteredRoles = ROLE_OPTIONS;
  const currentRoleItems: Record<string, string> = {};

  roles.forEach((role) => {
    currentRoleItems[role] = role;
  });

  if (currentRoleItems[EmployeeRole.DISPATCHERS]) {
    filteredRoles = [DISPATCHER_ROLE, ADMIN_ROLE, COLLABORATOR_ROLE];
  }

  if (currentRoleItems[EmployeeRole.LIGHTKEEPERS]) {
    filteredRoles = [LIGHTKEEPER_ROLE, ADMIN_ROLE, COLLABORATOR_ROLE];
  }

  if (currentRoleItems[EmployeeRole.SEARCHLIGHTS]) {
    filteredRoles = [SEARCHLIGHT_ROLE, ADMIN_ROLE, COLLABORATOR_ROLE];
  }

  if (currentRoleItems[EmployeeRole.ADMINISTRATORS]) {
    filteredRoles = filteredRoles.filter((role) => role !== COLLABORATOR_ROLE);
  }

  return filteredRoles.map((role) => {
    if (roles.length === 1) {
      const isDisabled = role.value === roles[0];
      return { ...role, isDisabled };
    }
    return { ...role, isDisabled: false };
  });
}
