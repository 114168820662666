import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { Control } from 'react-hook-form';

import { CancelButton, ControlledTextField, DropZone } from 'components';
import { theme } from 'styles';
import { NewMessageFormValues, FileWithId } from 'types';

import { EmailsSelect } from '../EmailsSelect';
import { SendButton } from '../SendButton';
import { ThreadsList } from '../ThreadsList';
import { inputProps, subjectInputStyles, textareaStyles } from './inputConfigs';
import { DividerW, IconsW, ThreadW } from './styled';
import { fileTypeValidator } from './utils';

interface EmailFormInputProps {
  control: Control<NewMessageFormValues>;
  isValid: boolean;
  withCc: boolean;
  withBcc: boolean;
  handleFormSubmit: () => void;
  setFiles: (files: FileWithPath[]) => void;
  handleFileDelete: (id: string) => void;
  attachedFiles: FileWithId[];
  isReplyMode?: boolean;
  threads?: string[];
  cancelReplyMode: () => void;
}

export function EmailFormInput({
  control,
  withCc,
  withBcc,
  handleFormSubmit,
  isValid,
  setFiles,
  handleFileDelete,
  attachedFiles,
  isReplyMode = false,
  threads,
  cancelReplyMode,
}: EmailFormInputProps): ReactElement {
  const dropZone = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: setFiles,
    validator: fileTypeValidator,
  });

  return (
    <>
      {withCc && (
        <>
          <DividerW />
          <EmailsSelect control={control} name="cc" label="Cc" placeholder="Cc" />
        </>
      )}
      {withBcc && (
        <>
          <DividerW />
          <EmailsSelect control={control} name="bcc" label="Bcc" placeholder="Bcc" />
        </>
      )}
      <DividerW />
      <ControlledTextField
        name="subject"
        control={control}
        variant="standard"
        placeholder="Subject"
        InputProps={{ ...inputProps }}
        sx={subjectInputStyles}
        disabled={isReplyMode}
        showAllErrors
      />
      <DividerW />
      <ControlledTextField
        name="content"
        control={control}
        multiline
        minRows={1}
        maxRows={5}
        variant="standard"
        showAllErrors
        placeholder="Type your message here"
        InputProps={{ ...inputProps }}
        sx={textareaStyles}
      />

      {isReplyMode && !!threads?.length && (
        <ThreadW>
          <CancelButton
            onClick={cancelReplyMode}
            buttonSx={{ position: 'absolute', right: '0' }}
            iconSx={{ width: '16px', height: '16px', color: theme.palette.secondary.main }}
          />
          <ThreadsList threads={threads} />
        </ThreadW>
      )}

      <IconsW>
        <DropZone files={attachedFiles} handleFileDelete={handleFileDelete} dropZone={dropZone} />
        <IconButton disabled={!isValid} onClick={handleFormSubmit}>
          <SendButton isDisabled={!isValid} />
        </IconButton>
      </IconsW>
    </>
  );
}
