import * as yup from 'yup';

import {
  getMaxLengthText,
  USE_DATE_FORMAT,
  NAME_PATTER_SYMBOLS,
  REQUIRED,
} from 'validations/messages';
import { LETTERS_DIGITS_SYMBOLS } from 'validations/regexps';
import { onlyFutureDate } from 'validations/tests';

export const tourFormSchema = yup.object().shape({
  guestCardRequested: yup.boolean().required(REQUIRED),
  pricingAndAvailabilityRequested: yup.boolean().required(REQUIRED),
  tourType: yup.string().required(REQUIRED),
  tourDate: yup.mixed().when({
    is: (v: unknown) => v instanceof Date,
    then(sch) {
      return sch.test('moveInDate', USE_DATE_FORMAT, onlyFutureDate);
    },
    otherwise(sch) {
      return sch.test('moveInDate', REQUIRED, (v) => (v ? true : false));
    },
  }),
  tourTime: yup.string().required(REQUIRED),
  comment: yup
    .string()
    .matches(LETTERS_DIGITS_SYMBOLS, NAME_PATTER_SYMBOLS)
    .max(1000, getMaxLengthText(1000))
    .nullable(),
});
