import { ReactElement } from 'react';

import { CampaignForm } from '../CampaignForm';
import { useCampaignCreateForm } from './hooks';

interface CampaignCreateFormProps {
  handleClose: () => void;
  contactFullName: string;
  isOpen: boolean;
}

export function CampaignCreateForm({
  contactFullName,
  handleClose,
  isOpen,
}: CampaignCreateFormProps): ReactElement {
  const { defaultValues, handleSubmit } = useCampaignCreateForm({
    handleClose,
    contactFullName,
  });

  return (
    <CampaignForm
      title="New campaign"
      onSubmit={handleSubmit}
      handleClose={handleClose}
      isOpen={isOpen}
      defaultValues={defaultValues}
      mode="create"
    />
  );
}
