import { Box, styled, Typography } from '@mui/material';

export const PageWrapper = styled(Box)`
  padding: 24px 28px 42px 24px;
`;

export const PageTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '28px',
  color: theme.palette.black.main,
}));

export const SearchAndFilterBar = styled(Box)(() => ({
  display: 'flex',
  gap: '12px',
  margin: '24px 0',
}));

export const SearchWrapper = styled('div')(() => ({
  width: '276px',
}));
