import * as yup from 'yup';

import { getMaxLengthText, getMinLengthText } from 'validations/messages';

export const buildingChatFormSchema = yup.object().shape({
  type: yup.string().required(),
  content: yup
    .string()
    .trim()
    .min(1, getMinLengthText(1))
    .max(1600, getMaxLengthText(1600))
    .nullable(),
});
