import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { InquiryStatus } from 'types';

interface UseApplyInquiriesStatesQueryReturn {
  applyInquiriesStates: Pick<InquiryStatus, 'id' | 'name' | 'color' | 'group'>[] | undefined;
  isApplyInquiriesStatesLoading: boolean;
  isApplyInquiriesStatesError: boolean;
}

export function useApplyInquiriesStatesQuery(): UseApplyInquiriesStatesQueryReturn {
  const { data, isLoading, isError } = useQuery(['apply-inquiries-states'], () =>
    API_HANDLERS.INQUIRIES.GET_APPLY_INQUIRIES_STATES(),
  );

  return {
    applyInquiriesStates: data?.data.states,
    isApplyInquiriesStatesLoading: isLoading,
    isApplyInquiriesStatesError: isError,
  };
}
