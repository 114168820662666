import { ReactElement, useCallback, useEffect, useState } from 'react';

import { ShowMoreButton } from 'components';

import { ThreadItem } from '../ThreadItem';
import { ListItem, List } from './styled';

interface ThreadsListProps {
  threads: string[];
}

export function ThreadsList({ threads }: ThreadsListProps): ReactElement {
  const [isAllThreadsShown, setIsAllThreadsShown] = useState<boolean>(false);
  const [firstItem, ...rest] = threads;

  useEffect(() => {
    setIsAllThreadsShown(false);
  }, [threads]);

  const handleShowMoreClick = useCallback(() => setIsAllThreadsShown(true), []);

  return (
    <List>
      <ListItem key={firstItem}>
        <ThreadItem innerHtml={firstItem} />
      </ListItem>

      {!!rest.length && !isAllThreadsShown && <ShowMoreButton onClick={handleShowMoreClick} />}

      {isAllThreadsShown &&
        rest.map((thread) => (
          <ListItem key={thread}>
            <ThreadItem innerHtml={thread} />
          </ListItem>
        ))}
    </List>
  );
}
