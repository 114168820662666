import { ReactElement } from 'react';

import { EventPayloadApplicationStatusChanged } from 'types';

import { Link, TextBold } from './styled';

interface ApplicationStatusChangedContentProps {
  data: EventPayloadApplicationStatusChanged;
  onInquiryClick: (inquiryId: string, buildingId?: string, campaignId?: string) => void;
  onBuildingClick: (buildingId: string, campaignId?: string) => void;
  onCampaignClick: (campaignId: string) => void;
}

export function ApplicationStatusChangedContent({
  data,
  onInquiryClick,
  onBuildingClick,
  onCampaignClick,
}: ApplicationStatusChangedContentProps): ReactElement {
  const { applyInquiryId, building, campaign, newStatus } = data;

  return (
    <span>
      <Link onClick={() => onInquiryClick(applyInquiryId, building.id, campaign.id)}>
        Application
      </Link>{' '}
      status changed to <TextBold>{newStatus.name}</TextBold> for{' '}
      <Link onClick={() => onBuildingClick(building.id, campaign.id)}>{building.name}</Link> in{' '}
      <Link onClick={() => onCampaignClick(campaign.id)}>{campaign.name}</Link>
    </span>
  );
}
