import { styled } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

export const FormControlLabelW = styled(FormControlLabel)`
  margin-left: -5px;

  span {
    font-size: 14px;
  }
`;

export const FormHelperTextW = styled(FormHelperText)`
  margin: 0 5px;
`;
