import { Box, MenuItem } from '@mui/material';
import { ReactElement, useRef } from 'react';

import { Form, Button, ControlledTextField, SideModal } from 'components';
import { messageEventOptions, messageTypeOptions } from 'features/settings/config';
import { BaseFormMode, MessageTemplateData, MessageTemplateFormData } from 'types';

import { MessageTemplateBodyInput } from '../MessageTemplateBodyInput';
import { PreviewMessageModal } from '../PreviewMessageModal';
import { MESSAGE_TEMPLATE_FORM_TITLE } from './constants';
import { useMessageTemplateForm } from './hooks';
import {
  bodySx,
  ButtonsGroupWrapper,
  FieldWrapper,
  FlexWrapper,
  HorizontalDivider,
  MessageWrapper,
} from './styled';

interface MessageTemplateFormProps {
  mode: BaseFormMode;
  isOpen: boolean;
  defaultValues?: MessageTemplateFormData;
  onSubmit: (values: MessageTemplateData, onSuccessAction?: () => void) => void;
  handleClose: () => void;
}

export function MessageTemplateForm({
  mode,
  isOpen,
  defaultValues,
  onSubmit,
  handleClose,
}: MessageTemplateFormProps): ReactElement {
  const isEditMode = mode === 'edit';
  const editorRef = useRef<HTMLElement>(null);
  const {
    handleMessageTypeChange,
    handleMessageEventChange,
    handleFormSubmit,
    handlePreviewClick,
    handleClosePreviewModal,
    handleBodyFocus,
    handleBodyBlur,
    handleCloseFormModal,
    control,
    fields,
    isPreviewModalOpen,
    templateBody,
    disableEvent,
    disableSubject,
    disableBody,
    disableSubmit,
    hideSubject,
    isBodyFocused,
    isSubmitting,
  } = useMessageTemplateForm({
    defaultValues,
    onSubmit,
    handleClose,
  });

  return (
    <>
      <SideModal
        isOpen={isOpen}
        title={MESSAGE_TEMPLATE_FORM_TITLE[mode]}
        width="80%"
        onClose={handleCloseFormModal}
      >
        <Form onSubmit={handleFormSubmit}>
          <FlexWrapper>
            <FieldWrapper>
              <ControlledTextField
                control={control}
                name="name"
                label="Template name"
                placeholder="Template name"
              />
            </FieldWrapper>

            <FieldWrapper>
              <ControlledTextField
                select
                id="type"
                label="Type of message"
                placeholder="Type of message"
                control={control}
                name="type"
                showAllErrors={isEditMode}
                disabled={isEditMode}
                sx={{ cursor: isEditMode ? 'not-allowed' : 'pointer' }}
                onChange={handleMessageTypeChange}
              >
                {messageTypeOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value} sx={{ fontSize: '14px' }}>
                    {label}
                  </MenuItem>
                ))}
              </ControlledTextField>
            </FieldWrapper>

            <FieldWrapper>
              <ControlledTextField
                select
                id="event"
                label="Event"
                placeholder="Event"
                control={control}
                name="event"
                showAllErrors={isEditMode}
                disabled={disableEvent || isEditMode}
                sx={{ cursor: disableEvent || isEditMode ? 'not-allowed' : 'pointer' }}
                onChange={handleMessageEventChange}
              >
                {messageEventOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value} sx={{ fontSize: '14px' }}>
                    {label}
                  </MenuItem>
                ))}
              </ControlledTextField>
            </FieldWrapper>
          </FlexWrapper>

          <HorizontalDivider />

          <FlexWrapper>
            <FieldWrapper>
              <ControlledTextField
                control={control}
                name="subject"
                label="Message subject"
                placeholder="Message subject"
                disabled={disableSubject}
                sx={{ display: hideSubject ? 'none' : 'block' }}
              />
            </FieldWrapper>

            <Box ref={editorRef} sx={{ width: 'calc(100% - 300px)' }}>
              <ControlledTextField
                multiline
                minRows={20}
                maxRows={20}
                name="body"
                id="body"
                label="Message body"
                placeholder="Message body"
                control={control}
                disabled={disableBody}
                focused={isBodyFocused}
                InputLabelProps={{
                  shrink: !!templateBody || isBodyFocused,
                  sx: { fontSize: '14px' },
                }}
                onFocusCapture={handleBodyFocus}
                onBlurCapture={handleBodyBlur}
                showAllErrors
                InputProps={{
                  components: { Input: MessageTemplateBodyInput },
                  inputProps: {
                    data: fields,
                    editorRef: editorRef,
                    control: control,
                    disabled: disableBody,
                  },
                }}
                sx={{ ...bodySx }}
              />
            </Box>
          </FlexWrapper>
          <ButtonsGroupWrapper>
            <Button disabled={!templateBody} color="secondary" onClick={handlePreviewClick}>
              See preview
            </Button>
            <Button type="submit" loading={isSubmitting} disabled={disableSubmit}>
              {isEditMode ? 'Save changes' : 'Save message template'}
            </Button>
          </ButtonsGroupWrapper>
        </Form>
      </SideModal>

      <PreviewMessageModal isOpen={isPreviewModalOpen} onClose={handleClosePreviewModal}>
        <MessageWrapper dangerouslySetInnerHTML={{ __html: templateBody }} />
      </PreviewMessageModal>
    </>
  );
}
