import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { QueryValueType, SearchArray } from '../../types/query';
import { createSearchString } from '../../utils/query';

export default function useSetQueryParams(
  query: { [key: string]: QueryValueType },
  keys: string[],
  replace = false,
): void {
  const navigate = useNavigate();

  useEffect(() => {
    const searchArr: SearchArray[] = keys.map((k) => [k, query[k]]);
    navigate({ search: createSearchString(searchArr) }, { replace });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, ...keys.map((k) => query[k])]);
}
