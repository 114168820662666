import { styled } from '@mui/material';

export const ListW = styled('div', {
  shouldForwardProp: (propname) => propname !== 'showBorder',
})<{ isSelected?: boolean; open?: boolean; showBorder: boolean }>(({ showBorder, theme }) => ({
  position: 'relative',

  '&::before': {
    content: '""',
    background: `${showBorder ? theme.palette.secondary.p020 : 'none'}`,
    position: 'absolute',
    top: 0,
    left: '32px',
    height: '1px',
    width: '100%',
  },
}));
