import { ReactElement } from 'react';

import { ContactFormDefaultValues } from 'types';

import { ContactForm } from '../ContactForm';
import { useContactEditForm } from './useContactEditForm';

interface ContactEditFormProps {
  defaultValues: ContactFormDefaultValues;
  contactId: string;
  isOpen: boolean;
  handleClose: () => void;
}

export function ContactEditForm({
  defaultValues,
  contactId,
  handleClose,
  isOpen,
}: ContactEditFormProps): ReactElement {
  const { handleSubmit } = useContactEditForm({ close: handleClose, contactId, defaultValues });

  return (
    <ContactForm
      mode="edit"
      title="Edit contact"
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      close={handleClose}
      isOpen={isOpen}
    />
  );
}
