import { MenuItem, styled } from '@mui/material';

export const ListW = styled('div')`
  border-radius: 4px;
  padding: 8px 0;
  max-height: 450px;
  overflow-y: auto;
  width: 197px;
`;

export const MenuItemW = styled(MenuItem)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  background: isSelected ? theme.palette.primary.p010 : 'none',
  fontSize: '14px',
  color: theme.palette.black.main,
  maxWidth: '100%',

  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const FilterName = styled('span')(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));
