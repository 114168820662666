import { ReactElement } from 'react';

import { TimeAgoDateCounter } from 'components';

import { DateCaption } from './styled';

interface MessageDateCaptionProps {
  date: string;
  refreshRateMs?: number;
}

export function MessageDateCaption({ date, refreshRateMs }: MessageDateCaptionProps): ReactElement {
  return (
    <DateCaption>
      <TimeAgoDateCounter date={date} refreshRateMs={refreshRateMs} />
    </DateCaption>
  );
}
