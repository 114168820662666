import { ClickAwayListener } from '@mui/material';
import { ReactElement, useState } from 'react';

import { EmptyStateSearch, SearchField } from 'components';

import { BuildingsContainer, InquiriesContainer, MessagesContainer } from './components';
import { useDetailsSearch } from './hooks/useDetailsSearch';
import { OptionsContainer, selectFieldSX } from './styled';

export function DetailsSearch(): ReactElement {
  const [search, setSearch] = useState('');

  const [focused, setFocused] = useState(false);

  const blur = () => setFocused(false);

  const { inquiries, buildings, messages, withFoundItems } = useDetailsSearch(search);

  const showOptions = focused && !!search;

  return (
    <ClickAwayListener onClickAway={blur}>
      <div>
        <SearchField
          value={search}
          onChange={setSearch}
          onFocus={() => setFocused(true)}
          onClear={() => setSearch('')}
          sx={selectFieldSX}
        />

        {showOptions && (
          <OptionsContainer largePaddings={withFoundItems}>
            {withFoundItems ? (
              <>
                {!!buildings.length && <BuildingsContainer blur={blur} buildings={buildings} />}
                {!!inquiries.length && <InquiriesContainer blur={blur} inquiries={inquiries} />}
                {!!messages.length && (
                  <MessagesContainer blur={blur} messages={messages} search={search} />
                )}
              </>
            ) : (
              <EmptyStateSearch title="Couldn't find anything" description="Please try again" />
            )}
          </OptionsContainer>
        )}
      </div>
    </ClickAwayListener>
  );
}
