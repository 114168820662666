import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useCallback } from 'react';

import { PAGE_LIMIT } from 'constants/index';

interface UseMessageTemplatesTableProps {
  totalCount: number;
  pageLimit: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setPageLimit: Dispatch<SetStateAction<number>>;
}

interface UseMessageTemplatesTableReturn {
  showPagination: boolean;
  handleChangePage: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function useMessageTemplatesTable({
  totalCount,
  pageLimit,
  setCurrentPage,
  setPageLimit,
}: UseMessageTemplatesTableProps): UseMessageTemplatesTableReturn {
  const showPagination = totalCount > (pageLimit < PAGE_LIMIT ? pageLimit : PAGE_LIMIT);

  const handleChangePage = useCallback(
    (_event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      setCurrentPage(page);
    },
    [setCurrentPage],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPageLimit(parseInt(event.target.value));
      setCurrentPage(0);
    },
    [setCurrentPage, setPageLimit],
  );

  return { showPagination, handleChangePage, handleChangeRowsPerPage };
}
