import { MenuItem, styled } from '@mui/material';

import { ReactComponent as ArrowDropDownIcon } from 'assets/icons/arrow-drop-down.svg';
import { $FixTypeLater } from 'types';

export const DropdownIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
  '& .path1': {
    fill: theme.palette.secondary.main,
  },
}));

export const createStyledMessageIcon = (
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
): $FixTypeLater =>
  styled(icon)<{ variant?: 'black' | 'primary' | 'secondary' }>(
    ({ variant = 'primary', theme }) => ({
      '& .path1': {
        fill: theme.palette[variant].main,
      },
    }),
  );

export const MenuItemW = styled(MenuItem)(({ theme }) => ({
  height: '40px',
  gap: '8px',
  color: theme.palette.black.main,
}));
