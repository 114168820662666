import { ReactElement } from 'react';

import { BuildingStatus } from '../BuildingStatus';
import { BuildingAddress, BuildingName, BuildingDetailsW } from './styled';

interface BuildingDetailsProps {
  name: string;
  address: string;
  onClick?: () => void;
  status?: string;
}

export function BuildingDetails({
  name,
  onClick,
  address,
  status,
}: BuildingDetailsProps): ReactElement {
  return (
    <BuildingDetailsW>
      <BuildingName variant="body2" onClick={onClick}>
        {name}
      </BuildingName>
      <BuildingAddress style={{ marginBottom: '6px' }}>{address}</BuildingAddress>
      {status && <BuildingStatus status={status} />}
    </BuildingDetailsW>
  );
}
