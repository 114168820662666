import { Dialog, DialogTitle, styled } from '@mui/material';

export const Wrapper = styled(Dialog)(() => ({
  '.MuiDialog-paper': {
    width: '452px',
    margin: 0,
    padding: '32px',
    overflowY: 'visible',
    borderRadius: '16px',
  },
}));

export const TitleWrapper = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '24px',
  padding: 0,
  fontWeight: 600,
  fontSize: '28px',
  lineHeight: '32px',
  color: theme.palette.black.main,
}));
