interface CanScrollToFirstUnreadProps {
  messagesCount: number;
  hasUnreadMessage: boolean;
}

const ONE_TIME_MAX_MESSAGES_COUNT_RESPONSE = 20;
const MAX_COUNT_OF_TWO_RESPONSES = ONE_TIME_MAX_MESSAGES_COUNT_RESPONSE * 2;

export const canScrollToFirstUnread = ({
  messagesCount,
  hasUnreadMessage,
}: CanScrollToFirstUnreadProps): boolean => {
  const isShortThread = messagesCount < ONE_TIME_MAX_MESSAGES_COUNT_RESPONSE;
  const isTwoPagesLoaded =
    messagesCount > ONE_TIME_MAX_MESSAGES_COUNT_RESPONSE &&
    messagesCount <= MAX_COUNT_OF_TWO_RESPONSES;

  // all messages are read already
  if (!hasUnreadMessage) {
    return false;
  }

  // no previous or next pages
  if (isShortThread) {
    return true;
  }

  // loaded previous page
  if (isTwoPagesLoaded) {
    return true;
  }

  return false;
};
