import { InputAdornment as MuiInputAdornment, styled, TextField } from '@mui/material';

import { theme } from 'styles';

export const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    color: theme.palette.black.main,
  },

  input: {
    ':-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
    },
    '&:placeholder': { fontSize: '14px' },

    // to remove arrow from number input
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  '.MuiOutlinedInput-root': {
    fontSize: '14px',
  },
  '.MuiFormControl-root': {
    position: 'relative',
  },
  '.MuiFormHelperText-root': {
    margin: '0 5px',
  },
  '& .MuiInputAdornment-root': {
    '.MuiTypography-root': {
      fontSize: '14px',
    },
  },
  '.Mui-disabled': {
    cursor: 'not-allowed',
    'input:disabled': {
      cursor: 'not-allowed',
    },
  },
});

export const InputAdornment = styled(MuiInputAdornment)`
  margin-top: 12px;
  align-self: flex-start;
`;
