import { InquiryType } from 'types';

export function unassignedBuildingInquiryItemAdapter<T extends { inquiryType: InquiryType }>(
  inquiries: T[] | undefined,
  type: InquiryType,
): T[] {
  if (!inquiries) return [];

  return inquiries.map((inquiry) => ({ ...inquiry, inquiryType: type }));
}
