import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { MenuItem, styled, Typography } from '@mui/material';

import { ReactComponent as ArrowDropDownIcon } from 'assets/icons/arrow-drop-down.svg';
import { MessageType, $FixTypeLater } from 'types';

import { getMessageBgColor } from '../NewMessageForm/utils';

export const NewMessageBlockW = styled('div')<{ messageType: MessageType }>(
  ({ theme, messageType }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px 16px 18px',
    borderTop: `1px solid ${theme.palette.secondary.p020}`,
    transition: 'background 0.2s',
    background: getMessageBgColor(theme, messageType),
  }),
);

export const MessageTypeW = styled('div')(() => ({
  display: 'inline-flex',
  gap: '10px',
  alignItems: 'center',
  // cursor: isReadOnlyAccess ? 'default' : 'pointer',
  padding: 0,
}));

export const LabelText = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
}));

export const DropdownIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
  '& .path1': {
    fill: theme.palette.secondary.main,
  },
}));

export const SendIcon = styled(SendRoundedIcon)<{ valid: boolean }>(({ theme, valid }) => ({
  cursor: 'pointer',
  transform: 'rotate(-45deg)',
  color: valid ? theme.palette.secondary.main : theme.palette.secondary.p030,
}));

export const createStyledMessageIcon = (
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
): $FixTypeLater =>
  styled(icon)<{ variant?: 'black' | 'primary' | 'secondary' }>(
    ({ variant = 'primary', theme }) => ({
      '& .path1': {
        fill: theme.palette[variant].main,
      },
    }),
  );

export const MenuItemW = styled(MenuItem)(({ theme }) => ({
  height: '40px',
  gap: '8px',
  color: theme.palette.black.main,
}));
