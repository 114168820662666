import { useMemo, useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { Socket } from 'socket.io-client';

import { createSocketConnection, queryClient, WS_STREAMS } from 'api';
import { EmptyStateArgs } from 'components';
import { useDebouncedValue, useSearch, useSetQueryParams } from 'hooks';
import { DashboardItems, EmptyStateIcons, InViewItemRef, WsDashboardChannel } from 'types';
import { getQueryParams } from 'utils';

import { DashboardFilterValues } from '../config';
import { QUERY_SEARCH_KEYS } from './config';
import { useDashboardQuery } from './useDashboardQuery';

interface UseDashboardReturn {
  search: string;
  onClearSearch: () => void;
  onSetSearch: (search: string) => void;
  dashboardList: DashboardItems;
  emptyState: EmptyStateArgs;
  isDashboardLoading: boolean;
  isFetchingNextDashboardPage: boolean;
  source: string;
  handleSourceChange: (tab: string) => void;
  filterCounters: Record<DashboardFilterValues, number>;
  ref: InViewItemRef;
}

export function useDashboard(): UseDashboardReturn {
  const querySearch = useMemo(() => getQueryParams(QUERY_SEARCH_KEYS), []);

  const [source, setSource] = useState<string>(
    querySearch.source || (DashboardFilterValues.ALL as string),
  );

  const { search, onSetSearch } = useSearch(querySearch.search?.toString() || '');

  const debouncedSearch = useDebouncedValue(search, 500);

  const searchParam = search.length ? debouncedSearch : '';

  useSetQueryParams(
    {
      search: searchParam,
      source,
    },
    QUERY_SEARCH_KEYS,
  );

  const { ref, inView } = useInView();

  const {
    dashboardList,
    isDashboardLoading,
    isFetchingNextDashboardPage,
    isError,
    contactsCount,
    dashboardTotalCounters,
  } = useDashboardQuery({
    search,
    source,
    inView,
  });

  const dashboardSocket = useRef<Socket | null>(null);

  useEffect(() => {
    if (dashboardSocket.current === null) {
      const socket = createSocketConnection({ stream: WS_STREAMS.dashboardStream });

      socket.on(WsDashboardChannel.UPDATE_DASHBOARD_ITEM, () => {
        queryClient.invalidateQueries('dashboard');
      });

      socket.on(WsDashboardChannel.DASHBOARD_COUNTERS_UPDATED, () => {
        queryClient.invalidateQueries('dashboard');
      });

      dashboardSocket.current = socket;
    }

    return () => {
      dashboardSocket.current?.close();
    };
  }, []);

  const emptyState: EmptyStateArgs = useMemo(() => {
    if (isError) {
      return {
        shouldAppear: true,
        title: 'Oops, something went wrong',
        description: 'Please try to reload this page',
        variant: EmptyStateIcons.ERROR,
      };
    }
    if (searchParam.length && !isDashboardLoading && contactsCount === 0) {
      return {
        shouldAppear: true,
        title: "Couldn't find anyone",
        variant: EmptyStateIcons.EMPTY_SEARCH,
      };
    }
    return {
      shouldAppear: !searchParam.length && !isDashboardLoading && contactsCount === 0,
      title: `Your contacts will be here`,
      variant: EmptyStateIcons.EMPTY_LIST,
    };
  }, [contactsCount, isDashboardLoading, isError, searchParam.length]);

  const filterCounters = {
    [DashboardFilterValues.ALL]: dashboardTotalCounters?.total || 0,
    [DashboardFilterValues.SMS]: dashboardTotalCounters?.sms || 0,
    [DashboardFilterValues.EMAIL]: dashboardTotalCounters?.email || 0,
    [DashboardFilterValues.PRIVATE_NOTES]: dashboardTotalCounters?.privateNotes || 0,
    [DashboardFilterValues.BUILDING_CHATS]: dashboardTotalCounters?.buildingMessages || 0,
    [DashboardFilterValues.REQUEST_MESSAGES]: dashboardTotalCounters?.requestMessages || 0,
    [DashboardFilterValues.OTHER_SYSTEM_MESSAGES]: dashboardTotalCounters?.otherSystemMessages || 0,
  };

  return {
    search,
    onClearSearch: () => onSetSearch(''),
    onSetSearch,
    dashboardList,
    emptyState,
    isDashboardLoading,
    isFetchingNextDashboardPage,
    source,
    handleSourceChange: setSource,
    filterCounters,
    ref,
  };
}
