import { Box } from '@mui/material';

import { Avatar } from 'components';
import { Employee, SelectOption } from 'types';
import { getFullName, transformEmployeeRole } from 'utils';

import { AvatarOverlay, AvatarW, EmployeeRolesW, IconW, DisplayLabelText } from './styled';

interface EmployeeOptionsAdapterProps {
  employeesList: Employee[];
  assigneeId: string;
}

export function employeeOptionsAdapter({
  employeesList,
  assigneeId,
}: EmployeeOptionsAdapterProps): SelectOption[] {
  return employeesList.map(({ roles, id, user: { picture, familyName, givenName } }) => ({
    displayLabel: (
      <Box display="flex">
        <AvatarW>
          <Avatar alt="contact owner avatar" src={picture} sx={{ width: 24, height: 24 }} />
          {assigneeId === id && (
            <>
              <AvatarOverlay />
              <IconW />
            </>
          )}
        </AvatarW>
        <DisplayLabelText>
          {getFullName({ firstName: givenName, lastName: familyName })}
          <EmployeeRolesW>({roles.map(transformEmployeeRole).join(', ')})</EmployeeRolesW>
        </DisplayLabelText>
      </Box>
    ),
    label: getFullName({ firstName: givenName, lastName: familyName }),
    value: id,
    icon: (
      <Avatar
        alt="inquiry assignee avatar"
        src={picture}
        sx={{ width: 24, height: 24, marginRight: '10px' }}
      />
    ),
  }));
}
