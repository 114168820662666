import { AxiosResponseError } from 'api/types';
import { EmptyStateArgs } from 'components/PageStatus';
import { EmptyStateIcons } from 'types';
import { getIs404Error } from 'utils';

export function getEmptyStateData(contactError: AxiosResponseError): EmptyStateArgs {
  if (getIs404Error(contactError)) {
    return {
      shouldAppear: Boolean(contactError),
      title: '',
      description: '',
      is404: true,
      variant: EmptyStateIcons.ERROR,
    };
  }

  return {
    shouldAppear: Boolean(contactError),
    title: 'Oops, something went wrong',
    description: 'Please try to reload this page',
    variant: EmptyStateIcons.ERROR,
  };
}

export function getMainContentWidth(pageSections: string[] | undefined): string {
  const isCampaignsVisible = Boolean(pageSections?.includes('campaigns'));
  const isInfoPanelVisible = Boolean(pageSections?.includes('infoPanel'));

  if (isCampaignsVisible && !isInfoPanelVisible) {
    return 'calc(100% - 580px)';
  }

  if (!isCampaignsVisible && isInfoPanelVisible) {
    return 'calc(100% - 380px)';
  }

  if (isCampaignsVisible && isInfoPanelVisible) {
    return 'calc(100% - 780px)';
  }

  return '100%';
}
