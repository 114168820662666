import { CircularProgress, Typography } from '@mui/material';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { alert, Button, LoaderWrapper } from 'components';
import { ButtonSizes } from 'components/Button/interface';
import { getQueryParams, openPageInNewTab } from 'utils';

import { QUERY_SETTINGS_KEYS } from '../SettingsPage/config';
import { REDIRECT_URL } from './constants';
import { Caption, UserEmail, Wrapper } from './styled';

interface CrmIntegrationsProps {
  setEmailConfigParam: (value: string) => void;
}

export function CrmIntegrations({ setEmailConfigParam }: CrmIntegrationsProps): ReactElement {
  const { emailConfig } = useMemo(() => getQueryParams(QUERY_SETTINGS_KEYS), []);
  const [url, setUrl] = useState('');

  const { data: connectionsData, isLoading } = useQuery('settings', () =>
    API_HANDLERS.SETTINGS.COMMUNICATION_EMAIL.GET(),
  );

  const { refetch } = useQuery(
    'communication-email-setup',
    () => API_HANDLERS.SETTINGS.SETUP.GET({ redirectTo: REDIRECT_URL }),
    {
      enabled: false,
      onSuccess: (res) => {
        setUrl(res.data);
      },
    },
  );

  useEffect(() => {
    if (url) {
      openPageInNewTab(url);
      setUrl('');
    }
  }, [url]);

  if (Boolean(emailConfig)) {
    alert.success('Gmail is successfully connected');
    setEmailConfigParam('');
  }

  if (isLoading) {
    return (
      <LoaderWrapper>
        <CircularProgress size={48} />
      </LoaderWrapper>
    );
  }

  return (
    <Wrapper>
      <Typography variant="body2" fontWeight={600}>
        Gmail
      </Typography>

      {connectionsData?.data.communicationEmail && (
        <>
          <Caption variant="body2">Connected:</Caption>
          <UserEmail variant="body2">{connectionsData?.data.communicationEmail}</UserEmail>
        </>
      )}

      <Button sx={{ marginTop: '12px' }} buttonSize={ButtonSizes.xSmall} onClick={() => refetch()}>
        Connect gmail
      </Button>
    </Wrapper>
  );
}
