import { Popover as MuiPopover, PopoverProps } from '@mui/material';
import { usePopupState, bindTrigger, bindMenu, PopupState } from 'material-ui-popup-state/hooks';
import { ReactNode } from 'react';

interface CustomPopoverProps {
  anchor: React.ReactNode;
  children: (props: PopupState) => ReactNode;
  popupId: string;
  anchorOrigin?: PopoverProps['anchorOrigin'];
  transformOrigin?: PopoverProps['transformOrigin'];
  sx?: PopoverProps['sx'];
  closeOnContainerClick?: boolean;
}

const Popover = ({
  anchor,
  children,
  popupId,
  closeOnContainerClick = true,
  ...popoverProps
}: CustomPopoverProps): React.ReactElement => {
  const popupState = usePopupState({ variant: 'popover', popupId });

  return (
    <>
      <div {...bindTrigger(popupState)}>{anchor}</div>
      <MuiPopover
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        {...popoverProps}
      >
        <div
          onClick={closeOnContainerClick ? popupState.close : undefined}
          onKeyDown={popupState.close}
          tabIndex={-1}
          role="menu"
        >
          {children(popupState)}
        </div>
      </MuiPopover>
    </>
  );
};

export default Popover;
