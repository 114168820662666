import { ReactElement } from 'react';

import { PreviewModal } from 'components';
import { DashboardItemActiveInquiryRequestsItem, InquiryType } from 'types';

import { InquiryRequestsList } from '../../RequestsList';

interface InquiryRequestsModalProps {
  inquiryType: InquiryType;
  open: boolean;
  onClose: () => void;
  requestsList: DashboardItemActiveInquiryRequestsItem[];
  contactId: string;
}

export function InquiryRequestsModal({
  inquiryType,
  open,
  onClose,
  requestsList,
  contactId,
}: InquiryRequestsModalProps): ReactElement {
  const title = inquiryType === 'tour' ? 'Tour requests' : 'Application requests';

  return (
    <PreviewModal
      open={open}
      title={title}
      content={
        <InquiryRequestsList
          inquiryType={inquiryType}
          requests={requestsList}
          contactId={contactId}
        />
      }
      onClose={onClose}
    />
  );
}
