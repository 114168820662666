import { styled, Box } from '@mui/material';

export const InfoPanelW = styled(Box)`
  width: 324px;

  display: flex;
  flex-direction: column;

  border-left: 1px solid #e0e4e5;
  padding: 20px 24px 28px;

  height: 100%;
  min-height: 100%;
  overflow-y: scroll;
`;
