import { Box } from '@mui/material';
import { ReactElement, useContext } from 'react';

import { DATE_FORMAT_WITH_TIME_AND_COMMA } from 'constants/index';
import { EmployeesContext } from 'context';
import { BuildingPreview } from 'features/buildings/components';
import { BuildingChat } from 'types';
import { getFormattedDateWithTime, parseUserMentions } from 'utils';

import {
  BadgeContainer,
  BuildingInfoSection,
  BuildingName,
  ChatItemW,
  ChatLink,
  DateCaption,
  LastMessageText,
  LeftSideSectionW,
  StyledBadge,
} from './styled';

interface Props extends BuildingChat {
  handleOpenChat: () => void;
}

export function BuildingChatListItem({
  building,
  unreadMessages,
  lastMessage,
  handleOpenChat,
}: Props): ReactElement {
  const { employeesList } = useContext(EmployeesContext);

  const lastMessageParsedText = parseUserMentions(lastMessage.text, employeesList);

  return (
    <ChatItemW key={building.id}>
      <LeftSideSectionW>
        <Box position="relative">
          <BadgeContainer>
            <StyledBadge badgeContent={unreadMessages} color="error" />
          </BadgeContainer>
          <BuildingPreview
            source={building.source}
            photo={building.photo}
            previewSx={{ marginRight: '0' }}
          />
        </Box>
        <BuildingInfoSection>
          <BuildingName>{building.name}</BuildingName>
          <LastMessageText>{lastMessageParsedText}</LastMessageText>
          <ChatLink variant="body2" onClick={handleOpenChat}>
            Open chat
          </ChatLink>
        </BuildingInfoSection>
      </LeftSideSectionW>
      <DateCaption variant="body2">
        {getFormattedDateWithTime(new Date(lastMessage.createdAt), DATE_FORMAT_WITH_TIME_AND_COMMA)}
      </DateCaption>
    </ChatItemW>
  );
}
