import { SelectOption } from 'types';

interface GetContactOwnerDisplayValueProps {
  contactOwner: string;
  options: SelectOption[];
}

export function getContactOwnerDisplayValue({
  contactOwner,
  options,
}: GetContactOwnerDisplayValueProps): string | undefined {
  const selectedContactOwner = options.find((option) => option.value === contactOwner);

  if (selectedContactOwner) {
    return selectedContactOwner.displayLabel as string;
  }
}
