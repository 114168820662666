import { MessageType, MessageEvent } from 'types';

export const MESSAGE_TYPE_LABELS: Partial<Record<MessageType, string>> = {
  [MessageType.OUTGOING_SMS]: 'SMS',
  [MessageType.OUTGOING_EMAIL]: 'Email',
};

export const MESSAGE_EVENT_LABELS: Record<MessageEvent, string> = {
  [MessageEvent.NEW_CONTACT_CREATED]: 'New Contact created',
  [MessageEvent.NEW_CAMPAIGN_CREATED]: 'New Campaign created',
  [MessageEvent.CAMPAIGN_STATUS_CHANGED]: 'Campaign status changed',
  [MessageEvent.NEW_LIST_SENT]: 'New list sent',
  [MessageEvent.TOUR_INQUIRY_ADDED]: 'Tour added',
  [MessageEvent.APPLICATION_INQUIRY_ADDED]: 'Apply added',
  [MessageEvent.TOUR_INQUIRY_STATUS_CHANGED]: 'Tour status changed',
  [MessageEvent.APPLICATION_INQUIRY_STATUS_CHANGED]: 'Apply status changed',
};

export const messageTypeOptions = Object.entries(MESSAGE_TYPE_LABELS).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const messageEventOptions = Object.entries(MESSAGE_EVENT_LABELS).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const confirmDeleteModalConfig = {
  title: 'This message template will be deleted',
  content: 'Are you sure you want to proceed?',
  confirmText: 'Delete',
  cancelText: 'Cancel',
};
