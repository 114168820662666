import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { Control, useForm, UseFormReset } from 'react-hook-form';

import { useMessageTemplateFieldsQuery } from 'features/settings/hooks';
import { useModal } from 'hooks';
import { MessageTemplateData, MessageTemplateFormData, MessageType } from 'types';
import { REQUIRED } from 'validations/messages';

import { MESSAGE_TEMPLATE_FORM_DEFAULT_VALUES } from './constants';
import { messageTemplateFormSchema } from './validationSchema';
interface UseMessageTemplateFormProps {
  defaultValues?: MessageTemplateFormData;
  onSubmit: (values: MessageTemplateData, onSuccessAction?: () => void) => void;
  handleClose: () => void;
}

interface UseMessageTemplateFormReturn {
  control: Control<MessageTemplateFormData>;
  fields: string[];
  reset: UseFormReset<MessageTemplateFormData>;
  handleMessageTypeChange: () => void;
  handleMessageEventChange: () => void;
  handleFormSubmit: () => Promise<void>;
  handlePreviewClick: () => void;
  isPreviewModalOpen: boolean;
  handleClosePreviewModal: () => void;
  handleCloseFormModal: () => void;
  templateBody: string;
  disableEvent: boolean;
  disableSubject: boolean;
  disableBody: boolean;
  disableSubmit: boolean;
  hideSubject: boolean;
  isBodyFocused: boolean;
  isSubmitting: boolean;
  handleBodyFocus: () => void;
  handleBodyBlur: () => void;
}

export function useMessageTemplateForm({
  defaultValues,
  onSubmit,
  handleClose,
}: UseMessageTemplateFormProps): UseMessageTemplateFormReturn {
  const [isBodyFocused, setIsBodyFocused] = useState(false);
  const [fields, setFields] = useState<string[]>([]);

  const {
    isOpen: isPreviewModalOpen,
    handleOpenModal: handleOpenPreviewModal,
    handleCloseModal: handleClosePreviewModal,
  } = useModal();

  const {
    handleSubmit,
    control,
    watch,
    resetField,
    setError,
    reset,
    formState: { isValid, isSubmitting, isDirty },
  } = useForm<MessageTemplateFormData>({
    resolver: yupResolver(messageTemplateFormSchema),
    mode: 'all',
    defaultValues: defaultValues || MESSAGE_TEMPLATE_FORM_DEFAULT_VALUES,
  });

  const templateBody = watch('body');
  const messageType = watch('type');
  const messageEvent = watch('event');

  const disableEvent = !messageType;
  const disableSubject = !messageType || !messageEvent;
  const disableBody = !messageType || !messageEvent;
  const disableSubmit = !isValid || isSubmitting || !isDirty;

  const hideSubject = messageType === MessageType.OUTGOING_SMS;

  const { fieldsList, isFieldsLoading } = useMessageTemplateFieldsQuery(messageEvent);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (messageEvent && !isFieldsLoading) {
      setFields(fieldsList);
    }
  }, [fieldsList, isFieldsLoading, messageEvent]);

  const handleMessageTypeChange = useCallback(() => {
    resetField('subject');
    resetField('body');
  }, [resetField]);

  const handleMessageEventChange = useCallback(() => {
    resetField('body');
  }, [resetField]);

  const handleFormSubmit = handleSubmit((values: MessageTemplateFormData) => {
    onSubmit(values, reset);
  });

  const handlePreviewClick = useCallback(() => {
    console.log('InnerHTML >>', { __html: templateBody });

    handleOpenPreviewModal();
  }, [handleOpenPreviewModal, templateBody]);

  const handleBodyFocus = useCallback(() => {
    setIsBodyFocused(true);
  }, [setIsBodyFocused]);

  const handleBodyBlur = useCallback(() => {
    setIsBodyFocused(false);

    if (!templateBody.length) {
      setError('body', { type: 'required', message: REQUIRED });
    }
  }, [setError, templateBody.length]);

  const handleCloseFormModal = useCallback(() => {
    handleClose();
    reset(defaultValues);
  }, [defaultValues, handleClose, reset]);

  return {
    control,
    templateBody,
    fields,
    reset,
    handleMessageTypeChange,
    handleMessageEventChange,
    handleFormSubmit,
    handlePreviewClick,
    isPreviewModalOpen,
    handleClosePreviewModal,
    handleCloseFormModal,
    disableEvent,
    disableSubject,
    disableBody,
    disableSubmit,
    hideSubject,
    isBodyFocused,
    isSubmitting,
    handleBodyFocus,
    handleBodyBlur,
  };
}
