import { ReactElement, useRef } from 'react';

import { ScrollContainerContext } from 'context';
import { UnassignedBuildings } from 'features/unassignedBuildings';
import { Wishlist } from 'features/wishlist';
import { Campaign } from 'types';

import { CampaignsList } from '../CampaignsList';
import { CampaignsSectionListW } from './styled';

interface CampaignsSectionContentProps {
  campaigns: Campaign[];
}

export function CampaignsSectionContent({ campaigns }: CampaignsSectionContentProps): ReactElement {
  const scrollContainerRef = useRef<HTMLElement>(null);

  return (
    <ScrollContainerContext.Provider value={scrollContainerRef}>
      <CampaignsSectionListW ref={scrollContainerRef}>
        <Wishlist />

        <UnassignedBuildings />

        <CampaignsList campaigns={campaigns} />
      </CampaignsSectionListW>
    </ScrollContainerContext.Provider>
  );
}
