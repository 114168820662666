import { FilterValue, FilterGroup, EntityFilterPayload, CreateFilterInput } from 'types';

function isFilterValue(value: FilterValue | FilterGroup): value is FilterValue {
  return (<FilterValue>value).entityName !== undefined;
}

export function filterFormAdapter(formValues: EntityFilterPayload): CreateFilterInput {
  const { group, name, conditions } = formValues;

  const transformedConditions = conditions.value.map((formCondition, idx) => {
    if (isFilterValue(formCondition)) {
      return {
        childNode: {
          entity: formCondition.entityName,
          field: formCondition.propertyName,
          comparator: formCondition.operator,
          value: formCondition.value || null,
        },
        booleanExpression: idx === 0 ? null : conditions.operator,
      };
    }

    return {
      childNode: formCondition.value.map((groupCondition, idx) => {
        if (isFilterValue(groupCondition)) {
          return {
            childNode: {
              entity: groupCondition.entityName,
              field: groupCondition.propertyName,
              comparator: groupCondition.operator,
              value: groupCondition.value || null,
            },
            booleanExpression: idx === 0 ? null : formCondition.operator,
          };
        }

        return {
          childNode: groupCondition.value.map((nestedGroupCondition, idx) => {
            if (isFilterValue(nestedGroupCondition)) {
              return {
                childNode: {
                  entity: nestedGroupCondition.entityName,
                  field: nestedGroupCondition.propertyName,
                  comparator: nestedGroupCondition.operator,
                  value: nestedGroupCondition.value || null,
                },
                booleanExpression: idx === 0 ? null : groupCondition.operator,
              };
            }
          }),
          booleanExpression: formCondition.operator,
        };
      }),
      booleanExpression: idx === 0 ? null : conditions.operator,
    };
  });

  return {
    name,
    group: group.trim(),
    conditions: {
      childNode: transformedConditions,
      booleanExpression: null,
    },
  };
}
