import { MutationCache, QueryCache, QueryClient } from 'react-query';

import { alert } from 'components';
import { $FixTypeLater } from 'types';
import { showResponseError } from 'utils';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error: $FixTypeLater, query) => {
      // only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (!error.isHandledGlobally) {
        if (query.state.data !== undefined) {
          alert.error(`Something went wrong: ${error.message}`);
        }
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error: $FixTypeLater) => {
      // handled on request level
      if (error.response?.data.existingContactId) {
        return;
      }

      showResponseError(error);
    },
  }),
});
