import { ReactElement } from 'react';

import { EventPayloadContactOwnerChanged } from 'types';

import { TextBold } from './styled';

interface ContactOwnerChangedContentProps {
  data: EventPayloadContactOwnerChanged;
}

export function ContactOwnerChangedContent({
  data,
}: ContactOwnerChangedContentProps): ReactElement {
  const { newOwner } = data;

  return (
    <span>
      <TextBold>
        {newOwner.firstName} {newOwner.lastName}
      </TextBold>{' '}
      was assigned as Contact owner
    </span>
  );
}
