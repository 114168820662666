import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { generatePath } from 'react-router-dom';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { ContactCreateResponseSuccess, AxiosResponseError, ContactCreateParams } from 'api/types';
import { alert } from 'components';
import { ContactExistError } from 'constants/apiErrors';
import { useAuth } from 'hooks';
import { Links } from 'settings';
import { ContactFormDefaultValues, ContactFormValues } from 'types';

import { createContactFormAdapter } from './adapters';
import { defaultValues } from './constants';

interface UseContactCreateFormReturn {
  handleSubmit: (values: ContactFormValues) => Promise<void>;
  defaultValues: ContactFormDefaultValues;
  isLoading: boolean;
}

interface UseContactCreateFormProps {
  close: () => void;
}

export function useContactCreateForm({
  close,
}: UseContactCreateFormProps): UseContactCreateFormReturn {
  const { employee } = useAuth();

  const { mutateAsync: createContactMutation, isLoading } = useMutation<
    AxiosResponse<ContactCreateResponseSuccess>,
    AxiosResponseError,
    ContactCreateParams
  >(({ employeeId, data }: ContactCreateParams) => API_HANDLERS.CONTACT.CREATE(employeeId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries('contacts');
    },
    onError: (error) => {
      if (error.response?.data.existingContactId) {
        const contactDetailsUrl = generatePath(Links.contacts.contact.index, {
          contactId: error.response?.data.existingContactId,
        });

        alert.error(ContactExistError, {
          linkUrl: contactDetailsUrl,
          label: 'See contact',
        });
      }
    },
  });

  async function handleSubmit({
    firstName,
    lastName,
    phone,
    contactStyle,
    cameFrom,
    email,
  }: ContactFormValues) {
    if (employee) {
      const response = await createContactMutation({
        data: createContactFormAdapter({
          firstName,
          lastName,
          email,
          phone,
          contactStyle,
          cameFrom,
        }),
        employeeId: employee?.id,
      });

      if (response) {
        alert.success('Contact is successfully created');
        close();
      }
    }
  }

  return {
    defaultValues,
    handleSubmit,
    isLoading,
  };
}
