import { ReactElement } from 'react';

import { CampaignStatusMap, EventPayloadCampaignStatusChanged } from 'types';

import { Link, TextBold } from './styled';

interface CampaignStatusChangedContentProps {
  data: EventPayloadCampaignStatusChanged;
  onCampaignClick: (campaignId: string) => void;
}

export function CampaignStatusChangedContent({
  data,
  onCampaignClick,
}: CampaignStatusChangedContentProps): ReactElement {
  const { campaignId, campaignName, toStatus } = data;

  return (
    <span>
      Status for <Link onClick={() => onCampaignClick(campaignId)}>{campaignName}</Link> was changed
      to <TextBold>{CampaignStatusMap[toStatus]}</TextBold>
    </span>
  );
}
