import { ReactElement } from 'react';

import { ThreadItemWrapper } from './styled';

interface ThreadItemProps {
  innerHtml: string;
}

export function ThreadItem({ innerHtml }: ThreadItemProps): ReactElement {
  return (
    <ThreadItemWrapper>
      <div dangerouslySetInnerHTML={{ __html: innerHtml }} />
    </ThreadItemWrapper>
  );
}
