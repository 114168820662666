import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { EmptyState, PageStatus, SearchField } from 'components';
import { CommunicationsBox } from 'features/communications';
import { ContactDetailsProvider } from 'features/contactDetails';
import { EmptyStateIcons } from 'types';

import {
  CardsList,
  DashboardFilters,
  InquiryRequestsModal,
  ActiveListRequestsModal,
} from '../../components';
import { useDashboard, useDashboardChat, useActiveRequestsModals } from '../../hooks';
import {
  ChatWrapper,
  LeftSideWrapper,
  ListW,
  PageContent,
  PageHeader,
  PageTitle,
  PageWrapper,
  selectFieldSX,
  PreloaderWrapper,
} from './styled';

export function DashboardPage(): ReactElement {
  const { selectedContactChat, handleSelectChat } = useDashboardChat();
  const {
    search,
    onClearSearch,
    onSetSearch,
    dashboardList,
    emptyState,
    isDashboardLoading,
    isFetchingNextDashboardPage,
    source,
    handleSourceChange,
    filterCounters,
    ref,
  } = useDashboard();

  const {
    isTourRequestsModalOpen,
    handleOpenTourRequestsModal,
    handleCloseTourRequestsModal,
    tourRequestsModalState,
    isApplicationRequestsModalOpen,
    handleOpenApplicationRequestsModal,
    handleCloseApplicationRequestsModal,
    applicationRequestsModalState,
    isActiveListRequestsModalOpen,
    handleCloseListRequestsModal,
    handleOpenActiveListRequestsModal,
    activeListRequestsModalState,
  } = useActiveRequestsModals();

  return (
    <PageWrapper>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <PageHeader>
        <PageTitle variant="h4">Dashboard</PageTitle>
        <SearchField
          value={search}
          onChange={onSetSearch}
          onClear={onClearSearch}
          sx={selectFieldSX}
        />
      </PageHeader>
      <Divider />
      <PageContent>
        <LeftSideWrapper>
          <DashboardFilters
            source={source}
            handleSourceChange={handleSourceChange}
            filterCounters={filterCounters}
          />
          <ListW>
            <PageStatus loading={isDashboardLoading} emptyState={emptyState}>
              <CardsList
                contacts={dashboardList}
                selectedContactChat={selectedContactChat}
                handleSelectChat={handleSelectChat}
                onToursRequestsClick={handleOpenTourRequestsModal}
                onApplicationRequestsClick={handleOpenApplicationRequestsModal}
                onActiveListRequestsClick={handleOpenActiveListRequestsModal}
                lastItemRef={ref}
              />

              {isFetchingNextDashboardPage && (
                <PreloaderWrapper>
                  <CircularProgress />
                </PreloaderWrapper>
              )}
            </PageStatus>
          </ListW>
        </LeftSideWrapper>
        <Divider orientation="vertical" />

        <ChatWrapper>
          {selectedContactChat ? (
            <ContactDetailsProvider>
              <CommunicationsBox dashboardContactId={selectedContactChat} />
            </ContactDetailsProvider>
          ) : (
            <EmptyState
              variant={EmptyStateIcons.EMPTY_DASHBOARD_CHAT}
              description="Select a contact to chat"
              descriptionSx={{ fontWeight: 600, fontSize: '28px', lineHeight: '32px' }}
              iconSx={{ marginBottom: '50px' }}
            />
          )}
        </ChatWrapper>
      </PageContent>

      {!!tourRequestsModalState?.requests?.length && (
        <InquiryRequestsModal
          inquiryType="tour"
          open={isTourRequestsModalOpen}
          onClose={handleCloseTourRequestsModal}
          requestsList={tourRequestsModalState.requests}
          contactId={tourRequestsModalState.contactId}
        />
      )}

      {!!applicationRequestsModalState?.requests?.length && (
        <InquiryRequestsModal
          inquiryType="application"
          open={isApplicationRequestsModalOpen}
          onClose={handleCloseApplicationRequestsModal}
          requestsList={applicationRequestsModalState.requests}
          contactId={applicationRequestsModalState.contactId}
        />
      )}

      {!!activeListRequestsModalState?.requests?.length && (
        <ActiveListRequestsModal
          open={isActiveListRequestsModalOpen}
          onClose={handleCloseListRequestsModal}
          requestsList={activeListRequestsModalState.requests}
          contactId={activeListRequestsModalState.contactId}
        />
      )}
    </PageWrapper>
  );
}
