import { theme } from 'styles';

export const customInputStyles = {
  width: '318px',
  padding: 0,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.black.main,
  backgroundColor: 'transparent',
  outline: 'none',
  border: 'none',
};
