import { AppBar, Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink as RouterLink } from 'react-router-dom';

export const NavBar = styled(AppBar)(({ theme }) => ({
  maxWidth: '56px',
  backgroundColor: theme.palette.white.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px 0 16px',
  justifyContent: 'space-between',
  position: 'static',
  boxShadow: 'none',
  borderRight: `1px solid ${theme.palette.secondary.p020}`,
}));

export const NavMenu = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const LogoW = styled(RouterLink)`
  display: flex;
  justify-content: center;
  margin-bottom: 38px;
`;

export const AdminOnlySection = styled(Box)``;

export const DividerW = styled(Divider)`
  margin-bottom: 12px;
`;
