import { Box, styled } from '@mui/material';

export const BuildingW = styled(Box)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  padding: '12px 7px 12px 32px',
  cursor: 'pointer',
  display: 'flex',

  borderBottom: `1px solid ${theme.palette.secondary.p020}`,
  '&:last-of-type': {
    borderBottom: `none`,
  },

  borderLeft: `2px solid ${isSelected ? theme.palette.primary.main : 'transparent'}`,
  background: isSelected ? theme.palette.secondary.p005 : theme.palette.background.paper,
}));
