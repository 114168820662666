import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { GetBuildingChatsResponseSuccess } from 'api/types';

interface UseBuildingsChatListReturn {
  isLoading: boolean;
  error: unknown;
  buildingChats: GetBuildingChatsResponseSuccess['buildingChats'];
  unreadMessages: GetBuildingChatsResponseSuccess['unreadMessages'];
  handleRefetch: () => void;
}

interface UseBuildingsChatQueryParams {
  contactId: string;
}

export function useBuildingsChatQuery({
  contactId,
}: UseBuildingsChatQueryParams): UseBuildingsChatListReturn {
  const { isLoading, error, data, refetch } = useQuery(
    ['building-chats', contactId],
    () => API_HANDLERS.COMMUNICATIONS.BUILDING_CHATS(contactId),
    {
      enabled: Boolean(contactId),
    },
  );

  function handleRefetch() {
    refetch();
  }

  return {
    isLoading,
    error,
    buildingChats: data?.data.buildingChats || [],
    unreadMessages: data?.data.unreadMessages || 0,
    handleRefetch,
  };
}
