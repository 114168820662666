import { Avatar } from '@mui/material';
import { ReactElement } from 'react';

import { DATE_FORMAT_WITH_TIME_AND_COMMA } from 'constants/index';
import { DateCaption } from 'features/inquiry/components/InquiryListItem/styled';
import { InquiryStatusLayout } from 'features/inquiry/components/InquiryStatusBlock/InquiryStatusLayout';
import { AppInquiry } from 'types';
import { getFormattedDateWithTime } from 'utils';

import {
  AvatarW,
  ContentW,
  InquiryIconW,
  InquiryName,
  InquiryW,
  RelatedToText,
  StyledInquiryIcon,
} from './styled';

interface ListItemProps {
  inquiry: AppInquiry;
  onSelect: () => void;
  relatedTo?: string;
}

export function ListItem({ inquiry, onSelect, relatedTo }: ListItemProps): ReactElement {
  const isTour = inquiry.inquiryType === 'tour' ? true : false;

  return (
    <InquiryW onClick={onSelect}>
      <InquiryIconW>
        <StyledInquiryIcon tour={isTour} />
      </InquiryIconW>

      <ContentW>
        <div>
          <InquiryName>{inquiry.inquiryType}</InquiryName>
          <DateCaption variant="body2">
            {getFormattedDateWithTime(new Date(inquiry.createdAt), DATE_FORMAT_WITH_TIME_AND_COMMA)}
          </DateCaption>
        </div>

        <AvatarW>
          <InquiryStatusLayout
            setFocused={() => {}}
            showOptions={false}
            closeTransitions={[]}
            defaultTransitions={[]}
            handleChangeStatus={() => {}}
            status={inquiry.status}
            isActive={false}
            isDisabled={false}
          />
          <Avatar sx={{ width: 32, height: 32 }} src={inquiry?.assignee?.user?.picture} />
        </AvatarW>
      </ContentW>

      <RelatedToText variant="body2">
        Related to <b>{relatedTo || '__'}</b>
      </RelatedToText>
    </InquiryW>
  );
}
