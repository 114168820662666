import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material';

export const ArrowIcon = styled(ArrowDropDownIcon, {
  shouldForwardProp: (propname) => propname !== 'isMenuOpen',
})<{ isMenuOpen: boolean }>(({ theme, isMenuOpen }) => ({
  color: theme.palette.black.light,
  transform: `rotate(${isMenuOpen ? '180' : '0'}deg)`,
  cursor: 'pointer',
}));
