import { ReactElement, useCallback } from 'react';

import { MessageDateCaption, MessageHeader } from 'components';
import {
  FileAttachment,
  Message,
  MediaFile,
  MessageType,
  ReplyToEmailParams,
  TwilioMessageState,
} from 'types';

import { MessageAttachment, MmsAttachment, ReplyButton } from './components';
import { MESSAGE_ITEM_TYPE_ICONS, MESSAGE_TITLE } from './config';
import { Content, FailedToSendW, Footer, MessageW } from './styled';

interface MessageProps {
  id: string;
  received: boolean;
  messageType: Message['type'];
  width: string;
  messageText: string | (string | JSX.Element)[];
  date: string;
  attachments?: FileAttachment[];
  subject?: string;
  threads?: string[];
  handleReplyToEmail?: (data: ReplyToEmailParams) => void;
  media?: MediaFile[];
  twilioState?: TwilioMessageState;
  errorCode?: string | null;
}

export function MessageItem({
  id,
  received,
  messageType,
  width,
  messageText,
  date,
  attachments = [],
  subject,
  threads,
  handleReplyToEmail,
  media = [],
  twilioState,
  errorCode,
}: MessageProps): ReactElement {
  const Icon = MESSAGE_ITEM_TYPE_ICONS[messageType];
  const title = subject ?? MESSAGE_TITLE[messageType];
  const isEmail =
    messageType === MessageType.OUTGOING_EMAIL || messageType === MessageType.INCOMING_EMAIL;

  const handleReplyButtonClick = useCallback(() => {
    handleReplyToEmail?.({
      id,
      subject: title,
      messageToReplyContent: messageText,
      threads,
    });
  }, [handleReplyToEmail, id, messageText, threads, title]);

  const hasTwilioError = twilioState === TwilioMessageState.FAILED;

  return (
    <MessageW
      isReceived={received}
      messageType={messageType}
      width={width}
      hasError={hasTwilioError}
    >
      <MessageHeader title={title} icon={<Icon variant="black" />} />
      <div>
        <Content>
          {isEmail ? (
            <div dangerouslySetInnerHTML={{ __html: messageText as string }} />
          ) : (
            messageText
          )}
        </Content>
      </div>

      {attachments.length > 0 && (
        <div>
          {attachments.map(({ attachmentId, filename, extension, size }) => (
            <MessageAttachment
              key={attachmentId}
              fileId={attachmentId}
              fileName={`${filename}.${extension}`}
              extension={extension}
              size={size}
            />
          ))}
        </div>
      )}

      {media.length > 0 && (
        <div style={{ width: '100%' }}>
          {media.map((file, idx) => (
            <MmsAttachment
              key={file.url}
              file={file}
              number={idx + 1}
              hasTwilioError={hasTwilioError}
            />
          ))}
        </div>
      )}

      <Footer>
        <div>
          {isEmail && <ReplyButton onClick={handleReplyButtonClick} />}
          {hasTwilioError && (
            <FailedToSendW>{`Failed to send - error code ${errorCode}`}</FailedToSendW>
          )}
        </div>

        <MessageDateCaption date={date} />
      </Footer>
    </MessageW>
  );
}
