import { ReactElement, useContext, useRef } from 'react';
import { Control, useController } from 'react-hook-form';
import { Mention, MentionsInput } from 'react-mentions';

import { FieldError, Suggestion } from 'components';
import { EmployeesContext } from 'context';
import { BuildingChatFormValues } from 'types';
import { setAll } from 'utils/crudOperations';

import { NEW_MESSAGE_INPUT_PLACEHOLDER } from '../../../constants';
import { InputW } from './styled';
import { mentionStyles } from './stylesConfig';
import { employeeOptionsAdapter } from './utils';

interface UserMentionInputProps {
  addMention: (is: string, display: string) => void;
  control: Control<BuildingChatFormValues>;
  maxWidth: number;
}

export function UserMentionInput({
  control,
  addMention,
  maxWidth,
}: UserMentionInputProps): ReactElement {
  const { employeesList } = useContext(EmployeesContext);

  const listOfUsers = employeeOptionsAdapter({ employeesList });
  const normalizedEmployeesList = setAll(listOfUsers);

  function onAddHandler(id: string | number, display: string): void {
    addMention(id as string, display);
  }

  const {
    field: { onChange, value },
    formState: {
      errors: { content: fieldError },
    },
  } = useController({
    control,
    name: 'content',
  });

  const editorRef = useRef<HTMLDivElement>(null);

  return (
    <InputW ref={editorRef} maxWidth={maxWidth}>
      <MentionsInput
        value={value}
        onChange={onChange}
        suggestionsPortalHost={editorRef.current as Element}
        placeholder={NEW_MESSAGE_INPUT_PLACEHOLDER}
        style={mentionStyles}
        forceSuggestionsAboveCursor={true}
        allowSuggestionsAboveCursor={true}
        allowSpaceInQuery
        spellCheck={false}
      >
        <Mention
          trigger="@"
          appendSpaceOnAdd
          renderSuggestion={(s) => (
            <Suggestion avatar={normalizedEmployeesList[s.id].icon} display={s.display || ''} />
          )}
          data={Object.values(normalizedEmployeesList)}
          displayTransform={(_id, display) => `@${display}`}
          onAdd={onAddHandler}
          style={{ backgroundColor: '#FFF6E8' }}
        />
      </MentionsInput>
      {fieldError && <FieldError>{fieldError?.message}</FieldError>}
    </InputW>
  );
}
