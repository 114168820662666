import { theme } from 'styles';
import { NestedRecord } from 'types';

export const textFieldStyles = (disabled: boolean): NestedRecord => ({
  caretColor: 'transparent',
  input: {
    cursor: disabled ? 'default' : 'pointer',
  },

  '.MuiInputBase-root': {
    cursor: disabled ? 'default' : 'pointer',
    '.Mui-disabled': {
      '-webkit-text-fill-color': disabled ? theme.palette.black.p040 : theme.palette.black.light,
      cursor: disabled ? 'default' : 'pointer',
    },
  },

  '.MuiFormLabel-root': {
    color: disabled ? theme.palette.black.p040 : theme.palette.black.light,
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: disabled ? theme.palette.black.p030 : theme.palette.black.main,
    },
  },
});
