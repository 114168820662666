import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { ReactElement } from 'react';

import { MediaFile } from 'types';

import { handleFileDownload, handleFilePreview } from '../../utils';
import {
  FileName,
  FlexContainer,
  ImageW,
  FileContainer,
  Preview,
  ActionsW,
  IconButtonW,
  NoImageIcon,
} from './styled';

interface MmsAttachmentProps {
  file: MediaFile;
  number: number;
  hasTwilioError: boolean;
}

export function MmsAttachment({ file, number, hasTwilioError }: MmsAttachmentProps): ReactElement {
  const { contentType, url } = file;
  const fileType = contentType.split('/')[1];

  return (
    <FileContainer>
      <FlexContainer alignItems="center">
        {hasTwilioError ? (
          <NoImageIcon fontSize="medium" />
        ) : (
          <Preview>
            <ImageW src={url} alt="media-file" />
          </Preview>
        )}
        <FileName>{`file-${number}.${fileType}`}</FileName>
      </FlexContainer>

      {!hasTwilioError && (
        <ActionsW>
          <IconButtonW onClick={() => handleFilePreview(url)}>
            <VisibilityRoundedIcon fontSize="small" />
          </IconButtonW>

          <IconButtonW onClick={() => handleFileDownload(url, contentType)}>
            <DownloadRoundedIcon fontSize="small" />
          </IconButtonW>
        </ActionsW>
      )}
    </FileContainer>
  );
}
