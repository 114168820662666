import { Avatar as MuiAvatar, SxProps, useTheme } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface AvatarProps {
  src?: string;
  children?: ReactNode;
  alt: string;
  sx?: SxProps;
  withBorder?: boolean;
  onClick?: () => void;
}

// TODO: reuse in all places where mui Avatar is used already
export function Avatar({
  src,
  children,
  alt,
  sx,
  withBorder = false,
  onClick,
}: AvatarProps): ReactElement {
  const theme = useTheme();

  return (
    <MuiAvatar
      alt={alt}
      src={src}
      sx={{
        border: withBorder ? `1px solid ${theme.palette.secondary.main}` : 'none',
        background: theme.palette.secondary.p020,
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </MuiAvatar>
  );
}
