import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { DropzoneState } from 'react-dropzone';

import { FileWithId } from 'types';

import { AttachedFile, RestrictedFilesList, FileWithPreview } from './components';
import { Attachment as AttachmentIcon, DropZoneW, FilesContainer } from './styled';

interface DropZoneProps {
  files: FileWithId[];
  dropZone: DropzoneState;
  handleFileDelete: (id: string) => void;
  showPreview?: boolean;
}

export function DropZone({
  files,
  dropZone,
  handleFileDelete,
  showPreview = false,
}: DropZoneProps): ReactElement {
  const { getRootProps, getInputProps, open, fileRejections } = dropZone;

  const FileComponent = showPreview ? FileWithPreview : AttachedFile;

  const attachedFiles = files?.map(({ name, preview, id, size }: FileWithId) => (
    <FileComponent
      key={id}
      name={name}
      imgSrc={preview}
      size={size}
      onDelete={() => handleFileDelete(id)}
    />
  ));

  return (
    <DropZoneW>
      <FilesContainer {...getRootProps()}>
        <input {...getInputProps()} />

        <>{attachedFiles}</>
        <RestrictedFilesList fileRejections={fileRejections} />
      </FilesContainer>

      <IconButton sx={{ marginRight: '26px' }} onClick={open}>
        <AttachmentIcon />
      </IconButton>
    </DropZoneW>
  );
}
