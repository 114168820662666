import { MessageType, NewMessageFormValues } from 'types';

export const getDefaultValues = (isReadOnlyAccess: boolean): NewMessageFormValues => ({
  type: isReadOnlyAccess ? MessageType.PRIVATE_NOTES : MessageType.OUTGOING_SMS,
  content: '',
  subject: '',
  withCc: false,
  withBcc: false,
  files: [],
  cc: [],
  bcc: [],
  replyTo: '',
});
