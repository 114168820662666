import styled from '@emotion/styled';
import { Badge } from '@mui/material';

import { theme } from 'styles';

export const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    minWidth: '20px',
    right: '4px',
    padding: '2px ',
    fontSize: '9px',
    lineHeight: '12px',
    borderRadius: '6px',
    border: `2px solid ${theme.palette.white.main}`,
  },
}));
