import { WithParent } from 'types';

// TODO: remove?
export function addParent<T>(entity: T, parentId: string): WithParent<T> {
  return {
    ...entity,
    parentId,
  };
}

export function addParentToArray<T>(arr: T[], parentId: string): Array<WithParent<T>> {
  return arr.map((entity) => ({
    ...entity,
    parentId,
  }));
}
