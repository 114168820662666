import { string } from 'prop-types';

import { MessageSourceType, MessageType } from 'types';

import { messageTypesBySource } from './config';

export function getSourcesParams(sources: string | null): MessageType[] {
  if (!string || !string.length) {
    return [];
  }

  const sourcesArray = sources?.split(',') as MessageSourceType[];

  return sourcesArray?.reduce(
    (previousValue: MessageType[], currentValue) => [
      ...previousValue,
      ...(messageTypesBySource[currentValue] || []),
    ],
    [],
  );
}

export function getMessageSourceArrayFromString(
  paramString: string | null,
): MessageSourceType[] | null {
  if (paramString?.length) {
    return paramString.split(',').map((source) => source.trim()) as MessageSourceType[];
  }

  return null;
}
