import { Employee } from 'types';
import { getFullName } from 'utils';
import { setAll } from 'utils/crudOperations';

export function parseUserMentions(text: string, employees: Employee[]): string {
  const msgItems = text.split(' ');
  const normalizedEmployees = setAll(employees);

  const transformedItems = msgItems.map((textItem) => {
    if (textItem.startsWith('${') && textItem.endsWith('}')) {
      const selectedUser = normalizedEmployees[textItem.replace('${', '').replace('}', '')];

      const userFullName = getFullName({
        firstName: selectedUser.user.givenName,
        lastName: selectedUser.user.familyName,
      });

      return `@${userFullName}`;
    }

    return textItem;
  });

  return transformedItems.join(' ');
}
