import { CampaignBuilding } from './buildings';

export enum CampaignStatus {
  CLOSED_WON = 'CLOSED_WON',
  MAKE_A_LIST = 'MAKE_A_LIST',
  LIST_MADE = 'LIST_MADE',
  LIST_SENT = 'LIST_SENT',
  ACTIVE_BUILDINGS = 'ACTIVE_BUILDINGS',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
}

export enum ClosedLostCampaignStatus {
  CLOSED_LOST = 'CLOSED_LOST',
}

export enum CloseCampaignReasons {
  NO_LONGER_MOVING = 'NO_LONGER_MOVING',
  GHOSTED = 'GHOSTED',
  COMPETITION = 'COMPETITION',
  UNCONFIRMED_REFERRAL = 'UNCONFIRMED_REFERRAL',
  ELIGIBILITY_BACKGROUND_ISSUES = 'ELIGIBILITY_BACKGROUND_ISSUES',
  ELIGIBILITY_FINANCIAL_ISSUES = 'ELIGIBILITY_FINANCIAL_ISSUES',
  ELIGIBILITY_RENTAL_HISTORY = 'ELIGIBILITY_RENTAL_HISTORY',
}

export const CampaignStatusMap: Record<CampaignStatus | ClosedLostCampaignStatus, string> = {
  [ClosedLostCampaignStatus.CLOSED_LOST]: 'Closed lost',
  [CampaignStatus.CLOSED_WON]: 'Closed won',
  [CampaignStatus.MAKE_A_LIST]: 'Make a list',
  [CampaignStatus.LIST_MADE]: 'List made',
  [CampaignStatus.LIST_SENT]: 'List sent',
  [CampaignStatus.ACTIVE_BUILDINGS]: 'Active buildings',
  [CampaignStatus.ACTION_REQUIRED]: 'Action required',
};

export const CloseCampaignReasonsMap: Record<CloseCampaignReasons, string> = {
  [CloseCampaignReasons.NO_LONGER_MOVING]: 'No longer moving ',
  [CloseCampaignReasons.GHOSTED]: 'Ghosted',
  [CloseCampaignReasons.COMPETITION]: 'Competition',
  [CloseCampaignReasons.UNCONFIRMED_REFERRAL]: 'Unconfirmed Referral',
  [CloseCampaignReasons.ELIGIBILITY_BACKGROUND_ISSUES]: 'Eligibility: Background Issues',
  [CloseCampaignReasons.ELIGIBILITY_FINANCIAL_ISSUES]: 'Eligibility: Financial Issues',
  [CloseCampaignReasons.ELIGIBILITY_RENTAL_HISTORY]: 'Eligibility: Rental History',
};

export enum CampaignIssues {
  NONE = 'noneApply',
  BROKEN_STUFF = 'brokenStuff',
  NO_CREDIT_HISTORY = 'noCreditHistory',
  LOW_CREDIT_SCORE = 'lowCreditScore',
  LOW_INCOME = 'lowIncome',
  BANKRUPTCIES = 'bankruptcies',
  MISDEMEANORS = 'misdemeanors',
}

export interface CampaignStatusDto {
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
}

export interface Campaign {
  id: string;
  moveInDate: string | Date;
  city: string;
  rentPrice: string;
  status: CampaignStatus | CloseCampaignReasons;
  title: string;
  location: string;
  bedroomsCount: string;
  amenities: string;
  createdAt: string;
  servicePreference: ServicePreferences;
  issues: CampaignIssues[];
  buildings?: CampaignBuilding[];
  notes: string;
}

export interface SelectOptionWithLabelString {
  value: string;
  label: string;
}

export interface CampaignFormValues {
  campaignName: string;
  servicePreference: string;
  moveInDate: string | Date;
  city: string;
  location: string;
  bedrooms: string;
  rentPrice: string;
  issues: CampaignIssues[];
  amenities: SelectOptionWithLabelString[];
  notes: string;
  contactId: string;
}

export type CampaignFormDefaultValues = Partial<CampaignFormValues>;

// TODO: check if need to be left
export enum ServicePreferences {
  GuidedSearch = 'guided-search',
  SelfSearch = 'self-search',
  Scheduled = 'scheduled',
}

export type CampaignDetails = Omit<
  Campaign,
  'status' | 'contactStyle' | 'buildings' | 'issues' | 'createdAt' | 'servicePreference'
> & {
  issues: string;
  servicePreference: string;
  notes: string;
};

export enum AdditionalServices {
  RapidSearch = 'Rapid search',
  FirstTimeRenting = 'First-time renting',
  PayForMyApplicationFee = 'Pay for my application fee',
  SecondChance = 'Second chance',
}

export type CampaignNotesFormValues = {
  notes: string;
};
