import { format, isValid, isToday } from 'date-fns';

import { DATE_FORMAT_WITH_TIME_AND_COMMA } from 'constants/index';

export function getFormattedDateLabel(date: Date): string {
  if (!isValid(date)) {
    return 'Invalid date';
  }

  if (!isToday(date)) {
    return format(date, DATE_FORMAT_WITH_TIME_AND_COMMA);
  }

  const msPassed = new Date().getTime() - date.getTime();

  if (msPassed > 3_600_000) {
    const hoursPassed = Math.floor(msPassed / 3_600_000);
    const hoursLabel = hoursPassed === 1 ? 'hour' : 'hours';

    return `${hoursPassed} ${hoursLabel} ago`;
  }

  if (msPassed > 60_000) {
    const minsPassed = Math.floor(msPassed / 60_000);
    const minsLabel = minsPassed === 1 ? 'min' : 'mins';

    return `${minsPassed} ${minsLabel} ago`;
  }

  return `Less than a minute ago`;
}
