import inNil from 'lodash/isNil';

import { CampaignEditRequest } from 'api/types';
import { CampaignFormValues } from 'types';
import { getFormattedDateISOString, getStringFromOptionsArray, removeExtraSpaces } from 'utils';

export function editCampaignInputAdapter({
  moveInDate,
  city,
  rentPrice,
  campaignName,
  location,
  bedrooms,
  amenities,
  issues,
  notes,
}: Partial<CampaignFormValues>): CampaignEditRequest {
  const date = new Date(String(moveInDate));

  return {
    ...(city && { city }),
    ...(inNil(rentPrice) ? undefined : { rentPrice: Number(rentPrice) }),
    ...(campaignName && { title: removeExtraSpaces(campaignName) }),
    ...(!inNil(location) && { location: removeExtraSpaces(location) }),
    ...(bedrooms && { bedroomsCount: bedrooms }),
    ...(amenities && { amenities: getStringFromOptionsArray(amenities) }),
    ...(inNil(notes) ? undefined : { notes: removeExtraSpaces(notes) }),
    ...(issues && { issues }),
    ...(moveInDate && { moveInDate: getFormattedDateISOString(date) }),
  };
}
