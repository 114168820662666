import { DialogContent, DialogContentText, DialogTitle, styled } from '@mui/material';

import { Button } from 'components';

export const DialogTitleW = styled(DialogTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 376px;
  padding: 14px 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

export const DialogContentW = styled(DialogContent)`
  padding: 8px 20px;
`;

export const DialogContentTextW = styled(DialogContentText)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.black.light,
}));

const ButtonW = styled(Button)`
  font-weight: 600;
  font-size: 14px;
`;

export const CancelBtn = styled(ButtonW)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const ConfirmBtn = styled(ButtonW, {
  shouldForwardProp: (propname) => propname !== 'highlightSubmitColor' && propname !== 'highlight',
})<{
  highlight?: boolean;
  highlightSubmitColor: 'error' | 'success';
}>(({ theme, highlight, highlightSubmitColor }) => ({
  color: highlight ? theme.palette[highlightSubmitColor].main : theme.palette.black.main,
}));
