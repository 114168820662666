import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { Box, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const CardWrapper = styled(Box, {
  shouldForwardProp: (propname) => propname !== 'expanded',
})<{ filled: boolean }>(({ filled, theme }) => ({
  width: '725px',
  padding: '16px 0 0 24px',
  borderBottom: `1px solid ${theme.palette.secondary.p020}`,
  backgroundColor: filled ? theme.palette.secondary.p005 : theme.palette.white.main,
}));

export const ArrowIcon = styled(ArrowRightRoundedIcon, {
  shouldForwardProp: (propname) => propname !== 'expanded',
})<{ expanded: boolean }>(({ expanded, theme }) => ({
  position: 'absolute',
  top: 'calc(50% - 12px)',
  left: 0,
  transform: `rotate(${expanded ? '90' : '0'}deg)`,
  color: theme.palette.secondary.main,
  cursor: 'pointer',
}));

export const InnerContent = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '20px',
  padding: '0 24px 16px 32px',
}));

export const CardHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
}));

export const Name = styled(Typography)(({ theme }) => ({
  width: '160px',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.black.main,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',
}));
