import { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { MessageRequestDirection } from 'api/types';
import { loadMessages } from 'features/communications';
import { getSourcesParams } from 'features/communications/utils';
import { useDispatch } from 'hooks';
import { Message } from 'types';

interface UseGetCommunicationMessagesProps {
  targetMessageId: string | null;
  enabled?: boolean;
  onSuccessCb?: () => void;
  sources?: string | null;
  contactId: string;
  inViewLastItem?: boolean;
  inViewFirstItem?: boolean;
}

interface UseGetCommunicationMessagesReturn {
  communicationMessages: Message[];
  isLoadingMessages: boolean;
}

export function useGetCommunicationMessages({
  targetMessageId,
  enabled = true,
  onSuccessCb,
  sources = null,
  contactId,
  inViewLastItem,
  inViewFirstItem,
}: UseGetCommunicationMessagesProps): UseGetCommunicationMessagesReturn {
  const dispatch = useDispatch();

  const {
    data: communicationData,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
  } = useInfiniteQuery(
    [`communication/${contactId}`, sources || 'ALL', targetMessageId],
    ({ pageParam = undefined }) =>
      API_HANDLERS.COMMUNICATIONS.GET_MESSAGES({
        contactId,
        ...((pageParam || targetMessageId) && {
          targetMessageId: pageParam.targetMessageId || targetMessageId,
          direction: pageParam.direction,
        }),
        sources: getSourcesParams(sources),
      }),
    {
      enabled,
      cacheTime: 0,
      onSuccess: (resp) => {
        dispatch(
          loadMessages({
            messages: resp.pages.map((page) => page.data.messages).flat() || [],
            sources,
            targetMessageId: null,
          }),
        );
        onSuccessCb?.();
      },
      getNextPageParam: (lastPage) => {
        return lastPage.data.messages.length === 20
          ? {
              targetMessageId: lastPage.data.messages[lastPage.data.messages.length - 1].id,
              direction: MessageRequestDirection.DOWN,
            }
          : undefined;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.data.messages.length === 20
          ? {
              targetMessageId: firstPage.data.messages[0].id,
              direction: MessageRequestDirection.UP,
            }
          : undefined;
      },
    },
  );

  useEffect(() => {
    if (inViewLastItem && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, inViewLastItem, hasNextPage]);

  useEffect(() => {
    if (inViewFirstItem && hasPreviousPage) {
      fetchPreviousPage();
    }
  }, [fetchPreviousPage, inViewFirstItem, hasPreviousPage]);

  return {
    communicationMessages: communicationData?.pages.map((page) => page.data.messages).flat() || [],
    isLoadingMessages: isLoading || isFetchingNextPage || isFetchingPreviousPage,
  };
}
