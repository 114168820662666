import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { ReactElement } from 'react';

import { InputAdornment } from './styled';

interface GetMultilinePropsParams {
  onClick: () => void;
  disabled?: boolean;
  value?: unknown;
}

interface MultilineProps {
  maxRows: number;
  minRows: number;
  InputProps: {
    endAdornment?: ReactElement;
  };
}

export const getMultilineProps = ({
  onClick,
  disabled = false,
  value,
}: GetMultilinePropsParams): MultilineProps => ({
  maxRows: 5,
  minRows: 5,
  InputProps: {
    endAdornment: value ? (
      <InputAdornment position="end" onClick={disabled ? undefined : onClick}>
        <CancelRoundedIcon sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }} />
      </InputAdornment>
    ) : undefined,
  },
});
