import { useRef, ReactElement } from 'react';

import { ScrollContainerContext } from 'context';
import { InViewItemRef, Message, MessageType, ReplyToEmailParams } from 'types';

import { CommunicationItem } from '../CommunicationItem';
import { SystemMessageItem } from '../SystemMessageItem';
import { MessagesW } from './styled';
import { canScrollToFirstUnread } from './utils';

interface CommunicationsListProps {
  messages: Message[];
  handleReplyToEmail: (data: ReplyToEmailParams) => void;
  contactId: string;
  lastItemRef: InViewItemRef;
  firstItemRef: InViewItemRef;
  readMessage: (messageId: string) => void;
}

export function CommunicationsList({
  messages,
  contactId,
  handleReplyToEmail,
  lastItemRef,
  firstItemRef,
  readMessage,
}: CommunicationsListProps): ReactElement {
  const containerRef = useRef<HTMLElement>(null);

  const firstUnreadMessage = messages.find((message) => message.acknowledged === false);

  return (
    <ScrollContainerContext.Provider value={containerRef}>
      <MessagesW ref={containerRef}>
        {messages.map((message, index) => {
          const lastItemR = index === messages.length - 1 ? lastItemRef : undefined;
          const firstItemR = index === 0 ? firstItemRef : undefined;
          const isFirstUnread = firstUnreadMessage?.id === message.id;

          const isAbleToScroll = canScrollToFirstUnread({
            messagesCount: messages.length,
            hasUnreadMessage: Boolean(firstUnreadMessage),
          });

          return message.type === MessageType.SYSTEM_MESSAGE ? (
            <SystemMessageItem
              key={message.id}
              data={message}
              contactId={contactId}
              lastItemRef={lastItemR}
              firstItemRef={firstItemR}
              readMessage={readMessage}
              isFirstUnread={isFirstUnread}
              canScrollToFirstUnread={isAbleToScroll}
            />
          ) : (
            <CommunicationItem
              key={message.id}
              data={message}
              handleReplyToEmail={handleReplyToEmail}
              lastItemRef={lastItemR}
              firstItemRef={firstItemR}
              readMessage={readMessage}
              isFirstUnread={isFirstUnread}
              canScrollToFirstUnread={isAbleToScroll}
            />
          );
        })}
      </MessagesW>
    </ScrollContainerContext.Provider>
  );
}
