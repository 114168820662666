import { ReactElement } from 'react';

import { Form } from 'components';
import { BuildingChatFormValues } from 'types';

import { MessageTypePopper, NewMessageInput } from './components';
import { useNewBuildingChatMessage } from './hooks';
import { ChangeMessageTypeW, NewMessageBlockW } from './styled';

interface BuildingChatMessageFormProps {
  defaultValues: BuildingChatFormValues | undefined;
  buildingId: string;
  contactId: string;
}

export function BuildingChatMessageForm({
  defaultValues,
  buildingId,
  contactId,
}: BuildingChatMessageFormProps): ReactElement {
  const { control, isValid, handleFormSubmit, messageType, handleAddMention } =
    useNewBuildingChatMessage({
      defaultValues,
      buildingId,
      contactId,
    });

  return (
    <Form
      onKeyDown={(event) => {
        if (!event.ctrlKey || event.key !== 'Enter') {
          return;
        }
        handleFormSubmit();
      }}
    >
      <NewMessageBlockW messageType={messageType}>
        <ChangeMessageTypeW>
          <MessageTypePopper control={control} />
        </ChangeMessageTypeW>

        <NewMessageInput
          control={control}
          isValid={isValid}
          messageType={messageType}
          handleAddMention={handleAddMention}
          handleFormSubmit={handleFormSubmit}
        />
      </NewMessageBlockW>
    </Form>
  );
}
