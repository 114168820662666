export const defaultValues = {
  campaignName: '',
  moveInDate: '',
  city: '',
  location: '',
  bedrooms: '',
  rentPrice: '',
  issues: [],
  amenities: [],
  additionalPreferences: [],
  notes: '',
};
