import { ApplyInquiryP, CampaignBuildingP, TourInquiryP } from 'types';
import { getFormattedBuildingAddress } from 'utils';

export function isContains(arr: string[], search: string): boolean {
  const substr = search.toLowerCase();

  return arr.some((value) => value.toLowerCase().includes(substr));
}

function pickInquiryValues(inquiry: TourInquiryP | ApplyInquiryP): string[] {
  return [
    inquiry.notes || '',
    inquiry.pricingAndAvailability || '',
    inquiry.status.name || '',
    inquiry.inquiryType,
    (inquiry as TourInquiryP)?.comment || '',
    (inquiry as TourInquiryP)?.commentResponse || '',
    (inquiry as ApplyInquiryP)?.propertyApplicationUrl || '',
  ];
}

function pickBuildingValues(building: CampaignBuildingP): string[] {
  return [
    building.name,
    getFormattedBuildingAddress(building.address, building.data.city),
    building.notes || '',
  ];
}

export function searchInquiry(
  inquiries: Array<TourInquiryP | ApplyInquiryP>,
  search: string,
): Array<TourInquiryP | ApplyInquiryP> {
  return inquiries.filter((inquiry) => isContains(pickInquiryValues(inquiry), search));
}

export function searchBuildings(
  buildings: CampaignBuildingP[],
  search: string,
): CampaignBuildingP[] {
  return buildings.filter((building) => {
    return isContains(pickBuildingValues(building), search);
  });
}
