import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
interface UseDeleteBuildingReturn {
  handleSubmit: () => void;
}

interface UseDeleteBuildingProps {
  buildingId: string;
  campaignId: string;
  isHardDelete: boolean;
  close: () => void;
}

export function useDeleteBuilding({
  buildingId,
  campaignId,
  isHardDelete,
  close,
}: UseDeleteBuildingProps): UseDeleteBuildingReturn {
  const { mutateAsync: deleteBuildingMutation } = useMutation(API_HANDLERS.BUILDINGS.DISMISS, {
    onSuccess: () => {
      queryClient.invalidateQueries('contact');

      alert.success(`Building ${isHardDelete ? 'removed' : 'updated'} successfully`);
    },
    onError: () => {
      close();
    },
  });

  async function handleSubmit() {
    await deleteBuildingMutation({ buildingId, campaignId });
    close();
  }

  return {
    handleSubmit,
  };
}
