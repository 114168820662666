import TextField, { TextFieldProps } from '@mui/material/TextField';
import { forwardRef, ReactElement, Ref } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import { getMultilineProps } from './config';
import { CssTextField } from './styled';

export type TextInputProps = TextFieldProps & {
  forwardRef?: Ref<HTMLInputElement>;
};

function TextFieldInput({
  label,
  name,
  variant = 'outlined',
  size = 'small',
  type = 'text',
  forwardRef,
  inputProps,
  ...otherProps
}: TextInputProps): ReactElement {
  return (
    <CssTextField
      fullWidth
      label={label}
      variant={variant}
      size={size}
      type={type}
      inputProps={{
        ...inputProps,
      }}
      InputLabelProps={{ style: { fontSize: 14 }, ...otherProps.InputLabelProps }}
      ref={forwardRef}
      {...otherProps}
    />
  );
}

const WrappedTextField = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => (
  <TextField forwardRef={ref} {...props} />
));

type ControlledTextFieldProps<T> = TextFieldProps &
  UseControllerProps<T> & {
    // TODO make a better typing for format
    format?: (v: string) => unknown;
    showAllErrors?: boolean;
  };

export function ControlledTextField<T>({
  control,
  name,
  rules,
  format,
  multiline,
  disabled,
  showAllErrors = false,
  onChange: externalOnChange,
  ...rest
}: ControlledTextFieldProps<T>): ReactElement {
  const {
    field,
    fieldState: { error, isTouched },
  } = useController({ control, name, rules });

  const showError = showAllErrors || isTouched;

  return (
    <TextFieldInput
      {...field}
      onChange={(...args) => {
        field.onChange(format ? format(args[0].target.value) : args[0].target.value);
        externalOnChange?.(...args);
      }}
      error={showError && error ? true : false}
      helperText={showError && error?.message}
      multiline={multiline}
      disabled={disabled}
      {...(multiline &&
        getMultilineProps({ onClick: () => field.onChange(''), disabled, value: field.value }))}
      {...(rest.select
        ? {
            SelectProps: {
              MenuProps: { disablePortal: true },
            },
          }
        : undefined)}
      {...rest}
    />
  );
}

WrappedTextField.displayName = 'TextField';

export default WrappedTextField;

export { TextFieldInput as TextField };
