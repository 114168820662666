import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { Control, useForm } from 'react-hook-form';

import { alert } from 'components';
import { EmployeesContext } from 'context';
import {
  InquiryAssignee,
  AssignInquiryFormValues,
  SelectOption,
  TourInquiry,
  ApplyInquiry,
} from 'types';
import { filterEmployeesByRole } from 'utils';

import { defaultValuesAdapter, employeeOptionsAdapter, formValuesAdapter } from '../adapters';
import { assigneeAllowedRoles } from './constants';
import { useAssignInquiryMutations } from './useAssignInquiryMutations';
import { assignInquiryFormSchema } from './validationSchema';

interface UseAssignInquiryFormReturn {
  contactAssigneeOptions: SelectOption[];
  control: Control<AssignInquiryFormValues>;
  handleOnSubmit: () => Promise<void>;
  disableSubmit: boolean;
  isSubmitting: boolean;
  handleClose: () => void;
}

interface UseAssignInquiryFormProps {
  close: () => void;
  inquiry: TourInquiry | ApplyInquiry;
  assignee: InquiryAssignee;
  isTour: boolean;
}

export function useAssignInquiryForm({
  close,
  inquiry,
  assignee,
  isTour,
}: UseAssignInquiryFormProps): UseAssignInquiryFormReturn {
  const { employeesList } = useContext(EmployeesContext);

  const filteredEmployeesList = filterEmployeesByRole(employeesList, assigneeAllowedRoles);

  const { assignTourMutation, assignApplyInquiryMutation } = useAssignInquiryMutations();

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm<AssignInquiryFormValues>({
    defaultValues: defaultValuesAdapter({ assignee }),
    mode: 'all',
    resolver: yupResolver(assignInquiryFormSchema),
  });

  useEffect(() => {
    const subscription = watch((_values, options) => {
      if (!options.type) return;

      if (options.name === 'team') {
        setValue('assignee', '');
      }
      if (options.name === 'assignee') {
        setValue('team', '');
      }
    });

    return subscription.unsubscribe;
  }, [setValue, watch]);

  const disableSubmit = !isValid || !isDirty || isSubmitting;

  const onSubmit = async ({ assignee, team }: AssignInquiryFormValues) => {
    let newAssignee;

    if (isTour) {
      newAssignee = await assignTourMutation({
        inquiryId: inquiry.id,
        assignee: formValuesAdapter({ assignee, team }),
      });
    } else {
      newAssignee = await assignApplyInquiryMutation({
        inquiryId: inquiry.id,
        assignee: formValuesAdapter({ assignee, team }),
      });
    }

    if (newAssignee) {
      close();
      alert.success('Inquiry successfully assigned');

      reset({ assignee: newAssignee.data.id, team: '' });
    }
  };

  const handleClose = (): void => {
    close();
    reset(defaultValuesAdapter({ assignee }));
  };

  const handleOnSubmit = handleSubmit((values) => onSubmit(values));

  return {
    control,
    contactAssigneeOptions: employeeOptionsAdapter({
      employeesList: filteredEmployeesList,
      assignee,
    }),
    handleOnSubmit,
    disableSubmit,
    isSubmitting,
    handleClose,
  };
}
