export enum SettingsTabsValues {
  FILTERS = 'filters',
  INTEGRATIONS = 'integrations',
  TEMPLATES = 'templates',
}

export const SettingsTabs = [
  { label: 'Filter presets', value: SettingsTabsValues.FILTERS },
  { label: 'Integrations', value: SettingsTabsValues.INTEGRATIONS },
  { label: 'Message templates', value: SettingsTabsValues.TEMPLATES },
];

export const QUERY_SETTINGS_KEYS = ['tab', 'page', 'limit', 'emailConfig'];
