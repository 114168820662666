import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Control, useForm } from 'react-hook-form';

import { useValidationTrigger } from 'hooks/useValidationTrigger';
import { ContactFormDefaultValues, ContactFormValues } from 'types';

import { contactFormSchema } from './validationSchema';

interface UseContactFormReturn {
  handleOnSubmit: () => Promise<void>;
  control: Control<ContactFormValues>;
  disableSubmit: boolean;
  isSubmitting: boolean;
  destroyForm: () => void;
  withConfirmClose: boolean;
}

interface UseContactFormProps {
  defaultValues: ContactFormDefaultValues;
  onSubmit: (values: ContactFormValues) => Promise<void>;
  close: () => void;
  isEditMode: boolean;
}

export function useContactForm({
  defaultValues,
  onSubmit,
  close,
  isEditMode,
}: UseContactFormProps): UseContactFormReturn {
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting },
    reset,
    trigger,
  } = useForm<ContactFormValues>({
    resolver: yupResolver(contactFormSchema),
    defaultValues,
    mode: 'all',
  });

  useValidationTrigger(trigger, isEditMode, defaultValues);

  const withConfirmClose = isEditMode && isDirty;

  const destroyForm = () => {
    close();
    reset(defaultValues);
  };

  useEffect(() => {
    if (!isEditMode) {
      return;
    }
    reset(defaultValues);
  }, [defaultValues, isEditMode, reset]);

  const disableSubmit = !isValid || !isDirty || isSubmitting;

  const handleOnSubmit = handleSubmit((values) =>
    onSubmit(values).then(() => reset(defaultValues)),
  );

  return {
    control,
    handleOnSubmit,
    disableSubmit,
    isSubmitting,
    destroyForm,
    withConfirmClose,
  };
}
