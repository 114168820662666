import { Box, ClickAwayListener } from '@mui/material';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import { DropdownMenu, ConfirmModal } from 'components';
import { CampaignStatus, CampaignStatusMap, CloseCampaignReasons } from 'types';

import { StatusBlock } from '../CampaignStatusBlock';
import { CloseCampaignReasonsSelect } from '../CloseCampaignReasonsSelect';
import { closeCampaignModalConfig } from './config';
import { useCampaignStatusSwitch } from './hooks';

interface CampaignStatusSwitchProps {
  campaignId: string;
  status: CampaignStatus | CloseCampaignReasons;
}

function CampaignStatusSwitch({ campaignId, status }: CampaignStatusSwitchProps): ReactElement {
  const {
    displayStatus,
    isReadOnlyAccess,
    isCampaignClosed,
    isAvailableStatusesLoading,
    isAvailableStatusesFetching,
    campaignStatusList,
    closeCampaignReasonsList,
    showStatusesOptions,
    isCloseCampaignModalOpen,
    handleStatusButtonClick,
    handleClickAway,
    handleStatusItemClick,
    handleChangeCampaignStatus,
    handleCloseCloseCampaignModal,
  } = useCampaignStatusSwitch({ status, campaignId });

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<{ closeReason: CloseCampaignReasons }>({
    mode: 'all',
  });

  const onSubmit = handleSubmit(({ closeReason }) => {
    handleChangeCampaignStatus(closeReason);
    handleCloseCloseCampaignModal();
  });

  return (
    <Box style={{ position: 'relative' }}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <StatusBlock
            status={displayStatus}
            onClick={handleStatusButtonClick}
            loading={isAvailableStatusesLoading || isAvailableStatusesFetching}
            isDisabled={isReadOnlyAccess || isCampaignClosed}
            disableRipple={isReadOnlyAccess || isCampaignClosed}
            withIcon={!isCampaignClosed}
          >
            {CampaignStatusMap[displayStatus]}
          </StatusBlock>
        </div>
      </ClickAwayListener>

      {showStatusesOptions && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <DropdownMenu
              menuItems={campaignStatusList.map((status) => ({
                id: status,
                label: CampaignStatusMap[status],
                onClick: () => handleStatusItemClick(status),
              }))}
              handleClose={handleClickAway}
              minWidth="max-content"
              right="0"
            />
          </div>
        </ClickAwayListener>
      )}

      {isCloseCampaignModalOpen && (
        <ConfirmModal
          title={closeCampaignModalConfig.title}
          cancelText={closeCampaignModalConfig.cancelText}
          confirmText={closeCampaignModalConfig.confirmText}
          content={
            <CloseCampaignReasonsSelect closeReasons={closeCampaignReasonsList} control={control} />
          }
          onClose={handleCloseCloseCampaignModal}
          onSubmit={onSubmit}
          highlightSubmit
          withCloseIcon
          isSubmitDisabled={!isValid || isSubmitting}
        />
      )}
    </Box>
  );
}

export default CampaignStatusSwitch;
