import { ReactElement, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { Links } from 'settings';
import { SystemMessageEvent, SystemMessageType } from 'types';
import {
  handleSelectBuilding,
  handleSelectCampaign,
  handleSelectInquiry,
  openPageInNewTab,
} from 'utils';

import { ApplicationRequestedContent } from './ApplicationRequestedContent';
import { ApplicationStatusChangedContent } from './ApplicationStatusChangedContent';
import { AssigneeChangedContent } from './AssigneeChangedContent';
import { BuildingDismissedContent } from './BuildingDismissedContent';
import { CampaignClosedContent } from './CampaignClosed';
import { CampaignStatusChangedContent } from './CampaignStatusChangedContent';
import { CampaignUpdatedContent } from './CampaignUpdatedContent';
import { ContactOwnerChangedContent } from './ContactOwnerChangedContent';
import { ListSentContent } from './ListSentContent';
import { NewCampaignCreatedContent } from './NewCampaignCreatedContent';
import { NewContactCreatedContent } from './NewContactCreatedContent';
import { TourRequestedContent } from './TourRequestedContent';
import { TourStatusChangedContent } from './TourStatusChangedContent';

interface SystemMessageContentProps {
  message: SystemMessageType;
  contactId?: string;
}

export function SystemMessageContent({
  message,
  contactId,
}: SystemMessageContentProps): ReactElement | null {
  const { pathname } = useLocation();
  const isLinkToPage = pathname === Links.dashboard.index;

  const { eventType } = message.payload;

  const onCampaignClick = useCallback(
    (campaignId: string) => {
      if (isLinkToPage && !!contactId) {
        openPageInNewTab(`/contacts/${contactId}?campaignId=${campaignId}`);
      } else {
        handleSelectCampaign(campaignId);
      }

      blur();
    },
    [contactId, isLinkToPage],
  );

  const onBuildingClick = useCallback(
    (buildingId: string, campaignId?: string) => {
      if (isLinkToPage && !!contactId) {
        openPageInNewTab(
          `/contacts/${contactId}?campaignId=${campaignId}&buildingId=${buildingId}`,
        );
      } else {
        handleSelectBuilding(buildingId, campaignId);
      }

      blur();
    },
    [contactId, isLinkToPage],
  );

  const onInquiryClick = useCallback(
    (inquiryId: string, buildingId?: string, campaignId?: string) => {
      if (isLinkToPage && !!contactId) {
        openPageInNewTab(
          `/contacts/${contactId}?campaignId=${campaignId}&buildingId=${buildingId}&inquiryId=${inquiryId}`,
        );
      } else {
        handleSelectInquiry(inquiryId, buildingId, campaignId);
      }

      blur();
    },
    [contactId, isLinkToPage],
  );

  switch (eventType) {
    case SystemMessageEvent.NEW_CAMPAIGN_CREATED:
      return (
        <NewCampaignCreatedContent
          data={message.payload.eventPayload}
          onCampaignClick={onCampaignClick}
        />
      );

    case SystemMessageEvent.CAMPAIGN_INFORMATION_UPDATED:
      return (
        <CampaignUpdatedContent
          data={message.payload.eventPayload}
          onCampaignClick={onCampaignClick}
        />
      );

    case SystemMessageEvent.NEW_CONTACT_CREATED:
      return <NewContactCreatedContent data={message.payload.eventPayload} />;

    case SystemMessageEvent.CONTACT_OWNER_CHANGED:
      return <ContactOwnerChangedContent data={message.payload.eventPayload} />;

    case SystemMessageEvent.CONTACT_ASSIGNEE_CHANGED:
      return <AssigneeChangedContent data={message.payload.eventPayload} />;

    case SystemMessageEvent.APPLICATION_REQUESTED:
      return (
        <ApplicationRequestedContent
          data={message.payload.eventPayload}
          onInquiryClick={onInquiryClick}
          onBuildingClick={onBuildingClick}
          onCampaignClick={onCampaignClick}
        />
      );

    case SystemMessageEvent.APPLICATION_STATUS_CHANGED:
      return (
        <ApplicationStatusChangedContent
          data={message.payload.eventPayload}
          onInquiryClick={onInquiryClick}
          onBuildingClick={onBuildingClick}
          onCampaignClick={onCampaignClick}
        />
      );

    case SystemMessageEvent.TOUR_REQUESTED:
      return (
        <TourRequestedContent
          data={message.payload.eventPayload}
          onInquiryClick={onInquiryClick}
          onBuildingClick={onBuildingClick}
          onCampaignClick={onCampaignClick}
        />
      );

    case SystemMessageEvent.TOUR_STATUS_CHANGED:
      return (
        <TourStatusChangedContent
          data={message.payload.eventPayload}
          onInquiryClick={onInquiryClick}
          onBuildingClick={onBuildingClick}
          onCampaignClick={onCampaignClick}
        />
      );

    case SystemMessageEvent.LIST_SENT:
      return (
        <ListSentContent data={message.payload.eventPayload} onCampaignClick={onCampaignClick} />
      );

    case SystemMessageEvent.BUILDING_DISMISSED:
      return (
        <BuildingDismissedContent
          data={message.payload.eventPayload}
          onBuildingClick={onBuildingClick}
          onCampaignClick={onCampaignClick}
          isLinkToPage={isLinkToPage}
        />
      );

    case SystemMessageEvent.CAMPAIGN_STATUS_CHANGED:
      return (
        <CampaignStatusChangedContent
          data={message.payload.eventPayload}
          onCampaignClick={onCampaignClick}
        />
      );

    case SystemMessageEvent.CAMPAIGN_CLOSED:
      return (
        <CampaignClosedContent
          data={message.payload.eventPayload}
          onCampaignClick={onCampaignClick}
        />
      );

    default:
      return <span>Unknown system message type</span>;
  }
}
