import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { API_HANDLERS } from 'api/apiHandlers';
import { useDispatch } from 'hooks';
import { WishlistBuilding } from 'types';

import { setWishlistBuildings } from '../../../context';

interface UseWishlistReturn {
  wishlistBuildings: WishlistBuilding[];
  isWishlistLoading: boolean;
}

export function useWishlist(): UseWishlistReturn {
  const dispatch = useDispatch();

  const { contactId = '' } = useParams<string>();

  const { data: wishlist, isLoading } = useQuery(
    ['wishlist', contactId],
    () => API_HANDLERS.WISHLIST.GET(contactId),
    {
      onSuccess: (res) => {
        if (res.data.buildings.length > 0) {
          dispatch(setWishlistBuildings({ buildings: res.data.buildings }));
        }
      },
    },
  );

  return {
    wishlistBuildings: wishlist?.data.buildings || [],
    isWishlistLoading: isLoading,
  };
}
