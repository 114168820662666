import { Link as LinkMUI, List, ListItem, styled } from '@mui/material';

export const Link = styled(LinkMUI)(() => ({
  cursor: 'pointer',
}));

export const TextBold = styled('span')(() => ({
  fontWeight: 600,
  fontStyle: 'italic',
}));

export const CampaignUpdatedList = styled(List)(() => ({
  padding: 0,
  marginTop: '4px',
}));

export const CampaignUpdatedItem = styled(ListItem)(() => ({
  display: 'block',
  padding: 0,
  marginBottom: '4px',
}));
