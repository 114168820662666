import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { FilterSelectController, SearchField } from 'components';
import { ApplyTable } from 'features/applyTable/components';
import { useApplyTable } from 'features/applyTable/hooks';

import { PageTitle, PageWrapper, SearchAndFilterBar, SearchWrapper } from './styled';

export function ApplyTablePage(): ReactElement {
  const {
    applyInquiries,
    isApplyInquiriesLoading,
    applyInquiriesTotal,
    emptyState,
    currentPage,
    pageLimit,
    setCurrentPage,
    setPageLimit,
    search,
    onSetSearch,
    onClearSearch,
    applyAssigneeFilterControl,
    applyAssigneeOptions,
    assigneeDisplayValue,
    applyStatusFilterControl,
    applyStatusOptions,
    statusDisplayValue,
  } = useApplyTable();

  return (
    <PageWrapper>
      <Helmet>
        <title>Apply table</title>
      </Helmet>

      <PageTitle variant="h4">Active applications</PageTitle>

      <SearchAndFilterBar>
        <SearchWrapper>
          <SearchField value={search} onChange={onSetSearch} onClear={onClearSearch} />
        </SearchWrapper>

        <FilterSelectController
          control={applyAssigneeFilterControl}
          name="assigneeId"
          placeholder="Assignee"
          label="Assignee"
          options={applyAssigneeOptions}
          id="apply-assignee-filter"
          width="160px"
          labelWidth="72px"
          renderValue={assigneeDisplayValue}
          defaultOptionText="All applications"
        />

        <FilterSelectController
          control={applyStatusFilterControl}
          name="status"
          placeholder="Status"
          label="Status"
          options={applyStatusOptions}
          id="apply-status-filter"
          width="144px"
          labelWidth="56px"
          renderValue={statusDisplayValue}
          defaultOptionText="All applications"
        />
      </SearchAndFilterBar>

      <ApplyTable
        isLoading={isApplyInquiriesLoading}
        applyInquiries={applyInquiries}
        emptyState={emptyState}
        totalCount={applyInquiriesTotal}
        currentPage={currentPage}
        pageLimit={pageLimit}
        setCurrentPage={setCurrentPage}
        setPageLimit={setPageLimit}
      />
    </PageWrapper>
  );
}
