import { useState } from 'react';

export interface ModalControl {
  isOpen: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  handleChange: (isOpen: boolean) => void;
}

export function useModal(): ModalControl {
  const [isOpen, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleChange = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  return {
    isOpen,
    handleOpenModal,
    handleCloseModal,
    handleChange,
  };
}
