import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Avatar, MenuItem, MenuList } from '@mui/material';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Socket } from 'socket.io-client';

import { createSocketConnection } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { WS_STREAMS } from 'api/webSocket/constants';
import { ReactComponent as ActiveApplicationsIcon } from 'assets/icons/activeApplications.svg';
import { ReactComponent as ActiveToursIcon } from 'assets/icons/activeTours.svg';
import { ReactComponent as NavLogoIcon } from 'assets/icons/navLogo.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/users.svg';
import { Badge, ConfirmModal } from 'components';
import Popover from 'components/Popover';
import { Links } from 'settings';
import { WsGlobalNotificationsChannel as WsChannel } from 'types';

import { NavLink } from '../NavLink';
import { useNavigationBar } from './hooks';
import { AdminOnlySection, DividerW, LogoW, NavBar, NavMenu } from './styled';

interface NotificationsCount {
  notificationCount: number;
}

export function NavigationBar(): ReactElement {
  const [notificationsCount, setNotificationsCount] = useState(0);
  const { user, logout, logoutModalControl, isAdmin, isReadOnlyAccess } = useNavigationBar();

  useQuery('notifications-count', () => API_HANDLERS.EMPLOYEES.ME.NOTIFICATIONS_COUNT(), {
    onSuccess: (resp) => {
      setNotificationsCount(resp.data.notificationCount);
    },
  });

  const globalNotificationsSocket = useRef<Socket | null>(null);

  useEffect(() => {
    if (globalNotificationsSocket.current === null) {
      const socket = createSocketConnection({ stream: WS_STREAMS.globalNotifications });

      socket.on(WsChannel.GLOBAL_COUNTER_UPDATED, (data: NotificationsCount) => {
        setNotificationsCount(data.notificationCount);
      });

      globalNotificationsSocket.current = socket;
    }

    return () => {
      globalNotificationsSocket.current?.close();
    };
  }, []);

  return (
    <NavBar>
      <div>
        <LogoW to={Links.contacts.index}>
          <NavLogoIcon />
        </LogoW>
        <NavMenu>
          {!isReadOnlyAccess && (
            <NavLink
              to={Links.dashboard.index}
              icon={
                <Badge badgeContent={notificationsCount} color="error" max={999}>
                  <DashboardIcon />
                </Badge>
              }
            />
          )}
          <NavLink to={Links.tourTable.index} icon={<ActiveToursIcon />} />
          <NavLink to={Links.applyTable.index} icon={<ActiveApplicationsIcon />} />
          <NavLink to={Links.contacts.index} icon={<PeopleOutlinedIcon />} />

          {/* TODO: uncomment when the pages below will be implemented */}
          {/* <NavLink to="/search" icon={<SearchOutlinedIcon />} disabled /> */}
          {/* <NavLink to="/reports" icon={<AssessmentOutlinedIcon />} disabled /> */}

          {isAdmin && (
            <AdminOnlySection>
              <DividerW />
              <NavLink to={Links.userManagement} icon={<UsersIcon />} />
              <NavLink to={Links.settings.index} icon={<SettingsOutlinedIcon />} />
            </AdminOnlySection>
          )}
        </NavMenu>
      </div>

      <Popover
        popupId="user-menu"
        anchor={
          <Avatar
            alt={user?.name}
            src={user?.picture}
            sx={{ width: 32, height: 32, cursor: 'pointer' }}
          />
        }
        sx={{ transform: 'translate(-6px, -4px)' }}
      >
        {() => (
          <MenuList>
            <MenuItem
              onClick={logoutModalControl.handleOpenModal}
              sx={{ typography: 'body2', height: '40px' }}
            >
              Log out
            </MenuItem>
          </MenuList>
        )}
      </Popover>

      {logoutModalControl.isOpen && (
        <ConfirmModal
          onSubmit={logout}
          onClose={logoutModalControl.handleCloseModal}
          title="Log out"
          content="Are you sure you want to log out?"
          confirmText="Log out"
          cancelText="Cancel"
        />
      )}
    </NavBar>
  );
}
