import { ReactElement, ReactNode } from 'react';

import { Title, TitleW } from './styled';

interface MessageHeaderProps {
  title: string;
  icon: ReactNode;
}
export function MessageHeader({ title, icon }: MessageHeaderProps): ReactElement {
  return (
    <TitleW>
      {icon}
      <Title>{title}</Title>
    </TitleW>
  );
}
