import { createContext } from 'react';
export { default as inquiryActionTypes } from './actionTypes';
export * from './actions';

import {
  ApplyInquiry,
  ApplyInquiryP,
  Campaign,
  CampaignBuilding,
  Normalized,
  TourInquiry,
  TourInquiryP,
} from 'types';

export const OpenCreateTourContext = createContext<
  (buildingId: CampaignBuilding['id'], campaignId: Campaign['id']) => void
>(() => {});

export const OpenEditTourContext = createContext<(tour: TourInquiry) => void>(() => {});

export const SelectedInquiryId = createContext<TourInquiry['id'] | ApplyInquiry['id'] | undefined>(
  undefined,
);

export const ToursState = createContext<Normalized<TourInquiryP>>({});

export const ApplyInquiryState = createContext<Normalized<ApplyInquiryP>>({});
