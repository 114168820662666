import { Theme } from '@mui/material';

import { BuildingChatItemType } from 'types';

export function getMessageBgColor(theme: Theme, messageType: BuildingChatItemType): string {
  switch (messageType) {
    case BuildingChatItemType.PRIVATE_NOTES: {
      return theme.palette.warning.p010;
    }

    default:
      return 'inherit';
  }
}
