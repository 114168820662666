import { ReactElement } from 'react';

import { BuildingDetails } from 'features/buildings/components/BuildingDetails/';
import { CampaignBuilding } from 'types';
import { getFormattedBuildingAddress, toCapitalize } from 'utils';

import { BuildingItem, Preview, ImageW } from './styled';

interface SendBuildingListItemProps {
  building: CampaignBuilding;
}

export function SendBuildingListItem({ building }: SendBuildingListItemProps): ReactElement {
  const buildingStatus = building.calculatedStatus || toCapitalize(building.status);

  return (
    <BuildingItem key={building.id} display="flex">
      <Preview previewUrl={building.data.photo}>
        {building.data.photo && <ImageW src={building.data.photo} alt="" />}
      </Preview>
      <BuildingDetails
        status={buildingStatus}
        address={getFormattedBuildingAddress(building.address, building.data.city)}
        name={building.name}
      />
    </BuildingItem>
  );
}
