import { styled } from '@mui/material';

import { Button } from 'components';

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: '6px 14px',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  textTransform: 'none',
  color: theme.palette.black.main,
  '&  path': {
    fill: 'currentColor',
  },
}));
