import { styled, Box } from '@mui/material';

export const CampaignsSectionListW = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  flex: '1 1 auto',
  background: theme.palette.background.paper,
  overflowY: 'auto',
  overflowX: 'hidden',
}));
