import { Divider } from '@mui/material';
import { ReactElement } from 'react';

import { SelectOption } from 'types';

import { FolderOptions } from '../FolderOptions';
import { FilterName, ListW, MenuItemW } from './styled';

interface FilterOptionsListProps {
  close: () => void;
  handleChange: (option: SelectOption<string>) => void;
  groups: { [key: string]: SelectOption<string>[] };
  withNoFolder: SelectOption<string>[];
  value: string;
  isOpen: boolean;
}

export function FilterOptionsList({
  close,
  handleChange,
  groups,
  withNoFolder,
  value,
}: FilterOptionsListProps): ReactElement {
  return (
    <ListW>
      <MenuItemW
        key="emptyValue"
        isSelected={value === ''}
        onClick={() => {
          handleChange({ value: '', label: '' });
          close();
        }}
      >
        <em>No filters</em>
      </MenuItemW>

      {Object.keys(groups)
        .sort((a, b) => a.localeCompare(b))
        .map((group) => (
          <FolderOptions
            key={group}
            onChange={handleChange}
            folderName={group}
            folderOptions={groups[group]}
            close={close}
            value={value}
          />
        ))}

      {withNoFolder.length > 0 && (
        <>
          <Divider />
          {withNoFolder.map((option) => (
            <MenuItemW
              key={option.value}
              isSelected={value === option.value}
              onClick={() => {
                handleChange(option);
                close();
              }}
            >
              <FilterName>{option.label}</FilterName>
            </MenuItemW>
          ))}
        </>
      )}
    </ListW>
  );
}
