import { Box } from '@mui/material';
import { ReactElement, useContext } from 'react';
import { Control } from 'react-hook-form';

import {
  FormGroup,
  RadioButtonsGroup,
  ControlledTextField,
  DatePicker,
  TimePicker,
} from 'components';
import { SelectedBuildingIdState, useSelectBuilding } from 'features/buildings';
import { useAuth } from 'hooks';
import { $FixTypeLater, BuildingStatus } from 'types';

import { cardSentOptions } from './config';
import { FormW } from './styled';

interface InquiryDetailsProps {
  isTour: boolean;
  control: Control<$FixTypeLater>;
  isEditable?: boolean;
}

export function InquiryDetailsFields({
  control,
  isTour,
  isEditable = true,
}: InquiryDetailsProps): ReactElement {
  const buildingId = useContext(SelectedBuildingIdState);
  const selectedBuilding = useSelectBuilding(buildingId || '');

  const { isReadOnlyAccess } = useAuth();

  const isDisabled =
    selectedBuilding?.status === BuildingStatus.DISMISSED || isReadOnlyAccess || !isEditable;

  return (
    <FormW>
      {!isTour && (
        <FormGroup marginbottom={29}>
          <ControlledTextField
            name="propertyApplicationUrl"
            control={control}
            id="propertyApplicationUrl"
            label="Property application URL"
            placeholder="Property application URL"
            showAllErrors
            disabled={isDisabled}
          />
        </FormGroup>
      )}

      <FormGroup>
        <RadioButtonsGroup
          name="guestCardSent"
          control={control}
          label="Guest card sent?"
          options={cardSentOptions}
          isBooleanValues
          disabled={isDisabled}
        />
      </FormGroup>

      {isTour && (
        <FormGroup sx={{ maxWidth: '322px' }} row={true}>
          <Box sx={{ width: '148px' }}>
            <DatePicker
              name="confirmedTourDate"
              label="Confirmed date"
              minDate={new Date()}
              control={control}
              showAllErrors
              disabled={isDisabled}
            />
          </Box>
          <Box sx={{ width: '148px' }}>
            <TimePicker
              name="confirmedTourTime"
              label="Confirmed time"
              control={control}
              showAllErrors
              disabled={isDisabled}
            />
          </Box>
        </FormGroup>
      )}

      <FormGroup>
        <ControlledTextField
          name="pricingAndAvailability"
          multiline
          id="pricingAndAvailability"
          label="Relevant pricing and availability"
          placeholder="Relevant pricing and availability"
          control={control}
          showAllErrors
          disabled={isDisabled}
        />
      </FormGroup>

      {isTour && (
        <FormGroup>
          <ControlledTextField
            name="commentResponse"
            multiline
            id="comment"
            label="Response to lead’s comments"
            placeholder="Response to lead’s comments"
            control={control}
            showAllErrors
            disabled={isDisabled}
          />
        </FormGroup>
      )}

      <FormGroup marginbottom={24}>
        <ControlledTextField
          name="notes"
          multiline
          id="notes"
          label="Notes"
          placeholder="Notes"
          control={control}
          showAllErrors
          disabled={isDisabled}
        />
      </FormGroup>
    </FormW>
  );
}
