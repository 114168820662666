import { Box, Collapse } from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';

import { hasNewMessages } from 'features/dashboard/utils';
import {
  DashboardItem,
  DashboardItemActiveInquiryRequestsItem,
  DashboardItemActiveListRequestItem,
  InViewItemRef,
} from 'types';

import { CardMeta } from '../CardMeta';
import { CardSummary } from '../CardSummary';
import { ExpandedCardContent } from '../ExpandedCardContent';
import { OpenChatButton } from '../OpenChatButton';
import { ArrowIcon, CardWrapper, InnerContent, Name, CardHeader, StyledLink } from './styled';

interface ContactCardProps {
  contact: DashboardItem;
  isChatOpened: boolean;
  handleSelectChat: (contactId: string) => void;
  onToursRequestsClick: (
    requests: DashboardItemActiveInquiryRequestsItem[],
    contactId: string,
  ) => void;
  onApplicationRequestsClick: (
    requests: DashboardItemActiveInquiryRequestsItem[],
    contactId: string,
  ) => void;
  onActiveListRequestsClick: (
    requests: DashboardItemActiveListRequestItem[],
    contactId: string,
  ) => void;
  lastItemRef?: InViewItemRef;
}

export function ContactCard({
  contact,
  isChatOpened,
  handleSelectChat,
  onToursRequestsClick,
  onApplicationRequestsClick,
  onActiveListRequestsClick,
  lastItemRef,
}: ContactCardProps): ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    id,
    counters,
    firstName,
    lastName,
    lastMessage,
    activeApplicationRequests,
    activeListRequests,
    activeTourRequests,
  } = contact;

  const handleToggle = useCallback(() => {
    setIsExpanded((state) => !state);
  }, [setIsExpanded]);

  const handleOpenChat = useCallback(() => {
    handleSelectChat(id);
  }, [handleSelectChat, id]);

  const handleClickToursRequests = useCallback(() => {
    if (activeTourRequests.items.length) {
      onToursRequestsClick(activeTourRequests.items, id);
    }
  }, [activeTourRequests.items, id, onToursRequestsClick]);

  const handleClickApplicationsRequests = useCallback(() => {
    if (activeApplicationRequests.items.length) {
      onApplicationRequestsClick(activeApplicationRequests.items, id);
    }
  }, [activeApplicationRequests.items, id, onApplicationRequestsClick]);

  const handleClickActiveListRequests = useCallback(() => {
    if (activeListRequests.items.length) {
      onActiveListRequestsClick(activeListRequests.items, id);
    }
  }, [activeListRequests.items, id, onActiveListRequestsClick]);

  const withNewMessages = hasNewMessages(counters);

  return (
    <CardWrapper filled={withNewMessages} ref={lastItemRef}>
      <InnerContent>
        <ArrowIcon expanded={isExpanded} onClick={handleToggle} />

        <Box sx={{ overflow: 'hidden' }}>
          <CardHeader>
            <StyledLink to={`/contacts/${id}`} target="_blank">
              <Name variant="h5">
                {firstName} {lastName}
              </Name>
            </StyledLink>
            <CardSummary counters={counters} />
          </CardHeader>

          <CardMeta message={lastMessage} contactId={id} hasNewMessages={withNewMessages} />
        </Box>

        <OpenChatButton isSelected={isChatOpened} onChange={handleOpenChat} />
      </InnerContent>

      <Collapse in={isExpanded}>
        <ExpandedCardContent
          activeApplicationsTotal={activeApplicationRequests.items.length}
          activeApplicationsNotificationsCount={activeApplicationRequests.totalNotificationCount}
          activeListsTotal={activeListRequests.items.length}
          activeListsNotificationCount={activeListRequests.totalNotificationCount}
          activeToursTotal={activeTourRequests.items.length}
          activeToursNotificationCount={activeTourRequests.totalNotificationCount}
          onToursRequestsClick={handleClickToursRequests}
          onApplicationsRequestsClick={handleClickApplicationsRequests}
          onActiveListRequestsClick={handleClickActiveListRequests}
        />
      </Collapse>
    </CardWrapper>
  );
}
