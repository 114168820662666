import { useState } from 'react';

import { useModal } from 'hooks';
import { TourInquiry, TourFormValues } from 'types';

import { editTourModalAdapter } from '../adapters/editTourFormAdapter';

interface UseEditTourModalReturn {
  editTourId?: string;
  isOpen: boolean;
  handleCloseModal: () => void;
  handleOpenModal: (tour: TourInquiry) => void;
  defaultValues?: TourFormValues;
}

export function useEditTourModal(): UseEditTourModalReturn {
  const editTourModalControl = useModal();

  const [editTourId, setEditTourId] = useState<TourInquiry['id']>();
  const [defaultValues, setDefaultValues] = useState<TourFormValues | undefined>();

  const handleOpenModal = (tour: TourInquiry) => {
    setEditTourId(tour.id);
    setDefaultValues(editTourModalAdapter(tour));
    editTourModalControl.handleOpenModal();
  };

  return {
    isOpen: editTourModalControl.isOpen,
    handleCloseModal: editTourModalControl.handleCloseModal,
    handleOpenModal,
    editTourId,
    defaultValues,
  };
}
