export const mentionStyles = {
  control: {
    fontSize: 14,
    fontWeight: 'normal',
    outline: 'none',
    maxHeight: '190px',
  },

  '&multiLine': {
    highlighter: {
      position: 'relative',
      maxHeight: '190px',
      overflowY: 'auto',
      lineHeight: 'normal',
      zIndex: 1,
      color: '#0055D3',
      textShadow: '1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white',
      pointerEvents: 'none',
    },
    input: {
      padding: 0,
      lineHeight: 'normal',
      border: 'none',
      outline: 'none',
      backgroundColor: '#FFF6E8',
      maxHeight: '190px',
      overflowY: 'auto',
    },
  },

  suggestions: {
    list: {
      padding: '8px 0',
      maxHeight: 210,
      overflowY: 'auto',
      backgroundColor: 'white',
      boxShadow: '0px 4px 12px rgba(36, 35, 71, 0.15)',
      fontSize: 14,
      borderRadius: '4px',
      minWidth: '280px',
      width: 'auto',
    },
    item: {
      padding: '8px 16px',
      '&focused': {
        backgroundColor: '#EFF1F2',
      },
    },
  },
};
