export * from './dateTime';
export * from './apiErrors';
export * from './errors';

export const EMPTY_VALUE = '--';
export const EMPTY_VALUE_SHORT = '-';
export const PAGE_LIMIT = 25;
export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [25, 50, 75, 100];
export const DEFAULT_SEARCH_PLACEHOLDER = 'Search';
export const CONTACT_DETAILS_PAGE_SECTIONS = ['chat', 'campaigns', 'infoPanel'];
