import { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { DashboardItems, DashboardTotalCounters } from 'types';

import { DashboardFilterValues } from '../config';

interface UseDashboardQueryReturn {
  isDashboardLoading: boolean;
  isFetchingNextDashboardPage: boolean;
  dashboardList: DashboardItems;
  dashboardTotalCounters: DashboardTotalCounters | undefined;
  isError: boolean;
  contactsCount: number;
}

interface UseDashboardQueryProps {
  search: string;
  source: string;
  inView: boolean;
}

export function useDashboardQuery({
  search,
  source,
  inView,
}: UseDashboardQueryProps): UseDashboardQueryReturn {
  const { data, isLoading, isFetchingNextPage, isError, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      ['dashboard', search, source],
      ({ pageParam = undefined }) =>
        API_HANDLERS.DASHBOARD.GET({
          ...(search && { search }),
          ...(source !== DashboardFilterValues.ALL && { source }),
          ...(pageParam && { targetContactId: pageParam }),
        }),
      {
        getNextPageParam: (lastPage) => {
          return lastPage.data.items.length === 15
            ? lastPage.data.items[lastPage.data.items.length - 1].id
            : undefined;
        },
      },
    );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const dashboardList = data?.pages.map((page) => page.data.items).flat() || [];
  const dashboardTotalCounters = data?.pages[data.pages.length - 1].data.counters;
  const contactsCount = dashboardList.length || 0;

  return {
    isDashboardLoading: isLoading,
    isFetchingNextDashboardPage: isFetchingNextPage,
    dashboardList,
    dashboardTotalCounters,
    isError,
    contactsCount,
  };
}
