import { ReactElement } from 'react';

import { ReactComponent as CampaignIcon } from 'assets/icons/campaignsSummary/activeCampaign.svg';
import { ReactComponent as TourIcon } from 'assets/icons/campaignsSummary/activeTour.svg';
import { Contact } from 'types';

import { ApplyIconW, ItemW, NumberW, SummaryW } from './styled';

enum ContactSummary {
  CAMPAIGN = 'CAMPAIGN',
  TOUR = 'TOUR',
  APPLY = 'APPLY',
}

const summaryIconsVariant: Record<ContactSummary, ReactElement> = {
  [ContactSummary.APPLY]: <ApplyIconW style={{ marginRight: '6px' }} />,
  [ContactSummary.TOUR]: <TourIcon style={{ marginRight: '6px' }} />,
  [ContactSummary.CAMPAIGN]: <CampaignIcon style={{ marginRight: '6px' }} />,
};

export function transformSummaryValues(summary: Contact['summary']): ReactElement {
  return (
    <SummaryW>
      <ItemW>
        {summaryIconsVariant[ContactSummary.CAMPAIGN]} <NumberW>{summary.activeCampaigns}</NumberW>
      </ItemW>
      <ItemW>
        {summaryIconsVariant[ContactSummary.TOUR]} <NumberW>{summary.activeTourInquires}</NumberW>
      </ItemW>
      <ItemW>
        {summaryIconsVariant[ContactSummary.APPLY]} <NumberW>{summary.activeApplyInquires}</NumberW>
      </ItemW>
    </SummaryW>
  );
}
