import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export const Td = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    borderColor: '#E0E4E5',
    padding: '5px 10px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '19px',
    height: 40,
  },
}));

export const Th = styled(Td)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    padding: '5px 10px',
    maxWidth: 'auto',
    lineHeight: '20px',
    height: 40,
  },
}));

export const DataW = styled('span')`
  cursor: text;
`;
