import { DialogActions, MenuItem, Typography } from '@mui/material';
import { ReactElement } from 'react';

import { AutocompleteField, ControlledTextField, Form, FormGroup } from 'components';
import { OptionItem } from 'components/DropdownMenu/styled';
import { theme } from 'styles';
import { ApplyInquiry, EmployeeRole, InquiryAssignee, TourInquiry } from 'types';

import { teamsOptionsAdapter } from './adapters';
import { useTeamsList, useAssignInquiryForm } from './hooks';
import { CancelBtn, ConfirmBtn, DialogTitleW, DialogW, OptionW } from './styled';
import { filterTeams } from './utils';

export interface SimpleDialogProps {
  onClose: () => void;
  inquiry: TourInquiry | ApplyInquiry;
  assignee: InquiryAssignee;
  isTour: boolean;
}

export function AssignInquiryForm({
  onClose,
  inquiry,
  assignee,
  isTour,
}: SimpleDialogProps): ReactElement {
  const { teams } = useTeamsList();

  const filteredTeamsList = filterTeams(teams, [
    EmployeeRole.LIGHTKEEPERS,
    EmployeeRole.SEARCHLIGHTS,
  ]);

  const {
    control,
    contactAssigneeOptions,
    handleOnSubmit,
    disableSubmit,
    isSubmitting,
    handleClose,
  } = useAssignInquiryForm({
    close: onClose,
    inquiry,
    assignee,
    isTour,
  });

  return (
    <DialogW open onClose={handleClose}>
      <DialogTitleW>Assign inquiry</DialogTitleW>

      <Form onSubmit={handleOnSubmit}>
        <ControlledTextField
          select
          id="team"
          label="Select group"
          placeholder="Select group"
          control={control}
          name="team"
        >
          {teamsOptionsAdapter(filteredTeamsList).map(({ value, label }) => (
            <MenuItem key={value} value={value} sx={{ fontSize: '14px' }}>
              {label}
            </MenuItem>
          ))}
        </ControlledTextField>

        <Typography fontSize="12px" color={theme.palette.black.light} sx={{ margin: '24px 0' }}>
          Or
        </Typography>

        <FormGroup>
          <AutocompleteField
            name="assignee"
            label="Select assignee"
            placeholder="Select assignee"
            control={control}
            options={contactAssigneeOptions}
            renderOption={(option) => (
              <OptionW isSelected={option.value === assignee.id}>
                <OptionItem>{option.displayLabel}</OptionItem>
              </OptionW>
            )}
            noOptionsText="Couldn't find anyone"
            withClearIcon
            optionsContainerHeight="260px"
          />
        </FormGroup>

        <DialogActions sx={{ padding: 0 }}>
          <CancelBtn onClick={handleClose} variant="text" color="secondary">
            Cancel
          </CancelBtn>
          <ConfirmBtn
            disabled={disableSubmit}
            type="submit"
            variant="text"
            color="secondary"
            loading={isSubmitting}
          >
            Assign
          </ConfirmBtn>
        </DialogActions>
      </Form>
    </DialogW>
  );
}
