import { styled } from '@mui/material';

import { theme } from 'styles';

export const tabStyles = {
  color: theme.palette.secondary.main,
  padding: 0,

  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },

  '&.MuiTab-root': {
    minWidth: '44px',
    borderRadius: '4px',
  },
};

export const tabListStyles = {
  paddingLeft: '24px',
};

export const IconW = styled('div', {
  shouldForwardProp: (propname) => propname !== 'isSelected',
})<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  background: isSelected ? theme.palette.primary.p010 : 'unset',
  borderRadius: '4px',
}));
