import { useContext } from 'react';

import { CampaignBuilding, CampaignBuildingP } from 'types';

import { BuildingsState } from '..';

export function useSelectBuilding(
  buildingId: CampaignBuilding['id'],
): CampaignBuildingP | undefined {
  const buildings = useContext(BuildingsState);

  return buildings.byId[buildingId];
}
