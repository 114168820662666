import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { GetMessageTemplatesParams } from 'api/types';
import { MessageTemplatesList } from 'types';

interface UseMessageTemplatesQueryReturn {
  isTemplatesLoading: boolean;
  isError: boolean;
  templatesList: MessageTemplatesList;
  templatesTotalCount: number;
}

export function useMessageTemplatesQuery({
  page,
  limit,
}: GetMessageTemplatesParams): UseMessageTemplatesQueryReturn {
  const {
    data: { data } = {},
    isLoading: isTemplatesListLoading,
    isError,
  } = useQuery(['message-templates', page, limit], () =>
    API_HANDLERS.MESSAGE_TEMPLATES.GET_MESSAGE_TEMPLATES({ page, limit }),
  );

  return {
    isTemplatesLoading: isTemplatesListLoading,
    isError,
    templatesList: data?.items || [],
    templatesTotalCount: data?.meta.totalItems || 0,
  };
}
