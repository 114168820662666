import { NavigateFunction } from 'react-router-dom';

import { httpClient } from 'api/httpClient';
import { alert } from 'components';

interface SetupHttpInterceptorsProps {
  navigate: NavigateFunction;
  logout?: () => void;
}

export const setupHttpInterceptors = ({ navigate, logout }: SetupHttpInterceptorsProps): void => {
  httpClient.interceptors.response.use(undefined, (error) => {
    if (
      !error.status &&
      !error.statusCode &&
      !error.response &&
      error.message === 'Network Error'
    ) {
      console.log('Error: ', error);
      // network error
      alert.error(`No internet connection`);
    }

    if (error.code === 504) {
      alert.error(`Gateway Timeout Error`);
    }

    if (error.response) {
      if (error.response.status === 401 && logout) {
        error.isHandledGlobally = true;
        logout();
        navigate('/login', { state: { unauthorized: true } });
      }
    }
    return Promise.reject(error);
  });
};
