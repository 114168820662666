import { AxiosError } from 'axios';

export function determineIfIsError<T>(
  toBeDetermined: T | AxiosError,
): toBeDetermined is AxiosError {
  if ((toBeDetermined as AxiosError).isAxiosError) {
    return true;
  }
  return false;
}
