import { styled, Box } from '@mui/material';

import { SendButton } from '../SendButton';

export const InputW = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  position: relative;
`;

export const SendButtonW = styled(SendButton)(() => ({
  position: 'absolute',
  bottom: '2px',
  right: '2px',
}));
