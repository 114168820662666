import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';

import { CampaignStatus, ClosedLostCampaignStatus } from 'types';

import { getStatusColors } from '../CampaignStatusSwitch/utils';

interface StatusBlockButtonProps {
  status: CampaignStatus | ClosedLostCampaignStatus;
  isDisabled: boolean;
}

export const StatusBlockButton = styled(LoadingButton, {
  shouldForwardProp: (propname) => propname !== 'isDisabled',
})<StatusBlockButtonProps>(({ status, isDisabled, theme }) => {
  const colors = getStatusColors(status, theme);

  return {
    minWidth: 'auto',
    padding: '4px 8px',
    whiteSpace: 'nowrap',
    pointerEvents: isDisabled ? 'none' : 'auto',
    textTransform: 'none',
    alignItems: 'center',
    fontWeight: 600,
    lineHeight: '16px',
    fontSize: '12px',
    ...colors,
  };
});
