export enum DashboardFilterValues {
  ALL = 'ALL',
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  PRIVATE_NOTES = 'PRIVATE_NOTES',
  BUILDING_CHATS = 'BUILDING_CHATS',
  REQUEST_MESSAGES = 'REQUEST_MESSAGES',
  OTHER_SYSTEM_MESSAGES = 'OTHER_SYSTEM_MESSAGES',
}

export const FiltersTabs = [
  { label: 'ALL', value: DashboardFilterValues.ALL },
  { label: 'SMS', value: DashboardFilterValues.SMS },
  { label: 'EMAIL', value: DashboardFilterValues.EMAIL },
  { label: 'PRIVATE_NOTES', value: DashboardFilterValues.PRIVATE_NOTES },
  { label: 'BUILDING_CHATS', value: DashboardFilterValues.BUILDING_CHATS },
  { label: 'REQUEST_MESSAGES', value: DashboardFilterValues.REQUEST_MESSAGES },
  { label: 'OTHER_SYSTEM_MESSAGES', value: DashboardFilterValues.OTHER_SYSTEM_MESSAGES },
];
