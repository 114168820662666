import { ReactElement } from 'react';

import { Button } from 'components';
import { useAuth, useModal } from 'hooks';

import { ContactForm } from '../ContactForm';
import { useContactCreateForm } from './hooks';
import { checkEmployeePermissions } from './utils';

export function ContactCreateForm(): ReactElement {
  const modalControl = useModal();

  const { defaultValues, handleSubmit } = useContactCreateForm({
    close: modalControl.handleCloseModal,
  });

  const { employee } = useAuth();

  const canEmployeeAddContact = checkEmployeePermissions(employee?.roles || []);

  return (
    <>
      {canEmployeeAddContact && <Button onClick={modalControl.handleOpenModal}>Add contact</Button>}
      <ContactForm
        mode="create"
        title="New contact"
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        close={modalControl.handleCloseModal}
        isOpen={modalControl.isOpen}
      />
    </>
  );
}
