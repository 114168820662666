import { CAMPAIGN_ISSUES } from 'features/campaigns/constants';
import { $FixTypeLater, CampaignIssues } from 'types';
import { getFormattedDate } from 'utils';

export function getDisplayFieldValue(field: string, value: $FixTypeLater): string {
  switch (field) {
    case 'moveInDate':
      return getFormattedDate(new Date(value));

    case 'rentPrice':
      return `$${value}`;

    case 'issues':
      return value.map((issue: CampaignIssues) => CAMPAIGN_ISSUES[issue]).join(', ');

    default:
      return value;
  }
}
