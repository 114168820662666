interface ClickAndHoldProps {
  onPointerDown: () => void;
  onClick: () => void;
  onPointerLeave: () => void;
  onPointerEnter: () => void;
}

export function clickAndHoldListener(onClick: () => void, delay: number): ClickAndHoldProps {
  let isClickAndHold = false;

  let timer: NodeJS.Timeout | undefined;

  const clearTimer = () => {
    timer && clearTimeout(timer);
    isClickAndHold = false;
  };

  const setTimer = () => {
    clearTimer();
    timer = setTimeout(() => {
      isClickAndHold = true;
    }, delay);
  };

  const handleClick = () => {
    if (!isClickAndHold) {
      onClick();
    }

    clearTimer();
  };

  return {
    onPointerDown: setTimer,
    onClick: handleClick,
    onPointerLeave: clearTimer,
    onPointerEnter: () => {
      isClickAndHold = true;
    },
  };
}
