import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Picker, { TimePickerProps as PickerProps } from '@mui/lab/TimePicker';
import { ReactElement, useState } from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';

import { TextField } from 'components';

import { textFieldStyles } from './stylesConfig';

interface TimePickerProps<T = FieldValues>
  extends UseControllerProps<T>,
    Omit<PickerProps, 'onChange' | 'value' | 'renderInput'> {
  label?: string;
  placeholder?: string;
  showAllErrors?: boolean;
  disabled: boolean;
}

export function TimePicker<T>({
  label,
  placeholder,
  name,
  control,
  minTime,
  rules,
  showAllErrors,
  disabled,
  ...timePickerProps
}: TimePickerProps<T>): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, ref, onBlur, value }, fieldState: { error, isTouched } }) => (
          <Picker
            {...timePickerProps}
            disabled={disabled}
            minTime={minTime}
            label={label}
            value={value}
            onChange={onChange}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            renderInput={({ ...otherProps }) => {
              return (
                <TextField
                  {...otherProps}
                  disabled
                  forwardRef={ref}
                  name={name}
                  size="small"
                  onBlur={onBlur}
                  onClick={disabled ? undefined : () => setIsOpen(true)}
                  error={(showAllErrors || isTouched) && error ? true : false}
                  helperText={(showAllErrors || isTouched) && error?.message}
                  placeholder={placeholder}
                  sx={textFieldStyles(disabled)}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  onKeyPress={(e) => {
                    e.preventDefault();
                  }}
                />
              );
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
