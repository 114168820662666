import { ReactElement } from 'react';

import { CloseCampaignReasonsMap, EventPayloadCampaignClosed } from 'types';

import { Link, TextBold } from './styled';

interface CampaignClosedContentProps {
  data: EventPayloadCampaignClosed;
  onCampaignClick: (campaignId: string) => void;
}

export function CampaignClosedContent({
  data,
  onCampaignClick,
}: CampaignClosedContentProps): ReactElement {
  const { campaignId, campaignName, status } = data;

  return (
    <span>
      <Link onClick={() => onCampaignClick(campaignId)}>{campaignName}</Link> was closed with the
      following reason: <TextBold>{CloseCampaignReasonsMap[status]}</TextBold>
    </span>
  );
}
