import { createContext, ReactElement, ReactNode } from 'react';
import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { useAuth } from 'hooks';
import { Employee } from 'types';

interface EmployeesContextProviderProps {
  children: ReactNode;
}

interface EmployeesContextValues {
  employeesContextListError: boolean;
  employeesList: Employee[];
}

const initialState = {
  employeesContextListError: false,
  employeesList: [],
};

export const EmployeesContext = createContext<EmployeesContextValues>(initialState);

export function EmployeesContextProvider({
  children,
}: EmployeesContextProviderProps): ReactElement {
  const { user } = useAuth();

  const { error: employeesContextListError, data: employeesData } = useQuery(
    ['employees'],
    () => API_HANDLERS.EMPLOYEES.GET_ALL(),
    {
      enabled: Boolean(user),
    },
  );

  return (
    <EmployeesContext.Provider
      value={{
        employeesContextListError: Boolean(employeesContextListError),
        employeesList: employeesData?.data.items || [],
      }}
    >
      {children}
    </EmployeesContext.Provider>
  );
}
