import { RegisterOptions } from 'react-hook-form';

import { CAMPAIGN_ISSUES } from 'features/campaigns/constants';
import { CampaignIssues } from 'types';

export const issuesOptions = Object.values(CampaignIssues).map((issue) => ({
  value: issue,
  label: CAMPAIGN_ISSUES[issue],
}));

export const bedroomsOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4+', label: '4+' },
  { value: 'studio', label: 'Studio' },
];

export const requiredGroup: RegisterOptions = {
  validate: (values: string[]) => {
    if (values.length) {
      return;
    }
    return 'Field is required';
  },
};

export const required: RegisterOptions = {
  required: {
    message: 'Field is required',
    value: true,
  },
};
