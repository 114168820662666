import { TableRow } from '@mui/material';
import { ReactElement } from 'react';

import { StatusButton, TableComponents } from 'components';
import { DATE_FORMAT_WITH_TIME_AND_COMMA_SHORT } from 'constants/index';
import { GUEST_CARD_NOT_SENT, GUEST_CARD_SENT } from 'features/applyTable/constants';
import { getContactOwnerTableValue } from 'features/contacts/utils';
import { ApplyInquiryTableItem } from 'types';
import { getFormattedDate } from 'utils';

type ApplyRowProps = ApplyInquiryTableItem;

export function ApplyRow({
  id,
  contact,
  building,
  status,
  guestCardSent,
  user,
  createdAt,
  updatedAt,
}: ApplyRowProps): ReactElement {
  const { Td } = TableComponents;

  return (
    <TableRow key={id}>
      <Td>
        {contact.firstName} {contact.lastName}
      </Td>
      <Td>{building.name}</Td>
      <Td>
        <StatusButton title={status.name} withIcon={false} colorScheme={status.color} isDisabled />
      </Td>
      <Td>{guestCardSent ? GUEST_CARD_SENT : GUEST_CARD_NOT_SENT}</Td>
      <Td>
        {getContactOwnerTableValue({
          picture: user.picture,
          familyName: user.familyName || undefined,
          givenName: user.givenName,
        })}
      </Td>
      <Td>{getFormattedDate(new Date(createdAt), DATE_FORMAT_WITH_TIME_AND_COMMA_SHORT)}</Td>
      <Td>{getFormattedDate(new Date(updatedAt), DATE_FORMAT_WITH_TIME_AND_COMMA_SHORT)}</Td>
    </TableRow>
  );
}
