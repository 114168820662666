import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ListIcon from '@mui/icons-material/List';
import { Divider } from '@mui/material';
import { ReactElement } from 'react';

import { ReactComponent as TourRequests } from 'assets/icons/dashboard/tourRequests.svg';

import { RequestsButton } from '../RequestsButton';
import { Wrapper, ButtonsWrapper } from './styled';

interface ExpandedCardContentProps {
  activeApplicationsTotal: number;
  activeApplicationsNotificationsCount: number;
  activeListsTotal: number;
  activeListsNotificationCount: number;
  activeToursTotal: number;
  activeToursNotificationCount: number;
  onToursRequestsClick: () => void;
  onApplicationsRequestsClick: () => void;
  onActiveListRequestsClick: () => void;
}

export function ExpandedCardContent({
  activeApplicationsTotal,
  activeApplicationsNotificationsCount,
  activeListsTotal,
  activeListsNotificationCount,
  activeToursTotal,
  activeToursNotificationCount,
  onToursRequestsClick,
  onApplicationsRequestsClick,
  onActiveListRequestsClick,
}: ExpandedCardContentProps): ReactElement {
  return (
    <Wrapper>
      <Divider />

      <ButtonsWrapper>
        <RequestsButton
          onClick={onActiveListRequestsClick}
          startIcon={<ListIcon />}
          badgeContent={activeListsNotificationCount}
          disabled={!activeListsTotal && !activeListsNotificationCount}
        >
          Active list requests: {activeListsTotal}
        </RequestsButton>

        <RequestsButton
          onClick={onToursRequestsClick}
          startIcon={<TourRequests />}
          badgeContent={activeToursNotificationCount}
          disabled={!activeToursTotal && !activeToursNotificationCount}
        >
          Tour requests: {activeToursTotal}
        </RequestsButton>

        <RequestsButton
          onClick={onApplicationsRequestsClick}
          startIcon={<AssignmentOutlinedIcon />}
          badgeContent={activeApplicationsNotificationsCount}
          disabled={!activeApplicationsTotal && !activeApplicationsNotificationsCount}
        >
          Application requests: {activeApplicationsTotal}
        </RequestsButton>
      </ButtonsWrapper>
    </Wrapper>
  );
}
