import { ReactElement } from 'react';

import { BuildingSearchCard } from 'features/buildings/components/BuildingListItem/BuildingSearchCard';
import { CampaignBuildingP } from 'types';
import { toCapitalize } from 'utils';

import { BuildingW } from './styled';

interface ListItemProps {
  building: CampaignBuildingP;
  onSelect: () => void;
  campaignName: string;
}

export function ListItem({ building, campaignName, onSelect }: ListItemProps): ReactElement {
  return (
    <BuildingW onClick={onSelect}>
      <BuildingSearchCard
        name={building.name}
        address={building.address}
        city={building.data.city}
        photo={building.data.photo}
        campaignName={campaignName}
        status={building.calculatedStatus || toCapitalize(building.status)}
      />
    </BuildingW>
  );
}
