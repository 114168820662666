import { Theme } from '@mui/material';
import { CSSProperties } from 'react';

import { CampaignStatus, ClosedLostCampaignStatus } from 'types';

type GetStatusColorsReturn = {
  color: CSSProperties['color'];
  background: CSSProperties['background'];
  '& path': {
    fill: CSSProperties['fill'];
  };
  '&:hover': {
    background: CSSProperties['background'];
  };
};

type CampaignStatusColor = 'info' | 'primary' | 'warning' | 'error';

const campaignColorScheme: Record<CampaignStatus | ClosedLostCampaignStatus, CampaignStatusColor> =
  {
    [CampaignStatus.MAKE_A_LIST]: 'info',
    [CampaignStatus.LIST_MADE]: 'primary',
    [CampaignStatus.LIST_SENT]: 'primary',
    [CampaignStatus.ACTIVE_BUILDINGS]: 'primary',
    [CampaignStatus.CLOSED_WON]: 'primary',
    [CampaignStatus.ACTION_REQUIRED]: 'warning',
    [ClosedLostCampaignStatus.CLOSED_LOST]: 'error',
  };

export const getStatusColors = (
  status: CampaignStatus | ClosedLostCampaignStatus,
  theme: Theme,
): GetStatusColorsReturn => {
  const colorScheme = campaignColorScheme[status] || 'primary';

  return {
    color: theme.palette[colorScheme].main,
    background: theme.palette[colorScheme].p010,
    '&:hover': {
      background: theme.palette[colorScheme].p005,
    },
    '& path': {
      fill: 'currentColor',
    },
  };
};
