import { ReactElement } from 'react';

import { ControlledTextField } from 'components';

import { useCampaignNotesForm } from './hooks';

interface ActiveListNotesFormProps {
  campaignId: string;
  notes: string;
}

export function CampaignNotesForm({ campaignId, notes }: ActiveListNotesFormProps): ReactElement {
  const { control } = useCampaignNotesForm({ campaignId, notes });

  return (
    <ControlledTextField
      name="notes"
      control={control}
      multiline
      id="notes"
      label="Notes"
      placeholder="Notes"
      showAllErrors
    />
  );
}
