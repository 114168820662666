import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { ReactElement, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { CommunicationAvatar } from 'components';
import { useAuth } from 'hooks';
import {
  BuildingChatItemType,
  BuildingChatMessage as BuildingChatMessageType,
  InViewItemRef,
} from 'types';

import { BuildingChatMessage } from '../BuildingChatMessage';
import { ListItemW } from './styled';

interface BuildingChatMessageContainerProps {
  message: BuildingChatMessageType;
  buildingName: string;
  lastItemRef?: InViewItemRef;
  readMessage: (messageId: string) => void;
}

export function BuildingChatMessageContainer({
  message,
  buildingName,
  lastItemRef,
  readMessage,
}: BuildingChatMessageContainerProps): ReactElement {
  const { employee } = useAuth();
  const { type, acknowledged, id } = message;

  const sender = type === BuildingChatItemType.INCOMING_MESSAGE ? null : message.payload.sender;
  const isIncomingMsgType = type === BuildingChatItemType.INCOMING_MESSAGE;
  const avatarImage = sender ? sender.photoUrl : '';
  const isReceived = sender?.id !== employee?.id;

  const [inViewRef, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView && !acknowledged) {
      readMessage(id);
    }
  }, [inView, id, readMessage, acknowledged]);

  return (
    <div ref={inViewRef}>
      <ListItemW isReceived={isReceived} ref={lastItemRef}>
        <CommunicationAvatar image={avatarImage}>
          {isIncomingMsgType && <PersonOutlineRoundedIcon fontSize="small" />}

          {isReceived && !avatarImage && (
            <>
              {sender?.firstName && sender?.firstName[0]?.toUpperCase()}
              {sender?.lastName && sender?.lastName[0]?.toUpperCase()}
            </>
          )}
        </CommunicationAvatar>

        <BuildingChatMessage
          message={message}
          isReceived={isReceived}
          buildingName={buildingName}
        />
      </ListItemW>
    </div>
  );
}
