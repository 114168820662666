import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { CampaignEditResponseSuccess, AxiosResponseError, CampaignEditParams } from 'api/types';
import { alert } from 'components';
import { CampaignFormDefaultValues, CampaignFormValues } from 'types';
import { objectsDifferences } from 'utils';

import { editCampaignInputAdapter } from './adapters';

interface UseCampaignEditFormReturn {
  isLoading: boolean;
  handleSubmit: (values: CampaignFormValues) => Promise<void>;
}

interface UseCampaignEditFormProps {
  defaultValues: CampaignFormDefaultValues;
  campaignId: string;
}

export function useCampaignEditForm({
  defaultValues,
  campaignId,
}: UseCampaignEditFormProps): UseCampaignEditFormReturn {
  const { mutateAsync: editCampaignMutation, isLoading } = useMutation<
    AxiosResponse<CampaignEditResponseSuccess>,
    AxiosResponseError,
    CampaignEditParams
  >(({ campaignId, data }: CampaignEditParams) => API_HANDLERS.CAMPAIGN.EDIT(campaignId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries('contact');
    },
  });

  async function handleSubmit(fromValues: CampaignFormValues) {
    const locationValue = fromValues.location ?? '';
    const fromValuesWithLocation = { ...fromValues, location: locationValue };

    const editInput = defaultValues
      ? objectsDifferences(defaultValues, fromValuesWithLocation)
      : fromValues;

    const data = await editCampaignMutation({
      data: editCampaignInputAdapter(editInput),
      campaignId,
    });

    if (data) {
      alert.success('The campaign is edited successfully');
    }
  }

  return {
    handleSubmit,
    isLoading,
  };
}
