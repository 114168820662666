import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { InputAdornment } from '@mui/material';
import { ReactElement } from 'react';

import { DEFAULT_SEARCH_PLACEHOLDER } from 'constants/index';

import { TextField, TextInputProps } from '../TextField';

type SearchFieldProps = Partial<Omit<TextInputProps, 'onChange'>> & {
  value: string;
  placeholder?: string;
  onChange: (search: string) => void;
  onClear: () => void;
};

const textFieldStyles = {
  input: {
    padding: '6px 10px 6px 0',
    color: '#191C1E',
    borderRadius: '4px',
  },
  '.MuiInputBase-root': {
    paddingLeft: '11.5px',
    background: '#EFF1F2',
    color: '#191C1E',
    borderRadius: '4px',
  },
};

const textInputProps = (value: string, onClear: () => void) => ({
  startAdornment: (
    <InputAdornment position="start">
      <SearchRoundedIcon sx={{ color: '#191C1E' }} />
    </InputAdornment>
  ),
  endAdornment: (
    <InputAdornment position="start">
      {value && (
        <CancelRoundedIcon sx={{ color: '#65787E', cursor: 'pointer' }} onClick={onClear} />
      )}
    </InputAdornment>
  ),
  style: { fontSize: 14 },
  disableUnderline: true,
});

export function SearchField({
  value,
  placeholder = DEFAULT_SEARCH_PLACEHOLDER,
  onChange,
  onClear,
  InputProps,
  sx,
  ...rest
}: SearchFieldProps): ReactElement {
  return (
    <TextField
      autoComplete="off"
      variant="standard"
      value={value}
      placeholder={placeholder}
      onChange={({ target: { value } }) => onChange(value)}
      sx={{ ...textFieldStyles, ...sx }}
      InputProps={{ ...textInputProps(value, onClear), ...InputProps }}
      {...rest}
    />
  );
}
