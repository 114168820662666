import { useCallback, useState } from 'react';

import { MessageType, NewMessageFormValues, ReplyToEmailParams } from 'types';

interface UseReplyToEmailModeReturn {
  isReplyMode: boolean;
  defaultValues: NewMessageFormValues | undefined;
  threads: string[] | undefined;
  handleReplyToEmail: (data: ReplyToEmailParams) => void;
  handleResetReplyMode: () => void;
}

export function useReplyToEmailMode(): UseReplyToEmailModeReturn {
  const [defaultValues, setDefaultValues] = useState<NewMessageFormValues | undefined>(undefined);
  const [isReplyMode, setIsReplyMode] = useState<boolean>(false);
  const [threads, setThreads] = useState<string[] | undefined>(undefined);

  const handleReplyToEmail = useCallback(
    ({ id, subject, messageToReplyContent, threads }: ReplyToEmailParams) => {
      const threadsChain = threads
        ? [messageToReplyContent.toString(), ...threads.reverse()]
        : [messageToReplyContent.toString()];

      setThreads(threadsChain);
      setIsReplyMode(true);

      setDefaultValues({
        type: MessageType.OUTGOING_EMAIL,
        content: '',
        subject,
        withCc: false,
        withBcc: false,
        files: [],
        cc: [],
        bcc: [],
        replyTo: id,
      });
    },
    [],
  );

  const handleResetReplyMode = () => {
    setThreads(undefined);
    setIsReplyMode(false);
  };

  return { isReplyMode, defaultValues, threads, handleReplyToEmail, handleResetReplyMode };
}
