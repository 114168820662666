import { ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';

import { useGetCommunicationMessages } from 'api/queries';
import { EmptyState } from 'components';
import { EmptyStateIcons } from 'types';
import { getQueryParams } from 'utils';

import { CommunicationsList, NewMessageForm } from '../../components';
import { QUERY_SEARCH_KEYS } from '../../config';
import { useGetSortedMessages } from '../../hooks';
import { useReplyToEmailMode } from './hooks';

interface GeneralChatProps {
  contactId: string;
  readMessage: (messageId: string) => void;
}

export function GeneralChat({ contactId, readMessage }: GeneralChatProps): ReactElement {
  const { sources } = getQueryParams(QUERY_SEARCH_KEYS);

  const [lastItemRef, inViewLastItem] = useInView();
  const [firstItemRef, inViewFirstItem] = useInView();

  useGetCommunicationMessages({
    targetMessageId: null,
    sources,
    contactId,
    inViewLastItem,
    inViewFirstItem,
  });

  const messages = useGetSortedMessages();

  const { isReplyMode, defaultValues, threads, handleReplyToEmail, handleResetReplyMode } =
    useReplyToEmailMode();

  return (
    <>
      {messages.length ? (
        <CommunicationsList
          messages={messages}
          handleReplyToEmail={handleReplyToEmail}
          contactId={contactId}
          lastItemRef={lastItemRef}
          firstItemRef={firstItemRef}
          readMessage={readMessage}
        />
      ) : (
        <EmptyState
          variant={EmptyStateIcons.EMPTY_MESSAGES}
          description="No messages"
          descriptionSx={{ fontWeight: 550, fontSize: '20px' }}
          iconSx={{ marginBottom: '16px', height: '48px' }}
        />
      )}

      <NewMessageForm
        defaultValues={defaultValues}
        isReplyMode={isReplyMode}
        threads={threads}
        handleResetReplyMode={handleResetReplyMode}
        contactId={contactId}
      />
    </>
  );
}
