import { useQuery } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { InquiryStatus } from 'types';

interface UseTourInquiriesQueryReturn {
  tourInquiriesStates: Pick<InquiryStatus, 'id' | 'name' | 'color' | 'group'>[] | undefined;
  isTourInquiriesStatesLoading: boolean;
  isTourInquiriesStatesError: boolean;
}

export function useTourInquiriesStatesQuery(): UseTourInquiriesQueryReturn {
  const { data, isLoading, isError } = useQuery(['tour-inquiries-states'], () =>
    API_HANDLERS.INQUIRIES.GET_TOUR_INQUIRIES_STATES(),
  );

  return {
    tourInquiriesStates: data?.data.states,
    isTourInquiriesStatesLoading: isLoading,
    isTourInquiriesStatesError: isError,
  };
}
