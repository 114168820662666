import { useCallback, useState } from 'react';

import { unassignedBuildingInquiriesListAdapter } from 'features/unassignedBuildings/adapters';
import {
  UnassignedBuilding,
  UnassignedBuildingApplyInquiry,
  UnassignedBuildingScheduleTourInquiry,
} from 'types';

interface UseUnassignedBuildingItemProps {
  building: UnassignedBuilding;
  onSelect: (id: string) => void;
  handleAddToCampaign: (id: string) => void;
}

interface UseUnassignedBuildingItemReturn {
  isExpanded: boolean;
  inquiries: (UnassignedBuildingApplyInquiry | UnassignedBuildingScheduleTourInquiry)[];
  handleToggle: () => void;
  handleSelectBuilding: () => void;
  handleClickAddToCampaign: () => void;
}

export function useUnassignedBuildingItem({
  building,
  onSelect,
  handleAddToCampaign,
}: UseUnassignedBuildingItemProps): UseUnassignedBuildingItemReturn {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { id, scheduleTourInquiries, applyInquiries, externalId } = building;

  const inquiries = unassignedBuildingInquiriesListAdapter({
    scheduleTourInquiries,
    applyInquiries,
  });

  const handleToggle = useCallback(() => {
    if (!isExpanded) {
      onSelect(id);
    }

    setIsExpanded((state) => !state);
  }, [id, isExpanded, onSelect]);

  const handleSelectBuilding = useCallback(() => {
    onSelect(id);
  }, [id, onSelect]);

  const handleClickAddToCampaign = useCallback(() => {
    handleAddToCampaign(externalId);
  }, [handleAddToCampaign, externalId]);

  return {
    isExpanded,
    inquiries,
    handleToggle,
    handleSelectBuilding,
    handleClickAddToCampaign,
  };
}
