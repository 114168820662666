import { AxiosResponseError } from 'api/types';
import { alert } from 'components';

export function showResponseError(error: AxiosResponseError): void {
  if (Array.isArray(error.response?.data.message)) {
    error.response?.data.message.forEach((err: string) => alert.error(err));
  } else {
    const message = error.response?.data.message || error.response?.data.error;

    if (message) {
      alert.error(message);
    }
  }
}
