import { Box } from '@mui/material';
import { ReactElement } from 'react';

import {
  DashboardItemActiveInquiryRequestsItem,
  DashboardItemActiveListRequestItem,
  DashboardItems,
  InViewItemRef,
} from 'types';

import { ContactCard } from '../ContactCard';

interface CardsListProps {
  contacts: DashboardItems;
  selectedContactChat: string | undefined;
  handleSelectChat: (contactId: string) => void;
  onToursRequestsClick: (
    requests: DashboardItemActiveInquiryRequestsItem[],
    contactId: string,
  ) => void;
  onApplicationRequestsClick: (
    requests: DashboardItemActiveInquiryRequestsItem[],
    contactId: string,
  ) => void;
  onActiveListRequestsClick: (
    requests: DashboardItemActiveListRequestItem[],
    contactId: string,
  ) => void;
  lastItemRef: InViewItemRef;
}

export function CardsList({
  contacts,
  selectedContactChat,
  handleSelectChat,
  onToursRequestsClick,
  onApplicationRequestsClick,
  onActiveListRequestsClick,
  lastItemRef,
}: CardsListProps): ReactElement {
  return (
    <Box>
      {contacts.map((contact, index) => (
        <ContactCard
          key={contact.id}
          contact={contact}
          isChatOpened={contact.id === selectedContactChat}
          handleSelectChat={handleSelectChat}
          onToursRequestsClick={onToursRequestsClick}
          onApplicationRequestsClick={onApplicationRequestsClick}
          onActiveListRequestsClick={onActiveListRequestsClick}
          lastItemRef={index === contacts.length - 1 ? lastItemRef : undefined}
        />
      ))}
    </Box>
  );
}
