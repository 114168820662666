import { ReactElement } from 'react';

import { AutocompleteField, Form, FormGroup } from 'components';
import { OptionItem } from 'components/DropdownMenu/styled';
import { useAuth } from 'hooks';
import { Contact } from 'types';

import { useContactOwnersForm } from './hooks';

interface ContactOwnersProps {
  contact?: Contact;
}

export function ContactOwnersForm({ contact }: ContactOwnersProps): ReactElement {
  const { control, assigneeOptions, ownerOptions } = useContactOwnersForm({
    contact,
  });

  const { isReadOnlyAccess } = useAuth();

  return (
    <Form>
      <FormGroup marginbottom={24}>
        <AutocompleteField
          name="ownerId"
          label="Contact owner"
          placeholder="Select contact owner"
          control={control}
          options={ownerOptions}
          renderOption={(option) => <OptionItem>{option.displayLabel}</OptionItem>}
          noOptionsText="Couldn't find anyone"
          optionsContainerHeight="260px"
          disabled={isReadOnlyAccess}
        />
      </FormGroup>
      <FormGroup marginbottom={24}>
        <AutocompleteField
          name="assigneeId"
          label="Assignee"
          placeholder="Select assignee"
          control={control}
          options={assigneeOptions}
          renderOption={(option) => <OptionItem>{option.displayLabel}</OptionItem>}
          noOptionsText="Couldn't find anyone"
          optionsContainerHeight="260px"
          disabled={isReadOnlyAccess}
        />
      </FormGroup>
    </Form>
  );
}
