import { styled, TableCell, tableCellClasses } from '@mui/material';

import { theme } from 'styles';

const Td = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.black.main,
    borderColor: '#E0E4E5',
    padding: '5px 10px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '19px',
    height: 40,
  },
}));

const Th = styled(Td)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.black.main,
    fontWeight: 600,
    padding: '5px 10px',
    maxWidth: 'auto',
    lineHeight: '20px',
    height: 40,
  },
}));

const TDataW = styled('span')`
  cursor: text;
`;

export const TableComponents = {
  Td,
  Th,
  TDataW,
};
