import MuiTabList from '@mui/lab/TabList';
import { Badge, BadgeProps, Box, Divider, styled, Tab as MuiTab } from '@mui/material';

export const TabList = styled(MuiTabList)(() => ({
  '& .MuiTabs-indicator': {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    height: '4px',
    zIndex: 1000,
  },
}));

export const Tab = styled(MuiTab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: 16,
  color: theme.palette.black.main,

  '&.Mui-selected': {
    color: theme.palette.black.main,
  },

  '&.MuiTab-root': {
    padding: '20px 16px 12px 16px',
    position: 'relative',
  },
}));

export const TabsBottomHighlighter = styled(Box)(({ theme }) => ({
  margin: '0 5px',
  height: '4px',
  background: theme.palette.secondary.p010,
  width: '451px',
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
}));

export const TabsBottomBorder = styled(Divider)(({ theme }) => ({
  position: 'absolute',
  bottom: '0',
  background: theme.palette.secondary.p020,
  width: '100%',
}));

export const TabsWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  paddingRight: '24px',
}));

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  position: 'static',

  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.background.paper}`,
    right: '12px',
    top: '12px',
    padding: '0 4px',
    borderRadius: '4px',
    fontSize: '9px',
    height: '18px',
    minWidth: '18px',
    position: 'absolute',
  },
}));
