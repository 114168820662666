import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { styled, Box } from '@mui/material';

export const AvatarW = styled(Box)`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const AvatarOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  opacity: 0.5,
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  background: theme.palette.primary.main,
}));

export const IconW = styled(CheckRoundedIcon)`
  color: #fff;
  width: 13px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const EmployeeRolesW = styled('span')(({ theme }) => ({
  marginLeft: '4px',
  color: theme.palette.black.light,
}));

export const DisplayLabelText = styled('div')(() => ({
  whiteSpace: 'break-spaces',
  wordBreak: 'break-word',
}));
