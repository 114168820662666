import { MessageSourceType, MessageType } from 'types';

export const QUERY_SEARCH_KEYS = ['sources'];

export const messageSourceValues: MessageSourceType[] = [
  'SMS',
  'EMAIL',
  'PRIVATE_NOTES',
  'SYSTEM_MESSAGE',
];

export const messageSourceLabels: Partial<Record<MessageSourceType, string>> = {
  SMS: 'SMS',
  EMAIL: 'Email',
  PRIVATE_NOTES: 'Private note',
  SYSTEM_MESSAGE: 'System messages',
};

export const messageSourceByType: Record<MessageType, MessageSourceType> = {
  [MessageType.OUTGOING_SMS]: 'SMS',
  [MessageType.INCOMING_SMS]: 'SMS',
  [MessageType.OUTGOING_EMAIL]: 'EMAIL',
  [MessageType.INCOMING_EMAIL]: 'EMAIL',
  [MessageType.PRIVATE_NOTES]: 'PRIVATE_NOTES',
  [MessageType.SYSTEM_MESSAGE]: 'SYSTEM_MESSAGE',
};

export const messageTypesBySource: Partial<Record<MessageSourceType, MessageType[]>> = {
  SMS: [MessageType.INCOMING_SMS, MessageType.OUTGOING_SMS],
  EMAIL: [MessageType.INCOMING_EMAIL, MessageType.OUTGOING_EMAIL],
  PRIVATE_NOTES: [MessageType.PRIVATE_NOTES],
  SYSTEM_MESSAGE: [MessageType.SYSTEM_MESSAGE],
};

export enum ChatTabsValues {
  GENERAL = 'generalChat',
  BUILDINGS = 'buildingsChat',
}
