import { CircularProgress, CircularProgressProps } from '@mui/material';
import { ReactElement } from 'react';

interface ButtonLoaderProps extends CircularProgressProps {
  size: number | string;
}

export const ButtonLoader = ({ size, ...rest }: ButtonLoaderProps): ReactElement => {
  const cssLoaderOffset = `calc(50% - ${(size as number) / 2}px)`;

  return (
    <CircularProgress
      size={size}
      sx={{
        position: 'absolute',
        top: cssLoaderOffset,
        left: cssLoaderOffset,
      }}
      {...rest}
    />
  );
};
