import { ApplyDetailsFormValues, ApplyInquiry, UnassignedBuildingApplyInquiry } from 'types';

export function getApplyDetailsDefaultValues(
  applyInquiry: ApplyInquiry | UnassignedBuildingApplyInquiry,
): Partial<ApplyDetailsFormValues> {
  return {
    propertyApplicationUrl: applyInquiry.propertyApplicationUrl || '',
    guestCardSent: applyInquiry.guestCardSent || false,
    pricingAndAvailability: applyInquiry.pricingAndAvailability || '',
    notes: applyInquiry.notes || '',
  };
}
