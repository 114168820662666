import { ReactElement, ReactNode } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { alert } from 'components';

interface CopyToClipboardButtonProps {
  textToCopy: string;
  children: ReactNode;
}

export function CopyToClipboardButton({
  textToCopy,
  children,
}: CopyToClipboardButtonProps): ReactElement {
  const handleSuccessCopy = () => alert.success('Link copied');

  return (
    <CopyToClipboard text={textToCopy} onCopy={handleSuccessCopy}>
      {children}
    </CopyToClipboard>
  );
}
