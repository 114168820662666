import { Box, TextFieldProps, BoxProps, ClickAwayListener } from '@mui/material';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import { EmptyStateSearch } from 'components';
import { OptionItem, OptionsContainer } from 'components/DropdownMenu/styled';
import { $FixTypeLater, SelectOption } from 'types';

import { TextField } from '..';
import { FormHelperTextW } from '../CheckboxGroup/styles';
import { getInputProps } from './config';
import { OptionW } from './styled';
import { defaultFilterFunction, getIconByValue, getLabelByValue } from './utils';

type AutocompleteFieldProps<T> = TextFieldProps &
  UseControllerProps<T> & {
    showAllErrors?: boolean;
    options: SelectOption[];
    renderOption?: (option: SelectOption) => ReactNode;
    saveInput?: boolean;
    filterFunction?: (option: SelectOption, inputValue: string) => boolean;
    clearable?: boolean;
    wrapProps?: BoxProps;
    noOptionsText?: string;
    withClearIcon?: boolean;
    optionsContainerHeight?: string;
  };

export function AutocompleteField<T>({
  control,
  name,
  rules,
  showAllErrors,
  options,
  renderOption,
  filterFunction = defaultFilterFunction,
  saveInput = false,
  clearable = false,
  wrapProps,
  noOptionsText,
  disabled,
  withClearIcon,
  optionsContainerHeight,
  ...rest
}: AutocompleteFieldProps<T>): ReactElement {
  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error, isTouched },
  } = useController({ control, name, rules });

  const [isFocused, setFocused] = useState(false);
  const [inputLabel, setInputLabel] = useState<$FixTypeLater>(() =>
    getLabelByValue(options, value),
  );
  const [inputIcon, setInputIcon] = useState(() => getIconByValue(options, value));

  const optionsContainerRef = useRef<HTMLInputElement>(null);

  // ref is needed for handling onBlur with setTimeout
  // const valueRef = useRef(value);
  // valueRef.current = value;

  // causes cursor position reset on input in saveInput mode
  useEffect(() => {
    const label = getLabelByValue(options, value);
    setInputLabel(label || (value as $FixTypeLater));
    setInputIcon(getIconByValue(options, value));
  }, [value, options]);

  const inputOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value: onChangeValue } = e.target;

    if (saveInput || (clearable && !onChangeValue?.length)) {
      onChange(onChangeValue);
      setInputLabel(onChangeValue);
    } else {
      setInputLabel(onChangeValue);
    }
  };

  // TODO: remove if no issues with onBlur
  // const inputOnBlur = () => {
  //   if (!saveInput) {
  //     setInputLabel(getLabelByValue(options, valueRef.current));
  //   }

  //   // setFocused(false);
  //   onBlur();
  // };

  const handleSelectOption = (option: SelectOption) => {
    onChange(option.value);
    onBlur();
    setFocused(false);
  };

  const filteredOptions = isFocused
    ? options.filter((option) => filterFunction(option, inputLabel))
    : [];

  const showError = (showAllErrors || isTouched) && !!error;

  const showSuggestions = isFocused && !!filteredOptions.length;

  const showNoOptionsText = isFocused && noOptionsText && !filteredOptions.length;

  return (
    <ClickAwayListener onClickAway={() => setFocused(false)}>
      <Box {...wrapProps}>
        <Box sx={{ position: 'relative' }}>
          <TextField
            fullWidth
            forwardRef={ref}
            value={inputLabel}
            name={name}
            onChange={inputOnChange}
            error={showError ? true : false}
            // onBlur={inputOnBlur}
            onFocus={() => {
              setFocused(true);
            }}
            disabled={disabled}
            InputProps={getInputProps({
              isFocused,
              onChange,
              inputLabel,
              inputIcon,
              disabled,
              withClearIcon,
            })}
            {...rest}
          />

          {showSuggestions && (
            <OptionsContainer maxHeight={optionsContainerHeight} ref={optionsContainerRef}>
              {filteredOptions.map((option) => (
                <Box key={option.value} onClick={() => handleSelectOption(option)}>
                  {renderOption ? (
                    <OptionW isSelected={option.value === value}>{renderOption(option)}</OptionW>
                  ) : (
                    <OptionW isSelected={option.value === value}>
                      <OptionItem>{option.label}</OptionItem>
                    </OptionW>
                  )}
                </Box>
              ))}
            </OptionsContainer>
          )}
          {showNoOptionsText && (
            <OptionsContainer>
              <EmptyStateSearch title={noOptionsText} wrapperSx={{ padding: '3px 15px' }} />
            </OptionsContainer>
          )}
        </Box>
        {showError && <FormHelperTextW error>{error.message}</FormHelperTextW>}
      </Box>
    </ClickAwayListener>
  );
}
