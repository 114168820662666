import { useCallback, useMemo, useState } from 'react';

import { EmptyStateArgs } from 'components';
import { editMessageTemplateAdapter } from 'features/settings/adapters';
import {
  useCreateMessageTemplateMutation,
  useDeleteMessageTemplateMutation,
  useEditMessageTemplateMutation,
  useMessageTemplateByIdQuery,
  useMessageTemplatesQuery,
} from 'features/settings/hooks';
import { useModal } from 'hooks';
import {
  MessageTemplateData,
  EmptyStateIcons,
  MessageTemplatesList,
  MessageTemplateFormData,
  MessageTemplateFormDefaultValues,
} from 'types';

interface UseMessageTemplatesProps {
  currentPage: number;
  pageLimit: number;
}

interface UseMessageTemplatesReturn {
  isCreateFormModalOpen: boolean;
  isEditFormModalOpen: boolean;
  isConfirmDeleteModalOpen: boolean;
  isLoading: boolean;
  emptyState: EmptyStateArgs;
  templatesList: MessageTemplatesList;
  templatesTotalCount: number;
  editTemplateDefaultValues?: MessageTemplateData;
  handleEditTemplateClick: (id: string) => void;
  handleDeleteTemplate: () => void;
  handleCreateTemplateSubmit: (values: MessageTemplateData, onSuccessAction?: () => void) => void;
  handleEditTemplateSubmit: (values: MessageTemplateFormData, onSuccessAction?: () => void) => void;
  handleOpenConfirmDeleteModal: (id: string) => void;
  handleCloseConfirmDeleteModal: () => void;
  handleOpenCreateFormModal: () => void;
  handleCloseCreateFormModal: () => void;
  handleOpenEditFormModal: () => void;
  handleCloseEditFormModal: () => void;
}

export function useMessageTemplates({
  currentPage,
  pageLimit,
}: UseMessageTemplatesProps): UseMessageTemplatesReturn {
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('');

  const {
    isOpen: isCreateFormModalOpen,
    handleOpenModal: handleOpenCreateFormModal,
    handleCloseModal: handleCloseCreateFormModal,
  } = useModal();

  const {
    isOpen: isEditFormModalOpen,
    handleOpenModal: handleOpenEditFormModal,
    handleCloseModal: handleCloseEditFormModal,
  } = useModal();

  const {
    isOpen: isConfirmDeleteModalOpen,
    handleOpenModal: openConfirmDeleteModal,
    handleCloseModal: handleCloseConfirmDeleteModal,
  } = useModal();

  const {
    isTemplatesLoading: isLoading,
    isError,
    templatesList,
    templatesTotalCount,
  } = useMessageTemplatesQuery({ page: currentPage, limit: pageLimit });

  const { handleCreateMessageTemplate } = useCreateMessageTemplateMutation();
  const { handleEditMessageTemplate } = useEditMessageTemplateMutation();
  const { handleDeleteMessageTemplate } = useDeleteMessageTemplateMutation();

  const { templateData } = useMessageTemplateByIdQuery(selectedTemplateId);

  const handleEditTemplateClick = useCallback(
    (id: string) => {
      setSelectedTemplateId(id);
      handleOpenEditFormModal();
    },
    [handleOpenEditFormModal],
  );

  const handleOpenConfirmDeleteModal = useCallback(
    (id: string) => {
      openConfirmDeleteModal();
      setSelectedTemplateId(id);
    },
    [openConfirmDeleteModal],
  );

  const handleDeleteTemplate = useCallback(() => {
    handleDeleteMessageTemplate(selectedTemplateId, handleCloseConfirmDeleteModal);
  }, [handleCloseConfirmDeleteModal, handleDeleteMessageTemplate, selectedTemplateId]);

  const handleCreateTemplateSubmit = useCallback(
    (values, onSuccessAction) => {
      const onSuccess = () => {
        handleCloseCreateFormModal();
        onSuccessAction?.();
      };

      handleCreateMessageTemplate(values, onSuccess);
    },
    [handleCloseCreateFormModal, handleCreateMessageTemplate],
  );

  const handleEditTemplateSubmit = useCallback(
    (values: MessageTemplateFormData | MessageTemplateFormDefaultValues, onSuccessAction) => {
      const data = editMessageTemplateAdapter({ values, id: selectedTemplateId });

      const onSuccess = () => {
        handleCloseEditFormModal();
        onSuccessAction?.();
      };

      handleEditMessageTemplate(data, onSuccess);
    },
    [handleCloseEditFormModal, handleEditMessageTemplate, selectedTemplateId],
  );

  const emptyState: EmptyStateArgs = useMemo(() => {
    if (isError) {
      return {
        shouldAppear: true,
        title: 'Oops, something went wrong',
        description: 'Please try to reload this page',
        variant: EmptyStateIcons.ERROR,
      };
    }

    return {
      shouldAppear: !isLoading && !templatesList.length,
      title: `Message templates will be here`,
      variant: EmptyStateIcons.EMPTY_MESSAGE_TEMPLATES,
    };
  }, [isError, isLoading, templatesList.length]);

  return {
    isCreateFormModalOpen,
    isEditFormModalOpen,
    isConfirmDeleteModalOpen,
    isLoading,
    emptyState,
    templatesList,
    templatesTotalCount,
    editTemplateDefaultValues: templateData,
    handleEditTemplateClick,
    handleDeleteTemplate,
    handleCreateTemplateSubmit,
    handleEditTemplateSubmit,
    handleOpenCreateFormModal,
    handleCloseCreateFormModal,
    handleOpenEditFormModal,
    handleCloseEditFormModal,
    handleOpenConfirmDeleteModal,
    handleCloseConfirmDeleteModal,
  };
}
