import { ReactElement, ReactNode } from 'react';

import { $FixTypeLater, SelectOption } from 'types';

export function defaultFilterFunction(option: SelectOption, inputValue: string): boolean {
  return String(option.label).toLowerCase().includes(inputValue?.toLowerCase());
}

export const getIconByValue = (options: SelectOption[], value: $FixTypeLater): ReactNode | null => {
  const selectedOption = options.find((option) => option.value === value);
  return selectedOption?.icon || null;
};

export const getLabelByValue = (
  options: SelectOption[],
  value: $FixTypeLater,
): string | ReactElement => options.find((option) => option.value === value)?.label || '';
