import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { Box, Divider, styled, Typography } from '@mui/material';

export const WishlistW = styled(Box)`
  position: relative;
  height: 60px;
  padding: 18px 24px 18px 32px;
  cursor: pointer;
`;

export const WishlistTitleW = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  cursor: 'pointer',
  color: theme.palette.black.main,
}));

export const DividerW = styled(Divider)(({ theme }) => ({
  borderWidth: '2px',
  borderColor: theme.palette.secondary.p020,
}));

export const BuildingsCount = styled(Box)(({ theme }) => ({
  minWidth: 'auto',
  padding: '4px 4px 4px 8px',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  gap: '4px',
  textTransform: 'none',
  alignItems: 'center',
  fontWeight: 600,
  lineHeight: '16px',
  fontSize: '12px',
  background: theme.palette.info.p010,
  color: theme.palette.info.main,
  borderRadius: '4px',
}));

export const ArrowIcon = styled(ArrowRightRoundedIcon, {
  shouldForwardProp: (propname) => propname !== 'open',
})<{ open: boolean }>(({ open, theme }) => ({
  position: 'absolute',
  cursor: 'pointer',
  color: theme.palette.secondary.main,
  top: 'calc(50% - 12px)',
  left: '5px',
  transform: `rotate(${open ? '90' : '0'}deg)`,
}));

export const BuildingsListW = styled(Box)(({ theme }) => ({
  cursor: 'pointer',

  borderTop: `1px solid ${theme.palette.secondary.p020}`,
}));
