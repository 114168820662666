import isNil from 'lodash/isNil';
import { ValidationRule } from 'react-hook-form';

import {
  AT_LEAST_TWO_CHARS,
  getMaxLengthText,
  getMinLengthText,
  USE_DATE_FORMAT,
  INVALID_FORMAT,
  ONLY_POSITIVE_DIGITS,
  REQUIRED,
  NAME_PATTER_SYMBOLS,
} from './messages';
import { LETTERS_DIGITS_SYMBOLS, ONLY_DIGITS_NO_ZERO_ON_START_REGEX } from './regexps';
import { isValidDate, onlyFutureDate } from './tests';

export const requiredRule = {
  message: REQUIRED,
  value: true,
};

export const getMaxLengthRule = (length: number): ValidationRule<number> => ({
  value: length,
  message: getMaxLengthText(length),
});

export const getMinLengthRule = (length: number): ValidationRule<number> => ({
  value: length,
  message: getMinLengthText(length),
});

export const getInvalidFormatRule = (regExp: RegExp): ValidationRule<RegExp> => ({
  message: INVALID_FORMAT,
  value: regExp,
});

export const invalidSpecialTextFieldRule = {
  message: LETTERS_DIGITS_SYMBOLS,
  value: NAME_PATTER_SYMBOLS,
};

export const onlyPositiveDigitsRule = {
  message: ONLY_POSITIVE_DIGITS,
  value: ONLY_DIGITS_NO_ZERO_ON_START_REGEX,
};

export const onlyFutureDateRule = (value: Date): string | undefined => {
  if (onlyFutureDate(value)) {
    return undefined;
  }
  return USE_DATE_FORMAT;
};

export const isValidDateRule = (value: Date): string | undefined => {
  if (isValidDate(value)) {
    return undefined;
  }
  return USE_DATE_FORMAT;
};

export const requiredBool = (value: boolean): string | undefined =>
  isNil(value) ? REQUIRED : undefined;

export const atLeast2CharsWithoutSpacesRule = (value: string): string | undefined =>
  value.trim().length < 2 ? AT_LEAST_TWO_CHARS : undefined;
