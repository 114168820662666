import isNil from 'lodash/isNil';

export function getParamsString<T extends Record<string, unknown>>(query: T): string {
  return Object.entries(query).reduce((acc, [key, value]) => {
    if (isNil(value)) {
      return acc;
    }

    return (acc += `&${key}=${value}`);
  }, '?');
}
