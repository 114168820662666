import {
  DialogTitle as DialogTitleMUI,
  Dialog as DialogMUI,
  styled,
  DialogContent as DialogContentMUI,
} from '@mui/material';

export const DialogTitle = styled(DialogTitleMUI)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '14px 20px',
  fontWeight: '600',
  fontSize: '20px',
  lineHeight: '24px',
}));

export const DialogContent = styled(DialogContentMUI)(() => ({
  padding: 0,
  overflowX: 'hidden',
}));

export const Dialog = styled(DialogMUI)(() => ({
  '.MuiDialog-paper': {
    width: '400px',
    maxHeight: '496px',
    overflow: 'hidden',
  },
}));
