import { styled } from '@mui/material';

import { ReactComponent as ArrowDropDownIcon } from 'assets/icons/arrow-drop-down.svg';

export const FilterNameW = styled('div')`
  display: flex;
  justify-content: flex-start;
  gap: 24px;
`;

export const SubmitFormW = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin: 24px 0;
`;

export const FormDivider = styled('div')(({ theme }) => ({
  margin: '24px 0',
  borderBottom: `1px solid ${theme.palette.secondary.p020}`,
}));

export const ArrowIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
  transform: 'scale(1.5)',
  '& .path1': {
    fill: theme.palette.secondary.main,
  },
}));

export const ConditionsW = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 32px;
`;
