import { Table as MuiTable, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Dispatch, ReactElement, SetStateAction } from 'react';

import { EmptyStateArgs, PageStatus, TableComponents, TablePagination } from 'components';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from 'constants/index';
import { MESSAGE_EVENT_LABELS, MESSAGE_TYPE_LABELS } from 'features/settings/config';
import { MessageTemplatesList } from 'types';

import { MoreBlock } from '../MoreBlock';
import { useMessageTemplatesTable } from './hooks';

interface MessageTemplatesTableProps {
  currentPage: number;
  totalCount: number;
  pageLimit: number;
  templates: MessageTemplatesList;
  isLoading: boolean;
  emptyState: EmptyStateArgs;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setPageLimit: Dispatch<SetStateAction<number>>;
  handleEditTemplate: (id: string) => void;
  handleDeleteTemplate: (id: string) => void;
}

export function MessageTemplatesTable({
  currentPage,
  totalCount,
  pageLimit,
  isLoading,
  emptyState,
  templates,
  setCurrentPage,
  setPageLimit,
  handleEditTemplate,
  handleDeleteTemplate,
}: MessageTemplatesTableProps): ReactElement {
  const { Th, Td } = TableComponents;
  const { showPagination, handleChangePage, handleChangeRowsPerPage } = useMessageTemplatesTable({
    totalCount,
    pageLimit,
    setCurrentPage,
    setPageLimit,
  });

  return (
    <PageStatus loading={isLoading} emptyState={emptyState} withCover>
      <TableContainer sx={{ flex: '1 0 auto', height: 'calc(100vh - 284px)' }}>
        <MuiTable aria-label="table" stickyHeader>
          <TableHead>
            <TableRow>
              <Th align="left" style={{ width: 250 }}>
                Template name
              </Th>
              <Th align="left">Type of message</Th>
              <Th align="left">Event</Th>
              <Th align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.map(({ id, name, type, event }) => {
              return (
                <TableRow key={id}>
                  <Td sx={{ width: '20%', maxWidth: '440px' }}>{name}</Td>
                  <Td sx={{ width: '20%', minWidth: '240px' }}>{MESSAGE_TYPE_LABELS[type]}</Td>
                  <Td>{MESSAGE_EVENT_LABELS[event]}</Td>
                  <Td align="right">
                    <MoreBlock
                      menuItems={[
                        {
                          label: 'Edit',
                          key: 'edit',
                          onClick: () => handleEditTemplate(id),
                        },
                        {
                          label: 'Delete',
                          key: 'delete',
                          onClick: () => handleDeleteTemplate(id),
                        },
                      ]}
                    />
                  </Td>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>

      {showPagination && (
        <TablePagination
          rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          count={totalCount}
          rowsPerPage={pageLimit}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </PageStatus>
  );
}
