import { Campaign, CampaignFormDefaultValues } from 'types';
import { getOptionsArrayFromString } from 'utils';

export function campaignDefaultValuesAdapter(campaign?: Campaign): CampaignFormDefaultValues {
  if (!campaign) {
    return {} as CampaignFormDefaultValues;
  }

  return {
    campaignName: campaign.title,
    servicePreference: campaign.servicePreference,
    moveInDate: new Date(campaign.moveInDate),
    city: campaign.city,
    location: campaign.location,
    bedrooms: campaign.bedroomsCount,
    rentPrice: campaign.rentPrice,
    issues: campaign.issues,
    amenities: getOptionsArrayFromString(campaign.amenities),
    notes: campaign.notes,
  };
}
