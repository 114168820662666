import { Box } from '@mui/material';
import { ReactElement, useState } from 'react';

import { ReactComponent as InquiryIcon } from 'assets/icons/inquiry.svg';
import { Avatar } from 'components';
import { DATE_FORMAT_WITH_TIME_AND_COMMA } from 'constants/index';
import { useAuth, useModal } from 'hooks';
import { useScrollQueue } from 'hooks/useScrollQueue';
import { theme } from 'styles';
import {
  $FixTypeLater,
  ApplyInquiry,
  BuildingStatus,
  InquiryStateGroup,
  InquiryType,
  TourInquiry,
} from 'types';
import { getFormattedDateWithTime } from 'utils';

import { ApplicationDetails } from '../ApplicationDetails';
import { AssignInquiryForm } from '../AssignInquiryForm';
import { InquiryStatusBlock } from '../InquiryStatusBlock';
import { TourDetails } from '../TourDetails';
import {
  DateCaption,
  InquiryItemW,
  InquiryName,
  ContainerW,
  ArrowIcon,
  SideBlockW,
  RightSideBlockW,
  CollapseW,
  CloseReasonW,
  Reason,
} from './styled';

interface InquiryListItemProps {
  inquiry: TourInquiry | ApplyInquiry;
  handleOpenEditTourModal: (tour: TourInquiry) => void;
  handleSelectInquiry: (id: string) => void;
  isSelected: boolean;
  buildingStatus: BuildingStatus;
}

export function InquiryListItem({
  inquiry,
  handleOpenEditTourModal,
  handleSelectInquiry,
  isSelected,
  buildingStatus,
}: InquiryListItemProps): ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);

  const assignInquiryModal = useModal();

  const { inquiryType, createdAt, status } = inquiry;

  const { isReadOnlyAccess } = useAuth();

  const isDisabled =
    buildingStatus === BuildingStatus.DISMISSED ||
    status.group === InquiryStateGroup.TERMINAL ||
    status.group === InquiryStateGroup.RESOLVED ||
    isReadOnlyAccess;

  const reason = status?.group === InquiryStateGroup.TERMINAL ? status?.name : '';

  const isTour = inquiryType === 'tour';

  const { assignee } = inquiry;

  const toggleExpanded = () => {
    if (!isExpanded) {
      handleSelectInquiry(inquiry.id);
    }

    setIsExpanded(!isExpanded);
  };

  const { scrollRef } = useScrollQueue({
    id: inquiry.id,
    open: setIsExpanded,
    select: handleSelectInquiry,
    scrollDelay: 500,
  });

  return (
    <ContainerW expanded={isExpanded} ref={scrollRef as $FixTypeLater}>
      <InquiryItemW isSelected={isSelected}>
        <Box position="relative">
          <ArrowIcon $isOpen={isExpanded} onClick={toggleExpanded} />
          <SideBlockW>
            <InquiryIcon
              style={{
                color: isTour
                  ? theme.palette.additionalColors.cards2
                  : theme.palette.additionalColors.cards1,
              }}
            />
            <InquiryName variant="caption" onClick={() => handleSelectInquiry(inquiry.id)}>
              {isTour ? 'Tour' : 'Application'}
            </InquiryName>
          </SideBlockW>
          <DateCaption variant="body2">
            {getFormattedDateWithTime(new Date(createdAt), DATE_FORMAT_WITH_TIME_AND_COMMA)}
          </DateCaption>
          {reason && (
            <CloseReasonW variant="body2">
              Reason to close: <Reason>{reason}</Reason>
            </CloseReasonW>
          )}
        </Box>
        <RightSideBlockW>
          <InquiryStatusBlock
            inquiryId={inquiry.id}
            status={status}
            inquiryType={inquiryType as InquiryType}
            isDisabled={isDisabled}
          />
          <Avatar
            sx={{
              width: 32,
              height: 32,
              marginLeft: '16px',
              cursor: isDisabled ? 'not-allowed' : 'pointer',
            }}
            alt="inquiry assignee avatar"
            onClick={isDisabled ? undefined : assignInquiryModal.handleOpenModal}
            src={assignee?.user?.picture}
          />
        </RightSideBlockW>
      </InquiryItemW>

      <CollapseW orientation="vertical" in={isExpanded}>
        {isTour ? (
          <TourDetails
            tour={inquiry as TourInquiry}
            handleOpenEditTourModal={() => handleOpenEditTourModal(inquiry as TourInquiry)}
          />
        ) : (
          <ApplicationDetails applyInquiry={inquiry as ApplyInquiry} />
        )}
      </CollapseW>

      {assignInquiryModal.isOpen && (
        <AssignInquiryForm
          assignee={assignee}
          inquiry={inquiry}
          onClose={assignInquiryModal.handleCloseModal}
          isTour={isTour}
        />
      )}
    </ContainerW>
  );
}
