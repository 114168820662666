import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';

interface UseDeleteEmployeeReturn {
  handleSubmit: (employeeId: string) => void;
}

interface UseDeleteEmployeeProps {
  close: () => void;
  resetId: () => void;
}

export function useDeleteEmployee({
  close,
  resetId,
}: UseDeleteEmployeeProps): UseDeleteEmployeeReturn {
  const { mutateAsync: deleteEmployeeMutation } = useMutation(API_HANDLERS.EMPLOYEES.DELETE, {
    onSuccess: () => {
      alert.success('Employee successfully deleted!');
      queryClient.invalidateQueries('employees');
    },
    onError: () => {
      close();
    },
  });

  async function handleSubmit(employeeId: string) {
    await deleteEmployeeMutation({ employeeId });
    resetId();
    close();
  }

  return {
    handleSubmit,
  };
}
