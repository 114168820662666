import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { setupHttpInterceptors } from 'api/setupHttpInterceptors';
import { useAuth } from 'hooks';

export function HttpInterceptorsConfig(): ReactElement {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    setupHttpInterceptors({ navigate, logout });
  }, [logout, navigate]);

  return <></>;
}
