export function setAll<T extends { id: string }>(arr: T[]): Record<string, T> {
  const normalized: Record<string, T> = {};

  arr.forEach((entity) => {
    const k = entity.id;

    normalized[k] = entity;
  });

  return normalized;
}
