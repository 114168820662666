import { ReactElement } from 'react';

import { InquiryRequestCard } from 'features/dashboard/components';
import { DashboardItemActiveInquiryRequestsItem, InquiryType } from 'types';

import { ListWrapper } from '../styled';

interface InquiryRequestsListProps {
  inquiryType: InquiryType;
  requests: DashboardItemActiveInquiryRequestsItem[];
  contactId: string;
}
export function InquiryRequestsList({
  inquiryType,
  requests,
  contactId,
}: InquiryRequestsListProps): ReactElement {
  return (
    <ListWrapper>
      {requests.map((request) => (
        <InquiryRequestCard
          key={request.id}
          inquiryType={inquiryType}
          inquiry={request}
          contactId={contactId}
        />
      ))}
    </ListWrapper>
  );
}
