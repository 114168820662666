import { IconButton } from '@mui/material';
import { ReactElement } from 'react';

import { Popover } from 'components';

import { AddIcon, MenuItem, MenuList, MoreIcon } from './styled';

interface UnassignedBuildingMenuProps {
  isDisabled: boolean;
  handleClickAddToCampaign: () => void;
}

export function UnassignedBuildingMenu({
  isDisabled,
  handleClickAddToCampaign,
}: UnassignedBuildingMenuProps): ReactElement {
  return (
    <Popover
      popupId="more-block"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchor={
        <IconButton id="long-button" aria-haspopup="true">
          <MoreIcon fontSize="small" />
        </IconButton>
      }
    >
      {() => (
        <MenuList sx={{ cursor: isDisabled ? 'not-allowed' : 'default' }}>
          <MenuItem
            key="add to campaign"
            onClick={handleClickAddToCampaign}
            sx={{ typography: 'body2', height: '32px' }}
            disabled={isDisabled}
          >
            <AddIcon fontSize="small" />
            Add to campaign
          </MenuItem>
        </MenuList>
      )}
    </Popover>
  );
}
