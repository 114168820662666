import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { PAGE_LIMIT } from 'constants/index';
import { useSetQueryParams } from 'hooks';
import { getQueryParams } from 'utils';

import { QUERY_SETTINGS_KEYS, SettingsTabsValues } from './config';

interface UseSettingsPageReturn {
  tab: string;
  currentPage: number;
  pageLimit: number;
  handleTabChange: (tabName: string) => void;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setPageLimit: Dispatch<SetStateAction<number>>;
  setEmailConfigParam: Dispatch<SetStateAction<string>>;
}

export function useSettingsPage(): UseSettingsPageReturn {
  const querySearch = useMemo(() => getQueryParams(QUERY_SETTINGS_KEYS), []);

  const [tab, setTab] = useState<string>(querySearch.tab || (SettingsTabsValues.FILTERS as string));
  const [currentPage, setCurrentPage] = useState<number>(Number(querySearch.page) || 0);
  const [pageLimit, setPageLimit] = useState<number>(Number(querySearch.limit) || PAGE_LIMIT);
  const [emailConfig, setEmailConfigParam] = useState<string>(querySearch.emailConfig || '');

  const queryParams = {
    tab,
    ...(tab === SettingsTabsValues.TEMPLATES && { page: currentPage, limit: pageLimit }),
    ...(emailConfig && { emailConfig }),
  };

  useSetQueryParams({ ...queryParams }, QUERY_SETTINGS_KEYS);

  const handleTabChange = useCallback(
    (tabName: string) => {
      setTab(tabName);
      setCurrentPage(0);
      setPageLimit(PAGE_LIMIT);
    },
    [setTab, setCurrentPage, setPageLimit],
  );

  return {
    tab,
    currentPage,
    pageLimit,
    handleTabChange,
    setCurrentPage,
    setPageLimit,
    setEmailConfigParam,
  };
}
