import { ReactElement } from 'react';
import { Control } from 'react-hook-form';

import { BuildingChatItemType, BuildingChatFormValues } from 'types';

import { BuildingChatInput, PrivateNoteInput } from './components';

interface NewMessageInputProps {
  messageType: BuildingChatItemType;
  isValid: boolean;
  handleAddMention: (id: string, display: string) => void;
  control: Control<BuildingChatFormValues>;
  handleFormSubmit: () => void;
}

export function NewMessageInput({
  messageType,
  isValid,
  handleAddMention,
  control,
  handleFormSubmit,
}: NewMessageInputProps): ReactElement {
  switch (messageType) {
    case BuildingChatItemType.PRIVATE_NOTES:
      return (
        <PrivateNoteInput
          isValid={isValid}
          handleFormSubmit={handleFormSubmit}
          handleAddMention={handleAddMention}
          control={control}
        />
      );

    default:
      return (
        <BuildingChatInput
          isValid={isValid}
          handleFormSubmit={handleFormSubmit}
          control={control}
        />
      );
  }
}
