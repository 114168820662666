import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { generatePath } from 'react-router-dom';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { AxiosResponseError, ContactEditParams, ContactEditResponseSuccess } from 'api/types';
import { alert } from 'components';
import { ContactExistError } from 'constants/apiErrors';
import { Links } from 'settings';
import { ContactFormValues, ContactFormDefaultValues } from 'types';
import { objectsDifferences } from 'utils';

import { editContactAdapter } from './adapters';

interface UseContactEditFormReturn {
  isLoading: boolean;
  handleSubmit: (values: ContactFormValues) => Promise<void>;
}

interface UseContactEditFormProps {
  close: () => void;
  contactId: string;
  defaultValues: ContactFormDefaultValues;
}

export function useContactEditForm({
  close,
  contactId,
  defaultValues,
}: UseContactEditFormProps): UseContactEditFormReturn {
  const { mutateAsync: editContactMutation, isLoading } = useMutation<
    AxiosResponse<ContactEditResponseSuccess>,
    AxiosResponseError,
    ContactEditParams
  >(({ contactId, data }: ContactEditParams) => API_HANDLERS.CONTACT.EDIT(contactId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries('contact');
    },
    onError: (error) => {
      if (error.response?.data.existingContactId) {
        const contactDetailsUrl = generatePath(Links.contacts.contact.index, {
          contactId: error.response?.data.existingContactId,
        });

        alert.error(ContactExistError, {
          linkUrl: contactDetailsUrl,
          label: 'See contact',
        });
      }
    },
  });

  async function handleSubmit(values: ContactFormValues) {
    const editInput = defaultValues ? objectsDifferences(defaultValues, values) : values;

    const reqData = editContactAdapter(editInput);

    const data = await editContactMutation({
      data: reqData,
      contactId,
    });

    if (data) {
      close();
      alert.success('The contact is edited successfully');
    }
  }

  return {
    isLoading,
    handleSubmit,
  };
}
