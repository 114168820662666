import { Box, MenuItem, styled } from '@mui/material';

export const StatusContainer = styled(Box)`
  position: relative;
  display: flex;
  max-width: calc(100% - 48px);
`;

export const OptionsContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  minWidth: '188px',
  width: 'auto',
  top: '100%',
  right: 0,
  maxHeight: '256px',
  overflowY: 'auto',
  padding: '8px 0',
  zIndex: 5000,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[6],
}));

export const OptionItem = styled(MenuItem)(({ theme }) => ({
  display: 'block',
  padding: '10px 10px 10px 16px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.p010,
  },
}));

export const ContentModalWrap = styled('div')`
  padding: 8px 0;
`;
