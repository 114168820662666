import { IconButton } from '@mui/material';
import { ReactElement } from 'react';

import { CopyToClipboardButton } from 'components';
import Popover from 'components/Popover';

import { CopyIcon, DeleteIcon, MenuItemW, MenuListW, MoreIcon } from './styled';

interface BuildingItemMenuProps {
  openDeleteModal: () => void;
  isDeleteIconVisible: boolean;
  buildingLink: string;
}

export function BuildingItemMenu({
  openDeleteModal,
  isDeleteIconVisible,
  buildingLink,
}: BuildingItemMenuProps): ReactElement {
  return (
    <Popover
      popupId="more-block"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchor={
        <IconButton id="long-button" aria-haspopup="true">
          <MoreIcon fontSize="small" />
        </IconButton>
      }
    >
      {() => (
        <MenuListW>
          <CopyToClipboardButton textToCopy={buildingLink}>
            <MenuItemW key="copy building" sx={{ typography: 'body2', height: '32px' }}>
              <>
                <CopyIcon fontSize="small" />
                <>Copy link</>
              </>
            </MenuItemW>
          </CopyToClipboardButton>
          {isDeleteIconVisible && (
            <MenuItemW
              key="delete building"
              onClick={openDeleteModal}
              sx={{ typography: 'body2', height: '32px' }}
            >
              <DeleteIcon fontSize="small" />
              Delete
            </MenuItemW>
          )}
        </MenuListW>
      )}
    </Popover>
  );
}
