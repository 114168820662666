import { Table as MuiTable, IconButton } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CSSProperties, ReactElement, useCallback } from 'react';

import { ReactComponent as TrashBinIcon } from 'assets/icons/employeeTrashBin.svg';
import { PageStatus, TablePagination } from 'components';
import { EmptyStateArgs } from 'components/PageStatus';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS, PAGE_LIMIT } from 'constants/index';
import { EmployeesTableData } from 'types';
import { clickAndHoldListener } from 'utils';

import { EmployeeRoleSelect } from '../EmployeeRoleSelect';
import { EmployeeTeamSelect } from '../EmployeeTeamSelect';
import { DataW, StatusLabel, Td, Th } from './styled';
import { getRoleOptions, getTeamOptions } from './utils';

interface TableProps {
  data: EmployeesTableData[];
  tableHeight: CSSProperties['height'];
  currentPage: number;
  totalCount: number;
  pageLimit: number;
  setCurrentPage: (page: number) => void;
  setPageLimit: (limit: number) => void;
  isTableVisible: boolean;
  onRowClick?(row: string): void;
  loading: boolean;
  emptyState: EmptyStateArgs;
  openDeleteModal: (employeeId: string) => void;
}

export function EmployeesTable({
  data,
  tableHeight,
  currentPage,
  setCurrentPage,
  totalCount,
  pageLimit,
  setPageLimit,
  isTableVisible = true,
  onRowClick,
  loading,
  openDeleteModal,
  emptyState,
}: TableProps): ReactElement {
  const showPagination = totalCount > (pageLimit < PAGE_LIMIT ? pageLimit : PAGE_LIMIT);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageLimit(parseInt(event.target.value));
    setCurrentPage(0);
  };

  const handleOnRowClick = useCallback(
    (row: string) => {
      onRowClick?.(row);
    },
    [onRowClick],
  );

  return (
    <>
      <PageStatus loading={loading} emptyState={emptyState} withCover>
        <TableContainer style={{ height: tableHeight }}>
          {isTableVisible && (
            <MuiTable aria-label="table" stickyHeader>
              <TableHead>
                <TableRow>
                  <Th align="left">Full name</Th>
                  <Th align="left">Email</Th>
                  <Th align="left">Role</Th>
                  <Th align="left">Team</Th>
                  <Th align="left">Status</Th>
                  <Th align="left">Created on</Th>
                  <Th align="left"></Th>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(({ id, fullName, email, roles, team, isActive, createdAt }) => {
                  const status = isActive ? 'Active' : 'Inactive';
                  const clickProps = clickAndHoldListener(() => handleOnRowClick(id), 150);
                  const teamOptions = getTeamOptions(roles);
                  const roleOptions = getRoleOptions(roles);

                  return (
                    <TableRow
                      {...clickProps}
                      key={id}
                      sx={{ '&:last-child th': { border: 0 }, cursor: 'pointer' }}
                      hover
                    >
                      <Td align="left" sx={{ width: '25%', maxWidth: '320px' }}>
                        <DataW>{fullName}</DataW>
                      </Td>
                      <Td align="left" sx={{ width: '25%', maxWidth: '280px' }}>
                        <DataW>{email}</DataW>
                      </Td>
                      <Td align="left" sx={{ maxWidth: '200px' }} style={{ overflow: 'visible' }}>
                        <DataW>
                          <EmployeeRoleSelect
                            defaultValues={roles}
                            employeeId={id}
                            disabled={!isActive}
                            options={roleOptions}
                          />
                        </DataW>
                      </Td>
                      <Td align="left" sx={{ maxWidth: '170px' }}>
                        <DataW>
                          <EmployeeTeamSelect
                            team={team}
                            options={teamOptions}
                            employeeId={id}
                            disabled={!isActive}
                          />
                        </DataW>
                      </Td>
                      <Td align="left">
                        <DataW>
                          <StatusLabel component="span" isActive={isActive}>
                            {status}
                          </StatusLabel>
                        </DataW>
                      </Td>
                      <Td align="left">
                        <DataW sx={{ maxWidth: '170px' }}>{createdAt}</DataW>
                      </Td>
                      <Td align="left">
                        {isActive && (
                          <DataW onClick={() => openDeleteModal(id)}>
                            <IconButton sx={{ width: '36px' }}>
                              <TrashBinIcon />
                            </IconButton>
                          </DataW>
                        )}
                      </Td>
                    </TableRow>
                  );
                })}
              </TableBody>
            </MuiTable>
          )}
        </TableContainer>
      </PageStatus>
      {showPagination && (
        <TablePagination
          rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          count={totalCount}
          rowsPerPage={pageLimit}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}
