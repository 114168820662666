import { ReactElement, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import { PageTitle, PageHeader, PageW, ContentW } from './styled';

interface PageWrapProps {
  helmet: ReactNode;
  title: ReactNode;
  children: ReactNode;
}

export function PageWrap({ helmet, title, children }: PageWrapProps): ReactElement {
  return (
    <PageW>
      <Helmet>{helmet}</Helmet>
      <PageHeader>
        <PageTitle>{title}</PageTitle>
      </PageHeader>
      <ContentW>{children}</ContentW>
    </PageW>
  );
}
