import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuItem, MenuList, styled } from '@mui/material';

export const DeleteIcon = styled(DeleteOutlineIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginRight: '8px',
}));

export const CopyIcon = styled(ContentCopyIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginRight: '8px',
}));

export const MoreIcon = styled(MoreVertIcon)(({ theme }) => ({
  color: theme.palette.black.main,
}));

export const MenuListW = styled(MenuList)(() => ({
  width: '130px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '20px 0',
}));

export const MenuItemW = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.black.main,
}));
