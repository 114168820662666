import { Box, Dialog, DialogTitle, styled } from '@mui/material';

import { Button } from 'components';

export const DialogTitleW = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 0,
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px',
  color: theme.palette.black.main,
  marginBottom: '22px',
}));

export const DialogW = styled(Dialog)(() => ({
  '.MuiDialog-paper': {
    minWidth: '376px',
    padding: '14px 20px',
    margin: 0,
    overflowY: 'visible',
  },
}));

const ButtonW = styled(Button)`
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
`;

export const CancelBtn = styled(ButtonW)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const ConfirmBtn = styled(ButtonW)(({ theme }) => ({
  color: theme.palette.success.main,
}));

export const OptionW = styled(Box)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  background: isSelected ? theme.palette.primary.p010 : 'none',
}));
