import { useContext } from 'react';

import { WishlistBuildingsState } from 'features/wishlist';
import { WishlistBuilding } from 'types';

export function useSelectWishlistBuilding(
  buildingId: WishlistBuilding['id'],
): WishlistBuilding | undefined {
  const buildings = useContext(WishlistBuildingsState);

  return buildings[buildingId];
}
