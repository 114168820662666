import { styled, Drawer, Box, Typography } from '@mui/material';

export const ModalW = styled(Drawer)<{ width?: string }>(({ width }) => ({
  width: `calc(100% - ${width || '424px'})`,

  '& .MuiDrawer-paper': {
    width: width || '424px',
  },
}));

export const ModalContent = styled(Box)`
  position: relative;
  height: 100%;
  padding: 22px 24px;
`;

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 24px;
  color: #191c1e;
`;

export const ModalHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;
