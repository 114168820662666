import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { AxiosResponseError, GetMessageTemplatesResponseSuccess } from 'api/types';
import { alert } from 'components';
import { MessageTemplateData } from 'types';

interface UseCreateMessageTemplateMutationReturn {
  handleCreateMessageTemplate: (template: MessageTemplateData, onSuccessAction: () => void) => void;
}

export function useCreateMessageTemplateMutation(): UseCreateMessageTemplateMutationReturn {
  const createMessageTemplate = useMutation<
    AxiosResponse<GetMessageTemplatesResponseSuccess>,
    AxiosResponseError,
    MessageTemplateData
  >(async (template: MessageTemplateData) => API_HANDLERS.MESSAGE_TEMPLATE.CREATE(template));

  const handleCreateMessageTemplate = useCallback(
    (template: MessageTemplateData, onSuccessAction?: () => void): void => {
      createMessageTemplate.mutate(template, {
        onSuccess: () => {
          queryClient.invalidateQueries('message-templates');
          alert.success('Message template was created successfully');
          onSuccessAction?.();
        },
      });
    },
    [createMessageTemplate],
  );

  return { handleCreateMessageTemplate };
}
