import * as yup from 'yup';

import {
  getMaxLengthText,
  getMinLengthText,
  USE_DATE_FORMAT,
  NAME_PATTER_SYMBOLS,
  REQUIRED,
  NO_MULTIPLE_SPACES_ALLOWED,
} from 'validations/messages';
import { LETTERS_DIGITS_SYMBOLS } from 'validations/regexps';
import { onlyPositiveDigitsRule } from 'validations/rules';
import { noMultipleSpaces, onlyFutureDate, requiredArray } from 'validations/tests';

const RENT_PRICE_POSITIVE_DIGITS = 'Rent price must be positive';

export const campaignFormSchema = yup.object().shape({
  campaignName: yup
    .string()
    .trim()
    .required(REQUIRED)
    .matches(LETTERS_DIGITS_SYMBOLS, NAME_PATTER_SYMBOLS)
    .min(2, getMinLengthText(2))
    .max(256, getMaxLengthText(256)),
  location: yup
    .string()
    .trim()
    .transform((value) => (value === '' ? undefined : value))
    .matches(LETTERS_DIGITS_SYMBOLS, NAME_PATTER_SYMBOLS)
    .min(2, getMinLengthText(2))
    .max(256, getMaxLengthText(256))
    .nullable(),
  city: yup.string().required(REQUIRED),
  bedrooms: yup.string().required(REQUIRED),
  amenities: yup.array().of(
    yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .nullable(),
  ),
  notes: yup
    .string()
    .test('notes', NO_MULTIPLE_SPACES_ALLOWED, noMultipleSpaces)
    .max(1000, getMaxLengthText(1000))
    .nullable(),
  moveInDate: yup.mixed().when({
    is: (v: unknown) => v instanceof Date,
    then(sch) {
      return sch.test('moveInDate', USE_DATE_FORMAT, onlyFutureDate);
    },
    otherwise(sch) {
      return sch.test('moveInDate', REQUIRED, (v) => (v ? true : false));
    },
  }),
  rentPrice: yup
    .string()
    .required(REQUIRED)
    .matches(onlyPositiveDigitsRule.value, RENT_PRICE_POSITIVE_DIGITS)
    .max(6, getMaxLengthText(6)),
  issues: yup.array().test('issues', REQUIRED, requiredArray),
});
