import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { ButtonProps, IconButton } from '@mui/material';
import { ReactElement } from 'react';

interface CancelButtonProps extends ButtonProps {
  onClick: () => void;
  buttonSx?: ButtonProps['sx'];
  iconSx?: ButtonProps['sx'];
}

// TODO: remove button duplications from the app
export function CancelButton({
  onClick,
  buttonSx,
  iconSx,
  disabled,
  ...otherProps
}: CancelButtonProps): ReactElement {
  return (
    <IconButton
      onClick={onClick}
      sx={{ cursor: disabled ? 'not-allowed' : 'pointer', ...buttonSx, zIndex: 1300 }}
      {...otherProps}
    >
      <CancelRoundedIcon sx={iconSx} />
    </IconButton>
  );
}
