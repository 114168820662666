export { default as wishlistActionTypes } from './actionTypes';
export * from './actions';

import { createContext } from 'react';

import { Normalized, WishlistBuilding } from 'types';

export const WishlistBuildingsState = createContext<Normalized<WishlistBuilding>>({});

export const SelectedWishlistBuildingIdState = createContext<WishlistBuilding['id'] | undefined>(
  undefined,
);
