import { ReactElement } from 'react';

import { EventPayloadNewContactCreated } from 'types';

import { TextBold } from './styled';

interface NewContactCreatedContentProps {
  data: EventPayloadNewContactCreated;
}

export function NewContactCreatedContent({ data }: NewContactCreatedContentProps): ReactElement {
  const { firstName, lastName } = data;

  return (
    <span>
      <TextBold>
        {firstName} {lastName}
      </TextBold>{' '}
      was created
    </span>
  );
}
