import { ReactElement } from 'react';

import { BuildingSearchItem, CampaignBuilding } from 'types';
import { getFormattedBuildingAddress } from 'utils';

import { BuildingAddress, BuildingItem, BuildingTitle } from './styled';

interface BuildingSearchSuggestionProps {
  building: BuildingSearchItem;
  handleAddBuilding: (buildingId: string) => void;
}

export const BuildingSearchSuggestion = ({
  building,
  handleAddBuilding,
}: BuildingSearchSuggestionProps): ReactElement => {
  return (
    <BuildingItem onClick={() => handleAddBuilding(building.id as CampaignBuilding['id'])}>
      <BuildingTitle variant="subtitle2">{building.name}</BuildingTitle>
      <BuildingAddress variant="body2">
        {getFormattedBuildingAddress(building.address, building.city)}
      </BuildingAddress>
    </BuildingItem>
  );
};
