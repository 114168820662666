import { InquiryAssignee, AssignInquiryFormValues } from 'types';

interface DefaultValuesAdapterProps {
  assignee: InquiryAssignee;
}

export function defaultValuesAdapter({
  assignee,
}: DefaultValuesAdapterProps): AssignInquiryFormValues {
  return {
    assignee: assignee.id,
    team: '',
  };
}
