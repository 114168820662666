import { Theme } from '@mui/material';
import { CSSProperties } from 'react';

import { BuildingStatus } from 'types';

import { ApplicationStatus, TourStatus } from './types';

type GetStatusColorsReturn = {
  color: CSSProperties['color'];
  background: CSSProperties['background'];
};

export const getStatusColors = (status: string, theme: Theme): GetStatusColorsReturn => {
  let colors;

  switch (status.toLowerCase()) {
    case BuildingStatus.NEW.toLowerCase():
    case TourStatus.New.toLowerCase():
    case TourStatus.Requested.toLowerCase():
    case ApplicationStatus.New.toLowerCase():
    case ApplicationStatus.Requested.toLowerCase():
      colors = {
        color: theme.palette.info.main,
        background: theme.palette.info.p010,
      };
      break;

    case BuildingStatus.SENT.toLowerCase():
    case TourStatus.Scheduled.toLowerCase():
    case TourStatus.ConfirmedWithRenter.toLowerCase():
    case TourStatus.PreTourFollowUp.toLowerCase():
    case TourStatus.TourCompletedWon.toLowerCase():
    case TourStatus.ReadyToApply.toLowerCase():
    case ApplicationStatus.Qualified.toLowerCase():
    case ApplicationStatus.AwaitingReply.toLowerCase():
    case ApplicationStatus.Applied.toLowerCase():
    case ApplicationStatus.ConfirmReferralWithBuilding.toLowerCase():
    case ApplicationStatus.ReferralConfirmed.toLowerCase():
    case ApplicationStatus.ApprovedForLease.toLowerCase():
    case ApplicationStatus.ConfirmMoveIn.toLowerCase():
    case ApplicationStatus.Leased.toLowerCase():
      colors = {
        color: theme.palette.primary.main,
        background: theme.palette.primary.p010,
      };
      break;

    case TourStatus.MoreInfo.toLowerCase():
    case TourStatus.NeedToReschedule.toLowerCase():
    case ApplicationStatus.MoreInfo.toLowerCase():
    case ApplicationStatus.Rescue.toLowerCase():
      colors = {
        color: theme.palette.warning.main,
        background: theme.palette.warning.p010,
      };
      break;

    case BuildingStatus.DISMISSED.toLowerCase():
    case TourStatus.Closed.toLowerCase():
    case ApplicationStatus.Closed.toLowerCase():
      colors = {
        color: theme.palette.error.main,
        background: theme.palette.error.p010,
      };
      break;

    default:
      colors = {
        color: theme.palette.primary.main,
        background: theme.palette.primary.p010,
      };
  }

  return colors;
};
