import { ButtonProps } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { ReactElement, ReactNode } from 'react';

import { CloseButton } from 'components';

import { CancelBtn, ConfirmBtn, DialogContentTextW, DialogContentW, DialogTitleW } from './styled';

export interface ConfirmModalProps extends ButtonProps {
  isOpen?: boolean;
  title: string;
  content: string | ReactNode;
  onSubmit: () => void;
  onClose: () => void;
  cancelText: string;
  confirmText: string;
  maxWidth?: string;
  withCloseIcon?: boolean;
  highlightSubmit?: boolean;
  highlightSubmitColor?: 'error' | 'success';
  isSubmitDisabled?: boolean;
}

export function ConfirmModal({
  title,
  content,
  cancelText,
  confirmText,
  onClose,
  onSubmit,
  maxWidth,
  withCloseIcon = false,
  highlightSubmit,
  highlightSubmitColor = 'error',
  isSubmitDisabled,
}: ConfirmModalProps): ReactElement {
  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '.MuiDialog-paper': {
          maxWidth: maxWidth || '100%',
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitleW id="alert-dialog-title">
        {title} {withCloseIcon && <CloseButton onClick={onClose} />}
      </DialogTitleW>
      <DialogContentW>
        <DialogContentTextW id="alert-dialog-description">{content}</DialogContentTextW>
      </DialogContentW>
      <DialogActions>
        <CancelBtn onClick={onClose} variant="text" color="secondary">
          {cancelText}
        </CancelBtn>
        <ConfirmBtn
          onClick={onSubmit}
          variant="text"
          color="secondary"
          highlight={highlightSubmit}
          highlightSubmitColor={highlightSubmitColor}
          disabled={isSubmitDisabled}
        >
          {confirmText}
        </ConfirmBtn>
      </DialogActions>
    </Dialog>
  );
}
