export function insertOrUpdateOne<T extends { id: string }>(
  state: { [key: string]: T },
  entity: T,
): { [key: string]: T } {
  const k = entity['id'];

  return {
    ...state,
    [k]: entity,
  };
}
