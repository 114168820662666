import { Avatar } from '@mui/material';
import { ReactElement } from 'react';

import { getFullName } from 'utils';

import { AvatarW, FullNameW } from './styled';

interface FullNameFieldAdapterProps {
  firstName: string;
  lastName: string;
  avatarUrl: string;
}

export function fullNameFieldAdapter({
  firstName,
  lastName,
  avatarUrl,
}: FullNameFieldAdapterProps): ReactElement {
  return (
    <AvatarW>
      <Avatar alt="employee avatar" src={avatarUrl} sx={{ width: 24, height: 24 }} />
      <FullNameW variant="body2">{getFullName({ lastName, firstName })}</FullNameW>
    </AvatarW>
  );
}
