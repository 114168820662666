import { ReactElement, ReactNode } from 'react';

import { CloseButton } from 'components';
import { theme } from 'styles';

import { Dialog, DialogTitle, DialogContent } from './styled';

export interface PreviewModalProps {
  open: boolean;
  title: string;
  content: string | ReactNode;
  onClose: () => void;
}

export function PreviewModal({ open, title, content, onClose }: PreviewModalProps): ReactElement {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title} <CloseButton onClick={onClose} sx={{ color: theme.palette.secondary.main }} />
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
}
