import escapeStringRegexp from 'escape-string-regexp';
import { ReactElement, useContext } from 'react';

import { MESSAGE_ITEM_TYPE_ICONS } from 'components/MessageItem/config';
import { EmployeesContext } from 'context';
import { MessageType, TextMessageType } from 'types';

import { MessageContent, MessageW, Title } from './styled';
import { parseUserMentions } from './utils';

interface ListItemProps {
  message: TextMessageType;
  onSelect: () => void;
  search: string;
}

export function ListItem({ message, onSelect, search }: ListItemProps): ReactElement {
  const { employeesList = [] } = useContext(EmployeesContext);

  const Icon = MESSAGE_ITEM_TYPE_ICONS[message.type];

  const messageText =
    message.type === MessageType.PRIVATE_NOTES && employeesList.length > 0 && message.payload.text
      ? parseUserMentions(message.payload.text, employeesList)
      : message.payload.text;

  const escapedString = escapeStringRegexp(search);

  const markedText = messageText.replace(
    new RegExp(escapedString, 'gi'),
    (match) => `<mark style="background-color: #D6EAE2; color: #5E6062;">${match}</mark>`,
  );

  return (
    <MessageW onClick={onSelect}>
      <Icon variant="black" />
      <div>
        <Title variant="body2">Title</Title>
        <MessageContent dangerouslySetInnerHTML={{ __html: markedText }} />
      </div>
    </MessageW>
  );
}
