import { Button as MuiButton, ButtonProps, CircularProgressProps, styled } from '@mui/material';
import { ReactElement } from 'react';

import { ButtonLoader } from './ButtonLoader';
import { buttonLoaderSizes, buttonSizes, getSecondaryButtonStyles } from './config';
import { ButtonSizes } from './interface';

const ButtonW = styled(MuiButton, {
  shouldForwardProp: (propname) => propname !== 'buttonSize',
})<{ buttonSize: ButtonSizes }>(({ theme, buttonSize, color, variant }) => ({
  borderRadius: '4px',
  lineHeight: '20px',
  border: 'none',
  fontSize: '16px',
  fontWeight: '600',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  boxShadow: 'none',
  ...buttonSizes[buttonSize],

  '&.Mui-disabled': {
    backgroundColor: theme.palette.black.p010,
    cursor: 'not-allowed',
    pointerEvents: 'all',
  },

  ...(color === 'secondary' && getSecondaryButtonStyles(theme)),
  ...(color === 'secondary' && variant === 'text' && { backgroundColor: theme.palette.white.main }),
}));

export interface CustomButtonProps extends ButtonProps {
  loading?: boolean;
  loaderProps?: CircularProgressProps;
  buttonSize?: ButtonSizes;
}

export function Button({
  children,
  loading = false,
  buttonSize = ButtonSizes.base,
  variant = 'contained',
  loaderProps,
  disabled,
  ...otherProps
}: CustomButtonProps): ReactElement {
  return (
    <ButtonW
      variant={variant}
      {...otherProps}
      disabled={loading || disabled}
      buttonSize={buttonSize}
    >
      {loading ? (
        <>
          <span style={{ visibility: 'hidden' }}>{children}</span>
          <ButtonLoader size={buttonLoaderSizes[buttonSize]} {...loaderProps} />
        </>
      ) : (
        children
      )}
    </ButtonW>
  );
}

export { CloseButton } from './CloseButton';
export { CancelButton } from './CancelButton';
export { StatusButton } from './StatusButton';
export { DropdownButton } from './DropdownButton';
export { ShowMoreButton } from './ShowMoreButton';
