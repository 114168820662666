import { useMemo, useState } from 'react';

import { EmptyStateArgs } from 'components/PageStatus';
import { PAGE_LIMIT } from 'constants/index';
import { useSetQueryParams } from 'hooks';
import { EmployeesTableData, EmptyStateIcons } from 'types';
import { getQueryParams } from 'utils';

import { employeeDataAdapter } from '../adapters';
import { QUERY_SEARCH_KEYS } from './config';
import { useEmployeesList } from './useEmployeesList';

interface UsePageDetailsReturn {
  employeesCount: number;
  isLoading: boolean;
  employeesList: EmployeesTableData[];
  emptyState: EmptyStateArgs;
  setCurrentPage: (page: number) => void;
  setPageLimit: (limit: number) => void;
  pageLimit: number;
  currentPage: number;
}

export function usePageDetails(): UsePageDetailsReturn {
  const querySearch = useMemo(() => getQueryParams(QUERY_SEARCH_KEYS), []);

  const [currentPage, setCurrentPage] = useState(Number(querySearch.page) || 0);

  const [pageLimit, setPageLimit] = useState(Number(querySearch.limit) || PAGE_LIMIT);

  useSetQueryParams(
    {
      page: currentPage,
      limit: pageLimit,
    },
    QUERY_SEARCH_KEYS,
  );

  const { employeesListData, employeesListLoading, employeesContextListError } = useEmployeesList({
    limit: pageLimit,
    pageNumber: currentPage,
  });

  const employeesCount = employeesListData?.meta.totalItems || 0;

  const emptyState: EmptyStateArgs = useMemo(() => {
    if (employeesContextListError) {
      return {
        shouldAppear: true,
        title: 'Oops, something went wrong',
        description: 'Please try to reload this page',
        variant: EmptyStateIcons.ERROR,
      };
    }
    return {
      shouldAppear: Boolean(!employeesListLoading && employeesCount === 0),
      title: `Your contacts will be here`,
      variant: EmptyStateIcons.EMPTY_LIST,
    };
  }, [employeesContextListError, employeesCount, employeesListLoading]);

  return {
    employeesCount,
    isLoading: employeesListLoading,
    employeesList: employeeDataAdapter(employeesListData?.items || []),
    emptyState,
    setCurrentPage,
    setPageLimit,
    pageLimit,
    currentPage,
  };
}
