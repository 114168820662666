export const REQUIRED = 'Field is required';
export const EMAIL_FORMAT = 'Use format email@examle.com';
export const NAME_PATTERN = "Should contain only latin letters, numbers, space or - ' symbols";
export const NAME_PATTER_SYMBOLS =
  'Should contain only latin letters, numbers, space or [!#$%&‘*+-/=?^_`{|}~.,]; symbols';
export const getMinLengthText = (length: number): string =>
  `Min length is ${length} ${length > 1 ? 'characters' : 'character'} `;
export const getMaxLengthText = (length: number): string => `Max length is ${length} characters`;
export const getMaxSizeText = (sizeMb: number, unit: string): string =>
  `Max size is ${sizeMb} ${unit}`;
export const INVALID_FORMAT = 'Invalid format';
export const INVALID_DATE = 'Invalid date';
export const USE_DATE_FORMAT = 'Use format mm/dd/yyyy';
export const ONLY_POSITIVE_DIGITS = 'Only positive digits allowed';
export const AT_LEAST_TWO_CHARS = 'At least two characters required';
export const PHONE_FORMAT = 'Phone format +19092023344';
export const INVALID_PHONE_NUMBER = 'Phone number is invalid';
export const WEBSITE_FORMAT = 'Use format https://website.com';
export const NO_SPACES_ALLOWED = 'No spaces allowed';
export const NO_MULTIPLE_SPACES_ALLOWED = 'Should not contain two successive spaces';
