export const LETTERS_DIGITS_DASHES_SPACES_SINGLE_QUOTE_REGEX = /^[A-Za-z0-9\-'\s]*$/;
export const LETTERS_DIGITS_DASHES_SPACES_SINGLE_QUOTE_FORWARD_SLASH_REGEX = /^[A-Za-z0-9\-'/\s]*$/;
export const LETTERS_DIGITS_SYMBOLS = /^[A-Za-z0-9\-'‘,.;~!?#$%&'*+=^_`/{}\[\]|/\s]*$/;
export const ONLY_ASCII_CHARS = /^[\x00-\x7F]*$/;
export const ONLY_DIGITS_REGEX = /^\d*$/;
export const ONLY_DIGITS_NO_ZERO_ON_START_REGEX = /^[1-9][0-9]*/;
export const EMAIL_REGEX =
  /^((?![\.\-])([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_FORMAT_REGEX = /^[+](?:\d*|\d)$/;
export const URL_FORMAT_REGEX =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export const NO_EMPTY_SPACES_REGEX = /^\S*$/;
export const MULTIPLE_SPACES_REGEX = /\s\s+/;
