import { styled, Typography } from '@mui/material';

import { getStatusColors } from './utils';

export const Status = styled(Typography)<{ status: string }>(({ status, theme }) => {
  const colors = getStatusColors(status, theme);

  return {
    display: 'inline-block',
    alignSelf: 'flex-start',
    fontSize: '12px',
    fontWeight: 600,
    padding: '3px 8px',
    borderRadius: '4px',
    ...colors,
  };
});
