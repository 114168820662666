import { Box, FormLabel } from '@mui/material';
import { ReactElement } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import { theme } from 'styles';

import { ChipW, CSSChip } from './styled';

type ControlledChipsProps<T> = UseControllerProps<T> & {
  label: string;
  options: Array<{ label: string; value: unknown }>;
};

export function ControlledChips<T>({
  control,
  name,
  rules,
  label,
  options,
}: ControlledChipsProps<T>): ReactElement {
  const { field } = useController({ control, name, rules });

  return (
    <Box>
      <FormLabel component="legend" sx={{ fontSize: 12, color: theme.palette.black.light }}>
        {label}
      </FormLabel>
      <ChipW>
        {options.map(({ label, value }) => (
          <CSSChip
            key={label}
            label={label}
            color={value === field.value ? 'primary' : 'secondary'}
            clickable
            onClick={() => {
              field.onChange(value);
              field.onBlur();
            }}
          />
        ))}
      </ChipW>
    </Box>
  );
}
