import { ReactElement, useContext } from 'react';

import { BuildingsState } from 'features/buildings';
import { CampaignsState } from 'features/campaigns';
import { AppInquiry } from 'types';
import { handleSelectInquiry } from 'utils/entitySelectHandler';

import { GroupWrap } from '../GroupWrap';
import { ListItem } from './ListItem';

interface InquiriesContainerProps {
  inquiries: AppInquiry[];
  blur: () => void;
}

export function InquiriesContainer({ inquiries, blur }: InquiriesContainerProps): ReactElement {
  const buildings = useContext(BuildingsState);
  const campaigns = useContext(CampaignsState);

  const onSelectInquiry = (inquiry: AppInquiry) => {
    const building = buildings.byId[inquiry.parentId];

    const campaign = campaigns[building.parentId];
    handleSelectInquiry(inquiry.id, building.id, campaign.id);
    blur();
  };

  return (
    <GroupWrap title="Inquiries">
      {inquiries.map((inquiry) => (
        <ListItem
          key={inquiry.id}
          inquiry={inquiry}
          onSelect={() => onSelectInquiry(inquiry)}
          relatedTo={campaigns[inquiry.campaignId]?.title}
        />
      ))}
    </GroupWrap>
  );
}
