import { Box, styled, Typography } from '@mui/material';

import { ReactComponent as InquiryIcon } from 'assets/icons/inquiry.svg';

export const InquiryW = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  position: 'relative',
  padding: '12px 12px 12px 36px',
  border: `1px solid ${theme.palette.secondary.p020}`,
  borderRadius: '4px',
  cursor: 'pointer',
  background: theme.palette.background.paper,

  '&:hover': {
    background: theme.palette.secondary.p005,
  },
}));

export const ContentW = styled(Box)`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
`;

export const AvatarW = styled(Box)`
  display: flex;
  gap: 16px;
`;

export const InquiryIconW = styled(Box)`
  position: absolute;
  top: 9px;
  left: 14px;
`;

export const StyledInquiryIcon = styled(InquiryIcon)<{ tour: boolean }>(({ tour, theme }) => ({
  color: theme.palette.additionalColors[tour ? 'cards2' : 'cards1'],
}));

export const InquiryName = styled(Typography)`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

export const RelatedToText = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.light,
  lineHeight: theme.spacing(2),
  fontSize: '12px',
}));
