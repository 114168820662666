import { SelectOptionWithLabelString, FileWithId } from 'types';

import { CampaignStatus, CloseCampaignReasons } from './campaigns';

export enum MessageType {
  OUTGOING_SMS = 'OUTGOING_SMS',
  INCOMING_SMS = 'INCOMING_SMS',
  PRIVATE_NOTES = 'PRIVATE_NOTES',
  OUTGOING_EMAIL = 'OUTGOING_EMAIL',
  INCOMING_EMAIL = 'INCOMING_EMAIL',
  SYSTEM_MESSAGE = 'SYSTEM_MESSAGE',
}

export interface MessageSender {
  firstName: string;
  id: string;
  lastName: string;
  photoUrl: string;
}

export interface FileAttachment {
  attachmentId: string;
  filename: string;
  data: string;
  extension: string;
  size: number;
}

export interface SystemMessageCampaignUpdatedFields {
  title: string;
  moveInDate: string | Date;
  city: string;
  location: string | null;
  bedroomsCount: string;
  rentPrice: string;
  amenities: string | null;
  notes: string | null;
  issues: string[];
}

export type SystemMessageCampaignKeys = keyof SystemMessageCampaignUpdatedFields;

export interface EventPayloadNewCampaignCreated {
  campaignId: string;
  campaignName: string;
}

export interface EventPayloadCampaignUpdated {
  campaignId: string;
  campaignName: string;
  from: SystemMessageCampaignUpdatedFields;
  to: SystemMessageCampaignUpdatedFields;
}

export interface EventPayloadNewContactCreated {
  contactId: string;
  firstName: string;
  lastName: string;
}

export interface EventPayloadContactOwnerChanged {
  contactId: string;
  newOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  oldOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export interface EventPayloadAssigneeChanged {
  contactId: string;
  newAssignee: {
    id: string;
    firstName: string;
    lastName: string;
  };
  oldAssignee: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export interface EventPayloadApplicationRequested {
  applyInquiryId: string;
  building: { id: string; name: string };
  campaign: { id: string; name: string } | null;
}

export interface EventPayloadApplicationStatusChanged {
  applyInquiryId: string;
  building: { id: string; name: string };
  campaign: { id: string; name: string };
  newStatus: { color: string; name: string };
}

export interface EventPayloadTourRequested {
  tourInquiryId: string;
  building: { id: string; name: string };
  campaign: { id: string; name: string } | null;
}

export interface EventPayloadTourStatusChanged {
  tourInquiryId: string;
  building: { id: string; name: string };
  campaign: { id: string; name: string };
  newStatus: { color: string; name: string };
}

export interface EventPayloadListSent {
  buildings: { id: string; name: string }[];
  campaign: { id: string; name: string };
}

export interface EventPayloadCampaignStatusChanged {
  campaignId: string;
  campaignName: string;
  fromStatus: CampaignStatus;
  toStatus: CampaignStatus;
}

export interface EventPayloadCampaignClosed {
  campaignId: string;
  campaignName: string;
  status: CloseCampaignReasons;
}

export interface EventPayloadBuildingDismissed {
  building: { id: string; name: string };
  campaign: { id: string; name: string };
}

interface SystemMessagePayload<E extends SystemMessageEvent, T> {
  eventPayload: T;
  eventType: E;
}

export type SystemMessagePayloadType =
  | SystemMessagePayload<SystemMessageEvent.NEW_CAMPAIGN_CREATED, EventPayloadNewCampaignCreated>
  | SystemMessagePayload<
      SystemMessageEvent.CAMPAIGN_INFORMATION_UPDATED,
      EventPayloadCampaignUpdated
    >
  | SystemMessagePayload<SystemMessageEvent.NEW_CONTACT_CREATED, EventPayloadNewContactCreated>
  | SystemMessagePayload<SystemMessageEvent.CONTACT_OWNER_CHANGED, EventPayloadContactOwnerChanged>
  | SystemMessagePayload<SystemMessageEvent.CONTACT_ASSIGNEE_CHANGED, EventPayloadAssigneeChanged>
  | SystemMessagePayload<SystemMessageEvent.APPLICATION_REQUESTED, EventPayloadApplicationRequested>
  | SystemMessagePayload<
      SystemMessageEvent.APPLICATION_STATUS_CHANGED,
      EventPayloadApplicationStatusChanged
    >
  | SystemMessagePayload<SystemMessageEvent.TOUR_REQUESTED, EventPayloadTourRequested>
  | SystemMessagePayload<SystemMessageEvent.TOUR_STATUS_CHANGED, EventPayloadTourStatusChanged>
  | SystemMessagePayload<SystemMessageEvent.LIST_SENT, EventPayloadListSent>
  | SystemMessagePayload<SystemMessageEvent.BUILDING_DISMISSED, EventPayloadBuildingDismissed>
  | SystemMessagePayload<
      SystemMessageEvent.CAMPAIGN_STATUS_CHANGED,
      EventPayloadCampaignStatusChanged
    >
  | SystemMessagePayload<SystemMessageEvent.CAMPAIGN_CLOSED, EventPayloadCampaignClosed>;

export interface TextMessagePayload {
  sender: MessageSender;
  text: string;
  subject?: string;
  emailAttachments?: Array<FileAttachment>;
  threadMessages?: string[];
  media?: Array<MediaFile>;
  errorCode?: string | null;
  state?: TwilioMessageState;
}

interface MessageItemType<T extends MessageType, P> {
  id: string;
  createdAt: string;
  payload: P;
  type: T;
  acknowledged: boolean;
}

export type SystemMessageType = MessageItemType<
  MessageType.SYSTEM_MESSAGE,
  SystemMessagePayloadType
>;

export type TextMessageType = MessageItemType<
  Exclude<MessageType, MessageType.SYSTEM_MESSAGE>,
  TextMessagePayload
>;

export type Message = SystemMessageType | TextMessageType;

export type CommunicationType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  messages: Message[];
};

export interface NewMessageFormValues {
  content: string;
  type: MessageType;
  subject?: string;
  cc: SelectOptionWithLabelString[];
  bcc: SelectOptionWithLabelString[];
  withCc: boolean;
  withBcc: boolean;
  files: FileWithId[];
  replyTo?: string;
}

export interface Mentions {
  [key: string]: string;
}

export enum MessageEvent {
  NEW_CONTACT_CREATED = 'NEW_CONTACT_CREATED',
  NEW_CAMPAIGN_CREATED = 'NEW_CAMPAIGN_CREATED',
  CAMPAIGN_STATUS_CHANGED = 'CAMPAIGN_STATUS_CHANGED',
  NEW_LIST_SENT = 'NEW_LIST_SENT',
  TOUR_INQUIRY_ADDED = 'TOUR_INQUIRY_ADDED',
  APPLICATION_INQUIRY_ADDED = 'APPLICATION_INQUIRY_ADDED',
  TOUR_INQUIRY_STATUS_CHANGED = 'TOUR_INQUIRY_STATUS_CHANGED',
  APPLICATION_INQUIRY_STATUS_CHANGED = 'APPLICATION_INQUIRY_STATUS_CHANGED',
}

export enum SystemMessageEvent {
  CONTACT_EXTERNAL_ID_CHANGED = 'CONTACT_EXTERNAL_ID_CHANGED',
  NEW_CONTACT_CREATED = 'NEW_CONTACT_CREATED',
  NEW_CAMPAIGN_CREATED = 'NEW_CAMPAIGN_CREATED',
  CAMPAIGN_INFORMATION_UPDATED = 'CAMPAIGN_INFORMATION_UPDATED',
  CAMPAIGN_CLOSED = 'CAMPAIGN_CLOSED',
  LIST_SENT = 'LIST_SENT',
  BUILDING_DISMISSED = 'BUILDING_DISMISSED',
  TOUR_REQUESTED = 'TOUR_REQUESTED',
  TOUR_STATUS_CHANGED = 'TOUR_STATUS_CHANGED',
  APPLICATION_REQUESTED = 'APPLICATION_REQUESTED',
  APPLICATION_STATUS_CHANGED = 'APPLICATION_STATUS_CHANGED',
  CONTACT_OWNER_CHANGED = 'CONTACT_OWNER_CHANGED',
  CONTACT_ASSIGNEE_CHANGED = 'CONTACT_ASSIGNEE_CHANGED',
  CAMPAIGN_STATUS_CHANGED = 'CAMPAIGN_STATUS_CHANGED',
}

export type EventType = SystemMessageEvent;

export interface MessageTemplateRow {
  id: string;
  event: MessageEvent;
  name: string;
  type: MessageType.OUTGOING_EMAIL | MessageType.OUTGOING_SMS;
}

export type MessageTemplatesList = MessageTemplateRow[];

export interface MessageTemplateFormData {
  name: string;
  type: MessageType.OUTGOING_EMAIL | MessageType.OUTGOING_SMS | '';
  event: MessageEvent | '';
  subject: string | null;
  body: string;
}

export interface MessageTemplateFormDefaultValues {
  name: '';
  type: '';
  event: '';
  subject: '';
  body: '';
}

export type MessageTemplateData = MessageTemplateFormData;

export interface EditMessageTemplateData
  extends Pick<MessageTemplateData, 'subject' | 'body' | 'name'> {
  id: string;
}

export type MessageSourceType = 'ALL' | 'SMS' | 'EMAIL' | 'PRIVATE_NOTES' | 'SYSTEM_MESSAGE';

export interface MediaFile {
  contentType: string;
  url: string;
}

export interface ReplyToEmailParams {
  id: string;
  subject: string;
  messageToReplyContent: string | (string | JSX.Element)[];
  threads?: string[];
}

export type TwilioError = string | null;

export enum TwilioMessageState {
  PENDING = 'PENDING',
  SENT = 'SENT',
  FAILED = 'FAILED',
}
