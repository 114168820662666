import { Box } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

import { Avatar } from 'components';
import { Employee } from 'types';
import { getFullName } from 'utils';

import { AvatarW, DisplayLabelText } from './styled';

interface EmployeeOptionsAdapterProps {
  employeesList: Employee[];
}

interface SuggestionItem {
  id: string;
  display: string;
  displayLabel: string | ReactNode | ReactElement;
  icon?: ReactElement;
}

export function employeeOptionsAdapter({
  employeesList,
}: EmployeeOptionsAdapterProps): SuggestionItem[] {
  return employeesList.map(({ id, user: { picture, familyName, givenName } }) => ({
    displayLabel: (
      <Box display="flex">
        <AvatarW>
          <Avatar alt="contact owner avatar" src={picture} sx={{ width: 24, height: 24 }} />
        </AvatarW>
        <DisplayLabelText>
          {getFullName({ firstName: givenName, lastName: familyName })}
        </DisplayLabelText>
      </Box>
    ),
    display: getFullName({ firstName: givenName, lastName: familyName }),
    id,
    icon: (
      <Avatar alt="user avatar" src={picture} sx={{ width: 24, height: 24, marginRight: '10px' }} />
    ),
  }));
}
