import { ReactElement } from 'react';

import { Status } from './styled';

interface BuildingStatusProps {
  status: string;
}

export function BuildingStatus({ status }: BuildingStatusProps): ReactElement {
  return <Status status={status}>{status}</Status>;
}
