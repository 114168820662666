import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { Control } from 'react-hook-form';

import { ControlledTextField, DropZone } from 'components';
import { FileWithId, NewMessageFormValues } from 'types';
import { getMaxLengthRule } from 'validations/rules';

import { NEW_MESSAGE_INPUT_PLACEHOLDER } from '../constants';
import { SendButton } from '../SendButton';
import { IconsW } from './styled';
import { textFieldStyles } from './stylesConfig';

interface SmsTextInputProps {
  control: Control<NewMessageFormValues>;
  handleFormSubmit: () => void;
  isValid: boolean;
  setFiles: (files: FileWithPath[]) => void;
  handleFileDelete: (id: string) => void;
  attachedFiles: FileWithId[];
}

export function SmsTextInput({
  control,
  handleFormSubmit,
  isValid,
  setFiles,
  handleFileDelete,
  attachedFiles,
}: SmsTextInputProps): ReactElement {
  const dropZone = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: setFiles,
    accept: {
      'image/*': ['.jpeg', '.png', '.gif'],
    },
  });

  return (
    <>
      <ControlledTextField
        name="content"
        control={control}
        multiline
        minRows={1}
        maxRows={5}
        variant="standard"
        showAllErrors
        placeholder={NEW_MESSAGE_INPUT_PLACEHOLDER}
        rules={{
          maxLength: getMaxLengthRule(1400),
          required: {
            value: true,
            message: '',
          },
        }}
        sx={textFieldStyles}
        InputProps={{
          disableUnderline: true,
        }}
      />

      <IconsW>
        <DropZone
          files={attachedFiles}
          handleFileDelete={handleFileDelete}
          dropZone={dropZone}
          showPreview
        />
        <IconButton disabled={!isValid} onClick={handleFormSubmit}>
          <SendButton isDisabled={!isValid} />
        </IconButton>
      </IconsW>
    </>
  );
}
