import { ReactElement } from 'react';

import { Form } from 'components';
import { MessageType, NewMessageFormValues } from 'types';

import { MessageTypePopper } from '../MessageTypePopper';
import { NewMessageInput } from './components';
import { useNewMessageForm } from './hooks';
import { ChangeMessageTypeW, ChipsW, NewMessageBlockW, StyledChip } from './styled';

interface NewMessageProps {
  defaultValues: NewMessageFormValues | undefined;
  isReplyMode?: boolean;
  handleResetReplyMode: () => void;
  threads?: string[];
  contactId: string;
}

export function NewMessageForm({
  defaultValues,
  isReplyMode = false,
  threads = [],
  handleResetReplyMode,
  contactId,
}: NewMessageProps): ReactElement {
  const {
    control,
    isValid,
    handleFormSubmit,
    messageType,
    handleAddMention,
    withCc,
    withBcc,
    handleChipsChange,
    setFiles,
    handleFileDelete,
    attachedFiles,
    cancelReplyMode,
  } = useNewMessageForm({
    defaultValues,
    handleResetReplyMode,
    contactId,
  });

  return (
    <Form
      onKeyDown={(event) => {
        if (!event.ctrlKey || event.key !== 'Enter') {
          return;
        }
        handleFormSubmit();
      }}
    >
      <NewMessageBlockW messageType={messageType}>
        <ChangeMessageTypeW>
          <MessageTypePopper control={control} onClick={handleResetReplyMode} />
          {messageType === MessageType.OUTGOING_EMAIL && (
            <ChipsW>
              <StyledChip
                label="Cc"
                clickable
                isSelected={withCc}
                onClick={() => handleChipsChange('withCc')}
              />
              <StyledChip
                label="Bcc"
                clickable
                isSelected={withBcc}
                onClick={() => handleChipsChange('withBcc')}
              />
            </ChipsW>
          )}
        </ChangeMessageTypeW>

        <NewMessageInput
          control={control}
          isValid={isValid}
          messageType={messageType}
          handleAddMention={handleAddMention}
          handleFormSubmit={handleFormSubmit}
          withCc={withCc}
          withBcc={withBcc}
          setFiles={setFiles}
          handleFileDelete={handleFileDelete}
          attachedFiles={attachedFiles}
          isReplyMode={isReplyMode}
          threads={threads}
          cancelReplyMode={cancelReplyMode}
        />
      </NewMessageBlockW>
    </Form>
  );
}
