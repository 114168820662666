import { Box, styled } from '@mui/material';

export const ItemW = styled(Box, {
  shouldForwardProp: (propname) => propname !== 'isReceived' && propname !== 'isRead',
})<{ isReceived: boolean; isRead: boolean }>(({ isReceived, isRead, theme }) => ({
  display: 'flex',
  gap: '8px',
  flexDirection: isReceived ? 'row' : 'row-reverse',
  alignItems: 'flex-end',
  background: !isRead ? theme.palette.primary.p020 : 'unset',
  borderRadius: '12px',
  transition: 'background 1.2s ease-in',
}));
