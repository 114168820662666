import { CampaignBuilding, Campaign } from 'types';
import { actionCreator } from 'utils';

import actionTypes from './actionTypes';

export const addBuilding = actionCreator<{
  building: CampaignBuilding;
  campaignId: Campaign['id'];
}>(actionTypes.addBuilding);

export const selectBuilding = actionCreator<{
  buildingId: CampaignBuilding['id'];
  campaignId: Campaign['id'];
}>(actionTypes.selectBuilding);
