import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { API_HANDLERS } from 'api/apiHandlers';
import { useAuth } from 'hooks';
import { Mentions, BuildingChatFormValues, BuildingChatItemType } from 'types';

import { newMessageValuesAdapter } from '../adapters';
import { getDefaultValues } from './config';
import { buildingChatFormSchema } from './validationSchema';

interface UseNewMessageFormReturn {
  control: Control<BuildingChatFormValues>;
  isValid: boolean;
  handleFormSubmit: () => void;
  messageType: BuildingChatItemType;
  handleAddMention: (id: string, display: string) => void;
}

interface UseNewMessageFormProps {
  defaultValues: BuildingChatFormValues | undefined;
  buildingId: string;
  contactId: string;
}

export function useNewBuildingChatMessage({
  defaultValues,
  buildingId,
  contactId,
}: UseNewMessageFormProps): UseNewMessageFormReturn {
  const [mentions, setMentions] = useState<Mentions>();

  const { isReadOnlyAccess } = useAuth();

  const { mutateAsync: postMessageMutation, isLoading } = useMutation(
    API_HANDLERS.COMMUNICATIONS.POST_BUILDING_CHAT_MESSAGE,
  );

  const defaultValuesInner = defaultValues || getDefaultValues(isReadOnlyAccess);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    reset,
    formState: { isValid, errors },
  } = useForm<BuildingChatFormValues>({
    mode: 'onChange',
    defaultValues: defaultValuesInner,
    resolver: yupResolver(buildingChatFormSchema),
  });

  const messageType = watch('type');

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    const subscription = watch((_values, options) => {
      if (!options.type) return;

      if (options.name === 'type') {
        setValue('content', '');
        clearErrors();
      }
    });

    return subscription.unsubscribe;
  }, [setValue, watch, clearErrors, errors]);

  const handleFormSubmit = handleSubmit(async (values) => {
    if (isLoading) {
      return;
    }

    const { type, ...rest } = newMessageValuesAdapter({
      ...values,
      mentions,
    });

    if (contactId) {
      const stringifiedPayload = JSON.stringify({ ...rest });

      const newMessageRequestData: { [key: string]: string } = {
        payload: stringifiedPayload,
        contactId,
        type,
        buildingId,
      };

      const formData = new FormData();

      for (const name in newMessageRequestData) {
        formData.append(name, newMessageRequestData[name]);
      }

      await postMessageMutation(formData);
    }

    reset({ ...getDefaultValues(isReadOnlyAccess), type });
  });

  function handleAddMention(id: string, display: string): void {
    const newMentions = mentions || {};
    newMentions[id] = `@[${display}](${id})`;

    setMentions(newMentions);
  }

  return {
    control,
    isValid,
    handleFormSubmit,
    messageType,
    handleAddMention,
  };
}
