import { $FixTypeLater } from 'types';

// scrollContainer must have css position property different from 'static'
export function scrollToElement(element: Element, scrollContainer: HTMLElement): void {
  let scrollDistance = 0;
  let currElement: $FixTypeLater = element;

  while (currElement !== scrollContainer) {
    if (!currElement) {
      break;
    }

    scrollDistance += currElement?.offsetTop;
    currElement = currElement?.offsetParent;
  }

  scrollContainer.scrollTo({
    top: scrollDistance,
    behavior: 'smooth',
  });
}
