import { styled, Typography } from '@mui/material';

export const TitleW = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  color: theme.palette.black.main,
}));

export const Title = styled(Typography)(() => ({
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 600,
}));
