import { Box, styled, Typography } from '@mui/material';

export const AvatarW = styled(Box)`
  display: flex;
  align-items: center;
`;

export const FullNameW = styled(Typography)`
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
