import { IconButton, styled } from '@mui/material';

export const MoreButton = styled(IconButton)(() => ({
  marginLeft: '4px',
  padding: '4px 8px',
  borderRadius: '8px',
  '& svg': {
    width: '14px',
    height: '14px',
  },
}));
