import { ReactElement, useContext } from 'react';

import { CampaignsState } from 'features/campaigns';
import { CampaignBuildingP } from 'types';
import { handleSelectBuilding } from 'utils/entitySelectHandler';

import { GroupWrap } from '../GroupWrap';
import { ListItem } from './ListItem';

interface BuildingsContainerProps {
  buildings: CampaignBuildingP[];
  blur: () => void;
}

export function BuildingsContainer({ buildings, blur }: BuildingsContainerProps): ReactElement {
  const campaigns = useContext(CampaignsState);

  const onSelectBuilding = (building: CampaignBuildingP) => {
    const campaign = campaigns[building.parentId];
    handleSelectBuilding(building.id, campaign.id);
    blur();
  };

  return (
    <GroupWrap title="Buildings">
      {buildings.map((building) => (
        <ListItem
          key={`${building.parentId}/${building.id}`}
          building={building}
          onSelect={() => onSelectBuilding(building)}
          campaignName={campaigns[building.parentId]?.title}
        />
      ))}
    </GroupWrap>
  );
}
