import { useContext, useMemo } from 'react';

import { Message } from 'types';
import { sortByKey } from 'utils';

import { GeneralChatState } from '../context';

export function useGetSortedMessages(): Message[] {
  const { messages } = useContext(GeneralChatState);

  return useMemo(() => sortByKey(Object.values(messages), 'createdAt', 'asc'), [messages]);
}
