import { styled } from '@mui/material';

export const FieldsSectionW = styled('div')<{ nesting: number }>(({ theme, nesting }) => ({
  ...(nesting > 0
    ? {
        padding: '32px 24px 24px 24px',
        border: `1px solid ${theme.palette.secondary.p020}`,
        borderRadius: '4px',
      }
    : undefined),
}));

export const AddFilterBtnW = styled('div')(() => ({
  position: 'relative',
  display: 'inline-block',
  marginLeft: '100px',
}));

export const FilterW = styled('div')`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
  align-items: flex-start;
`;

export const OperatorFieldW = styled('div')`
  width: 76px;
  flex-shrink: 0;
`;

export const MoreBlockW = styled('div')<{ isGroup?: boolean }>(({ isGroup }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '36px',
  marginTop: isGroup ? '32px' : '0',
}));
