import { FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ReactElement } from 'react';
import { Control, Controller, FieldValues, UseControllerProps } from 'react-hook-form';

import { theme } from 'styles';
import { SelectOption } from 'types';

import { transformBooleanOption } from './utils';

interface RadioButtonsGroupProps<T = FieldValues> extends UseControllerProps<T> {
  label: string;
  options: SelectOption<string | boolean>[];
  control: Control<T>;
  isBooleanValues?: boolean;
  showAllErrors?: boolean;
  disabled?: boolean;
}

export function RadioButtonsGroup<T>({
  label,
  options,
  name,
  rules,
  control,
  isBooleanValues = false,
  showAllErrors = false,
  disabled = false,
}: RadioButtonsGroupProps<T>): ReactElement {
  return (
    <FormControl component="fieldset" disabled={disabled}>
      {/* TODO: move to ui component, not material */}
      <FormLabel
        component="legend"
        sx={{
          fontSize: 12,
          color: theme.palette.black.light,
        }}
      >
        {label}
      </FormLabel>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, ...restField }, fieldState: { error, isTouched } }) => (
          <>
            <RadioGroup
              {...restField}
              onChange={
                isBooleanValues ? (e) => onChange(transformBooleanOption(e.target.value)) : onChange
              }
            >
              {options.map(({ value, label }) => (
                <FormControlLabel
                  key={label as string}
                  value={value}
                  label={label}
                  sx={{
                    marginLeft: '-7px',
                    '& .MuiTypography-root': {
                      fontSize: 14,
                      color: theme.palette.black.main,
                    },
                  }}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 14,
                        },
                        '& .MuiButtonBase-root-MuiRadio-root': {
                          padding: 3,
                        },
                      }}
                    />
                  }
                />
              ))}
            </RadioGroup>
            {(isTouched || showAllErrors) && !!error ? (
              <FormHelperText>{error.message}</FormHelperText>
            ) : null}
          </>
        )}
      />
    </FormControl>
  );
}
