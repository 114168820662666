import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { Box, Collapse, Divider } from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';

import { DATE_FORMAT_WITH_TIME_AND_COMMA } from 'constants/index';
import { servicePreferenceProps } from 'features/campaigns/components/CampaignListItem/config';
import { ServicePreferencesBlock } from 'features/campaigns/components/CampaignListItem/styled';
import { CampaignNotesForm } from 'features/dashboard/components';
import { DashboardItemActiveListRequestItem, ServicePreferences } from 'types';
import { getFormattedDateWithTime } from 'utils';

import {
  ActiveListRequestCardContent,
  ActiveListRequestCardWrapper,
  DateCaption,
  StyledBadge,
  Title,
  ToggleButton,
  NotesWrapper,
  StyledLink,
} from '../styled';

interface ActiveListRequestCardProps {
  request: DashboardItemActiveListRequestItem;
  contactId: string;
}

export function ActiveListRequestCard({
  request,
  contactId,
}: ActiveListRequestCardProps): ReactElement {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { id, title, createdAt, notificationCount, notes } = request;

  const handleToggleExpanded = useCallback(() => {
    setIsExpanded((state) => !state);
  }, [setIsExpanded]);

  return (
    <>
      <ActiveListRequestCardWrapper>
        <ActiveListRequestCardContent>
          <div>
            <StyledBadge badgeContent={notificationCount} color="error">
              <StyledLink to={`/contacts/${contactId}?campaignId=${id}`} target="_blank">
                <Title variant="body2">{title}</Title>
              </StyledLink>
            </StyledBadge>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <ServicePreferencesBlock
                {...servicePreferenceProps[ServicePreferences.GuidedSearch]}
              />

              <DateCaption variant="body2">
                {getFormattedDateWithTime(new Date(createdAt), DATE_FORMAT_WITH_TIME_AND_COMMA)}
              </DateCaption>
            </Box>
          </div>

          <ToggleButton
            value="show-notes"
            selected={isExpanded}
            onChange={handleToggleExpanded}
            centerRipple
          >
            <StickyNote2OutlinedIcon />
          </ToggleButton>
        </ActiveListRequestCardContent>

        <Collapse in={isExpanded}>
          <NotesWrapper>
            <CampaignNotesForm campaignId={id} notes={notes || ''} />
          </NotesWrapper>
        </Collapse>
      </ActiveListRequestCardWrapper>

      <Divider />
    </>
  );
}
