import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { CircularProgress } from '@mui/material';
import { ReactElement } from 'react';

import { getFileSizeDisplayValue } from 'components/MessageItem/utils';

import { allowedPreviewExtensions } from './constants';
import { useMessageAttachment } from './hooks';
import {
  FileItemW,
  FileName,
  FlexWrapper,
  IconButton,
  DefaultFilePreview,
  FileSize,
  FileMeta,
} from './styled';

interface MessageAttachmentProps {
  fileId: string;
  fileName: string;
  extension: string;
  size: number;
}

export function MessageAttachment({
  fileName,
  fileId,
  extension,
  size,
}: MessageAttachmentProps): ReactElement {
  const withPreview = allowedPreviewExtensions[extension];

  const { isLoading, previewSrc, handlePreviewClick, handleDownloadClick, thumbnailPreview } =
    useMessageAttachment({
      fileId,
      fileName,
      extension,
    });

  const attachmentPreview = withPreview ? (
    <img src={thumbnailPreview} alt="email attachment preview" width={44} height={44} />
  ) : (
    <DefaultFilePreview>{extension.toUpperCase()}</DefaultFilePreview>
  );

  return (
    <FileItemW>
      <FlexWrapper>
        {isLoading ? <CircularProgress size={24} /> : attachmentPreview}

        <FileMeta>
          <FileName variant="body2">{fileName}</FileName>
          <FileSize>{getFileSizeDisplayValue(size)}</FileSize>
        </FileMeta>
      </FlexWrapper>

      <FlexWrapper>
        {previewSrc && withPreview && (
          <IconButton onClick={handlePreviewClick}>
            <VisibilityRoundedIcon fontSize="small" />
          </IconButton>
        )}

        <IconButton onClick={handleDownloadClick}>
          <DownloadRoundedIcon fontSize="small" />
        </IconButton>
      </FlexWrapper>
    </FileItemW>
  );
}
