import { AxiosResponse } from 'axios';
import { UseMutateAsyncFunction, useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { ChangeEmployeeTeamParams } from 'api/types';
import { alert } from 'components';

interface UseChangeTeamMutationReturn {
  changeTeamMutation: UseMutateAsyncFunction<
    AxiosResponse,
    unknown,
    ChangeEmployeeTeamParams,
    unknown
  >;
}

export function useChangeTeamMutation(): UseChangeTeamMutationReturn {
  const { mutateAsync: changeTeamMutation } = useMutation(API_HANDLERS.EMPLOYEES.CHANGE_TEAM, {
    onSuccess: (resp) => {
      if (resp.data) {
        queryClient.invalidateQueries('employees');
        alert.success(`Team successfully updated!`);
      }
    },
  });

  return {
    changeTeamMutation,
  };
}
