import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ReactElement, ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { queryClient } from 'api';
import { AlertsContainer, HttpInterceptorsConfig } from 'components';
import { AuthProvider } from 'context';
import { theme } from 'styles';

import { EmployeesContextProvider } from './EmployeesContext';

interface ContextProvidersProps {
  children: ReactNode;
}

export function ContextProvidersWithoutAuth({ children }: ContextProvidersProps): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HelmetProvider>{children}</HelmetProvider>
    </ThemeProvider>
  );
}

function ContextProviders({ children }: ContextProvidersProps): ReactElement {
  return (
    <BrowserRouter>
      <ContextProvidersWithoutAuth>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <HttpInterceptorsConfig />
            <EmployeesContextProvider>{children}</EmployeesContextProvider>
            <AlertsContainer />
          </AuthProvider>
        </QueryClientProvider>
      </ContextProvidersWithoutAuth>
    </BrowserRouter>
  );
}

export default ContextProviders;
