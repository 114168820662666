import { Table as MuiTable, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { ReactElement } from 'react';

import { EmptyStateArgs, PageStatus, TableComponents, TablePagination } from 'components';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS, PAGE_LIMIT } from 'constants/index';
import { TourInquiryTableItem } from 'types';

import { TourRow } from '../TourRow';

interface TourTableProps {
  isLoading: boolean;
  tourInquiries: TourInquiryTableItem[];
  emptyState: EmptyStateArgs;
  currentPage: number;
  totalCount: number;
  pageLimit: number;
  setCurrentPage: (page: number) => void;
  setPageLimit: (limit: number) => void;
}

export function TourTable({
  isLoading,
  tourInquiries,
  emptyState,
  currentPage,
  totalCount,
  pageLimit,
  setCurrentPage,
  setPageLimit,
}: TourTableProps): ReactElement {
  const { Th } = TableComponents;

  const showPagination = totalCount > (pageLimit < PAGE_LIMIT ? pageLimit : PAGE_LIMIT);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageLimit(parseInt(event.target.value));
    setCurrentPage(0);
  };

  return (
    <PageStatus loading={isLoading} emptyState={emptyState} withCover>
      <TableContainer sx={{ flex: '1 0 auto', height: 'calc(100vh - 196px)' }}>
        {!isLoading && (
          <MuiTable aria-label="table" stickyHeader>
            <TableHead>
              <TableRow>
                <Th align="left">Contact</Th>
                <Th align="left">Building</Th>
                <Th align="left" sx={{ minWidth: '124px' }}>
                  Requested date
                </Th>
                <Th align="left" sx={{ minWidth: '124px' }}>
                  Requested time
                </Th>
                <Th align="left">Status</Th>
                <Th align="left" sx={{ minWidth: '124px' }}>
                  Confirmed date
                </Th>
                <Th align="left" sx={{ minWidth: '124px' }}>
                  Confirmed time
                </Th>
                <Th align="left">Assignee</Th>
                <Th align="left">Created on</Th>
                <Th align="left">Updated on</Th>
              </TableRow>
            </TableHead>

            <TableBody>
              {tourInquiries.map((tour) => (
                <TourRow key={tour.id} {...tour} />
              ))}
            </TableBody>
          </MuiTable>
        )}
      </TableContainer>

      {showPagination && (
        <TablePagination
          rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          count={totalCount}
          rowsPerPage={pageLimit}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </PageStatus>
  );
}
