import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { Collapse } from '@mui/material';
import { ReactElement, useState } from 'react';

import { SelectOption } from 'types';

import { FilterName, MenuItemW } from '../FilterOptionsList/styled';
import { ListSubheaderW, GroupNameW, OptionsList, ArrowIcon, FolderName } from './styled';

interface FolderOptionsProps {
  folderName: string;
  folderOptions: SelectOption[];
  onChange: (option: SelectOption) => void;
  close: () => void;
  value: string;
}

export function FolderOptions({
  folderName,
  folderOptions,
  onChange,
  close,
  value,
}: FolderOptionsProps): ReactElement {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListSubheaderW onClick={handleClick}>
        <GroupNameW>
          <FolderOutlinedIcon fontSize="small" sx={{ marginRight: '5px' }} />
          <FolderName>{folderName}</FolderName>
        </GroupNameW>
        <ArrowIcon fontSize="small" isOpen={open} />
      </ListSubheaderW>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <OptionsList>
          {folderOptions
            .sort((a, b) => (a.label as string).localeCompare(b.label as string))
            .map((option) => (
              <MenuItemW
                key={option.value}
                sx={{ paddingLeft: '30px' }}
                isSelected={value === option.value}
                onClick={() => {
                  onChange(option);
                  close();
                }}
              >
                <FilterName>{option.label}</FilterName>
              </MenuItemW>
            ))}
        </OptionsList>
      </Collapse>
    </>
  );
}
