import { AxiosResponse } from 'axios';
import { UseMutateAsyncFunction, useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { ContactAssigneeEditRequest } from 'api/types';
import { Contact } from 'types';

interface UseEditContactAssigneeMutationReturn {
  editContactAssigneeMutation: UseMutateAsyncFunction<
    AxiosResponse<Contact, unknown>,
    unknown,
    ContactAssigneeEditRequest,
    unknown
  >;
}

export function useEditContactAssigneeMutation(): UseEditContactAssigneeMutationReturn {
  const { mutateAsync: editContactAssigneeMutation } = useMutation(
    API_HANDLERS.CONTACT.EDIT_ASSIGNEE,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contact');
      },
    },
  );

  return {
    editContactAssigneeMutation,
  };
}
