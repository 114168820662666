import { FilterBooleanExpression, FilterFormValue, FilterGroup, FilterType } from 'types';

import { FILTER_CHILDREN_NAME } from './config';

export function isFieldsGroup(type: FilterType): boolean {
  return type === FilterType.GROUP;
}

export function getBaseFilterGroupSet(): FilterGroup {
  return {
    type: FilterType.GROUP,
    operator: FilterBooleanExpression.AND,
    [FILTER_CHILDREN_NAME]: [],
  };
}

export function getBaseFilterFieldsSet(): FilterFormValue {
  return {
    type: '',
    entityName: '',
    propertyName: '',
    operator: '',
    value: '',
  };
}
