import { MessageType } from 'types';

interface GetIsReceivedProps {
  messageType: MessageType;
  senderId?: string;
  employeeId?: string;
}

export function getIsReceived({ messageType, senderId, employeeId }: GetIsReceivedProps): boolean {
  if (messageType === MessageType.INCOMING_SMS) {
    return true;
  }

  return senderId !== employeeId;
}
