export { default as unassignedBuildingsActionTypes } from './actionTypes';
export * from './actions';

import { createContext } from 'react';

import { Normalized, UnassignedBuilding } from 'types';

export const UnassignedBuildingsState = createContext<Normalized<UnassignedBuilding>>({});

export const SelectedUnassignedBuildingIdState = createContext<
  UnassignedBuilding['id'] | undefined
>(undefined);
