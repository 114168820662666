import { MenuItem, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Control, useController } from 'react-hook-form';

import { ControlledTextField } from 'components';
import { $FixTypeLater, FilterBooleanExpression } from 'types';
import { toCapitalize } from 'utils';

import { OperatorLabelW } from './styled';

interface OperatorFieldProps {
  name: string;
  control: Control<$FixTypeLater>;
  index: number;
  isGroup: boolean;
}

const operatorFieldOptions = [
  { value: FilterBooleanExpression.AND, label: toCapitalize(FilterBooleanExpression.AND) },
  { value: FilterBooleanExpression.OR, label: toCapitalize(FilterBooleanExpression.OR) },
];

export function OperatorField({ name, control, index, isGroup }: OperatorFieldProps): ReactElement {
  const {
    field: { value },
  } = useController({ control, name });

  if (index === 1) {
    return (
      <ControlledTextField
        select
        name={name}
        control={control}
        sx={isGroup ? { marginTop: '34px' } : undefined}
      >
        {operatorFieldOptions.map(({ label, value }) => (
          <MenuItem key={value} value={value} sx={{ fontSize: '14px' }}>
            {label}
          </MenuItem>
        ))}
      </ControlledTextField>
    );
  }

  return (
    <OperatorLabelW sx={isGroup ? { marginTop: '32px' } : undefined}>
      <Typography variant="body2" lineHeight="36px">
        {index === 0
          ? 'Where'
          : operatorFieldOptions.find(({ value: optionValue }) => value === optionValue)?.label}
      </Typography>
    </OperatorLabelW>
  );
}
