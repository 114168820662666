import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Box, styled, Typography } from '@mui/material';

export const MessagesListW = styled(Box)`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  overflow-y: auto;
  gap: 16px;
`;

export const ChatHeader = styled(Box)`
  padding: 14px 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const BuildingTitle = styled(Typography)`
  font-weight: 600;
`;

export const CancelIcon = styled(CancelRoundedIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.secondary.main};
`;
