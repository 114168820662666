import { ReactElement } from 'react';
import { Control } from 'react-hook-form';

import { ControlledTextField } from 'components';
import { BuildingChatFormValues } from 'types';

import { NEW_MESSAGE_INPUT_PLACEHOLDER } from '../constants';
import { SendButton } from '../SendButton';
import { InputW } from '../styled';
import { IconsW } from './styled';
import { textFieldStyles } from './stylesConfig';

interface BuildingChatInputProps {
  control: Control<BuildingChatFormValues>;
  isValid: boolean;
  handleFormSubmit: () => void;
}

export function BuildingChatInput({
  isValid,
  handleFormSubmit,
  control,
}: BuildingChatInputProps): ReactElement {
  return (
    <InputW>
      <ControlledTextField
        name="content"
        control={control}
        multiline
        minRows={1}
        maxRows={5}
        variant="standard"
        showAllErrors
        placeholder={NEW_MESSAGE_INPUT_PLACEHOLDER}
        sx={textFieldStyles}
        InputProps={{
          disableUnderline: true,
        }}
      />

      <IconsW>
        <SendButton isDisabled={!isValid} onClick={handleFormSubmit} />
      </IconsW>
    </InputW>
  );
}
