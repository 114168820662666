import { Typography } from '@mui/material';
import { CSSProperties, ReactElement, ReactNode } from 'react';

import { OptionsContainer, OptionItem } from './styled';

export interface DropdownMenuProps {
  menuItems: { label: ReactNode; onClick: () => void; id: string }[];
  handleClose: () => void;
  minWidth?: CSSProperties['minWidth'];
  right?: CSSProperties['right'];
}

export function DropdownMenu({
  menuItems,
  handleClose,
  minWidth,
  right,
}: DropdownMenuProps): ReactElement {
  return (
    <OptionsContainer minWidth={minWidth} right={right}>
      {menuItems.map(({ label, id, onClick }) => (
        <OptionItem
          key={id}
          onClick={() => {
            onClick();
            handleClose();
          }}
        >
          <Typography variant="subtitle2">{label}</Typography>
        </OptionItem>
      ))}
    </OptionsContainer>
  );
}
