import { ReactElement } from 'react';
import { FileRejection, FileWithPath } from 'react-dropzone';

import { FileError } from './styled';

interface FileError {
  code: string;
  message: string;
}

interface RejectionItem {
  file: FileWithPath;
  errors: FileError[];
}

interface RestrictedFilesListProps {
  fileRejections: FileRejection[];
}

export function RestrictedFilesList({ fileRejections }: RestrictedFilesListProps): ReactElement {
  return (
    <>
      {fileRejections.map(({ file, errors }: RejectionItem) => (
        <div key={file.path}>
          {errors.map((e) => (
            <FileError key={e.code}>{e.message}</FileError>
          ))}
        </div>
      ))}
    </>
  );
}
