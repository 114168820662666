import entitySelectHandler from './index';

export function handleSelectInquiry(
  inquiryId: string,
  buildingId?: string,
  campaignId?: string,
): void {
  return entitySelectHandler.setQueue([
    inquiryId,
    `${buildingId}${campaignId}` || '',
    campaignId || '',
  ]);
}

export function handleSelectBuilding(buildingId: string, campaignId?: string): void {
  return entitySelectHandler.setQueue([`${buildingId}${campaignId}` || '', campaignId || '']);
}

export function handleSelectCampaign(campaignId: string): void {
  return entitySelectHandler.setQueue([campaignId || '']);
}
