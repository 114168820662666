import { ReactElement } from 'react';

import { Badge, Tabs } from 'components';

import { DashboardFilterValues, FiltersTabs } from '../../config';
import { SOURCE_ICONS } from './constants';
import { IconW, tabListStyles, tabStyles } from './styles';

interface DashboardFiltersProps {
  source: string;
  handleSourceChange: (value: string) => void;
  filterCounters: Record<DashboardFilterValues, number>;
}

export function DashboardFilters({
  source,
  handleSourceChange,
  filterCounters,
}: DashboardFiltersProps): ReactElement {
  const filterTabs = FiltersTabs.map((tab) => {
    const Icon = SOURCE_ICONS[tab.value];

    const tabLabel = (
      <Badge badgeContent={filterCounters[tab.value]} color="error" max={999}>
        <IconW isSelected={source === tab.value}>
          <Icon />
        </IconW>
      </Badge>
    );

    return { ...tab, label: tabLabel };
  });

  return (
    <div style={{ paddingTop: '10px' }}>
      <Tabs
        tabs={filterTabs}
        value={source}
        handleChange={handleSourceChange}
        tabSx={tabStyles}
        tabListSx={tabListStyles}
      />
    </div>
  );
}
