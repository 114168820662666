import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { Box, Divider, styled, Typography } from '@mui/material';

import { Button } from 'components';
import { ServicePreferences } from 'types';

export const CampaignListItemWrap = styled(Box, {
  shouldForwardProp: (propname) => propname !== 'open',
})<{ isSelected?: boolean; open?: boolean }>(({ isSelected, open, theme }) => ({
  position: 'relative',
  gridTemplateColumns: '10px 1fr',
  height: '88px',
  padding: open ? '18px 24px 20px 32px' : '18px 24px 24px 32px',
  borderLeft: `2px solid ${isSelected ? theme.palette.primary.main : 'transparent'}`,
  background: isSelected ? theme.palette.secondary.p005 : theme.palette.background.paper,
  transition: 'border-color 0.25s ease-out',
}));

export const CampaignTitleWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: '100%',
  gap: theme.spacing(0.5),
  marginBottom: '4px',
}));

export const CampaignDescriptionWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const CampaignTitle = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: 600,
  cursor: 'pointer',
  color: theme.palette.black.main,
}));

export const DateCaption = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.light,
  fontSize: '12px',
}));

export const ServicePreferencesBlock = styled(Box)<{ variant: ServicePreferences }>(
  ({ theme, variant }) => {
    const color =
      variant === ServicePreferences.GuidedSearch
        ? theme.palette.primary.main
        : theme.palette.info.main;

    return {
      display: 'inline-block',
      padding: '1px 3px',
      border: `1px solid ${color}`,
      borderRadius: '4px',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '600',
      color,
    };
  },
);

export const ArrowIcon = styled(ArrowRightRoundedIcon, {
  shouldForwardProp: (propname) => propname !== 'open',
})<{ open: boolean }>(({ open, theme }) => ({
  position: 'absolute',
  cursor: 'pointer',
  color: theme.palette.secondary.main,
  top: 'calc(50% - 12px)',
  left: '5px',
  transform: `rotate(${open ? '90' : '0'}deg)`,
}));

export const BuildingButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: `20px 40px`,
}));

export const CampaignDivider = styled(Box)(({ theme }) => ({
  borderBottom: `4px solid ${theme.palette.secondary.p020}`,
}));

export const SmallDivider = styled(Divider)(({ theme }) => ({
  marginLeft: theme.spacing(5),
}));

export const BuildingActionBtn = styled(Button)(() => ({
  fontSize: '14px',
  padding: '6px 14px',
  marginRight: '8px',
}));

export const CloseReasonWrapper = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.light,
  fontSize: '12px',
  lineHeight: '16px',
}));

export const Reason = styled('span')(({ theme }) => ({
  color: theme.palette.black.main,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 600,
  marginLeft: '2px',
}));
