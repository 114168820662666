import { ReactElement, ReactNode } from 'react';

import { Badge } from 'components';
import { ButtonSizes } from 'components/Button/interface';

import { StyledButton } from './styled';

interface RequestsButtonProps {
  onClick: () => void;
  startIcon: ReactNode;
  children: ReactNode;
  badgeContent?: number;
  disabled?: boolean;
}

export function RequestsButton({
  onClick,
  startIcon,
  children,
  badgeContent = 0,
  disabled = false,
}: RequestsButtonProps): ReactElement {
  return (
    <Badge badgeContent={badgeContent} color="error" sx={{ right: '6px' }}>
      <StyledButton
        color="secondary"
        buttonSize={ButtonSizes.xSmall}
        onClick={onClick}
        startIcon={startIcon}
        centerRipple
        disabled={disabled}
      >
        {children}
      </StyledButton>
    </Badge>
  );
}
