import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { Control, useForm } from 'react-hook-form';

import { useValidationTrigger } from 'hooks/useValidationTrigger';
import { CampaignFormDefaultValues, CampaignFormValues } from 'types';

import { campaignFormSchema } from './validationSchema';

interface UseCampaignFormProps {
  defaultValues: CampaignFormDefaultValues;
  onSubmit: (values: CampaignFormValues) => Promise<void>;
  handleClose: () => void;
  isEditMode: boolean;
}

interface UseCampaignFormReturn {
  handleOnSubmit: () => Promise<void>;
  control: Control<CampaignFormValues>;
  disableSubmit: boolean;
  isSubmitting: boolean;
  destroyForm: () => void;
  withConfirmClose: boolean;
  handleSetValue: (
    field: keyof CampaignFormValues,
    value: CampaignFormValues[keyof CampaignFormValues],
  ) => void;
}

export function useCampaignForm({
  defaultValues,
  onSubmit,
  handleClose,
  isEditMode,
}: UseCampaignFormProps): UseCampaignFormReturn {
  const {
    handleSubmit,
    control,
    trigger,
    formState: { isSubmitting, isDirty, isValid, isSubmitSuccessful },
    reset,
    setValue,
  } = useForm<CampaignFormValues>({
    resolver: yupResolver(campaignFormSchema),
    defaultValues,
    mode: 'onChange',
  });

  useValidationTrigger(trigger, isEditMode, defaultValues);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset, isSubmitSuccessful]);

  const disableSubmit = !isValid || !isDirty || isSubmitting;

  const destroyForm = () => {
    handleClose();
    reset(defaultValues);
  };

  const withConfirmClose = isEditMode && isDirty;

  const handleOnSubmit = handleSubmit((values) => onSubmit(values).then(destroyForm));

  const handleSetValue = useCallback(
    (name, value) => {
      setValue(name, value, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
    },
    [setValue],
  );

  return {
    destroyForm,
    withConfirmClose,
    handleOnSubmit,
    control,
    disableSubmit,
    isSubmitting,
    handleSetValue,
  };
}
