import { ReactElement } from 'react';

import { ActiveListRequestCard } from 'features/dashboard/components';
import { DashboardItemActiveListRequestItem } from 'types';

import { ListWrapper } from '../styled';

interface ActiveListRequestsListProps {
  requests: DashboardItemActiveListRequestItem[];
  contactId: string;
}
export function ActiveListRequestsList({
  requests,
  contactId,
}: ActiveListRequestsListProps): ReactElement {
  return (
    <ListWrapper>
      {requests.map((request) => (
        <ActiveListRequestCard key={request.id} request={request} contactId={contactId} />
      ))}
    </ListWrapper>
  );
}
