import { styled } from '@mui/system';

export const FilterFieldsW = styled('div')`
  display: flex;
  align-items: flex-start;
  gap: 24px;
`;

export const OperatorLabelW = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  color: theme.palette.black.light,
}));
