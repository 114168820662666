import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { Control, useFieldArray } from 'react-hook-form';

import { $FixTypeLater } from 'types';

import { FilterBaseFields } from '../FilterBaseFields';
import { OperatorField } from '../FilterBaseFields/OperatorField';
import { FILTER_CHILDREN_NAME } from '../FilterForm/config';
import { getBaseFilterFieldsSet, getBaseFilterGroupSet, isFieldsGroup } from '../FilterForm/utils';
import { MoreBlock } from '../MoreBlock';
import { AddFilterButton } from './AddFilterButton';
import { FieldsSectionW, FilterW, MoreBlockW, OperatorFieldW } from './styled';

interface FilterFieldsSectionProps {
  namePrefix: string;
  control: Control<$FixTypeLater>;
  nestingLevel: number;
}

export function FilterFieldsSection({
  namePrefix,
  control,
  nestingLevel,
}: FilterFieldsSectionProps): ReactElement {
  const childrenName = `${namePrefix}.${FILTER_CHILDREN_NAME}`;

  const { fields, remove, append } = useFieldArray({ name: childrenName, control });

  const appendFilterFields = () => append(getBaseFilterFieldsSet());

  const appendFilterGroup = () => append(getBaseFilterGroupSet());

  const filtersList = fields.map(({ id, type }: $FixTypeLater, index) => (
    <FilterW key={id}>
      <OperatorFieldW>
        <OperatorField
          control={control}
          name={`${namePrefix}.operator`}
          index={index}
          isGroup={isFieldsGroup(type)}
        />
      </OperatorFieldW>
      <Box sx={{ width: '76px', flexGrow: '1' }}>
        {isFieldsGroup(type) ? (
          <FilterFieldsSection
            control={control}
            namePrefix={`${childrenName}.${index}`}
            nestingLevel={nestingLevel + 1}
          />
        ) : (
          <FilterBaseFields
            key={id as string}
            control={control}
            namePrefix={`${childrenName}.${index}`}
            removeFields={() => remove(index)}
          />
        )}
      </Box>
      <MoreBlockW isGroup={isFieldsGroup(type)}>
        <MoreBlock
          menuItems={[
            {
              key: 'del',
              label: 'Delete',
              onClick: () => remove(index),
            },
          ]}
        />
      </MoreBlockW>
    </FilterW>
  ));

  return (
    <FieldsSectionW nesting={nestingLevel}>
      {filtersList}
      <AddFilterButton
        nestingLevel={nestingLevel}
        appendFilterFields={appendFilterFields}
        appendFilterGroup={appendFilterGroup}
      />
    </FieldsSectionW>
  );
}
