import { Contact } from 'types';
import { removeExtraSpaces } from 'utils';

export function createContactFormAdapter({
  firstName,
  lastName,
  contactStyle,
  cameFrom,
  ...otherValues
}: Partial<Contact>): Partial<Contact> {
  return {
    firstName: removeExtraSpaces(firstName || ''),
    lastName: removeExtraSpaces(lastName || ''),
    ...(contactStyle && contactStyle.length > 0 && { contactStyle }),
    ...(cameFrom && { cameFrom }),
    ...otherValues,
  };
}
