import { Box, IconButton as IconButtonMUI, styled, Typography } from '@mui/material';

export const FileItemW = styled('div')`
  width: 286px;
  overflow: hidden;
  padding: 6px 10px;
  border-radius: 20px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FileName = styled(Typography)`
  width: 142px;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.black.main};
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
`;

export const FileSize = styled('span')`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8c8d8e;
`;

export const FlexWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

export const IconButton = styled(IconButtonMUI)`
  width: 33px;
  height: 33px;
`;

export const DefaultFilePreview = styled(Box)`
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #34966d;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

export const FileMeta = styled(Box)`
  margin-left: 10px;
`;
