import { Box, styled } from '@mui/material';

export const MessagesW = styled(Box)`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 24px;
  overflow-y: auto;
`;
