import { MenuItem } from '@mui/material';
import { ReactElement } from 'react';
import { Control, Controller, FieldValues, UseControllerProps } from 'react-hook-form';

import { SelectOption } from 'types';
import { blurActiveElement } from 'utils';

import { TextField } from '../TextField';

const defaultFieldWidth = '200px';

const textFieldStyles = (width?: string, paddingLeft?: string) => ({
  input: {
    color: '#191C1E',
    borderRadius: '4px',
  },
  '.MuiSelect-select': {
    padding: '6px 10px',
    paddingLeft: paddingLeft ?? '110px',
  },
  '.MuiInputBase-root': {
    background: '#EFF1F2',
    color: '#191C1E',
    borderRadius: '4px',
    marginTop: 0,
    width: width ?? defaultFieldWidth,

    '&.Mui-focused': {
      backgroundColor: '#65787E',
      color: '#fff',
    },
  },
  '.MuiFormLabel-root': {
    top: '50%',
    left: '8px',
    transform: 'translateY(-50%)',
    '&.Mui-focused': {
      color: '#fff',
    },
  },
  '.MuiInputAdornment-root': {
    marginTop: '2px',
  },
});

interface TextFieldControllerProps<T = FieldValues> extends UseControllerProps<T> {
  options: SelectOption[];
  width?: string;
  control: Control<T>;
  error?: boolean;
  helperText?: string;
  placeholder: string;
  label: string;
  labelWidth?: string;
  id: string;
  renderValue?: string;
  defaultOptionText?: string;
}

const inputProps = {
  style: { fontSize: 14 },
  disableUnderline: true,
};

export function FilterSelectController<T>({
  label,
  placeholder,
  options,
  width,
  labelWidth,
  control,
  name,
  renderValue,
  defaultOptionText,
  ...selectFieldProps
}: TextFieldControllerProps<T>): ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, value } }) => (
        <TextField
          {...selectFieldProps}
          variant="filled"
          fullWidth={false}
          select
          id={name}
          size="small"
          name={name}
          value={value}
          label={!value ? label : `${label}: `}
          placeholder={placeholder}
          onChange={(e) => {
            onChange(e);
            blurActiveElement();
          }}
          forwardRef={ref}
          sx={textFieldStyles(width, labelWidth)}
          InputProps={inputProps}
          SelectProps={{
            ...(renderValue && { renderValue: () => renderValue }),
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              sx: {
                '.MuiList-root': {
                  maxHeight: '450px',
                  width: '200px',
                },
              },
            },
          }}
        >
          {defaultOptionText && (
            <MenuItem value="">
              <em>{defaultOptionText}</em>
            </MenuItem>
          )}
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value} sx={{ fontSize: '14px', padding: '4px 24px' }}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
}
