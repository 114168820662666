import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { ReactElement } from 'react';
import { components, ControlProps } from 'react-select';

import { SelectOptionWithLabelString } from 'types';

export function AmenitiesControl(
  props: ControlProps<SelectOptionWithLabelString, true>,
): ReactElement {
  const { children, ...otherProps } = props;
  return (
    <components.Control {...otherProps}>
      <SearchRoundedIcon /> {children}
    </components.Control>
  );
}
