import { MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import { ReactElement } from 'react';

import { Button, Form, FormGroup, CheckboxGroup, ControlledTextField, SideModal } from 'components';
import { useAuth } from 'hooks';
import { BaseFormMode, ContactFormDefaultValues, ContactFormValues } from 'types';

import { ACQUISITION_CHANNEL_OPTIONS, COMMUNICATION_OPTIONS } from './constants';
import { useContactForm } from './hooks';

interface ContactFormProps {
  mode: BaseFormMode;
  defaultValues: ContactFormDefaultValues;
  formDisabled?: boolean;
  onSubmit: (values: ContactFormValues) => Promise<void>;
  title: string;
  close: () => void;
  isOpen: boolean;
}

export function ContactForm({
  defaultValues,
  onSubmit,
  title,
  mode,
  isOpen,
  close,
}: ContactFormProps): ReactElement {
  const isEditMode = mode === 'edit';

  const { handleOnSubmit, control, disableSubmit, isSubmitting, destroyForm, withConfirmClose } =
    useContactForm({
      defaultValues,
      onSubmit,
      close,
      isEditMode,
    });

  const { isReadOnlyAccess } = useAuth();

  return (
    <SideModal
      isOpen={isOpen}
      onClose={destroyForm}
      title={title}
      withConfirmClose={withConfirmClose}
    >
      <Form onSubmit={handleOnSubmit}>
        <div>
          <FormGroup>
            <ControlledTextField
              name="firstName"
              id="firstName"
              label="First name"
              placeholder="First name"
              control={control}
              showAllErrors={isEditMode}
              disabled={isReadOnlyAccess}
            />
          </FormGroup>
          <FormGroup>
            <ControlledTextField
              name="lastName"
              id="lastName"
              label="Last name"
              placeholder="Last name"
              control={control}
              showAllErrors={isEditMode}
              disabled={isReadOnlyAccess}
            />
          </FormGroup>
          <FormGroup>
            <ControlledTextField
              name="email"
              id="email"
              type="email"
              label="Email"
              placeholder="Email"
              control={control}
              showAllErrors={isEditMode}
              disabled={isReadOnlyAccess}
            />
          </FormGroup>
          <FormGroup>
            <ControlledTextField
              name="phone"
              id="phone"
              label="Phone number"
              placeholder="Phone number"
              control={control}
              showAllErrors={isEditMode}
              disabled={isReadOnlyAccess}
            />
          </FormGroup>
          <FormGroup>
            <CheckboxGroup
              control={control}
              options={COMMUNICATION_OPTIONS}
              label="Communication preference"
              name="contactStyle"
              showAllErrors={isEditMode}
              disabled={isReadOnlyAccess}
            />
          </FormGroup>
          <FormGroup>
            <ControlledTextField
              select
              name="cameFrom"
              id="cameFrom"
              label="Acquisition channel"
              placeholder="Acquisition channel"
              control={control}
              showAllErrors={isEditMode}
              disabled={isReadOnlyAccess}
            >
              {ACQUISITION_CHANNEL_OPTIONS.map((channel) => (
                <MenuItem key={channel.value} value={channel.value} sx={{ fontSize: '14px' }}>
                  {channel.label}
                </MenuItem>
              ))}
            </ControlledTextField>
          </FormGroup>
        </div>

        <Box display="flex" justifyContent="flex-end">
          <Button type="submit" disabled={disableSubmit || isReadOnlyAccess} loading={isSubmitting}>
            {isEditMode ? 'Save changes' : 'Create contact'}
          </Button>
        </Box>
      </Form>
    </SideModal>
  );
}
