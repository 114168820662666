import { styled } from '@mui/material';
import FormGroup, { FormGroupProps as MuiFormGroupProps } from '@mui/material/FormGroup';

interface FormGroupProps extends MuiFormGroupProps {
  marginbottom?: number;
}

const FormGroupW = styled(FormGroup)<FormGroupProps>`
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${(p) =>
    p.marginbottom || p.marginbottom === 0 ? `${p.marginbottom}px` : '32px'};
`;

export { FormGroupW as FormGroup };
