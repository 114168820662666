import { Palette, PaletteColor, Theme } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createMixins';

import { InquiryStateColor } from 'types';

const colorSchemeKeys: Record<InquiryStateColor, string> = {
  [InquiryStateColor.GREEN]: 'primary',
  [InquiryStateColor.YELLOW]: 'warning',
  [InquiryStateColor.RED]: 'error',
  [InquiryStateColor.GRAY]: 'secondary',
  [InquiryStateColor.BLUE]: 'info',
  [InquiryStateColor.PURPLE]: 'info',
};

export const getStatusColors = (
  scheme: InquiryStateColor,
  theme: Theme,
  isDisabled?: boolean,
): Partial<CSSProperties> => {
  const colorSchemeKey = colorSchemeKeys[scheme] || colorSchemeKeys.blue;

  const paletteColor = theme.palette[colorSchemeKey as keyof Palette] as PaletteColor;

  return {
    color: paletteColor.main,
    background: paletteColor.p010,

    '&:hover': {
      background: isDisabled ? paletteColor.p005 : paletteColor.p010,
    },
  };
};
