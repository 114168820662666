import { Dispatch, ReactElement, SetStateAction } from 'react';

import { Button, ConfirmModal } from 'components';
import { ButtonSizes } from 'components/Button/interface';
import { confirmDeleteModalConfig } from 'features/settings/config';

import { MessageTemplatesTable, MessageTemplateForm } from '../../components';
import { useMessageTemplates } from './hooks';
import { InnerPageHeader } from './styled';

interface MessageTemplatesProps {
  currentPage: number;
  pageLimit: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setPageLimit: Dispatch<SetStateAction<number>>;
}

export function MessageTemplates({
  currentPage,
  pageLimit,
  setCurrentPage,
  setPageLimit,
}: MessageTemplatesProps): ReactElement {
  const {
    isCreateFormModalOpen,
    isEditFormModalOpen,
    isConfirmDeleteModalOpen,
    isLoading,
    emptyState,
    templatesList,
    templatesTotalCount,
    editTemplateDefaultValues,
    handleEditTemplateClick,
    handleDeleteTemplate,
    handleCreateTemplateSubmit,
    handleEditTemplateSubmit,
    handleOpenCreateFormModal,
    handleCloseCreateFormModal,
    handleCloseEditFormModal,
    handleOpenConfirmDeleteModal,
    handleCloseConfirmDeleteModal,
  } = useMessageTemplates({ currentPage, pageLimit });

  return (
    <>
      <InnerPageHeader>
        <Button buttonSize={ButtonSizes.xSmall} onClick={handleOpenCreateFormModal}>
          Add message template
        </Button>
      </InnerPageHeader>
      <MessageTemplatesTable
        isLoading={isLoading}
        emptyState={emptyState}
        templates={templatesList}
        handleEditTemplate={handleEditTemplateClick}
        handleDeleteTemplate={handleOpenConfirmDeleteModal}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalCount={templatesTotalCount}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
      />
      <MessageTemplateForm
        mode="create"
        isOpen={isCreateFormModalOpen}
        onSubmit={handleCreateTemplateSubmit}
        handleClose={handleCloseCreateFormModal}
      />
      <MessageTemplateForm
        mode="edit"
        isOpen={isEditFormModalOpen}
        onSubmit={handleEditTemplateSubmit}
        handleClose={handleCloseEditFormModal}
        defaultValues={editTemplateDefaultValues}
      />

      {isConfirmDeleteModalOpen && (
        <ConfirmModal
          title={confirmDeleteModalConfig.title}
          cancelText={confirmDeleteModalConfig.cancelText}
          confirmText={confirmDeleteModalConfig.confirmText}
          content={confirmDeleteModalConfig.content}
          onClose={handleCloseConfirmDeleteModal}
          onSubmit={handleDeleteTemplate}
        />
      )}
    </>
  );
}
