import axios from 'axios';

import { AxiosResponseSuccess, ClientConfig } from 'api/types';
import { API_HOST } from 'settings';

const ACCESS_TOKEN = 'accessToken';

export async function makeHttpRequest<SuccessPayload>(
  config: ClientConfig,
): AxiosResponseSuccess<SuccessPayload> {
  const { headers, authorizeRequest = true } = config;
  const headersToSend = { ...headers };
  if (authorizeRequest) {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    headersToSend.Authorization = `Bearer ${accessToken}`;
  }

  return httpClient.request<SuccessPayload>({ ...config, headers: headersToSend });
}

export const httpClient = axios.create({
  baseURL: API_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
});
