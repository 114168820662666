import { AxiosResponse } from 'axios';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import {
  AxiosResponseError,
  ChangeCampaignTransitionStatusSuccess,
  PatchCampaignTransitionStatusParams,
} from 'api/types';
import { ContactState } from 'features/contactDetails/context/ContactDetailsPageContext';
import { useAuth, useModal } from 'hooks';
import { CampaignStatus, CloseCampaignReasons, ClosedLostCampaignStatus } from 'types';

interface UseCampaignStatusSwitchProps {
  status: CampaignStatus | CloseCampaignReasons;
  campaignId: string;
}

interface UseCampaignStatusSwitchReturn {
  displayStatus: CampaignStatus | ClosedLostCampaignStatus;
  isReadOnlyAccess: boolean;
  isCampaignClosed: boolean;
  campaignStatusList: Array<CampaignStatus | ClosedLostCampaignStatus>;
  closeCampaignReasonsList: CloseCampaignReasons[];
  isAvailableStatusesLoading: boolean;
  isAvailableStatusesFetching: boolean;
  showStatusesOptions: boolean;
  isCloseCampaignModalOpen: boolean;
  handleStatusButtonClick: () => void;
  handleClickAway: (event?: MouseEvent | TouchEvent) => void;
  handleChangeCampaignStatus: (status: CampaignStatus | CloseCampaignReasons) => void;
  handleStatusItemClick: (status: CampaignStatus | ClosedLostCampaignStatus) => void;
  handleCloseCloseCampaignModal: () => void;
}

export function useCampaignStatusSwitch({
  status,
  campaignId,
}: UseCampaignStatusSwitchProps): UseCampaignStatusSwitchReturn {
  const { isReadOnlyAccess } = useAuth();
  const [isFocused, setIsFocused] = useState(false);
  const contact = useContext(ContactState);
  const contactId = contact?.id || '';

  const {
    isOpen: isCloseCampaignModalOpen,
    handleOpenModal: handleOpenCloseCampaignModal,
    handleCloseModal: handleCloseCloseCampaignModal,
  } = useModal();

  const {
    data: { data } = {},
    isFetching: isAvailableStatusesFetching,
    isLoading: isAvailableStatusesLoading,
  } = useQuery(
    ['campaign-status-transition', campaignId],
    () => API_HANDLERS.CAMPAIGN.GET_CAMPAIGN_STATUS_TRANSITION(campaignId),
    { enabled: isFocused },
  );

  const availableCampaignStatusList: Array<CampaignStatus | CloseCampaignReasons> = useMemo(
    () => data?.to || [],
    [data?.to],
  );

  const closeCampaignReasonsList: CloseCampaignReasons[] = useMemo(
    () =>
      Object.values(CloseCampaignReasons).filter((status) =>
        availableCampaignStatusList.includes(status),
      ) || [],
    [availableCampaignStatusList],
  );

  const campaignStatusList: CampaignStatus[] = useMemo(
    () =>
      Object.values(CampaignStatus).filter((status) =>
        availableCampaignStatusList.includes(status),
      ) || [],
    [availableCampaignStatusList],
  );

  const isClosedReasons = !!closeCampaignReasonsList.length;

  const campaignStatusOptions = useMemo(
    () =>
      isClosedReasons
        ? [...campaignStatusList, ClosedLostCampaignStatus.CLOSED_LOST]
        : campaignStatusList,
    [campaignStatusList, isClosedReasons],
  );

  const changeCampaignStatus = useMutation<
    AxiosResponse<ChangeCampaignTransitionStatusSuccess>,
    AxiosResponseError,
    PatchCampaignTransitionStatusParams
  >(async ({ campaignId, status }: PatchCampaignTransitionStatusParams) =>
    API_HANDLERS.CAMPAIGN.CHANGE_CAMPAIGN_STATUS({ campaignId, status }),
  );

  const handleStatusButtonClick = useCallback(() => {
    setIsFocused((state) => !state);
  }, [setIsFocused]);

  const handleClickAway = useCallback(() => {
    setIsFocused(false);
  }, [setIsFocused]);

  const handleChangeCampaignStatus = useCallback(
    (status: CampaignStatus | CloseCampaignReasons): void => {
      if (isReadOnlyAccess) return;

      changeCampaignStatus.mutate(
        { status, campaignId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['contact', contactId]);
          },
        },
      );
    },
    [campaignId, changeCampaignStatus, contactId, isReadOnlyAccess],
  );

  const handleStatusItemClick = useCallback(
    (status: CampaignStatus | ClosedLostCampaignStatus) => {
      status === ClosedLostCampaignStatus.CLOSED_LOST
        ? handleOpenCloseCampaignModal()
        : handleChangeCampaignStatus(status as CampaignStatus | CloseCampaignReasons);
    },
    [handleChangeCampaignStatus, handleOpenCloseCampaignModal],
  );

  const showStatusesOptions =
    !isAvailableStatusesLoading &&
    !isAvailableStatusesFetching &&
    !!availableCampaignStatusList.length &&
    isFocused;

  const isCampaignClosed = status === CampaignStatus.CLOSED_WON || status in CloseCampaignReasons;

  const displayStatus = (
    status in CampaignStatus ? status : ClosedLostCampaignStatus.CLOSED_LOST
  ) as CampaignStatus | ClosedLostCampaignStatus;

  return {
    displayStatus,
    isReadOnlyAccess,
    isCampaignClosed,
    campaignStatusList: campaignStatusOptions,
    closeCampaignReasonsList,
    isAvailableStatusesLoading,
    isAvailableStatusesFetching,
    showStatusesOptions,
    isCloseCampaignModalOpen,
    handleStatusButtonClick,
    handleClickAway,
    handleChangeCampaignStatus,
    handleStatusItemClick,
    handleCloseCloseCampaignModal,
  };
}
