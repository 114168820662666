import * as yup from 'yup';

import { getMaxLengthText, NAME_PATTER_SYMBOLS } from 'validations/messages';
import { LETTERS_DIGITS_SYMBOLS } from 'validations/regexps';

export const buildingNotesFormSchema = yup.object().shape({
  notes: yup
    .string()
    .matches(LETTERS_DIGITS_SYMBOLS, NAME_PATTER_SYMBOLS)
    .max(1000, getMaxLengthText(1000))
    .nullable(),
});
