import { MenuList } from '@mui/material';
import { ReactElement } from 'react';
import { Control, useController } from 'react-hook-form';

import Popover from 'components/Popover';
import { useAuth } from 'hooks';
import { BuildingChatItemType, BuildingChatFormValues } from 'types';

import {
  DropdownIcon,
  LabelText,
  MenuItemW,
  MessageTypeW,
} from '../../../MessageTypePopper/styled';
import { MESSAGE_TYPE_ICONS, MESSAGE_TYPE_LABELS } from './config';

interface MessageTypePopperProps {
  control: Control<BuildingChatFormValues>;
  onClick?: () => void;
}

export function MessageTypePopper({ control, onClick }: MessageTypePopperProps): ReactElement {
  const {
    field: { value, onChange },
  } = useController({ control, name: 'type' });

  const { isReadOnlyAccess } = useAuth();

  const options = Object.entries(MESSAGE_TYPE_LABELS).map(([type, label]) => {
    const Icon = MESSAGE_TYPE_ICONS[type as BuildingChatItemType];
    return (
      <MenuItemW
        key={type}
        onClick={() => {
          onChange(type);
          onClick?.();
        }}
        sx={{ typography: 'body2' }}
      >
        <Icon variant="secondary" />
        {label}
      </MenuItemW>
    );
  });

  const labelText = MESSAGE_TYPE_LABELS[value];
  const LabelIcon = MESSAGE_TYPE_ICONS[value];

  const label = (
    <MessageTypeW sx={{ cursor: isReadOnlyAccess ? 'default' : 'pointer' }}>
      <LabelIcon variant="primary" />
      <LabelText variant="body2">{labelText}</LabelText>
      {!isReadOnlyAccess && <DropdownIcon />}
    </MessageTypeW>
  );

  return isReadOnlyAccess ? (
    label
  ) : (
    <Popover popupId="user-menu" anchor={label} sx={{ transform: 'translate(-6px, -4px)' }}>
      {() => <MenuList sx={{ width: '140px' }}>{options}</MenuList>}
    </Popover>
  );
}
