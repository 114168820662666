import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { InputAdornment } from '@mui/material';
import { MouseEventHandler, ReactElement } from 'react';

interface ClearButtonProps {
  disabled: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
}

export function ClearButton({ disabled, onClick }: ClearButtonProps): ReactElement {
  return (
    <InputAdornment position="end" onClick={!disabled ? onClick : undefined}>
      <CancelRoundedIcon
        sx={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          position: 'absolute',
          top: '12px',
          right: '16px',
        }}
      />
    </InputAdornment>
  );
}
