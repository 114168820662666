import isNil from 'lodash/isNil';

import { SearchArray, SearchStateType } from '../../types/query';

export const createSearchString = (searchArr: SearchArray[]): string =>
  searchArr.reduce((accum, [k, v]) => {
    const concatSign = accum.length === 0 ? '' : '&';

    if (isNil(v) || v === '') {
      return accum;
    }

    return (accum += `${concatSign}${k}=${v}`);
  }, '');

export function getQueryParams(key: string, paramString?: string): string | null;
export function getQueryParams(paramKeys: string[], paramString?: string): SearchStateType;
export function getQueryParams(
  paramKeys: string | string[],
  paramString?: string,
): SearchStateType | string | null {
  const params = new URLSearchParams(
    paramString === undefined ? window.location.search : paramString,
  );

  if (typeof paramKeys === 'string') {
    return params.get(paramKeys);
  }

  const query: SearchStateType = {};

  paramKeys.forEach((key) => {
    query[key] = params.get(key);
  });

  return query;
}
