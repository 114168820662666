import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import { Box, IconButton, styled, Typography } from '@mui/material';

export const FlexContainer = styled(Box)`
  display: flex;
`;

export const FileContainer = styled(FlexContainer)`
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const ActionsW = styled(FlexContainer)`
  align-items: center;
`;

export const IconButtonW = styled(IconButton)`
  width: 33px;
  height: 33px;
`;

export const ImageW = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

export const Preview = styled(Box)(({ theme }) => ({
  width: '44px',
  height: '44px',
  borderRadius: '4px',
  marginRight: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.p020,
  position: 'relative',
  overflow: 'hidden',
}));

export const FileName = styled(Typography)`
  max-width: 150px;
  line-height: 16px;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.black.main};
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
`;

export const NoImageIcon = styled(BrokenImageOutlinedIcon)(({ theme }) => ({
  color: theme.palette.black.light,
  marginRight: '4px',
}));
