import { styled, MenuItem as MuiMenuItem } from '@mui/material';

import { getMenuItemsStyles, getMenuWrapStyles } from 'styles/menu';

export const MenuContainer = styled('div')(({ theme }) => ({
  ...getMenuWrapStyles(theme),
  minWidth: '96px',
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => getMenuItemsStyles(theme));
