import { ReactElement, useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { InquiryDetailsFields } from 'features/inquiry/components/InquiryDetailsFields';
import { useEditInquiryDetailsForm } from 'features/inquiry/hooks';
import { TourDetailsFormValues, TourInquiry, UnassignedBuildingScheduleTourInquiry } from 'types';
import { objectsDifferences } from 'utils';

import { getTourDetailsDefaultValues } from './utils';
import { inquiryDetailsFormSchema } from './validationSchema';

interface TourDetailsProps {
  tour: TourInquiry | UnassignedBuildingScheduleTourInquiry;
  isDisabled?: boolean;
}

export function TourDetailsForm({ tour, isDisabled = false }: TourDetailsProps): ReactElement {
  const defaultValues = useMemo(() => getTourDetailsDefaultValues(tour), [tour]);

  const { mutateAsync: editDetailsMutation } = useMutation(
    API_HANDLERS.INQUIRIES.EDIT_TOUR_DETAILS,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contact');
      },
    },
  );

  const onSubmit = useCallback(
    async (values: TourDetailsFormValues) => {
      const diffs = defaultValues ? objectsDifferences(defaultValues, values) : values;

      await editDetailsMutation({ id: tour.id, data: diffs });
    },
    [defaultValues, editDetailsMutation, tour.id],
  );

  const { control } = useEditInquiryDetailsForm<TourDetailsFormValues>({
    defaultValues,
    onSubmit,
    validationSchema: inquiryDetailsFormSchema,
  });

  return <InquiryDetailsFields control={control} isTour isEditable={!isDisabled} />;
}
