import { EMPTY_VALUE } from 'constants/index';
import { CampaignBuilding, CampaignBuildingDetails } from 'types';
import { getFormattedBuildingAddress } from 'utils';

export function buildingDetailsAdapter(
  building?: CampaignBuilding,
): CampaignBuildingDetails | void {
  if (!building) {
    return;
  }

  const minRate = building.data.minRent ? `$${building.data.minRent}` : EMPTY_VALUE;
  const maxRate = building.data.maxRent ? `$${building.data.maxRent}` : EMPTY_VALUE;

  const buildingAddress = getFormattedBuildingAddress(building.address, building.data.city);

  return {
    id: building.data.id,
    name: building.data.name,
    address: buildingAddress,
    photo: building.data.photo || '',
    email: building.data.email || EMPTY_VALUE,
    phone: building?.data.phone || EMPTY_VALUE,
    website: building.data.website || EMPTY_VALUE,
    minMaxRate: `${minRate} - ${maxRate}`,
  };
}
