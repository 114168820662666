import { styled, ToggleButton as ToggleButtonMUI } from '@mui/material';

export const ToggleButton = styled(ToggleButtonMUI, {
  shouldForwardProp: (propname) => propname !== 'selected',
})(({ selected, theme }) => ({
  backgroundColor: selected ? theme.palette.secondary.main : theme.palette.secondary.p020,
  color: selected ? theme.palette.secondary.p010 : theme.palette.secondary.main,
  border: 'none',
  borderRadius: '4px',

  '&:hover': {
    backgroundColor: selected ? theme.palette.secondary.main : theme.palette.secondary.p020,
  },
}));
