import { Link, styled } from '@mui/material';

export const LinkW = styled(Link, {
  shouldForwardProp: (propname) => propname !== 'isDisabled',
})<{ isDisabled: boolean }>(({ theme, isDisabled }) => ({
  fontSize: '14px',
  marginRight: '15px',

  cursor: isDisabled ? 'not-allowed' : 'pointer',
  color: isDisabled ? theme.palette.secondary.main : theme.palette.primary.main,
  textDecorationColor: isDisabled ? theme.palette.secondary.main : theme.palette.primary.main,
}));
