import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ButtonProps, IconButton, styled } from '@mui/material';
import { ReactElement } from 'react';

interface CloseButtonProps extends ButtonProps {
  onClick?: () => void;
}

const IconButtonW = styled(IconButton)`
  width: 40px;
  height: 40px;
`;

export function CloseButton({ onClick, ...otherProps }: CloseButtonProps): ReactElement {
  return (
    <IconButtonW
      onClick={onClick}
      sx={({ palette }) => ({ color: palette.black.main })}
      {...otherProps}
    >
      <CloseRoundedIcon />
    </IconButtonW>
  );
}
