export const getURLParams = (): {
  [key: string]: string;
} => {
  return window.location.hash
    .replace('#', '')
    .split('&')
    .reduce((params: { [key: string]: string }, p) => {
      const tuple = p.split('=');
      params[tuple[0]] = tuple[1];
      return params;
    }, {});
};
