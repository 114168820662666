import { getLinks } from 'utils/getLinks';

export const Paths = {
  index: '/',
  login: 'login',
  profile: 'profile',
  redirect: 'redirect',
  noAccess: '403',
  notFound: '404',
  contacts: {
    index: 'contacts',
    contact: {
      index: ':contactId',
    },
  },
  settings: {
    index: 'settings',
  },
  userManagement: 'user-management',
  dashboard: {
    index: 'dashboard',
  },
  applyTable: {
    index: 'apply-table',
  },
  tourTable: {
    index: 'tour-table',
  },
} as const;

export type PathsType = typeof Paths;

export const Links = getLinks(Paths);
