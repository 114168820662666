import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import { IconButton } from '@mui/material';
import { ReactElement, useContext } from 'react';

import { EMPTY_VALUE } from 'constants/index';
import { SelectedBuildingIdState, useSelectBuilding } from 'features/buildings';
import { useAuth } from 'hooks';
import { BuildingStatus, TourInquiry, TourTypes } from 'types';
import { getFormattedDate, toCapitalize } from 'utils';

import { editIconStyles } from './constants';
import { DetailsCardW, Row, RowValue } from './styled';
import { getTimeValue } from './utils';

interface DetailsCardProps {
  guestCardRequested: TourInquiry['guestCardRequested'];
  pricingAndAvailabilityRequested: TourInquiry['pricingAndAvailabilityRequested'];
  tourDate: TourInquiry['tourDate'];
  tourTime: TourInquiry['tourTime'];
  tourType: TourInquiry['tourType'];
  comment: TourInquiry['comment'];
  handleOpenEditTourModal?: () => void;
}

export function DetailsCard({
  guestCardRequested,
  pricingAndAvailabilityRequested,
  tourDate,
  tourTime,
  tourType,
  comment,
  handleOpenEditTourModal,
}: DetailsCardProps): ReactElement {
  const type = tourType === TourTypes.Personal ? 'In-person' : tourType;

  const buildingId = useContext(SelectedBuildingIdState);
  const selectedBuilding = useSelectBuilding(buildingId || '');

  const { isReadOnlyAccess } = useAuth();

  const isDisabled =
    selectedBuilding?.status === BuildingStatus.DISMISSED ||
    isReadOnlyAccess ||
    !handleOpenEditTourModal;

  return (
    <DetailsCardW>
      {!isDisabled && (
        <IconButton
          sx={{
            ...editIconStyles,
            position: 'absolute',
          }}
          onClick={handleOpenEditTourModal}
        >
          <CreateRoundedIcon sx={{ width: '16px', height: '16px' }} />
        </IconButton>
      )}
      <Row>
        Requested date:
        <RowValue>{getFormattedDate(new Date(tourDate))} </RowValue>
      </Row>
      <Row>
        Requested time: <RowValue>{getTimeValue(tourTime)}</RowValue>
      </Row>
      <Row>
        Tour type: <RowValue>{toCapitalize(type)}</RowValue>
      </Row>
      <Row>
        Guest card requested: <RowValue>{guestCardRequested ? 'Yes' : 'No'}</RowValue>
      </Row>
      <Row>
        Pricing and availability requested:
        <RowValue>{pricingAndAvailabilityRequested ? 'Yes' : 'No'}</RowValue>
      </Row>
      <div>
        <div>Lead&rsquo;s comments:</div>
        <RowValue sx={{ margin: 0 }}>{comment || EMPTY_VALUE}</RowValue>
      </div>
    </DetailsCardW>
  );
}
