import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { ChangeEmployeeRoleParams } from 'api/types';
import { alert } from 'components';

interface UseChangeRoleMutationReturn {
  handleRoleChange: ({ employeeId, roles, cb }: HandleRoleChangeProps) => void;
  isLoading: boolean;
}

interface HandleRoleChangeProps extends ChangeEmployeeRoleParams {
  cb: () => void;
}

export function useChangeRoleMutation(): UseChangeRoleMutationReturn {
  const { mutateAsync: changeRoleMutation, isLoading } = useMutation(
    API_HANDLERS.EMPLOYEES.CHANGE_ROLE,
    {
      onSuccess: (resp) => {
        if (resp.data) {
          queryClient.invalidateQueries('employees');
          alert.success(`Roles successfully updated!`);
        }
      },
    },
  );

  async function handleRoleChange({ employeeId, roles, cb }: HandleRoleChangeProps) {
    await changeRoleMutation({ employeeId, roles });
    cb();
  }

  return {
    handleRoleChange,
    isLoading,
  };
}
