import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Navigate, useRoutes } from 'react-router-dom';

import { PageLayout, PrivateRoute, Page404, PageStatus } from 'components';
import { ApplyTablePage } from 'features/applyTable';
import { SettingsPage } from 'features/settings';
import { TourTablePage } from 'features/tourTable';
import { useAuth } from 'hooks';
import {
  SignInPage,
  ContactsPage,
  ContactDetailsPage,
  UserManagementPage,
  DashboardPage,
} from 'pages';
import { Paths } from 'settings';

export default function App(): ReactElement {
  const { isAdmin, isReadOnlyAccess, user } = useAuth();

  const elements = useRoutes([
    { path: Paths.index, element: <Navigate to={Paths.contacts.index} /> },
    { path: Paths.login, element: <SignInPage /> },
    {
      path: Paths.contacts.index,
      children: [
        { path: '', element: <PrivateRoute component={ContactsPage} /> },
        {
          path: Paths.contacts.contact.index,
          element: <PrivateRoute component={ContactDetailsPage} />,
        },
      ],
    },
    { path: Paths.redirect, element: <PageStatus loading /> },
    {
      path: Paths.settings.index,
      element:
        isAdmin && user ? (
          <PrivateRoute component={SettingsPage} />
        ) : !user ? (
          <SignInPage />
        ) : (
          <Page404 />
        ),
    },
    {
      path: Paths.userManagement,
      element:
        isAdmin && user ? (
          <PrivateRoute component={UserManagementPage} />
        ) : !user ? (
          <SignInPage />
        ) : (
          <Page404 />
        ),
    },
    {
      path: Paths.dashboard.index,
      element: !isReadOnlyAccess ? <PrivateRoute component={DashboardPage} /> : <Page404 />,
    },
    {
      path: Paths.applyTable.index,
      element: user ? <PrivateRoute component={ApplyTablePage} /> : <SignInPage />,
    },
    {
      path: Paths.tourTable.index,
      element: user ? <PrivateRoute component={TourTablePage} /> : <SignInPage />,
    },
    { path: '*', element: <Page404 /> },
  ]);

  return (
    <>
      <Helmet defaultTitle="Lighthouse">
        <meta name="description" content="Lighthouse" />
      </Helmet>

      <PageLayout>{elements}</PageLayout>

      {process.env.NODE_ENV === 'development' && (
        <ReactQueryDevtools
          position="bottom-right"
          initialIsOpen={false}
          toggleButtonProps={{ style: { opacity: '0.2' } }}
        />
      )}
    </>
  );
}
