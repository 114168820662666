import { Box, styled, Typography } from '@mui/material';

export const SystemMessageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  padding: '12px 16px',
  gap: '16px',
  backgroundColor: theme.palette.secondary.p010,
  borderRadius: '12px',
}));

export const MessageBody = styled('div')(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '16px',
  color: theme.palette.black.main,
}));

export const DateCaption = styled(Typography)(({ theme }) => ({
  flex: 'auto',
  textAlign: 'right',
  fontSize: '12px',
  lineHeight: '16px',
  color: theme.palette.black.light,
}));
