import { useEffect } from 'react';

import { useDispatch } from 'hooks';
import {
  getQueryParams,
  handleSelectCampaign,
  handleSelectBuilding,
  handleSelectInquiry,
} from 'utils';

import { QUERY_SEARCH_KEYS } from '../config';

export function useSelectDetailsItem(): void {
  const dispatch = useDispatch();

  const queryParams = getQueryParams(QUERY_SEARCH_KEYS);

  const { campaignId, buildingId, inquiryId } = queryParams;

  useEffect(() => {
    // TODO: add cleanup or remove timer
    if (campaignId && !buildingId && !inquiryId) {
      setTimeout(() => handleSelectCampaign(campaignId), 1000);
    }

    if (campaignId && buildingId && !inquiryId) {
      setTimeout(() => handleSelectBuilding(buildingId, campaignId), 1000);
    }

    if (campaignId && buildingId && inquiryId) {
      setTimeout(() => handleSelectInquiry(inquiryId, buildingId, campaignId), 1000);
    }
  }, [buildingId, campaignId, dispatch, inquiryId]);
}
