import { ReactComponent as EmailIcon } from 'assets/icons/communication/item-email.svg';
import { ReactComponent as PrivateNoteIcon } from 'assets/icons/communication/item-private-message.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/communication/message.svg';
import { MessageType } from 'types';

import { createStyledMessageIcon } from './styled';

export const MESSAGE_ITEM_TYPE_ICONS = {
  [MessageType.PRIVATE_NOTES]: createStyledMessageIcon(PrivateNoteIcon),
  [MessageType.OUTGOING_SMS]: createStyledMessageIcon(MessageIcon),
  [MessageType.OUTGOING_EMAIL]: createStyledMessageIcon(EmailIcon),
  [MessageType.INCOMING_EMAIL]: createStyledMessageIcon(EmailIcon),
  [MessageType.SYSTEM_MESSAGE]: null,
  [MessageType.INCOMING_SMS]: createStyledMessageIcon(MessageIcon),
};

export const MESSAGE_TITLE = {
  [MessageType.PRIVATE_NOTES]: 'Private note',
  [MessageType.OUTGOING_SMS]: 'Text message',
  [MessageType.INCOMING_SMS]: 'Text message',
  [MessageType.OUTGOING_EMAIL]: 'Email',
  [MessageType.INCOMING_EMAIL]: 'Email',
  [MessageType.SYSTEM_MESSAGE]: '',
};
