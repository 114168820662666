import { ReactElement } from 'react';

import { BuildingSource } from 'types';
import { getFormattedBuildingAddress } from 'utils';

import { BuildingDetails } from '../BuildingDetails';
import { BuildingPreview } from '../BuildingPreview';
import { ArrowIcon, BuildingW } from './styled';

interface BuildingCardProps {
  toggleExpanded: () => void;
  handleSelectBuilding: (id: string) => void;
  photo?: string;
  address: string;
  city: string;
  name: string;
  id: string;
  isExpanded?: boolean;
  status: string;
  source: BuildingSource;
}

export function BuildingCard({
  address,
  city,
  handleSelectBuilding,
  isExpanded,
  name,
  toggleExpanded,
  photo,
  id,
  status,
  source,
}: BuildingCardProps): ReactElement {
  return (
    <BuildingW>
      <ArrowIcon $isOpen={isExpanded || false} onClick={toggleExpanded} />
      <BuildingPreview source={source} photo={photo} />
      <BuildingDetails
        name={name}
        address={getFormattedBuildingAddress(address, city)}
        onClick={() => handleSelectBuilding(id)}
        status={status}
      />
    </BuildingW>
  );
}
