import { Collapse } from '@mui/material';
import { ReactElement, useState } from 'react';

import { WishlistBuilding } from 'features/wishlist/components';

import { useWishlist } from './hooks';
import {
  ArrowIcon,
  BuildingsCount,
  BuildingsListW,
  DividerW,
  Title,
  WishlistTitleW,
  WishlistW,
} from './styled';

export function Wishlist(): ReactElement | null {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = () => {
    setIsOpen((prev) => !prev);
  };
  const { wishlistBuildings } = useWishlist();

  const buildingsCount = wishlistBuildings.length;

  if (buildingsCount === 0) {
    return null;
  }

  return (
    <div>
      <WishlistW>
        <WishlistTitleW onClick={handleChange}>
          <Title variant="body2">Wishlist</Title>
          <BuildingsCount>
            {buildingsCount} {buildingsCount === 1 ? 'building' : 'buildings'}
          </BuildingsCount>
        </WishlistTitleW>
        <ArrowIcon open={isOpen} onClick={handleChange} />
      </WishlistW>

      <Collapse in={isOpen}>
        <BuildingsListW>
          {wishlistBuildings.map((building) => (
            <WishlistBuilding key={building.id} building={building} />
          ))}
        </BuildingsListW>
      </Collapse>

      <DividerW />
    </div>
  );
}
