import { EmployeeRole } from 'types';

export const defaultValues = {
  email: '',
  phone: '',
  lastName: '',
  firstName: '',
  cameFrom: '',
};

export const rolesAllowedToAddContact = [
  EmployeeRole.LIGHTKEEPERS,
  EmployeeRole.DISPATCHERS,
  EmployeeRole.SEARCHLIGHTS,
];
