import { ReactElement } from 'react';

import { useAddUnassignedBuildingToCampaign } from 'features/unassignedBuildings/hooks';
import { ContactDetailsSelectItem, UnassignedBuilding } from 'types';

import { AddToCampaignModal } from '../AddBuildingToCampaignModal';
import { UnassignedBuildingItem } from '../UnassignedBuildingItem';
import { useUnassignedBuildingsList } from './hooks';
import { ListWrapper } from './styled';

interface UnassignedBuildingsListProps {
  buildings: UnassignedBuilding[];
}

export function UnassignedBuildingsList({ buildings }: UnassignedBuildingsListProps): ReactElement {
  const { selectedBuildingId, selectedItemType, handleSelectBuilding } =
    useUnassignedBuildingsList();

  const {
    isAddToCampaignModalOpen,
    activeCampaigns,
    activeCampaignsCount,
    handleCloseAddToCampaignModal,
    handleAddToCampaign,
    submitAddUnassignedBuildingToCampaign,
  } = useAddUnassignedBuildingToCampaign();

  return (
    <ListWrapper>
      {buildings.map((building) => (
        <UnassignedBuildingItem
          key={building.id}
          building={building}
          isSelected={
            building.id === selectedBuildingId &&
            selectedItemType === ContactDetailsSelectItem.Building
          }
          handleSelectBuilding={handleSelectBuilding}
          handleAddToCampaign={handleAddToCampaign}
          disableAddToCampaignButton={!activeCampaignsCount}
        />
      ))}

      {isAddToCampaignModalOpen && (
        <AddToCampaignModal
          activeCampaigns={activeCampaigns}
          handleCloseModal={handleCloseAddToCampaignModal}
          handleSubmit={submitAddUnassignedBuildingToCampaign}
        />
      )}
    </ListWrapper>
  );
}
