import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { Control, useForm } from 'react-hook-form';

import { EmptyStateArgs } from 'components';
import { PAGE_LIMIT } from 'constants/index';
import { EmployeesContext } from 'context';
import { QUERY_SEARCH_KEYS } from 'features/applyTable/config';
import { employeesOptionsAdapter } from 'features/contacts/adapters';
import { getContactOwnerDisplayValue } from 'features/contacts/utils';
import { useDebouncedValue, useSearch, useSetQueryParams } from 'hooks';
import { EmptyStateIcons, ApplyInquiryTableItem, SelectOption } from 'types';
import { getQueryParams } from 'utils';

import { useApplyInquiriesQuery } from './useApplyInquiriesQuery';
import { useApplyInquiriesStatesQuery } from './useApplyInquiriesStatesQuery';

interface ApplyAssigneeFilterValues {
  assigneeId: string;
}

interface ApplyStatusFilterValues {
  status: string;
}

interface UseApplyTableReturn {
  applyInquiries: ApplyInquiryTableItem[];
  applyInquiriesTotal: number;
  isApplyInquiriesLoading: boolean;
  emptyState: EmptyStateArgs;
  currentPage: number;
  pageLimit: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setPageLimit: Dispatch<SetStateAction<number>>;
  search: string;
  onSetSearch: (search: string) => void;
  onClearSearch: () => void;
  applyAssigneeFilterControl: Control<ApplyAssigneeFilterValues>;
  applyAssigneeOptions: SelectOption[];
  assigneeDisplayValue: string | undefined;
  applyStatusFilterControl: Control<ApplyStatusFilterValues>;
  applyStatusOptions: SelectOption[];
  statusDisplayValue: string | undefined;
}

export function useApplyTable(): UseApplyTableReturn {
  const querySearch = useMemo(() => getQueryParams(QUERY_SEARCH_KEYS), []);

  const [currentPage, setCurrentPage] = useState(Number(querySearch.page) || 0);
  const [pageLimit, setPageLimit] = useState(Number(querySearch.limit) || PAGE_LIMIT);

  const { search, onClear, onSetSearch } = useSearch(querySearch.search?.toString() || '');
  const debouncedSearch = useDebouncedValue(search, 500);
  const searchParam = search.length ? debouncedSearch : '';

  const handleSearchChange = (search: string) => {
    onSetSearch(search);
    setCurrentPage(0);
  };

  const handleClearSearch = () => onClear(setCurrentPage);

  const { control: applyAssigneeFilterControl, watch: assigneeIdWatch } =
    useForm<ApplyAssigneeFilterValues>({
      defaultValues: { assigneeId: querySearch.ownerId?.toString() || '' },
      mode: 'onTouched',
    });

  const assigneeId = assigneeIdWatch('assigneeId');

  const { employeesList, employeesContextListError } = useContext(EmployeesContext);

  const applyAssigneeOptions = employeesOptionsAdapter({
    employeesData: employeesList.map((employee) => ({
      firstName: employee.user.givenName,
      id: employee.id,
      lastName: employee.user.familyName ?? '',
      photo: employee.user.picture,
    })),
    contactOwner: assigneeId,
  });

  const assigneeDisplayValue = getContactOwnerDisplayValue({
    contactOwner: assigneeId,
    options: applyAssigneeOptions,
  });

  const { applyInquiriesStates, isApplyInquiriesStatesError } = useApplyInquiriesStatesQuery();

  const applyStatusOptions: SelectOption<string>[] =
    applyInquiriesStates?.map(({ id, name }) => ({
      value: id,
      label: name,
    })) || [];

  const { control: applyStatusFilterControl, watch: applyStatusWatch } =
    useForm<ApplyStatusFilterValues>({
      defaultValues: { status: querySearch.status?.toString() || '' },
      mode: 'onTouched',
    });

  const statusId = applyStatusWatch('status');

  const statusDisplayValue = applyInquiriesStates?.find(({ id }) => id === statusId)?.name;

  useSetQueryParams(
    {
      page: currentPage,
      limit: pageLimit,
      search: searchParam,
      assignee: assigneeId,
      status: statusId,
    },
    QUERY_SEARCH_KEYS,
  );

  const { applyInquiriesData, isApplyInquiriesLoading, isApplyInquiriesError } =
    useApplyInquiriesQuery({
      page: currentPage,
      limit: pageLimit,
      search: searchParam,
      assigneeId,
      statusId,
    });

  const emptyState: EmptyStateArgs = useMemo(() => {
    if (isApplyInquiriesError || employeesContextListError || isApplyInquiriesStatesError) {
      return {
        shouldAppear: true,
        title: 'Oops, something went wrong',
        description: 'Please try to reload this page',
        variant: EmptyStateIcons.ERROR,
      };
    }

    if (
      (searchParam.length || assigneeId || statusId) &&
      !isApplyInquiriesLoading &&
      applyInquiriesData?.totalCount === 0
    ) {
      return {
        shouldAppear: true,
        title: "Couldn't find anyone",
        variant: EmptyStateIcons.EMPTY_SEARCH,
      };
    }

    return {
      shouldAppear: Boolean(!isApplyInquiriesLoading && applyInquiriesData?.totalCount === 0),
      title: `Active applications will be here`,
      variant: EmptyStateIcons.EMPTY_APPLY_TABLE,
    };
  }, [
    isApplyInquiriesError,
    employeesContextListError,
    isApplyInquiriesStatesError,
    searchParam.length,
    assigneeId,
    statusId,
    isApplyInquiriesLoading,
    applyInquiriesData?.totalCount,
  ]);

  return {
    applyInquiries: applyInquiriesData?.items || [],
    applyInquiriesTotal: applyInquiriesData?.totalCount || 0,
    isApplyInquiriesLoading,
    emptyState,
    currentPage,
    pageLimit,
    setCurrentPage,
    setPageLimit,
    search,
    onSetSearch: handleSearchChange,
    onClearSearch: handleClearSearch,
    applyAssigneeFilterControl,
    applyAssigneeOptions,
    assigneeDisplayValue,
    applyStatusFilterControl,
    applyStatusOptions,
    statusDisplayValue,
  };
}
