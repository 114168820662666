import { ReactElement } from 'react';

import { InquiryStatus, InquiryType, TourInquiry } from 'types';

import { ApplyStatusBlock } from './ApplyStatusBlock';
import { TourStatusBlock } from './TourStatusBlock';

interface InquiryStatusProps {
  status: InquiryStatus;
  inquiryId: TourInquiry['id'];
  inquiryType: InquiryType;
  isDisabled: boolean;
}

export function InquiryStatusBlock({
  inquiryId,
  status,
  inquiryType,
  isDisabled,
}: InquiryStatusProps): ReactElement {
  const InquiryComponent = inquiryType === 'tour' ? TourStatusBlock : ApplyStatusBlock;

  return <InquiryComponent inquiryId={inquiryId} status={status} isDisabled={isDisabled} />;
}
