import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import { ReactElement, useContext } from 'react';

import { openCampaignInfo } from 'features/contactDetails/context/actions';
import { CampaignInfoState } from 'features/contactDetails/context/ContactDetailsPageContext';
import { useAuth, useDispatch } from 'hooks';
import { CampaignDetails } from 'types';

import { editIconStyles, arrowIconStyles } from '../constants';
import {
  SectionW,
  SectionHeader,
  SectionTitle,
  ValueItemsW,
  DataW,
  Title,
  Value,
  IconButtonW,
} from '../styled';

interface CampaignInfoProps {
  title: string;
  data: CampaignDetails;
  labelsConfig: { [key: string]: string };
  isEditable?: boolean;
  openModal?: () => void;
}

export function CampaignInfo({
  title,
  data,
  labelsConfig,
  isEditable,
  openModal,
}: CampaignInfoProps): ReactElement {
  const isCampaignInfoOpen = useContext(CampaignInfoState);

  const dispatch = useDispatch();

  const setSectionOpen = () => dispatch(openCampaignInfo({ isOpen: !isCampaignInfoOpen }));

  const { isReadOnlyAccess } = useAuth();

  return (
    <SectionW>
      <SectionHeader>
        <SectionTitle>{title}</SectionTitle>
        {isEditable && !isReadOnlyAccess && (
          <IconButtonW onClick={openModal}>
            <CreateRoundedIcon sx={editIconStyles} />
          </IconButtonW>
        )}
      </SectionHeader>

      {isCampaignInfoOpen && (
        <ValueItemsW>
          {Object.keys(labelsConfig).map((key) => (
            <DataW key={key}>
              <Title>{labelsConfig[key]}</Title>
              <Value>{data[key as keyof CampaignDetails]}</Value>
            </DataW>
          ))}
        </ValueItemsW>
      )}

      <ArrowRightRoundedIcon
        sx={{
          ...arrowIconStyles,
          position: 'absolute',
          transform: isCampaignInfoOpen ? 'rotate(90deg)' : 'rotate(0)',
        }}
        onClick={setSectionOpen}
      />
    </SectionW>
  );
}
