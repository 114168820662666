import { EmployeeRole } from 'types';
import { toCapitalize } from 'utils';

export function transformSelectedValues(values: string[]): string {
  return values
    .map((role) => {
      if (role === EmployeeRole.ADMINISTRATORS) {
        return 'Admin';
      }
      return toCapitalize(role);
    })
    .join(', ');
}
