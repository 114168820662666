import { ReactElement } from 'react';
import { components, InputProps } from 'react-select';

import { SelectOptionWithLabelString } from 'types';

import { customInputStyles } from './styled';

export function AmenitiesInput(props: InputProps<SelectOptionWithLabelString, true>): ReactElement {
  return <components.Input style={customInputStyles} {...props} />;
}
