import { useState } from 'react';

import { useModal } from 'hooks';
import { Campaign, CampaignBuilding } from 'types';

interface UseCreateTourModalReturn {
  buildingId?: string;
  isOpen: boolean;
  handleCloseModal: () => void;
  handleOpenModal: (buildingId: CampaignBuilding['id'], campaignId: Campaign['id']) => void;
  campaignId?: Campaign['id'];
}

export function useCreateTourModal(): UseCreateTourModalReturn {
  const createTourModalControl = useModal();

  const [tourBuildingId, setTourBuildingId] = useState<CampaignBuilding['id'] | undefined>();
  const [campaignId, setCampaignId] = useState<Campaign['id'] | undefined>();

  const handleOpenModal = (buildingId: CampaignBuilding['id'], campaignId: Campaign['id']) => {
    setTourBuildingId(buildingId);
    setCampaignId(campaignId);
    createTourModalControl.handleOpenModal();
  };

  return {
    buildingId: tourBuildingId,
    isOpen: createTourModalControl.isOpen,
    handleCloseModal: createTourModalControl.handleCloseModal,
    handleOpenModal,
    campaignId,
  };
}
