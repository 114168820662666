import { Box } from '@mui/material';
import { ReactElement } from 'react';

import { ReactComponent as CheckMark } from 'assets/icons/checkMarkRoundGreen.svg';
import { EMPTY_VALUE } from 'constants/index';

interface TransformConfirmedProps {
  value?: string;
  isConfirmed: boolean;
}

export function transformConfirmedValues({
  value,
  isConfirmed,
}: TransformConfirmedProps): ReactElement {
  return (
    <>
      {value ? (
        <Box display="flex" alignItems="center" sx={{ height: '20px' }}>
          <span style={{ marginRight: '6px' }}>{value}</span> {isConfirmed && <CheckMark />}
        </Box>
      ) : (
        EMPTY_VALUE
      )}
    </>
  );
}
