import AddIconMUI from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuItem as MenuItemMUI, MenuList as MenuListMUI, styled } from '@mui/material';

export const AddIcon = styled(AddIconMUI)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginRight: '8px',
}));

export const MoreIcon = styled(MoreVertIcon)(({ theme }) => ({
  color: theme.palette.black.main,
}));

export const MenuList = styled(MenuListMUI)(() => ({
  width: 'max-content',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '8px 0',
}));

export const MenuItem = styled(MenuItemMUI)(({ theme }) => ({
  color: theme.palette.black.main,
  '&.Mui-disabled': {
    cursor: 'not-allowed',
  },
}));
