import { ReactElement } from 'react';

import { TourDetails, ApplicationDetails } from 'features/inquiry/components';
import { UnassignedBuildingApplyInquiry, UnassignedBuildingScheduleTourInquiry } from 'types';

interface UnassignedBuildingInquiryDetailsProps {
  isTour: boolean;
  inquiry: UnassignedBuildingApplyInquiry | UnassignedBuildingScheduleTourInquiry;
}

export function UnassignedBuildingInquiryDetails({
  isTour,
  inquiry,
}: UnassignedBuildingInquiryDetailsProps): ReactElement {
  return (
    <>
      {isTour ? (
        <TourDetails tour={inquiry as UnassignedBuildingScheduleTourInquiry} isUnassignedBuilding />
      ) : (
        <ApplicationDetails
          applyInquiry={inquiry as UnassignedBuildingApplyInquiry}
          isUnassignedBuilding
        />
      )}
    </>
  );
}
