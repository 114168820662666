import * as yup from 'yup';

import {
  getMaxLengthText,
  NAME_PATTER_SYMBOLS,
  NO_SPACES_ALLOWED,
  WEBSITE_FORMAT,
} from 'validations/messages';
import {
  LETTERS_DIGITS_SYMBOLS,
  NO_EMPTY_SPACES_REGEX,
  URL_FORMAT_REGEX,
} from 'validations/regexps';

export const applyDetailsFormSchema = yup.object().shape({
  propertyApplicationUrl: yup
    .string()
    .when({
      is: (v: string) => v.length > 0,
      then(sch) {
        return sch.test('propertyApplicationUrl', WEBSITE_FORMAT, (v) =>
          URL_FORMAT_REGEX.test(v as string),
        );
      },
    })
    .matches(NO_EMPTY_SPACES_REGEX, NO_SPACES_ALLOWED)
    .max(2083, getMaxLengthText(2083))
    .nullable(),
  guestCardSent: yup.boolean(),
  pricingAndAvailability: yup
    .string()
    .matches(LETTERS_DIGITS_SYMBOLS, NAME_PATTER_SYMBOLS)
    .max(1000, getMaxLengthText(1000))
    .nullable(),
  commentResponse: yup
    .string()
    .matches(LETTERS_DIGITS_SYMBOLS, NAME_PATTER_SYMBOLS)
    .max(1000, getMaxLengthText(1000))
    .nullable(),
  notes: yup
    .string()
    .matches(LETTERS_DIGITS_SYMBOLS, NAME_PATTER_SYMBOLS)
    .max(1000, getMaxLengthText(1000))
    .nullable(),
});
