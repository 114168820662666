import { useEffect } from 'react';

export function useValidationTrigger(
  trigger: () => void,
  validate: boolean,
  ...deps: unknown[]
): void {
  useEffect(() => {
    if (validate) {
      setTimeout(() => trigger(), 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, validate, ...deps]);
}
