import { Control, useForm, UseFormSetValue } from 'react-hook-form';

export type ContactFiltersDefaultValues = ContactFiltersValues;

export interface ContactFiltersValues {
  filterId: string;
}

export interface UseContactFiltersValuesReturn {
  contactsFilterControl: Control<ContactFiltersValues>;
  contactFilter: string;
  setValue: UseFormSetValue<ContactFiltersValues>;
}

export function useContactFilters(initialValue: string): UseContactFiltersValuesReturn {
  const { control, watch, setValue } = useForm<ContactFiltersValues>({
    defaultValues: { filterId: initialValue },
    mode: 'onTouched',
  });

  const filterId = watch('filterId');

  return {
    contactsFilterControl: control,
    contactFilter: filterId,
    setValue,
  };
}
