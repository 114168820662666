import { Box, styled } from '@mui/material';

export const PreviewWrapper = styled(Box, {
  shouldForwardProp: (propname) => propname !== 'isnew',
})<{ isnew: boolean }>(({ isnew }) => ({
  fontWeight: isnew ? 600 : 'unset',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));
