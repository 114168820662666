import { ReactElement } from 'react';
import { FileWithPath } from 'react-dropzone';
import { Control } from 'react-hook-form';

import { FileWithId, MessageType, NewMessageFormValues } from 'types';

import { EmailFormInput, PrivateNoteInput, SmsTextInput } from './components';

interface NewMessageInputProps {
  messageType: MessageType;
  isValid: boolean;
  handleAddMention: (id: string, display: string) => void;
  control: Control<NewMessageFormValues>;
  handleFormSubmit: () => void;
  withCc: boolean;
  withBcc: boolean;
  setFiles: (files: FileWithPath[]) => void;
  handleFileDelete: (id: string) => void;
  attachedFiles: FileWithId[];
  isReplyMode?: boolean;
  threads?: string[];
  cancelReplyMode: () => void;
}

export function NewMessageInput({
  messageType,
  isValid,
  handleAddMention,
  control,
  handleFormSubmit,
  withCc,
  withBcc,
  setFiles,
  handleFileDelete,
  attachedFiles,
  isReplyMode,
  threads,
  cancelReplyMode,
}: NewMessageInputProps): ReactElement {
  switch (messageType) {
    case MessageType.PRIVATE_NOTES:
      return (
        <PrivateNoteInput
          isValid={isValid}
          handleFormSubmit={handleFormSubmit}
          handleAddMention={handleAddMention}
          control={control}
        />
      );

    case MessageType.OUTGOING_SMS:
      return (
        <SmsTextInput
          control={control}
          isValid={isValid}
          handleFormSubmit={handleFormSubmit}
          setFiles={setFiles}
          handleFileDelete={handleFileDelete}
          attachedFiles={attachedFiles}
        />
      );

    default:
      return (
        <EmailFormInput
          control={control}
          isValid={isValid}
          withCc={withCc}
          withBcc={withBcc}
          handleFormSubmit={handleFormSubmit}
          setFiles={setFiles}
          handleFileDelete={handleFileDelete}
          attachedFiles={attachedFiles}
          isReplyMode={isReplyMode}
          threads={threads}
          cancelReplyMode={cancelReplyMode}
        />
      );
  }
}
