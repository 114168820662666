import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { Box, styled } from '@mui/material';

export const SummaryW = styled(Box)`
  display: flex;
  align-items: center;
`;

export const ApplyIconW = styled(AssignmentTurnedInOutlinedIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
  width: '17px',
}));

export const ItemW = styled(Box)`
  display: flex;
  align-items: center;
  margin-right: 7px;
`;

export const NumberW = styled(Box)`
  width: 25px;
`;
