export * from './path';

// Enum values should correspond with .env variable names suffixes
// Example: 'REACT_APP_API_STAGE' === `REACT_APP_API_${Environment.STAGING.toUpperCase()}`
enum Environment {
  LOCAL = 'local',
  DEVELOPMENT = 'dev',
  STAGING = 'stage',
  PRODUCTION = 'prod',
}

enum Protocol {
  HTTPS = 'https',
  HTTP = 'http',
  WEB_SOCKETS = 'ws',
  WEB_SOCKETS_SECURE = 'wss',
}

const ENV = process.env.REACT_APP_ENV as Environment;
const PROTOCOL = ENV === Environment.LOCAL ? Protocol.HTTP : Protocol.HTTPS;

export const API_HOST = process.env[`REACT_APP_API_${ENV.toUpperCase()}`];
export const OAUTH_CLIENT_ID = process.env[`REACT_APP_OAUTH_CLIENT_ID_${ENV.toUpperCase()}`];
export const REDIRECT_URI = `${PROTOCOL}://${window.location.host}/redirect`;
