import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactElement } from 'react';

import { MoreButton } from './styled';
interface ShowMoreButtonProps {
  onClick: () => void;
}
export function ShowMoreButton({ onClick }: ShowMoreButtonProps): ReactElement {
  return (
    <MoreButton onClick={onClick}>
      <MoreHorizIcon fontSize="small" />
    </MoreButton>
  );
}
