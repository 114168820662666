import { format, formatISO } from 'date-fns';

import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_FORMAT_WITH_TIME } from 'constants/index';

export const getFormattedDate = (date: Date, formatString = DEFAULT_DATE_FORMAT): string =>
  format(date, formatString);

export const getFormattedDateWithTime = (
  date: Date,
  formatString = DEFAULT_DATE_FORMAT_WITH_TIME,
): string => format(date, formatString);

export const getFormattedDateISOString = (date: Date): string => formatISO(date);
