import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { EmptyStateArgs } from 'components';
import { PAGE_LIMIT } from 'constants/index';
import { EmployeesContext } from 'context';
import { employeesOptionsAdapter } from 'features/contacts/adapters';
import { getContactOwnerDisplayValue } from 'features/contacts/utils';
import { useDebouncedValue, useSearch, useSetQueryParams } from 'hooks';
import { EmptyStateIcons, SelectOption, TourInquiryTableItem } from 'types';
import { getQueryParams } from 'utils';

import { QUERY_SEARCH_KEYS } from '../config';
import { useTourAssigneeFilter, UseTourAssigneeFilterReturn } from './useTourAssigneeFilter';
import { useTourInquiriesQuery } from './useTourInquiriesQuery';
import { useTourInquiriesStatesQuery } from './useTourInquiriesStatesQuery';
import { useTourStatusFilter, UseTourStatusFilterReturn } from './useTourStatusFilter';

interface UseTourTableReturn {
  tourInquiries: TourInquiryTableItem[];
  tourInquiriesTotal: number;
  isTourInquiriesLoading: boolean;
  emptyState: EmptyStateArgs;
  currentPage: number;
  pageLimit: number;
  search: string;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setPageLimit: Dispatch<SetStateAction<number>>;
  onSetSearch: (search: string) => void;
  onClearSearch: () => void;
  tourAssigneeFilterControl: UseTourAssigneeFilterReturn['tourAssigneeFilterControl'];
  tourAssigneeOptions: SelectOption[];
  assigneeDisplayValue: string | undefined;
  tourStatusFilterControl: UseTourStatusFilterReturn['tourStatusFilterControl'];
  tourStatusOptions: SelectOption[];
  statusDisplayValue: string | undefined;
}

export function useTourTable(): UseTourTableReturn {
  const querySearch = useMemo(() => getQueryParams(QUERY_SEARCH_KEYS), []);

  const [currentPage, setCurrentPage] = useState(Number(querySearch.page) || 0);
  const [pageLimit, setPageLimit] = useState(Number(querySearch.limit) || PAGE_LIMIT);

  const { search, onClear, onSetSearch } = useSearch(querySearch.search?.toString() || '');
  const debouncedSearch = useDebouncedValue(search, 500);
  const searchParam = search.length ? debouncedSearch : '';

  const handleSearchChange = (search: string) => {
    onSetSearch(search);
    setCurrentPage(0);
  };

  const handleClearSearch = () => onClear(setCurrentPage);

  const { tourAssigneeFilterControl, assigneeId } = useTourAssigneeFilter(
    querySearch.ownerId?.toString() || '',
  );

  const { employeesList, employeesContextListError } = useContext(EmployeesContext);

  const tourAssigneeOptions = employeesOptionsAdapter({
    employeesData: employeesList.map((employee) => ({
      firstName: employee.user.givenName,
      id: employee.id,
      lastName: employee.user.familyName ?? '',
      photo: employee.user.picture,
    })),
    contactOwner: assigneeId,
  });

  const assigneeDisplayValue = getContactOwnerDisplayValue({
    contactOwner: assigneeId,
    options: tourAssigneeOptions,
  });

  const { tourInquiriesStates, isTourInquiriesStatesError } = useTourInquiriesStatesQuery();

  const tourStatusOptions: SelectOption<string>[] =
    tourInquiriesStates?.map(({ id, name }) => ({
      value: id,
      label: name,
    })) || [];

  const { tourStatusFilterControl, statusId } = useTourStatusFilter(
    querySearch.status?.toString() || '',
  );

  const statusDisplayValue = tourInquiriesStates?.find(({ id }) => id === statusId)?.name;

  useSetQueryParams(
    {
      page: currentPage,
      limit: pageLimit,
      search: searchParam,
      assignee: assigneeId,
      status: statusId,
    },
    QUERY_SEARCH_KEYS,
  );

  const { tourInquiriesData, isTourInquiriesLoading, isTourInquiriesError } = useTourInquiriesQuery(
    {
      page: currentPage,
      limit: pageLimit,
      search: searchParam,
      assigneeId,
      statusId,
    },
  );

  const emptyState: EmptyStateArgs = useMemo(() => {
    if (isTourInquiriesError || employeesContextListError || isTourInquiriesStatesError) {
      return {
        shouldAppear: true,
        title: 'Oops, something went wrong',
        description: 'Please try to reload this page',
        variant: EmptyStateIcons.ERROR,
      };
    }

    if (
      (searchParam.length || assigneeId || statusId) &&
      !isTourInquiriesLoading &&
      tourInquiriesData?.totalCount === 0
    ) {
      return {
        shouldAppear: true,
        title: "Couldn't find anyone",
        variant: EmptyStateIcons.EMPTY_SEARCH,
      };
    }

    return {
      shouldAppear: Boolean(!isTourInquiriesLoading && tourInquiriesData?.totalCount === 0),
      title: `Active tours will be here`,
      variant: EmptyStateIcons.EMPTY_TOUR_TABLE,
    };
  }, [
    assigneeId,
    employeesContextListError,
    isTourInquiriesError,
    isTourInquiriesLoading,
    isTourInquiriesStatesError,
    searchParam.length,
    statusId,
    tourInquiriesData?.totalCount,
  ]);

  return {
    tourInquiries: tourInquiriesData?.items || [],
    tourInquiriesTotal: tourInquiriesData?.totalCount || 0,
    isTourInquiriesLoading,
    emptyState,
    currentPage,
    pageLimit,
    search,
    setCurrentPage,
    setPageLimit,
    onSetSearch: handleSearchChange,
    onClearSearch: handleClearSearch,
    tourAssigneeFilterControl,
    tourAssigneeOptions,
    assigneeDisplayValue,
    tourStatusFilterControl,
    tourStatusOptions,
    statusDisplayValue,
  };
}
