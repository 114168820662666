import { styled, FormControlLabel as FormControlLabelMUI } from '@mui/material';

export const FormControlLabel = styled(FormControlLabelMUI)(() => ({
  '&:not(:last-of-type)': {
    marginBottom: '48px',
  },
}));

export const CampaignsList = styled('div')(() => ({
  marginTop: '24px',
  overflowX: 'hidden',
  overflowY: 'auto',
}));
