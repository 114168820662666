import { useCallback, useState } from 'react';

import { useModal } from 'hooks';
import {
  ActiveInquiryRequestsModalState,
  ActiveListRequestsModalState,
  DashboardItemActiveInquiryRequestsItem,
  DashboardItemActiveListRequestItem,
} from 'types';

interface UseActiveRequestsModalsReturn {
  isTourRequestsModalOpen: boolean;
  handleOpenTourRequestsModal: (
    requests: DashboardItemActiveInquiryRequestsItem[],
    contactId: string,
  ) => void;
  handleCloseTourRequestsModal: () => void;
  tourRequestsModalState: ActiveInquiryRequestsModalState | undefined;
  isApplicationRequestsModalOpen: boolean;
  handleOpenApplicationRequestsModal: (
    requests: DashboardItemActiveInquiryRequestsItem[],
    contactId: string,
  ) => void;
  handleCloseApplicationRequestsModal: () => void;
  applicationRequestsModalState: ActiveInquiryRequestsModalState | undefined;
  activeListRequestsModalState: ActiveListRequestsModalState | undefined;
  isActiveListRequestsModalOpen: boolean;
  handleOpenActiveListRequestsModal: (
    requests: DashboardItemActiveListRequestItem[],
    contactId: string,
  ) => void;
  handleCloseListRequestsModal: () => void;
}

export function useActiveRequestsModals(): UseActiveRequestsModalsReturn {
  const [tourRequestsModalState, setTourRequestsModalState] = useState<
    ActiveInquiryRequestsModalState | undefined
  >(undefined);

  const [applicationRequestsModalState, setApplicationRequestsModalState] = useState<
    ActiveInquiryRequestsModalState | undefined
  >(undefined);

  const [activeListRequestsModalState, setActiveListRequestsModalState] = useState<
    ActiveListRequestsModalState | undefined
  >(undefined);

  const {
    isOpen: isTourRequestsModalOpen,
    handleOpenModal: openTourRequestsModal,
    handleCloseModal: handleCloseTourRequestsModal,
  } = useModal();

  const {
    isOpen: isApplicationRequestsModalOpen,
    handleOpenModal: openApplicationRequestsModal,
    handleCloseModal: handleCloseApplicationRequestsModal,
  } = useModal();

  const {
    isOpen: isActiveListRequestsModalOpen,
    handleOpenModal: openActiveListRequestsModalOpen,
    handleCloseModal: handleCloseListRequestsModal,
  } = useModal();

  const handleOpenTourRequestsModal = useCallback(
    (requests: DashboardItemActiveInquiryRequestsItem[], contactId: string) => {
      setTourRequestsModalState({ requests, contactId });
      openTourRequestsModal();
    },
    [openTourRequestsModal],
  );

  const handleOpenApplicationRequestsModal = useCallback(
    (requests: DashboardItemActiveInquiryRequestsItem[], contactId: string) => {
      setApplicationRequestsModalState({ requests, contactId });
      openApplicationRequestsModal();
    },
    [openApplicationRequestsModal],
  );

  const handleOpenActiveListRequestsModal = useCallback(
    (requests: DashboardItemActiveListRequestItem[], contactId: string) => {
      setActiveListRequestsModalState({ requests, contactId });
      openActiveListRequestsModalOpen();
    },
    [openActiveListRequestsModalOpen],
  );

  return {
    isTourRequestsModalOpen,
    handleOpenTourRequestsModal,
    handleCloseTourRequestsModal,
    tourRequestsModalState,
    isApplicationRequestsModalOpen,
    handleOpenApplicationRequestsModal,
    handleCloseApplicationRequestsModal,
    applicationRequestsModalState,
    activeListRequestsModalState,
    isActiveListRequestsModalOpen,
    handleOpenActiveListRequestsModal,
    handleCloseListRequestsModal,
  };
}
