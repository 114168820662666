import { ReactElement } from 'react';

import { FileName, FileItemW, CancelIcon } from './styled';

interface AttachedFileProps {
  name: string;
  onDelete: () => void;
}

export function AttachedFile({ name, onDelete }: AttachedFileProps): ReactElement {
  return (
    <FileItemW>
      <FileName variant="body2">{name}</FileName>
      <CancelIcon onClick={onDelete} />
    </FileItemW>
  );
}
