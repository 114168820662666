import { styled } from '@mui/material';

export const CommunicationAvatar = styled('div')<{ image?: string }>(({ theme, image }) => ({
  width: 24,
  height: 24,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.p020,
  backgroundImage: `url(${image})`,
  border: `1px solid ${image ? theme.palette.secondary.main : theme.palette.primary.main}`,
  borderRadius: '50%',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  textAlign: 'center',
  fontSize: '10px',
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
