import { ReactElement } from 'react';

import { PreviewModal } from 'components';
import { DashboardItemActiveListRequestItem } from 'types';

import { ActiveListRequestsList } from '../../RequestsList';

interface ActiveListRequestsModalProps {
  open: boolean;
  onClose: () => void;
  requestsList: DashboardItemActiveListRequestItem[];
  contactId: string;
}

export function ActiveListRequestsModal({
  open,
  onClose,
  requestsList,
  contactId,
}: ActiveListRequestsModalProps): ReactElement {
  return (
    <PreviewModal
      open={open}
      title="Active list requests"
      content={<ActiveListRequestsList requests={requestsList} contactId={contactId} />}
      onClose={onClose}
    />
  );
}
