import { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';

import { Campaign } from 'types';

import { ActiveCampaignsList } from '../ActiveCampaignsList';

interface AddToCampaignModalContentProps {
  activeCampaigns: Campaign[];
  control: Control<{ campaign: string }>;
}

export function AddToCampaignModalContent({
  activeCampaigns,
  control,
}: AddToCampaignModalContentProps): ReactElement {
  return (
    <Controller
      control={control}
      name="campaign"
      render={({ field: { onChange }, ...rest }) => (
        <ActiveCampaignsList activeCampaigns={activeCampaigns} onChange={onChange} {...rest} />
      )}
    />
  );
}
