import { Box, styled } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

import { NavigationBar } from 'features/navigation';
import { useAuth } from 'hooks';

const PageLayoutW = styled(Box)`
  display: flex;
  height: 100vh;
`;

const Main = styled(Box)`
  width: 100%;
  position: relative;
  overflow-y: hidden;
`;

export interface PageLayoutProps {
  children: ReactNode;
}

export function PageLayout({ children }: PageLayoutProps): ReactElement {
  const { user } = useAuth();

  return (
    <PageLayoutW>
      {user && <NavigationBar />}
      <Main>{children}</Main>
    </PageLayoutW>
  );
}
