import { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetCommunicationMessages } from 'api/queries';
import { TextMessageType } from 'types';

import { GroupWrap } from '../GroupWrap';
import { ListItem } from './ListItem';

interface MessagesContainerProps {
  messages: TextMessageType[];
  blur: () => void;
  search: string;
}

export function MessagesContainer({
  messages,
  search,
  blur,
}: MessagesContainerProps): ReactElement {
  const [messageId, setMessageId] = useState<string | null>(null);

  const { contactId = '' } = useParams<string>();

  useGetCommunicationMessages({
    enabled: !!search && !!messageId,
    targetMessageId: messageId,
    onSuccessCb: blur,
    contactId,
  });

  const onSelectMessage = (message: TextMessageType) => {
    setMessageId(message.id);
  };

  return (
    <GroupWrap title="Messages">
      {messages.map((message) => (
        <ListItem
          key={message.id}
          message={message}
          onSelect={() => onSelectMessage(message)}
          search={search}
        />
      ))}
    </GroupWrap>
  );
}
