import { ReactElement } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { Props as SelectProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { FieldError } from 'components';
import { $FixTypeLater, SelectOptionWithLabelString } from 'types';

import { customStyles } from './stylesConfig';

export type EmailsSelectProps<T> = UseControllerProps<T> &
  SelectProps<SelectOptionWithLabelString, true> & {
    label: string;
  };

export function EmailsSelect<T>({
  control,
  name,
  placeholder,
  label,
}: EmailsSelectProps<T>): ReactElement {
  const {
    field: { onChange, ref, value },
    formState: {
      errors: { [name]: fieldErrors },
    },
  } = useController({ control, name });

  return (
    <>
      <CreatableSelect
        isMulti
        isClearable={false}
        onChange={onChange}
        styles={customStyles(label)}
        controlShouldRenderValue
        ref={ref}
        value={value as SelectOptionWithLabelString[]}
        placeholder={placeholder}
        components={{
          DropdownIndicator: null,
        }}
      />
      {fieldErrors && <FieldError>{(fieldErrors as $FixTypeLater)[0]?.value?.message}</FieldError>}
    </>
  );
}
