import { useEffect, useCallback, useContext } from 'react';
import { Control, useForm } from 'react-hook-form';

import { EmployeesContext } from 'context';
import { Contact, ContactOwnerAndAssigneeFormValues, SelectOption } from 'types';
import { filterEmployeesByRole } from 'utils';

import { defaultValuesAdapter, employeeOptionsAdapter } from '../adapters';
import { assigneeAllowedRoles, ownerAllowedRoles } from './constants';
import { useEditContactAssigneeMutation } from './useEditContactAssigneeMutation';
import { useEditContactOwnerMutation } from './useEditContactOwnerMutation';

interface UseContactOwnersFormProps {
  contact?: Contact;
}

interface UseContactOwnersFormReturn {
  control: Control<ContactOwnerAndAssigneeFormValues>;
  assigneeOptions: SelectOption[];
  ownerOptions: SelectOption[];
}

export function useContactOwnersForm({
  contact,
}: UseContactOwnersFormProps): UseContactOwnersFormReturn {
  const { employeesList } = useContext(EmployeesContext);

  const { control, watch, reset, setValue } = useForm<ContactOwnerAndAssigneeFormValues>({
    defaultValues: { assigneeId: '', ownerId: '' },
  });

  const contactId = contact?.id || '';

  const { editContactOwnerMutation } = useEditContactOwnerMutation();
  const { editContactAssigneeMutation } = useEditContactAssigneeMutation();

  const handleAssigneeSubmit = useCallback(
    async (assigneeId: string) => {
      await editContactAssigneeMutation({ contactId, assigneeId });
    },
    [contactId, editContactAssigneeMutation],
  );

  const handleOwnerSubmit = useCallback(
    async (ownerId: string) => {
      await editContactOwnerMutation({ contactId, ownerId });
    },
    [contactId, editContactOwnerMutation],
  );

  useEffect(() => {
    reset(defaultValuesAdapter({ contact }));
  }, [contact, reset]);

  useEffect(() => {
    const subscription = watch((values, options) => {
      if (!options.type) return;

      // handle owner update
      if (options.name === 'ownerId' && values.ownerId) {
        if (contact?.owner?.id !== values.ownerId) {
          setValue('assigneeId', values.ownerId);
          handleOwnerSubmit(values.ownerId);
        }
      }

      // handle assignee update
      if (options.name === 'assigneeId' && values.assigneeId) {
        if (contact?.assignee?.id !== values.assigneeId) {
          handleAssigneeSubmit(values.assigneeId);
        }
      }
    });

    return subscription.unsubscribe;
  }, [
    contact?.assignee?.id,
    contact?.owner?.id,
    handleAssigneeSubmit,
    handleOwnerSubmit,
    setValue,
    watch,
  ]);

  const assigneeFilteredOptions = filterEmployeesByRole(employeesList, assigneeAllowedRoles);
  const ownerFilteredOptions = filterEmployeesByRole(employeesList, ownerAllowedRoles);

  const assigneeOptions = employeeOptionsAdapter({
    employeesList: assigneeFilteredOptions,
    assigneeId: contact?.assignee?.id || '',
  });

  const ownerOptions = employeeOptionsAdapter({
    employeesList: ownerFilteredOptions,
    assigneeId: contact?.owner?.id || '',
  });

  return {
    control,
    assigneeOptions,
    ownerOptions,
  };
}
