import { CAMPAIGNS_REGULAR_WIDTH, CAMPAIGNS_WIDE_WIDTH } from '../constants';

interface GetCampaignsWidthProps {
  isInfoPanelVisible: boolean;
  isCampaignsVisible: boolean;
}

export function getCampaignsSectionWidth({
  isInfoPanelVisible,
  isCampaignsVisible,
}: GetCampaignsWidthProps): number {
  if (!isCampaignsVisible) {
    return 0;
  }

  if (!isInfoPanelVisible) {
    return CAMPAIGNS_WIDE_WIDTH;
  }

  return CAMPAIGNS_REGULAR_WIDTH;
}
