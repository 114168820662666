import { ReactElement, useState } from 'react';

import { ConfirmModal, PageWrap } from 'components';
import { useModal } from 'hooks';

import { EmployeesTable } from '../../components';
import { modalConfig } from './config';
import { useDeleteEmployee } from './hooks';
import { usePageDetails } from './hooks/usePageDetails';

export function UserManagementPage(): ReactElement {
  const [employeeId, setEmployeeId] = useState('');

  const {
    employeesCount,
    isLoading,
    employeesList,
    emptyState,
    setCurrentPage,
    setPageLimit,
    currentPage,
    pageLimit,
  } = usePageDetails();

  const deleteEmployeeModal = useModal();

  function handleOpenDeleteModal(employeeId: string) {
    setEmployeeId(employeeId);
    deleteEmployeeModal.handleOpenModal();
  }

  const { handleSubmit } = useDeleteEmployee({
    close: deleteEmployeeModal.handleCloseModal,
    resetId: () => setEmployeeId(''),
  });

  return (
    <PageWrap helmet={<title>User management</title>} title="User management">
      <EmployeesTable
        loading={isLoading}
        emptyState={emptyState}
        data={employeesList}
        isTableVisible={Boolean(employeesCount)}
        currentPage={currentPage}
        tableHeight="calc(100vh - 150px)"
        totalCount={employeesCount}
        setCurrentPage={setCurrentPage}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        openDeleteModal={handleOpenDeleteModal}
      />

      {deleteEmployeeModal.isOpen && (
        <ConfirmModal
          maxWidth="280px"
          title={modalConfig.title}
          cancelText="Cancel"
          confirmText="Delete"
          content={modalConfig.description}
          onClose={deleteEmployeeModal.handleCloseModal}
          onSubmit={() => handleSubmit(employeeId)}
          highlightSubmit
        />
      )}
    </PageWrap>
  );
}
