import { UserOwner, TourInquiryP } from 'types';

import { ApplyInquiryP } from './applyInquiry';

export type InquiryType = 'tour' | 'application';

export enum InquiryStateGroup {
  INITIAL = 'initial',
  INTERMEDIATE = 'intermediate',
  TERMINAL = 'terminal',
  RESOLVED = 'resolved',
}

export enum InquiryStateColor {
  GRAY = 'gray',
  GREEN = 'green',
  BLUE = 'blue',
  YELLOW = 'yellow',
  RED = 'red',
  PURPLE = 'purple',
}

export interface InquiryStatus {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  group: InquiryStateGroup;
  color: InquiryStateColor;
}

export interface InquiryAssignee {
  availableCapacity?: number;
  createdAt: string;
  id: string;
  isArchived: boolean;
  isAvailable: boolean;
  maxCapacity: number;
  teams: string[];
  updatedAt: string;
  user: UserOwner;
}

export interface InquiryStateTransition {
  id: string;
  createdAt: string;
  updatedAt: string;
  from: InquiryStatus;
  to: InquiryStatus;
}

export type AppInquiry = ApplyInquiryP | TourInquiryP;

export interface AssignInquiryFormValues {
  team: string;
  assignee: string;
}
