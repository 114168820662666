import { ServicePreferences } from 'types';

export const servicePreferenceProps = {
  [ServicePreferences.GuidedSearch]: {
    children: 'Guided search',
    variant: ServicePreferences.GuidedSearch,
  },
  [ServicePreferences.SelfSearch]: {
    children: 'Self-serve',
    variant: ServicePreferences.SelfSearch,
  },
  [ServicePreferences.Scheduled]: {
    children: 'Scheduled',
    variant: ServicePreferences.Scheduled,
  },
};

type PlusIconStyles = {
  opacity: string | number;
  transition: string;
};

export const buttonIconStyles = {
  sendIcon: {
    width: '13.5px',
    height: '13.5px',
    transform: 'rotate(-45deg)',
    marginBottom: '3px',
  },
  plusIcon(isAddBuildingMode: boolean): PlusIconStyles {
    return {
      opacity: isAddBuildingMode ? 0.3 : 'unset',
      transition: 'opacity 0.2s ease-in',
    };
  },
};
