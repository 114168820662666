import { Box, styled, Typography } from '@mui/material';

export const MessageW = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  position: 'relative',
  padding: '12px 14px',
  border: `1px solid ${theme.palette.secondary.p020}`,
  borderRadius: '4px',
  cursor: 'pointer',
  background: theme.palette.background.paper,

  '&:hover': {
    background: theme.palette.secondary.p005,
  },
}));

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

export const MessageContent = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.black.light,
  lineHeight: '16px',
  maxWidth: '270px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));
