import { ReactElement, useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { useEditInquiryDetailsForm } from 'features/inquiry/hooks';
import { ApplyDetailsFormValues, ApplyInquiry, UnassignedBuildingApplyInquiry } from 'types';
import { objectsDifferences } from 'utils';

import { InquiryDetailsFields } from '../InquiryDetailsFields';
import { getApplyDetailsDefaultValues } from './utils';
import { applyDetailsFormSchema } from './validationSchema';

interface ApplicationDetailsProps {
  applyInquiry: ApplyInquiry | UnassignedBuildingApplyInquiry;
  isUnassignedBuilding?: boolean;
}

export function ApplicationDetails({
  applyInquiry,
  isUnassignedBuilding = false,
}: ApplicationDetailsProps): ReactElement {
  const defaultValues = useMemo(() => getApplyDetailsDefaultValues(applyInquiry), [applyInquiry]);

  const { mutateAsync: editDetailsMutation } = useMutation(
    API_HANDLERS.INQUIRIES.EDIT_APPLY_DETAILS,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contact');
      },
    },
  );

  const onSubmit = useCallback(
    async (values: ApplyDetailsFormValues) => {
      const diffs = defaultValues ? objectsDifferences(defaultValues, values) : values;

      await editDetailsMutation({ id: applyInquiry.id, data: diffs });
    },
    [defaultValues, editDetailsMutation, applyInquiry.id],
  );

  const { control } = useEditInquiryDetailsForm<ApplyDetailsFormValues>({
    defaultValues,
    onSubmit,
    validationSchema: applyDetailsFormSchema,
  });

  return (
    <InquiryDetailsFields control={control} isTour={false} isEditable={!isUnassignedBuilding} />
  );
}
