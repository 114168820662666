import { Box } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

import { ReactComponent as ArrowDropDownIcon } from 'assets/icons/arrow-drop-down.svg';
import { CampaignStatus, ClosedLostCampaignStatus } from 'types';

import { StatusBlockButton } from './styled';

interface StatusBlockProps {
  onClick: () => void;
  status: CampaignStatus | ClosedLostCampaignStatus;
  isDisabled: boolean;
  children: ReactNode;
  loading: boolean;
  withIcon: boolean;
  disableRipple: boolean;
}

export function StatusBlock({
  onClick,
  status,
  isDisabled,
  withIcon,
  children,
}: StatusBlockProps): ReactElement {
  return (
    <Box sx={{ cursor: isDisabled ? 'not-allowed' : 'default' }}>
      <StatusBlockButton
        onClick={onClick}
        status={status}
        isDisabled={isDisabled}
        loadingPosition="end"
        endIcon={withIcon && <ArrowDropDownIcon />}
      >
        {children}
      </StatusBlockButton>
    </Box>
  );
}
