import { ContactFormValues } from 'types';
import { removeExtraSpaces } from 'utils';

export function editContactAdapter(values: Partial<ContactFormValues>): Partial<ContactFormValues> {
  const { firstName, lastName } = values;
  return {
    ...values,
    ...(firstName ? { firstName: removeExtraSpaces(firstName) } : undefined),
    ...(lastName ? { lastName: removeExtraSpaces(lastName) } : undefined),
  };
}
