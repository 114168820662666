import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { Table, SearchField, FilterSelectController } from 'components';
import { ContactCreateForm, FilterPresetsSelect } from 'features/contacts/components';
import { useContacts } from 'features/contacts/hooks';

import { PageW, PageHeader, PageTitle, SearchAndFilterW, SearchW, TableW } from './styled';

export function ContactsPage(): ReactElement {
  const {
    currentPage,
    setCurrentPage,
    isContactsLoading,
    tableContacts,
    contactsCount,
    pageLimit,
    setPageLimit,
    emptyState,
    handleRowClick,
    search,
    onSetSearch,
    onClearSearch,
    contactOwnerOptions,
    contactOwnerFilterControl,
    filterDisplayValue,
    contactsFilterControl,
    filterPresetOptions,
    filtersList,
    setFilterPresetValue,
  } = useContacts();

  return (
    <PageW>
      <Helmet>
        <title>Contacts</title>
      </Helmet>
      <PageHeader>
        <PageTitle variant="h4">Contacts</PageTitle>
        <ContactCreateForm />
      </PageHeader>
      {/* TODO: move controllers outside to component */}
      <SearchAndFilterW>
        <SearchW>
          <SearchField value={search} onChange={onSetSearch} onClear={onClearSearch} />
        </SearchW>
        <FilterSelectController
          control={contactOwnerFilterControl}
          name="contactOwner"
          placeholder="Contact owner"
          label="Contact owner"
          options={contactOwnerOptions}
          id="contact-owner-filter"
          width="195px"
          renderValue={filterDisplayValue}
          defaultOptionText="All contacts"
        />
        <div style={{ marginLeft: '12px' }}>
          <FilterPresetsSelect
            control={contactsFilterControl}
            name="filterId"
            label="Filter presets"
            options={filterPresetOptions}
            id="Filter presets"
            filtersList={filtersList}
            setFilterPresetValue={setFilterPresetValue}
          />
        </div>
      </SearchAndFilterW>
      <TableW>
        <Table
          loading={isContactsLoading}
          emptyState={emptyState}
          data={tableContacts}
          isTableVisible={Boolean(tableContacts.length)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          tableHeight="calc(100vh - 200px)"
          totalCount={contactsCount}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          onRowClick={handleRowClick}
        />
      </TableW>
    </PageW>
  );
}
