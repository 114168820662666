export const acquisitionLabels: { [key: string]: string } = {
  signUpLink: 'Sign Up page',
  acquisitionChannel: 'Acquisition channel',
  lighthouseId: 'Lighthouse ID',
  stytchId: 'Stytch ID',
  referredBy: 'Referred by',
  gAId: 'GA ID',
  source: 'Source',
  campaign: 'Campaign',
  term: 'Term',
  medium: 'Medium',
  userIP: 'User IP',
  device: 'Device',
  gclId: 'GCL ID',
};

export const contactInfoLabels: { [key: string]: string } = {
  fullName: 'Full name',
  email: 'Email',
  phone: 'Phone number',
  contactStyle: 'Communication preference',
  createdAt: 'Contact created',
};

export const campaignLabels: { [key: string]: string } = {
  title: 'Campaign name',
  servicePreference: 'Service preference',
  city: 'City',
  location: 'Location',
  bedroomsCount: 'Bedrooms',
  rentPrice: 'Rent price',
  moveInDate: 'Move in date',
  issues: 'Issues',
  amenities: 'Amenities',
  notes: 'Notes',
};
