import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const PageW = styled(Box)`
  min-height: 100vh;
  padding: 24px 28px 42px 24px;
  display: flex;
  flex-direction: column;
`;

export const PageHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageTitle = styled(Typography)`
  font-weight: 600;
  font-size: 28px;
  color: #191c1e;
  margin-bottom: 24px;
`;

export const ContentW = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
