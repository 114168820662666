import { ReactElement, useContext, useMemo } from 'react';

import { Button, ControlledTextField, Form, SideModal } from 'components';
import { AutocompleteField } from 'components/Fields';
import { FilterGroupsContext } from 'features/settings/containers/FiltersPreset/context';
import { $FixTypeLater, EntityFilterPayload } from 'types';

import { FilterFieldsSection } from '../FilterFieldsSection';
import { FILTERS_NAME_PREFIX } from './config';
import { filterGroupRules, filterNameRules } from './rules';
import { ConditionsW, FilterNameW, FormDivider, SubmitFormW } from './styled';
import { useFilterForm } from './useFilterForm';

interface FilterFormProps {
  handleClose: () => void;
  onSubmit: (values: EntityFilterPayload) => Promise<unknown>;
  isOpen: boolean;
  defaultValues: $FixTypeLater;
}

export function FilterForm({
  handleClose,
  onSubmit,
  isOpen,
  defaultValues,
}: FilterFormProps): ReactElement {
  const { disableSubmit, isSubmitting, control, submitForm, destroyForm } = useFilterForm({
    onSubmit,
    defaultValues,
    handleClose,
  });

  const existingFilterGroups = useContext(FilterGroupsContext);

  const filterOptions = useMemo(
    () =>
      existingFilterGroups.map((group) => ({
        label: group,
        value: group,
      })),
    [existingFilterGroups],
  );

  return (
    <SideModal title="New filter preset" onClose={destroyForm} isOpen={isOpen} width="80%">
      <Form onSubmit={submitForm}>
        <FilterNameW>
          <div style={{ width: '276px' }}>
            <ControlledTextField
              control={control}
              name="name"
              label="Filter name"
              placeholder="Filter name"
              rules={filterNameRules}
            />
          </div>

          <AutocompleteField
            saveInput
            name="group"
            label="Folder"
            placeholder="Folder"
            sx={{ width: '276px' }}
            control={control}
            options={filterOptions}
            rules={filterGroupRules}
          />
        </FilterNameW>

        <FormDivider />

        <ConditionsW>
          <FilterFieldsSection
            namePrefix={FILTERS_NAME_PREFIX}
            control={control}
            nestingLevel={0}
          />
        </ConditionsW>

        <SubmitFormW>
          <Button type="submit" disabled={disableSubmit} loading={isSubmitting}>
            Save filter preset
          </Button>
        </SubmitFormW>
      </Form>
    </SideModal>
  );
}
