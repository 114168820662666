import { ReactElement, ReactNode } from 'react';

import { ReactComponent as ArrowDropDownIcon } from 'assets/icons/arrow-drop-down.svg';
import { InquiryStateColor } from 'types';

import { StatusBlock, StatusName } from './styled';

interface StatusButtonProps {
  title: ReactNode;
  withIcon?: boolean;
  colorScheme?: InquiryStateColor;
  onClick?: () => unknown;
  isActive?: boolean;
  isDisabled: boolean;
  isLoading?: boolean;
}

export function StatusButton({
  title,
  withIcon = true,
  colorScheme = InquiryStateColor.BLUE,
  onClick,
  isActive = true,
  isDisabled,
  isLoading = false,
}: StatusButtonProps): ReactElement {
  return (
    <StatusBlock
      scheme={colorScheme}
      onClick={onClick}
      icon={withIcon}
      disableRipple={isActive}
      isDisabled={isDisabled}
      loading={isLoading}
      endIcon={withIcon && <ArrowDropDownIcon />}
      loadingPosition={withIcon ? 'end' : undefined}
    >
      <StatusName>{title}</StatusName>
    </StatusBlock>
  );
}
