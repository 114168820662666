import { ReactElement } from 'react';

import { UserOwner } from 'types';

export enum EmployeeRole {
  DISPATCHERS = 'dispatcher',
  ADMINISTRATORS = 'administrator',
  COLLABORATORS = 'collaborator',
  LIGHTKEEPERS = 'lightkeeper',
  SEARCHLIGHTS = 'searchlight',
}

export interface Employee {
  id: string;
  roles: EmployeeRole[];
  user: UserOwner;
  isAvailable: boolean;
  createdAt: string;
  team?: string;
}

export interface EmployeeTeam {
  name: string;
  role: EmployeeRole;
  id: string;
}

export interface EmployeesTableData {
  id: string;
  fullName: ReactElement;
  email: string;
  roles: EmployeeRole[];
  team: string;
  isActive: boolean;
  createdAt: string;
}
