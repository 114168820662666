import { TourTimes } from 'types';

export const editIconStyles = {
  color: '#65787E',
  top: '10px',
  right: '10px',
};

export const tourTimeOptions = {
  [TourTimes.Morning]: 'Morning (9-12)',
  [TourTimes.Afternoon]: 'Afternoon (12-3)',
  [TourTimes.Evening]: 'Evening (3-6)',
};
