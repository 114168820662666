import { UnassignedBuilding } from 'types';
import { actionCreator } from 'utils';

import actionTypes from './actionTypes';

export const setUnassignedBuildings = actionCreator<{
  buildings: UnassignedBuilding[];
}>(actionTypes.setUnassignedBuildings);

export const selectUnassignedBuilding = actionCreator<{
  buildingId: UnassignedBuilding['id'];
}>(actionTypes.selectUnassignedBuilding);
