import { Theme } from '@mui/material';

import { MessageType } from 'types';

export function getMessageStyles(
  theme: Theme,
  messageType: MessageType,
  hasError: boolean,
): React.CSSProperties {
  if (hasError) {
    return {
      background: theme.palette.error.p010,
      borderColor: theme.palette.error.main,
    };
  }

  switch (messageType) {
    case MessageType.INCOMING_EMAIL:
    case MessageType.OUTGOING_EMAIL: {
      return {
        background: theme.palette.primary.p010,
        borderColor: theme.palette.primary.main,
      };
    }
    case MessageType.PRIVATE_NOTES: {
      return {
        background: theme.palette.warning.p010,
        borderColor: theme.palette.warning.main,
        borderRadius: `12px !important`,
      };
    }

    default:
      return {};
  }
}
