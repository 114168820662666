import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ReactElement } from 'react';
import { Control, useController, FieldValues, UseControllerProps } from 'react-hook-form';

import { Checkbox, FormGroup } from 'components';
import { SelectOption } from 'types';

import { FormControlLabelW, FormHelperTextW } from './styles';

interface CheckboxGroupProps<T = FieldValues> extends UseControllerProps<T> {
  label?: string;
  options: SelectOption[];
  control: Control<T>;
  showAllErrors?: boolean;
  disabled?: boolean;
}

function CheckboxGroup<T>({
  control,
  rules,
  name,
  label,
  showAllErrors = false,
  options,
  disabled,
}: CheckboxGroupProps<T>): React.ReactElement {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error, isTouched },
  } = useController({ control, rules, name });

  const checkboxOnChangeHandler = (optionValue: string) => (_: unknown, checked: boolean) => {
    let newValue;

    if (checked) {
      newValue = value instanceof Array ? [...value, optionValue] : [optionValue];
    } else {
      newValue = value instanceof Array ? value.filter((option) => option !== optionValue) : [];
    }

    onChange(newValue);
    onBlur();
  };

  const showError = showAllErrors || isTouched;

  return (
    <FormControl>
      <FormLabel component="legend" sx={{ fontSize: '12px' }}>
        {label}
      </FormLabel>
      <FormGroup marginbottom={0}>
        {options.map((option) => (
          <FormControlLabelW
            key={option.value}
            label={option.label || (option.displayLabel as ReactElement)}
            disabled={option.isDisabled || disabled}
            control={
              <Checkbox
                checked={((value as Array<string>) || []).includes(option.value)}
                onChange={checkboxOnChangeHandler(option.value)}
              />
            }
          />
        ))}
      </FormGroup>
      {showError && error && <FormHelperTextW error>{error.message}</FormHelperTextW>}
    </FormControl>
  );
}

export default CheckboxGroup;
