import { Box } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface SuggestionProps {
  avatar: ReactNode;
  display: string;
}

export function Suggestion({ avatar, display }: SuggestionProps): ReactElement {
  return (
    <Box display="flex">
      {avatar} {display}
    </Box>
  );
}
