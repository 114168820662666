import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import { theme } from 'styles';

export const Td = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.black.main,
    borderColor: '#E0E4E5',
    padding: '0 10px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '19px',
    height: 40,
  },
}));

export const Th = styled(Td)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.black.main,
    fontWeight: 600,
    padding: '5px 10px',
    maxWidth: 'auto',
    lineHeight: '20px',
    height: 40,
  },
}));

export const DataW = styled('span')`
  cursor: text;
  height: 40px;
`;

export const StatusLabel = styled(Box)<{ isActive: boolean }>(({ theme, isActive }) => ({
  fontWeight: 600,
  fontSize: '12px',
  padding: '5px 8px',
  color: isActive ? theme.palette.primary.main : theme.palette.error.main,
  background: isActive ? theme.palette.primary.p010 : theme.palette.error.p010,
  textAlign: 'center',
  borderRadius: '4px',
}));
