import { FileWithPath } from 'react-dropzone';

import { RESTRICTED_FILE_TYPES } from '../constants';

type ValidatorReturn = {
  code: string;
  message: string;
} | null;

export function fileTypeValidator(file: FileWithPath): ValidatorReturn {
  const fileType = file.path;

  if (RESTRICTED_FILE_TYPES.some((fileExtension) => fileType?.endsWith(fileExtension))) {
    return {
      code: 'restricted-file-type',
      message: `File type is not allowed - ${fileType}`,
    };
  }

  return null;
}
