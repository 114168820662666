import { CampaignCreateRequest } from 'api/types';
import { CampaignFormDefaultValues, CampaignFormValues } from 'types';
import {
  getFormattedDate,
  getFormattedDateISOString,
  removeExtraSpaces,
  getStringFromOptionsArray,
} from 'utils';

interface CampaignDefaultValuesAdapterProps {
  contactFullName: string;
  defaultValues: CampaignFormDefaultValues;
}

function getCampaignName(contactFullName: string) {
  return `${contactFullName} ${getFormattedDate(new Date())}`;
}

export function defaultValuesAdapter({
  defaultValues,
  contactFullName,
}: CampaignDefaultValuesAdapterProps): CampaignFormDefaultValues {
  const { campaignName, ...otherValues } = defaultValues;

  return {
    campaignName: getCampaignName(contactFullName),
    ...otherValues,
  };
}

export function campaignFormAdapter({
  moveInDate,
  city,
  rentPrice,
  campaignName,
  location,
  bedrooms,
  amenities,
  issues,
  notes,
  contactId,
}: CampaignFormValues): CampaignCreateRequest {
  const date = new Date(String(moveInDate));

  return {
    contactId,
    moveInDate: getFormattedDateISOString(date),
    city,
    rentPrice: Number(rentPrice),
    title: removeExtraSpaces(campaignName),
    ...(location && { location: removeExtraSpaces(location) }),
    bedroomsCount: bedrooms,
    amenities: getStringFromOptionsArray(amenities),
    issues,
    notes: removeExtraSpaces(notes),
  };
}
