import { Box } from '@mui/material';
import { ReactElement } from 'react';

import { ReactComponent as InquiryIcon } from 'assets/icons/inquiry.svg';
import { Avatar, StatusButton } from 'components';
import { DATE_FORMAT_WITH_TIME_AND_COMMA } from 'constants/index';
import { theme } from 'styles';
import { UnassignedBuildingApplyInquiry, UnassignedBuildingScheduleTourInquiry } from 'types';
import { getFormattedDateWithTime } from 'utils';

import { UnassignedBuildingInquiryDetails } from '../UnassignedBuildingInquiryDetails';
import { useUnassignedBuildingInquiryItem } from './hooks';
import {
  DateCaption,
  InquiryItemWrapper,
  InquiryName,
  ContainerWrapper,
  ArrowIcon,
  SideBlockWrapper,
  RightSideBlockWrapper,
  CollapseWrapper,
  CloseReasonWrapper,
  Reason,
} from './styled';

interface InquiryListItemProps {
  inquiry: UnassignedBuildingScheduleTourInquiry | UnassignedBuildingApplyInquiry;
  handleSelectInquiry: (id: string) => void;
  isSelected: boolean;
}

export function UnassignedBuildingInquiryItem({
  inquiry,
  handleSelectInquiry,
  isSelected,
}: InquiryListItemProps): ReactElement {
  const { isExpanded, isTour, statusName, reason, handleToggle } = useUnassignedBuildingInquiryItem(
    { inquiry, handleSelectInquiry },
  );

  return (
    <ContainerWrapper expanded={isExpanded}>
      <InquiryItemWrapper isSelected={isSelected}>
        <Box position="relative">
          <ArrowIcon $isOpen={isExpanded} onClick={handleToggle} />
          <SideBlockWrapper>
            <InquiryIcon
              style={{
                color: isTour
                  ? theme.palette.additionalColors.cards2
                  : theme.palette.additionalColors.cards1,
              }}
            />
            <InquiryName variant="caption" onClick={() => handleSelectInquiry(inquiry.id)}>
              {isTour ? 'Tour' : 'Application'}
            </InquiryName>
          </SideBlockWrapper>
          <DateCaption variant="body2">
            {getFormattedDateWithTime(new Date(inquiry.createdAt), DATE_FORMAT_WITH_TIME_AND_COMMA)}
          </DateCaption>
          {reason && (
            <CloseReasonWrapper variant="body2">
              Reason to close: <Reason>{reason}</Reason>
            </CloseReasonWrapper>
          )}
        </Box>
        <RightSideBlockWrapper>
          <StatusButton
            title={statusName}
            withIcon={false}
            colorScheme={inquiry.status.color}
            isDisabled
          />

          {inquiry?.assignee?.user?.picture && (
            <Avatar
              sx={{
                width: 32,
                height: 32,
                marginLeft: '16px',
              }}
              alt="inquiry assignee avatar"
              src={inquiry?.assignee?.user?.picture}
            />
          )}
        </RightSideBlockWrapper>
      </InquiryItemWrapper>

      <CollapseWrapper orientation="vertical" in={isExpanded}>
        <UnassignedBuildingInquiryDetails isTour={isTour} inquiry={inquiry} />
      </CollapseWrapper>
    </ContainerWrapper>
  );
}
