import { FormLabel } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { RefCallBack } from 'react-hook-form';
import { Props } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { SelectOptionWithLabelString } from 'types';
import { LETTERS_DIGITS_DASHES_SPACES_SINGLE_QUOTE_REGEX } from 'validations/regexps';

import { AmenitiesChipsList } from '../AmenitiesChipsList';
import { AmenitiesControl } from '../AmenitiesControl';
import { AmenitiesInput } from '../AmenitiesInput';
import { customStyles } from './styled';

interface AmenitiesSelectProps extends Props<SelectOptionWithLabelString, true> {
  inputRef: RefCallBack;
  options: SelectOptionWithLabelString[];
  handleDelete: (value: string) => () => void;
  label?: ReactNode;
}

export function AmenitiesSelect({
  inputRef,
  options,
  value,
  onChange,
  handleDelete,
  label,
  placeholder,
  isDisabled = false,
  ...otherProps
}: AmenitiesSelectProps): ReactElement {
  const isValueArray = Array.isArray(value) && value.length;

  return (
    <>
      <FormLabel
        component="legend"
        sx={{ fontSize: '12px', marginBottom: isValueArray ? '4px' : '10px' }}
      >
        {label}
      </FormLabel>

      {isValueArray ? <AmenitiesChipsList amenities={value} handleDelete={handleDelete} /> : null}

      <CreatableSelect<SelectOptionWithLabelString, true>
        isMulti
        backspaceRemovesValue={false}
        isClearable={false}
        isDisabled={isDisabled}
        options={options}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        formatCreateLabel={(newValue) => newValue}
        controlShouldRenderValue={false}
        createOptionPosition="first"
        components={{
          DropdownIndicator: null,
          Control: AmenitiesControl,
          Input: AmenitiesInput,
        }}
        styles={customStyles}
        ref={inputRef}
        noOptionsMessage={() => 'No suggestions'}
        isValidNewOption={(inputValue) =>
          LETTERS_DIGITS_DASHES_SPACES_SINGLE_QUOTE_REGEX.test(inputValue) &&
          !!inputValue.trim().length
        }
        {...otherProps}
      />
    </>
  );
}
