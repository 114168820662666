import { ApplyInquiry, TourInquiry } from 'types';

import { WithParent } from './utility';

export interface CampaignBuilding {
  id: string;
  externalId: string;
  name: string;
  address: string;
  scheduleTourInquiries?: TourInquiry[];
  applyInquiries?: ApplyInquiry[];
  learnMoreInquiries?: [];
  createdAt: string;
  updatedAt: string;
  calculatedStatus: string | null;
  status: BuildingStatus;
  source: BuildingSource;
  notes: string;
  data: {
    id: string;
    address: string;
    cashback?: string;
    city: string;
    email?: string;
    minRent?: number;
    maxRent?: number;
    name: string;
    phone?: string;
    photo?: string;
    state: string;
    website?: string;
    zip?: number;
  };
}

export type CampaignBuildingP = WithParent<CampaignBuilding>;

export type BuildingSearchItem = {
  id: string;
  name: string;
  address: string;
  city: string;
};

export type CampaignBuildingDetails = {
  id: string;
  photo: string;
  name: string;
  address: string;
  website: string;
  email: string;
  phone: string;
  minMaxRate: string;
};

export type BuildingNotesFormValues = {
  notes: string;
};

export interface SendBuildingsFormValues {
  buildings?: string[];
}

export enum BuildingStatus {
  NEW = 'NEW',
  SENT = 'SENT',
  DISMISSED = 'DISMISSED',
}

export enum BuildingSource {
  DEFAULT = 'DEFAULT',
  INQUIRY = 'INQUIRY',
  MANUAL_CREATION = 'MANUAL_CREATION',
  SAD_LIST = 'SAD_LIST',
  WISHLIST = 'WISHLIST',
}

export interface BuildingChat {
  building: {
    id: string;
    name: string;
    photo: string;
    source: BuildingSource;
  };
  unreadMessages: number;
  lastMessage: {
    id: string;
    text: string;
    createdAt: string;
  };
}
