import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { ReactElement, ReactNode } from 'react';

import { ReactComponent as ErrorIcon } from 'assets/icons/errorIcon.svg';
import { LinkFields } from 'types';

import {
  AlertContent,
  AlertContentW,
  AlertIconW,
  AlertMessage,
  AlertW,
  CloseButton,
  NavLinkW,
} from './styled';

export interface AlertProps {
  children: ReactNode;
  variant?: 'info' | 'success' | 'danger';
  close?(): void;
  withIcon?: boolean;
  link?: LinkFields;
}

function getAlertIcon(variant?: AlertProps['variant']) {
  switch (variant) {
    case 'success':
      return <CheckIcon color="primary" />;
    case 'info':
      return <CheckIcon />;
    default:
      return <ErrorIcon />;
  }
}

export function Alert({
  children,
  close,
  variant = 'info',
  withIcon = true,
  link,
}: AlertProps): ReactElement {
  return (
    <AlertW variant={variant} aria-label={`alert ${variant}`}>
      {withIcon && <AlertIconW>{getAlertIcon(variant)}</AlertIconW>}

      <AlertContentW>
        <AlertContent>
          <AlertMessage>{children}</AlertMessage>
        </AlertContent>
        {link && <NavLinkW to={link.linkUrl}>{link.label}</NavLinkW>}
      </AlertContentW>

      {close && (
        <CloseButton type="button" aria-label="Close" onClick={close} sx={{ padding: 0 }}>
          <CloseIcon />
        </CloseButton>
      )}
    </AlertW>
  );
}
