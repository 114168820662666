import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ReactElement } from 'react';

import { EmployeeRole, SelectOption } from 'types';

import { UNASSIGNED } from '../../constants';
import { useChangeTeamMutation } from './hooks';
import { FormControlW } from './styled';
import { inputProps, MenuProps, selectStyles } from './stylesConfig';

interface EmployeeTeamSelectProps {
  team: string;
  options: SelectOption<string>[];
  disabled?: boolean;
  employeeId: string;
}

export function EmployeeTeamSelect({
  team,
  options,
  disabled = false,
  employeeId,
}: EmployeeTeamSelectProps): ReactElement {
  const { changeTeamMutation } = useChangeTeamMutation();

  const handleChange = (event: SelectChangeEvent<typeof team>) => {
    const {
      target: { value },
    } = event;
    const teamValue = value === UNASSIGNED ? null : (value as EmployeeRole);

    changeTeamMutation({ employeeId, teamName: teamValue });
  };

  return (
    <FormControlW variant="outlined">
      <Select
        value={team}
        onChange={handleChange}
        MenuProps={MenuProps}
        inputProps={inputProps}
        sx={selectStyles}
        disabled={disabled}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value} sx={{ padding: '10px 16px', fontSize: '14px' }}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControlW>
  );
}
