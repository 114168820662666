import { ComponentType, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from 'hooks';
import { Links } from 'settings';

interface PrivateRouteProps {
  component: ComponentType;
}

export function PrivateRoute({ component: Component }: PrivateRouteProps): ReactElement | null {
  const { user } = useAuth();

  if (!Component) {
    return null;
  }

  if (user) {
    return <Component />;
  }

  return (
    <Navigate
      to={{
        pathname: Links.login,
      }}
    />
  );
}
