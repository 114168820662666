import { Contact, ContactFormDefaultValues } from 'types';

export function contactDefaultValuesAdapter(contact?: Contact): ContactFormDefaultValues {
  if (!contact) {
    return;
  }

  return {
    firstName: contact.firstName,
    lastName: contact.lastName,
    phone: contact.phone,
    email: contact.email,
    contactStyle: contact.contactStyle,
    cameFrom: contact.cameFrom === 'default' ? '' : contact.cameFrom,
  };
}
