import { ClickAwayListener } from '@mui/material';
import { ReactElement, ReactNode, useRef } from 'react';

import { CloseButton } from 'components';
import { useModal } from 'hooks';

import { ConfirmModal, ConfirmModalProps } from '..';
import { ModalW, ModalContent, ModalHeader, Title } from './styled';

interface SideModalProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  withConfirmClose?: boolean;
  confirmModalProps?: Partial<ConfirmModalProps>;
  title?: string;
  width?: string;
}

export function SideModal({
  children,
  isOpen,
  onClose,
  withConfirmClose,
  confirmModalProps,
  title = 'Modal',
  width,
}: SideModalProps): ReactElement {
  const clickedAway = useRef(false);

  const closeModalControl = useModal();

  const handleCloseForm = () => {
    if (withConfirmClose) {
      closeModalControl.handleOpenModal();
      return;
    }
    onClose();
  };

  const handleSubmitCloseForm = () => {
    closeModalControl.handleCloseModal();
    onClose();
  };

  return (
    <>
      <ModalW
        hideBackdrop
        anchor="right"
        open={isOpen}
        width={width}
        onPointerDownCapture={() => {
          clickedAway.current = true;
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (clickedAway.current) {
              handleCloseForm();
              clickedAway.current = false;
            }
          }}
        >
          <ModalContent
            onPointerDownCapture={() => {
              clickedAway.current = false;
            }}
          >
            <ModalHeader>
              <Title>{title}</Title>
              {closeModalControl.isOpen && (
                <ConfirmModal
                  title="Do you want to close form?"
                  cancelText="Cancel"
                  confirmText="Close"
                  content="All changes won't be saved"
                  {...confirmModalProps}
                  onClose={closeModalControl.handleCloseModal}
                  onSubmit={handleSubmitCloseForm}
                  withCloseIcon
                />
              )}
              <CloseButton onClick={handleCloseForm} />
            </ModalHeader>
            {children}
          </ModalContent>
        </ClickAwayListener>
      </ModalW>
    </>
  );
}
