import { CommunicationPreferences } from 'types';

export function getContactStyle(status: string): string {
  switch (status) {
    case CommunicationPreferences.PHONE:
      return 'Phone call';
    case CommunicationPreferences.TEXTING:
      return 'Texting';
    case CommunicationPreferences.EMAIL:
      return 'Email';
    case CommunicationPreferences.ZOOM:
      return 'Zoom';
    default:
      return status;
  }
}
