import { theme } from 'styles';

interface StylesConfig {
  display: string;
  alignItems: string;

  ':before': {
    content: string;
    marginRight: number;
    marginTop: number;
    color: string;
  };
}

export function setInputLabelText(text: string): StylesConfig {
  return {
    display: 'flex',
    alignItems: 'flex-start',
    ':before': {
      content: `"${text}: "`,
      marginRight: 6,
      marginTop: 8,
      color: theme.palette.black.main,
    },
  };
}
