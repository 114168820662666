import { ReactElement } from 'react';
import { Control } from 'react-hook-form';

import { CheckboxGroup } from 'components';
import { CampaignBuilding, SendBuildingsFormValues } from 'types';

import { SendBuildingListItem } from '../SendBuildingListItem';
import { ContentW } from './styles';

interface SendBuildingsFormProps {
  campaignId: string;
  buildings: CampaignBuilding[];
  control: Control<SendBuildingsFormValues>;
}

export function SendBuildingsForm({ buildings, control }: SendBuildingsFormProps): ReactElement {
  const checkboxOptions = buildings.map((building) => ({
    label: '',
    value: building.id,
    displayLabel: <SendBuildingListItem building={building} />,
  }));

  return (
    <ContentW>
      <CheckboxGroup control={control} name="buildings" options={checkboxOptions} />
    </ContentW>
  );
}
