export const inputProps = {
  style: { fontSize: 14 },
  disableUnderline: true,
};

export const subjectInputStyles = {
  input: {
    padding: '9px 0',
  },
};

export const textareaStyles = {
  '.MuiInput-root': {
    padding: '9px 0',
  },
};
