import { ReactElement, ReactNode } from 'react';

export * from './contacts';
export * from './campaigns';
export * from './employees';
export * from './buildings';
export * from './tours';
export * from './inquiry';
export * from './applyInquiry';
export * from './utility';
export * from './state';
export * from './socket';
export * from './entitySelect';
export * from './filters';
export * from './communications';
export * from './wishlist';
export * from './files';
export * from './unassignedBuildings';
export * from './dashboard';
export * from './buildingChat';
export * from './inViewItem';

export type NestedRecord = {
  [key: string]: NestedRecord | string;
};

export type MapNestedRecord<Type extends NestedRecord> = {
  [Property in keyof Type]: Type[Property] extends NestedRecord
    ? MapNestedRecord<Type[Property]>
    : string;
};

export type ValidationErrors<T> = Partial<Record<keyof T, string>>;

export type BaseFormMode = 'create' | 'edit';

export interface BaseFormProps<T, I> {
  mode: BaseFormMode;
  onSubmit: (data: T) => Promise<void>;
  defaultValues?: Partial<T>;
  validationErrors?: ValidationErrors<I>;
}

export interface SelectOption<T = string> {
  value: T;
  label: string | ReactElement;
  color?: string;
  backgroundColor?: string;
  displayLabel?: string | ReactNode | ReactElement;
  imageUrl?: string;
  icon?: ReactElement;
  isDisabled?: boolean;
}

export interface SelectOptionWithIcon<T = string> extends SelectOption<T> {
  icon: ReactElement;
}

export interface AlertProps {
  message: string;
  status: 'error' | 'success';
}

export enum CommunicationPreferences {
  DEFAULT = 'default',
  PHONE = 'phone',
  TEXTING = 'texting',
  EMAIL = 'email',
  ZOOM = 'zoom',
}

export enum AcquisitionChannel {
  DEFAULT = 'default',
  FACEBOOK = 'facebook',
  LH_WEB_SITE = 'lighthouse-web-site',
  PERSONAL_REFERENCE = 'personal-reference',
  SECONDARY_CONTACT = 'secondary-contact',
}

export interface ResponseError {
  error: string;
  message: string | string[];
  statusCode: number;
  existingContactId?: string;
}

export enum EmptyStateIcons {
  EMPTY_SEARCH = 'emptySearch',
  EMPTY_LIST = 'emptyList',
  EMPTY_CAMPAIGNS = 'emptyCampaigns',
  ERROR = 'error',
  // EMPTY_NOTIFICATIONS = 'emptyNotifications',
  EMPTY_MESSAGES = 'emptyMessages',
  EMPTY_MESSAGE_TEMPLATES = 'emptyMessageTemplates',
  EMPTY_DASHBOARD_CHAT = 'emptyDashboardChat',
  EMPTY_TOUR_TABLE = 'emptyTourTable',
  EMPTY_APPLY_TABLE = 'emptyApplyTable',
}

export enum ContactDetailsSelectItem {
  Contact = 'Contact',
  Campaign = 'Campaign',
  Building = 'Building',
  Inquiry = 'Inquiry',
}

export interface LinkFields {
  linkUrl: string;
  label: string;
}
