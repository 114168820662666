import { ApplyInquiry, TourInquiry, CampaignBuilding } from 'types';

import { inquiryAdapter } from '../adapters';

interface InquiriesListAdapterProps {
  scheduleTourInquiries: CampaignBuilding['scheduleTourInquiries'];
  applyInquiries: CampaignBuilding['applyInquiries'];
}

export function inquiriesListAdapter({
  scheduleTourInquiries = [],
  applyInquiries = [],
}: InquiriesListAdapterProps): (TourInquiry | ApplyInquiry)[] {
  const tourList = inquiryAdapter(scheduleTourInquiries, 'tour');
  const applyInquiriesList = inquiryAdapter(applyInquiries, 'application');

  return [...tourList, ...applyInquiriesList];
}
