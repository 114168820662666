import { ReactElement } from 'react';
import { useMutation } from 'react-query';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { alert } from 'components';
import { Campaign, CampaignBuilding, TourFormValues } from 'types';

import { TourForm } from '../TourForm';
import { createTourDefaultValues } from './config';

interface CreateTourProps {
  handleClose: () => void;
  isOpen: boolean;
  buildingId?: CampaignBuilding['id'];
  campaignId?: Campaign['id'];
}

export const CreateTour = ({
  handleClose,
  isOpen,
  buildingId,
  campaignId,
}: CreateTourProps): ReactElement => {
  const { mutateAsync: createTourMutation } = useMutation(API_HANDLERS.INQUIRIES.CREATE_TOUR, {
    onSuccess: () => {
      queryClient.invalidateQueries('contact');
    },
  });

  const handleSubmit = async (values: TourFormValues) => {
    if (!buildingId || !campaignId) {
      return;
    }

    await createTourMutation({ campaignId, buildingId, data: { ...values } });

    alert.success('Tour successfully created');
  };

  return (
    <TourForm
      title="Tour"
      onSubmit={handleSubmit}
      handleClose={handleClose}
      isOpen={isOpen}
      defaultValues={createTourDefaultValues}
    />
  );
};
