import { Box, styled, Typography } from '@mui/material';

export const PageHeader = styled(Box)`
  grid-area: header;
  height: 60px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  border-bottom: 1px solid #e0e4e5;
  padding: 16px 25px 16px 27px;
`;

export const ControlW = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const ContactName = styled(Typography)`
  font-weight: 600;
  font-size: 28px;
  color: #191c1e;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 1;
  flex-grow: 1;
`;

export const SearchW = styled(Box)`
  position: relative;
  display: flex;
  margin-right: 54px;
`;
