import { useCallback, useContext } from 'react';

import { SelectedItemState } from 'features/contactDetails';
import {
  SelectedUnassignedBuildingIdState,
  selectUnassignedBuilding,
} from 'features/unassignedBuildings/context';
import { useDispatch } from 'hooks';
import { ContactDetailsSelectItem } from 'types';

interface UseUnassignedBuildingsListReturn {
  selectedBuildingId: string | undefined;
  selectedItemType: ContactDetailsSelectItem;
  handleSelectBuilding: (buildingId: string) => void;
}

export function useUnassignedBuildingsList(): UseUnassignedBuildingsListReturn {
  const dispatch = useDispatch();

  const selectedBuildingId = useContext(SelectedUnassignedBuildingIdState);
  const selectedItemType = useContext(SelectedItemState);

  const handleSelectBuilding = useCallback(
    (buildingId: string) => {
      dispatch(selectUnassignedBuilding({ buildingId }));
    },
    [dispatch],
  );

  return { selectedBuildingId, selectedItemType, handleSelectBuilding };
}
