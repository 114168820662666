import { Box, styled, Typography } from '@mui/material';

import { ServicePreferences } from 'types';

export const DescriptionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const Title = styled(Typography)(({ theme }) => ({
  maxWidth: '324px',
  marginBottom: '4px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: 600,
  cursor: 'pointer',
  color: theme.palette.black.main,
}));

export const DateCaption = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.light,
  fontSize: '12px',
}));

export const ServicePreferencesBlock = styled(Box)<{ variant: ServicePreferences }>(
  ({ theme, variant }) => {
    const color =
      variant === ServicePreferences.GuidedSearch
        ? theme.palette.primary.main
        : theme.palette.info.main;

    return {
      display: 'inline-block',
      padding: '1px 3px',
      border: `1px solid ${color}`,
      borderRadius: '4px',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '600',
      color,
    };
  },
);
