import { MenuItem, styled } from '@mui/material';
import { CSSProperties } from 'react';

import { getMenuItemsStyles, getMenuWrapStyles } from 'styles/menu';

interface OptionsContainerProps {
  maxHeight?: string;
  minWidth?: CSSProperties['minWidth'];
  right?: CSSProperties['right'];
}

export const OptionsContainer = styled('div')<OptionsContainerProps>(
  ({ theme, maxHeight, minWidth = '100%', right }) => ({
    position: 'absolute',
    minWidth,
    top: '100%',
    right,
    zIndex: 5000,
    ...(maxHeight && {
      maxHeight: maxHeight,
      overflow: 'auto',
    }),
    ...getMenuWrapStyles(theme),
  }),
);

export const OptionItem = styled(MenuItem)(({ theme }) => getMenuItemsStyles(theme));
