export async function getBlobFile(fileUrl: string): Promise<string> {
  return fetch(fileUrl, { method: 'GET', mode: 'cors' })
    .then((response) => response.blob())
    .then((blob) => window.URL.createObjectURL(blob));
}

export async function handleFileDownload(
  fileUrl: string,
  contentType?: string,
  fileName?: string,
): Promise<void> {
  const url = await getBlobFile(fileUrl);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', contentType?.replace('/', '.') || fileName || 'file');
  link.click();
  link.remove();
}
