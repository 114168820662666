import { ReactElement, useCallback, useContext } from 'react';

import { SelectedItemState } from 'features/contactDetails/context/ContactDetailsPageContext';
import { SelectedInquiryId, selectInquiry } from 'features/inquiry';
import { useDispatch } from 'hooks';
import {
  ContactDetailsSelectItem,
  UnassignedBuildingApplyInquiry,
  UnassignedBuildingScheduleTourInquiry,
  ApplyInquiry,
  TourInquiry,
} from 'types';

import { UnassignedBuildingInquiryItem } from '../UnassignedBuildingInquiryItem';

interface InquiryListProps {
  inquiries: Array<UnassignedBuildingScheduleTourInquiry | UnassignedBuildingApplyInquiry>;
}

export function UnassignedBuildingInquiriesList({ inquiries }: InquiryListProps): ReactElement {
  const dispatch = useDispatch();

  const selectedInquiryId = useContext(SelectedInquiryId);
  const selectedItemType = useContext(SelectedItemState);

  const handleSelectInquiry = useCallback(
    (inquiryId: ApplyInquiry['id'] | TourInquiry['id']) => dispatch(selectInquiry(inquiryId)),
    [dispatch],
  );

  return (
    <>
      {inquiries.map((inquiry) => (
        <UnassignedBuildingInquiryItem
          key={inquiry.id}
          inquiry={inquiry}
          isSelected={
            selectedInquiryId === inquiry.id &&
            selectedItemType === ContactDetailsSelectItem.Inquiry
          }
          handleSelectInquiry={handleSelectInquiry}
        />
      ))}
    </>
  );
}
