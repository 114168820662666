import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { queryClient } from 'api';
import { API_HANDLERS } from 'api/apiHandlers';
import { AxiosResponseError, CampaignCreateParams, CampaignCreateResponseSuccess } from 'api/types';
import { alert } from 'components';
import { CampaignFormDefaultValues, CampaignFormValues } from 'types';
import { determineIfIsError, showResponseError } from 'utils';

import { campaignFormAdapter, defaultValuesAdapter } from './adapters';
import { defaultValues } from './constants';

interface UseCampaignCreateFormProps {
  handleClose: () => void;
  contactFullName: string;
}

interface UseCampaignCreateFormReturn {
  defaultValues: CampaignFormDefaultValues;
  handleSubmit: (values: CampaignFormValues) => Promise<void>;
  isLoading: boolean;
}

export function useCampaignCreateForm({
  contactFullName,
}: UseCampaignCreateFormProps): UseCampaignCreateFormReturn {
  const { contactId = '' } = useParams<string>();

  const { mutateAsync: createCampaignMutation, isLoading } = useMutation<
    AxiosResponse<CampaignCreateResponseSuccess>,
    AxiosResponseError,
    CampaignCreateParams
  >(({ data }: CampaignCreateParams) => API_HANDLERS.CAMPAIGN.CREATE(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('contact');
    },
  });

  async function handleSubmit(fromValues: CampaignFormValues) {
    const response = await createCampaignMutation({
      data: { ...campaignFormAdapter(fromValues), contactId },
      contactId,
    });

    if (determineIfIsError(response)) {
      showResponseError(response);
    } else {
      alert.success('Campaign successfully created');
    }
  }

  const defaultFormValues = useMemo(
    () =>
      defaultValuesAdapter({
        defaultValues,
        contactFullName,
      }),
    [contactFullName],
  );

  return {
    defaultValues: defaultFormValues,
    handleSubmit,
    isLoading,
  };
}
