import { Box, styled, Typography, Link, Badge } from '@mui/material';

export const ChatItemW = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 0',
  borderBottom: `1px solid ${theme.palette.secondary.p020}`,
}));

export const BuildingInfoSection = styled(Box)(() => ({
  maxWidth: '232px',
  marginLeft: '14px',
}));

export const LeftSideSectionW = styled(Box)(() => ({
  display: 'flex',
}));

export const TextOverflow = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: '14px',
}));

export const BuildingName = styled(TextOverflow)(() => ({
  fontWeight: 600,
}));

export const LastMessageText = styled(TextOverflow)(() => ({
  fontWeight: 500,
}));

export const ChatLink = styled(Link)(() => ({
  cursor: 'pointer',
}));

export const DateCaption = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.light,
  fontSize: '12px',
  justifySelf: 'end',
}));

export const StyledBadge = styled(Badge)(() => ({
  '.BaseBadge-badge': {
    borderRadius: '6px',
    height: '16px',
    fontSize: '9px',
    padding: '8px 4px',
    border: `3px solid #fff`,
  },
}));

export const BadgeContainer = styled(Box)`
  position: absolute;
  top: -8px;
  right: -8px;
  min-width: 22px;
  height: 22px;
  z-index: 1300;

  display: flex;
  justify-content: center;
  align-items: center;
`;
