import { Box, Chip, styled } from '@mui/material';

export const ChipW = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  padding: `${theme.spacing(1)} 0`,
}));

export const CSSChip = styled(Chip)(({ color, theme }) => ({
  fontSize: '14px',
  padding: '4px 12px',
  color: color === 'primary' ? theme.palette.white.main : theme.palette.black.main,
  background: color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.p020,

  '&:hover': {
    background: color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.p030,
  },
}));
