import { BaseFormMode, MessageTemplateFormDefaultValues } from 'types';

export const MESSAGE_TEMPLATE_FORM_DEFAULT_VALUES: MessageTemplateFormDefaultValues = {
  name: '',
  type: '',
  event: '',
  subject: '',
  body: '',
};

export const MESSAGE_TEMPLATE_FORM_TITLE: Record<BaseFormMode, string> = {
  create: 'New message template',
  edit: 'Edit message template',
};
