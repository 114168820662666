import { Box, styled } from '@mui/material';

export const ListItemW = styled(Box, {
  shouldForwardProp: (propname) => propname !== 'isReceived',
})<{ isReceived: boolean }>(({ isReceived }) => ({
  display: 'flex',
  gap: '8px',
  flexDirection: isReceived ? 'row' : 'row-reverse',
  alignItems: 'flex-end',
}));
