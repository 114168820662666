import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ReactElement, useState } from 'react';

import { Checkbox } from 'components';
import { Employee, EmployeeRole, SelectOption } from 'types';

import { useChangeRoleMutation } from './hooks';
import { FormControlW } from './styled';
import { inputProps, selectStyles, MenuProps } from './stylesConfig';
import { transformSelectedValues } from './utils';

export interface EmployeeRoleSelectProps {
  defaultValues: EmployeeRole[];
  employeeId: Employee['id'];
  disabled?: boolean;
  options: SelectOption<EmployeeRole>[];
}

export function EmployeeRoleSelect({
  defaultValues,
  employeeId,
  disabled = false,
  options,
}: EmployeeRoleSelectProps): ReactElement {
  const [roles, setRoles] = useState<EmployeeRole[]>(defaultValues);
  const [isOpen, setIsOpen] = useState(false);

  const { handleRoleChange, isLoading } = useChangeRoleMutation();

  const handleChange = (event: SelectChangeEvent<typeof roles>) => {
    const {
      target: { value },
    } = event;
    // handle change from Collaborator to any other role
    if (Array.isArray(value) && roles.length === 1 && roles[0] === EmployeeRole.COLLABORATORS) {
      const newValue = value.filter((role) => role !== EmployeeRole.COLLABORATORS);

      handleRoleChange({ employeeId, roles: newValue, cb: () => setRoles(newValue) });
      return;
    }

    // handle change from any other role to Collaborator
    if (!roles.includes(EmployeeRole.COLLABORATORS) && value.includes(EmployeeRole.COLLABORATORS)) {
      handleRoleChange({
        employeeId,
        roles: [EmployeeRole.COLLABORATORS],
        cb: () => setRoles([EmployeeRole.COLLABORATORS]),
      });
      return;
    }

    handleRoleChange({
      employeeId,
      roles: value as EmployeeRole[],
      cb: () => setRoles(value as EmployeeRole[]),
    });
  };

  return (
    <FormControlW variant="outlined">
      <Select
        labelId="user-roles"
        id="user-roles-checkbox"
        multiple
        value={roles}
        onChange={handleChange}
        renderValue={transformSelectedValues}
        MenuProps={MenuProps}
        inputProps={inputProps}
        sx={selectStyles}
        disabled={disabled}
        open={isOpen}
        onClick={(e) => (disabled ? e.preventDefault() : setIsOpen(!isOpen))}
      >
        {options.map(({ label, value, isDisabled }) => (
          <MenuItem
            disabled={isDisabled || isLoading}
            key={value}
            value={value}
            sx={{ padding: '10px 16px', fontSize: '14px' }}
          >
            <Checkbox style={{ padding: '0 9px 0 0' }} checked={roles.indexOf(value) > -1} />
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControlW>
  );
}
