import { InquiryType } from 'types';

export function singleInquiryAdapter<T extends { inquiryType: InquiryType }>(
  inquiry: T,
  type: InquiryType,
): T {
  inquiry.inquiryType = type;

  return inquiry;
}
