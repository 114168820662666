import { ReactElement, useCallback, useContext } from 'react';

import { BuildingDetails, BuildingPreview } from 'features/buildings/components';
import { SelectedItemState } from 'features/contactDetails/context/ContactDetailsPageContext';
import { useDispatch } from 'hooks';
import { ContactDetailsSelectItem, WishlistBuilding as IWishlistBuilding } from 'types';
import { getFormattedBuildingAddress } from 'utils';

import { SelectedWishlistBuildingIdState, selectWishlistBuilding } from '../../context';
import { BuildingW } from './styled';

interface WishlistBuildingProps {
  building: IWishlistBuilding;
}

export function WishlistBuilding({ building }: WishlistBuildingProps): ReactElement {
  const dispatch = useDispatch();

  const selectedBuildingId = useContext(SelectedWishlistBuildingIdState);

  const selectedItemType = useContext(SelectedItemState);

  const handleSelectBuilding = useCallback(
    (buildingId: IWishlistBuilding['id']) => dispatch(selectWishlistBuilding({ buildingId })),
    [dispatch],
  );

  return (
    <BuildingW
      onClick={() => handleSelectBuilding(building.id)}
      isSelected={
        selectedBuildingId === building.id && selectedItemType === ContactDetailsSelectItem.Building
      }
    >
      <BuildingPreview source={building.source} photo={building.data.photo} />

      <BuildingDetails
        address={getFormattedBuildingAddress(building.address, building.data.city)}
        name={building.name}
      />
    </BuildingW>
  );
}
