import { useContext } from 'react';

import { AuthContext, AuthContextValues } from 'context';

type UseAuthReturn = AuthContextValues;

function useAuth(): UseAuthReturn {
  return useContext(AuthContext);
}

export default useAuth;
